import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
require("react-datepicker/dist/react-datepicker.css");
import MaskedTextInput from "react-text-mask";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      dateFormat={["dd-MM-yyyy","dd/MM/yyyy","dd.MM.yyyy"]}
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        console.log(val)

        setFieldValue(field.name, val);
      }}
      customInput={
        <MaskedTextInput
          type="text"
          mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
        />
      }
      locale="fr"   
    />
  );
};

export default DatePickerField;
