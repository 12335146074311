function generateOverlappingMarker(lon, lat, index, total) {
	let newLat = 0;
	let newLon = 0;

	let a = 360.0 / total;
	newLat = lat + -0.000006 * Math.cos(((+a * index) / 180) * Math.PI); //x
	newLon = lon + -0.000006 * Math.sin(((+a * index) / 180) * Math.PI); //Y
	return [newLon, newLat];
}

exports.generateOverlappingMarker = generateOverlappingMarker;
