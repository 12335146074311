function getGroupementColisState(state) {
  let etat = "";

  switch (state) {
    case "EAE":
      etat = "En attente d’enlèvement";
      break;
    case "EAL":
      etat = "En cours de livraison";
      break;
    case "C":
      etat = "Collecté";
      break;
    case "E":
      etat = "Enlevé";
      break;
    case "L":
      etat = "Livré";
      break;
    case "ER":
      etat = "Endommagé";
      break;
    case "CRF":
      etat = "Refusé";
      break;
    case "AV":
      etat = "Avisé";
      break;
    case "CR":
      etat = "Non commandé";
      break;
    case "MVAD":
      etat="Adresse éronnée";
      break;
  }

  return etat;
}

function getGroupementColisStateCss(state) {
  let css_class_etat = "";

  switch (state) {
    case "EAE":
      css_class_etat = "attente";
      break;
    case "EAL":
      css_class_etat = "livraison";
      break;
    case "C":
      css_class_etat = "collecte";
      break;
    case "E":
      css_class_etat = "annule";
      break;
    case "L":
      css_class_etat = "pec";
      break;
    case "ER":
      css_class_etat = "livre";
      break;
    case "CRF":
      css_class_etat = "refuse";
      break;
    case "AV":
      css_class_etat = "absent";
      break;
    case "CR":
      css_class_etat = "livre";
      break;
    case "MVAD" :
      css_class_etat ="Adresse éronnée";
      break;
  }

  return css_class_etat;
}

function getGroupementColisStateHex(state) {
  let etat = "";

  switch (state) {
    case "30":
      etat = "En attente d’enlèvement";
      break;
    case "31":
      etat = "Collecté";
      break;
    case "32":
      etat = "Annulé";
      break;
    case "33":
      etat = "Colis enlevé";
      break;
    case "34":
      etat = "Livré";
      break;
    case "35":
      etat = "Reporté";
      //TO ASK CLIENT FOR MORE INFOS
      //css_class_etat = "livre";
      break;
    case "36":
      etat = "Colis manquant";
      //TO ASK CLIENT FOR MORE INFOS
      //css_class_etat = "livre";
      break;
    case "37":
      etat = "Refusé";
      break;
    case "38":
      etat = "Avisé";
      break;
    case "50":
      etat = "Partiellement collecté";
      break;
    case "51":
      etat = "Partiellement livré";
      break;
    case "52":
      etat = "Partiellement refusé";
      break;
    case "53":
      etat ="Adresse éronnée";
      break;
    case "54":
      etat ="En cours de livraison";
      break;
  }

  return etat;
}

function getGroupementColisStateHexCss(state) {
  let css_class_etat = "";

  switch (state) {
    case "30":
      css_class_etat = "attente";
      break;
    case "31":
      css_class_etat = "collecte";
      break;
    case "32":
      css_class_etat = "annule";
      break;
    case "33":
      css_class_etat = "pec";
      break;
    case "34":
      css_class_etat = "livre";
      break;
    case "35":
      css_class_etat = "Reporté";
      //TO ASK CLIENT FOR MORE INFOS
      //css_class_etat = "livre";
      break;
    case "36":
      css_class_etat = "cm";
      break;
    case "37":
      css_class_etat = "refuse";
      break;
    case "38":
      css_class_etat = "absent";
      break;
    case "50":
      css_class_etat = "partiellement_collecte";
      break;
    case "51":
      css_class_etat = "partiellement_livre";
      break;
    case "52":
      css_class_etat = "partiellement_refuse";
      break;
    case "53":
      css_class_etat ="Adresse éronnée";
      break;
    case "54":
      css_class_etat ="En cours de livraison";
      break;
  }

  return css_class_etat;
}

function getGroupementColisTraite(traitehex) {
  let traite = "";

  switch (traitehex) {
    case "30":
      traite = "Non";
      break;
    case "31":
      traite = "Oui";
      break;
  }

  return traite;
}

function getGroupementColisTraiteCss(traitehex) {
  let css_class_traite = "";

  switch (traitehex) {
    case "30":
      css_class_traite = "nontraite";
      break;
    case "31":
      css_class_traite = "traite";
      break;
  }

  return css_class_traite;
}

exports.getGroupementColisState = getGroupementColisState;
exports.getGroupementColisStateCss = getGroupementColisStateCss;
exports.getGroupementColisStateHex = getGroupementColisStateHex;
exports.getGroupementColisStateHexCss = getGroupementColisStateHexCss;
exports.getGroupementColisTraite = getGroupementColisTraite;
exports.getGroupementColisTraiteCss = getGroupementColisTraiteCss;
