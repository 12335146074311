/* eslint-disable import/first */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logo from "asset/images/citycolis-holding-logo.svg";
import { Row, Col, Table, Container } from "react-bootstrap";
const dateFormatConvert = require("helpers/dateFormatConvert");
import html2canvas from "html2canvas";
var Barcode = require("react-barcode");
/*CSS*/
import "./etiquetteThermique.css";
const getColisInfos = require("helpers/getColisInfos");

export class EtiquetteNormal extends React.Component {
	constructor(props) {
		super(props);
		this.EtiquetteNormal = this.EtiquetteNormal.bind(this);
	}

	EtiquetteNormal() {
		let etiquetteCounter = 1;
		return (
			<div>
				<Container>
					{this.props.colis.map((dynamicData, Key) => {
						let keys = Object.keys(dynamicData);
						let d = dynamicData;
						let lotId = "";
						return d[keys].map((data, index) => {
							//Visualization
							if (index === 0 && getColisInfos.checkLotPrincipalId(data)) {
								lotId = data.colis_id;
							}
							//provisional lot
							if (data.lotId) {
								lotId = data.lotId;
							}
							return (
								<div
									className={
										"expediteur-infos " +
										(etiquetteCounter++ % 2 === 0 ? "page-break " : "")
									}
									key={"partie_1_" + data.colis_id}
								>
									<Row>
										<Col xs={6}>
											<div>{data.groupement_id}</div>
										</Col>
										<Col xs={6}>
											<div
												style={{
													float: "right",
												}}
											>
												{data.groupement_id}
											</div>
										</Col>
									</Row>
									<Row>
										<Col xs={2}>{this.props.logo}</Col>
										<Col
											sm={6}
											className="d-flex justify-content-center align-items-flex-start"
										>
											<Barcode
												value={data.colis_id}
												width={3}
												height={110}
												fontSize={25}
											/>
										</Col>
										<Col
											xs={4}
											className="etiquettes-citycolis-infos pl-5 pt-2"
										>
											<div>{this.props.agence_name}</div>
											<div>{this.props.agence_address}</div>
											<div>{this.props.agence_ville}</div>
											<div>Tél:{this.props.agence_phone}</div>
										</Col>
									</Row>

									<Row>
										<Col
											sm={4}
											className="d-flex flex-column justify-content-center"
										>
											<div>Expéditeur : {this.props.expediteur}</div>
											<div>Téléphone : {this.props.telephone}</div>
										</Col>
										<Col
											sm={4}
											className="date-poids flex-column justify-content-center"
										>
											<div>{this.props.datedemande}</div>
											{/* TODO : Si > 1000 kg afficheer t pour tonnes */}
											<div>
												{data.poidscolis} kg{" "}
												{data.estLot && !data.colis_id.startsWith("L")
													? " - " + this.props.lot_package_total + " colis"
													: ""}
											</div>
										</Col>
										<Col
											xs={4}
											className="d-flex justify-content-center align-items-flex-start"
										>
											{lotId ? <Barcode value={lotId} /> : null}
										</Col>
									</Row>

									<div className="destinataire-frame">
										<Row>
											<Col sm={6}>
												<h2>Destinataire</h2>
												<Table>
													<tbody>
														<tr>
															<td>Nom :</td>
															<td>{data.nomdest}</td>
														</tr>
														<tr>
															<td>Prénom :</td>
															<td>{data.prenomdest}</td>
														</tr>
														<tr>
															<td>Adresse :</td>
															<td>{data.adressedest}</td>
														</tr>
														<tr>
															<td>Code postal :</td>
															<td>{data.codepostaldest}</td>
														</tr>
														<tr>
															<td>Ville :</td>
															<td>{data.villedest}</td>
														</tr>
														<tr>
															<td>Téléphone :</td>
															<td>{data.telephonedest}</td>
														</tr>
													</tbody>
												</Table>
											</Col>
											<Col sm={6} className="">
												<div className="informations-particulieres">
													Informations particulières :<br></br>
													<br></br>
													{data.infosParticulieres}
												</div>
											</Col>
										</Row>
										<Row>
											<Col xs={6}>
												<div>{data.groupement_id}</div>
											</Col>
											<Col xs={6}>
												<div
													style={{
														float: "right",
													}}
												>
													{data.groupement_id}
												</div>
											</Col>
										</Row>
									</div>
								</div>
							);
						});
					})}
				</Container>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.EtiquetteNormal />
			</div>
		);
	}
}

export default withRouter(EtiquetteNormal);
