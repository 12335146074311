/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");
import SummaryTableCA from "./summaryTableCA";
import ColisbyAgenceTable from "./colisbyAgenceTable";
import CalculSum from "./calculSum";
import { Formik } from "formik";

/*Css*/

export class affichageCAOrigine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			colisclient: {},
			profils: {},
			colis: [],
			array_interval: {},
			nb_total: {},
			sum_total: {},
			client_list: [],
			filter_array: [],
			lesMois: config.lesMois,
			messages: "En cours de chargement...",
			sup_hors_site: config.hors_site_sup_value,
			origine_filter_name: "",
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}

		config
			.apiFetch(
				config.Api_Url +
					"/colis/getAllColisbyDateAndOrigine/" +
					this.props.match.params.agence +
					"/" +
					this.props.match.params.month +
					"/" +
					this.props.match.params.year
			)
			.then((response) => response.json())
			.then((colis) => {
				if (colis.data.length == 0) {
					this.setState({
						messages:
							"Aucun colis ou il n'y a pas de profils de facturation correspondant pour ce client",
					});
				}
				let temp_client = "";
				let temp_array = [];
				let temp_client_obj = {};
				let client_list = [];
				let agence_cible_list = [];
				let filter_array = [];
				colis["data"].forEach((c) => {
					if (temp_client == c.id_client) {
						//adding colis to the array of the current client
						temp_array.push(c);
					} else {
						//adding to client_id list
						client_list.push(temp_client);
						//adding the current colis array to the object
						temp_client_obj[temp_client] = temp_array;
						//update client_id
						temp_client = c.id_client;
						//starting a new array of colius for the next client
						temp_array = [];
						temp_array.push(c);
					}
					if (!agence_cible_list.includes(c.agence_cible)) {
						agence_cible_list.push(c.agence_cible);
						filter_array.push(
							<option
								key={"option_filter_" + c.agence_cible}
								value={c.agence_cible}
							>
								{c.cible}
							</option>
						);
					}
					this.setState({ origine_filter_name: c.origine });
				});
				//add the last colis array to the object, then delete the empty one
				temp_client_obj[temp_client] = temp_array;
				delete temp_client_obj[""];

				client_list.push(temp_client);
				client_list.shift();

				this.setState({
					colisclient: temp_client_obj,
					client_list: client_list,
					filter_array: filter_array,
				});

				config
					.apiFetch(config.Api_Url + "/profil/getProfilbyIdClientList", {
						method: "POST",
						body: JSON.stringify({
							client_list: this.state.client_list,
						}),
						headers: {
							"Content-Type": "application/json",
						},
					})
					.then((response) => response.json())
					.then((data) => {
						let temp_client = "";
						let temp_array = [];
						let client_list = [];
						let temp_array_interval = [];
						let temp_nb_total = [];
						let temp_sum_total = [];
						let temp_client_obj = {};
						let temp_array_interval_obj = {};
						let temp_nb_total_obj = {};
						let temp_sum_total_obj = {};
						if (data["data"]) {
							data["data"].forEach((c) => {
								if (temp_client == c.id_client) {
									//adding colis to the array of the current client
									temp_array.push(c);
									temp_array_interval.push(c.max);
									temp_nb_total.push(0);
									temp_sum_total.push(0);
								} else {
									//adding to client_id list
									client_list.push(temp_client);
									//adding the current colis array to the object
									temp_client_obj[temp_client] = temp_array;
									temp_array_interval_obj[temp_client] = temp_array_interval;
									temp_nb_total_obj[temp_client] = temp_nb_total;
									temp_sum_total_obj[temp_client] = temp_sum_total;
									//update client_id
									temp_client = c.id_client;
									//starting a new array of colius for the next client
									temp_array = [];
									temp_array_interval = [];
									temp_nb_total = [];
									temp_sum_total = [];
									temp_array.push(c);
									temp_array_interval.push(c.max);
									temp_nb_total.push(0);
									temp_sum_total.push(0);
								}
							});
							//add the last colis array to the object, then delete the empty one
							temp_client_obj[temp_client] = temp_array;
							temp_array_interval_obj[temp_client] = temp_array_interval;
							temp_nb_total_obj[temp_client] = temp_nb_total;
							temp_sum_total_obj[temp_client] = temp_sum_total;
							delete temp_client_obj[""];
							delete temp_array_interval_obj[""];
							delete temp_nb_total_obj[""];
							delete temp_sum_total_obj[""];
							this.setState({
								profils: temp_client_obj,
								array_interval: temp_array_interval_obj,
								sum_total: temp_sum_total_obj,
								nb_total: temp_nb_total_obj,
							});
						}
					});
			});
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	count_array(array) {
		let count = 0;
		for (let i = 0; i < array.length; ++i) {
			if (array[i] == 0) count++;
		}
		return count;
	}

	render() {
		if (
			//Object.keys(this.state.profils).length == 0 ||
			Object.keys(this.state.colisclient).length == 0
			//Object.keys(this.state.array_interval).length == 0 ||
			//Object.keys(this.state.sum_total).length == 0 ||
			//Object.keys(this.state.nb_total).length == 0
		)
			return (
				<div>
					<NavAdmin />
					<div className="container-fluid">
						<div className="text-center">
							<h2>{this.state.messages}</h2>
						</div>
					</div>
				</div>
			);
		else {
			let item = [];
			for (var col in this.state.colisclient) {
				item.push(
					<div key={"div_" + col}>
						<CalculSum
							details={this.state.profils[col]}
							array_interval={this.state.array_interval[col]}
							nb_total={this.state.nb_total[col]}
							sum_total={this.state.sum_total[col]}
							colis={this.state.colisclient[col]}
							sup_hors_site={this.state.sup_hors_site}
						/>

						<SummaryTableCA
							details={this.state.profils[col]}
							array_interval={this.state.array_interval[col]}
							nb_total={this.state.nb_total[col]}
							sum_total={this.state.sum_total[col]}
							client={this.state.colisclient[col][0]}
							sup_hors_site={this.state.sup_hors_site}
						/>
						<div
							style={{
								height: "300px",
							}}
						></div>
					</div>
				);
			}

			return (
				<div>
					<NavAdmin />
					<div className="container-fluid">
						<div className="text-center">
							<h1>Facturation</h1>
							<h2>
								Récapitulatif de{" "}
								{this.state.lesMois[this.props.match.params.month - 1] +
									" " +
									this.props.match.params.year}{" "}
								de {this.state.origine_filter_name}
								<br />
							</h2>
							<Button
								size="sm"
								variant="secondary"
								className="no-print"
								onClick={() => window.print()}
							>
								Imprimer cette facture
							</Button>
						</div>
						<div className="text-center">
							<Formik
								initialValues={{
									cible: "",
								}}
								enableReinitialize={true}
								onSubmit={(values) => {
									this.props.history.push(
										"/admin/facturationhorssite/affichagecaoriginepercible/" +
											this.props.match.params.agence +
											"/" +
											values.cible +
											"/" +
											this.props.match.params.month +
											"/" +
											this.props.match.params.year
									);
								}}
							>
								{(props) => {
									const {
										status,
										handleChange,
										handleSubmit,
										isSubmitting,
										/* and other goodies */
									} = props;
									return (
										<Form
											onSubmit={handleSubmit}
											autoComplete="off"
											className="no-print"
										>
											<Row>
												<Col md={4}></Col>
												<Col md={4}>
													<Form.Label className="required">Filtre :</Form.Label>
													<Form.Control
														required
														as="select"
														name="cible"
														onChange={handleChange}
													>
														<option></option>
														{this.state.filter_array}
													</Form.Control>
													<Button
														size="sm"
														type="submit"
														disabled={isSubmitting}
														variant="primary"
														className="mt-3"
													>
														Valider
													</Button>
												</Col>
											</Row>
										</Form>
									);
								}}
							</Formik>
						</div>
						<div>
							{item}

							<Button
								onClick={this.handleClick}
								size="sm"
								className="pr-3 back-button mb-5 no-print"
							>
								<i className="fas fa-angle-left mr-2"></i>
								Retour
							</Button>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(affichageCAOrigine);
