/* eslint-disable import/first */
import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Table, Container } from "react-bootstrap";
const dateFormatConvert = require("helpers/dateFormatConvert");
var Barcode = require("react-barcode");
/*CSS*/
import "./etiquetteThermique.css";
const getColisInfos = require("helpers/getColisInfos");

export class EtiquetteThermique extends React.Component {
	constructor(props) {
		super(props);
		this.EtiquetteThermique = this.EtiquetteThermique.bind(this);
	}

	EtiquetteThermique() {
		let etiquette_list_items = [];

		return (
			<div className="myDivToPrint">
				<Container>
					{this.props.colis.map((dynamicData, Key) => {
						let keys = Object.keys(dynamicData);
						let d = dynamicData;
						let lotId = "";
						return d[keys].map((data, index) => {
							//Visualization
							if (index === 0 && getColisInfos.checkLotPrincipalId(data)) {
								lotId = data.colis_id;
							}
							//provisional lot
							if (data.lotId) {
								lotId = data.lotId;
							}
							etiquette_list_items.push(
								<div
									className="expediteur-infos-thermique page-break"
									key={"partie_1_" + data.colis_id}
								>
									<Row>
										<Col xs={6}>
											<div>{data.groupement_id}</div>
										</Col>
										<Col xs={6}>
											<div
												style={{
													float: "right",
												}}
											>
												{data.groupement_id}
											</div>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>{this.props.logo}</Col>
										<Col
											xs={6}
											className="etiquettes-citycolis-infos pt-2 d-flex flex-column justify-content-center"
										>
											<div>{this.props.agence_name}</div>
											<div>{this.props.agence_address}</div>
											<div>{this.props.agence_ville}</div>
											<div>Tél:{this.props.agence_phone}</div>
										</Col>
									</Row>
									<Row>
										<Col
											xs={6}
											className="d-flex justify-content-center align-items-flex-end"
										>
											{lotId ? <Barcode value={lotId} /> : null}
										</Col>
										<Col
											xs={6}
											className="date-poids flex-column justify-content-center"
										>
											<div>{this.props.datedemande}</div>
											{/* TODO : Si > 1000 kg afficheer t pour tonnes */}
											<div>
												{data.poidscolis} kg{" "}
												{data.estLot && !data.colis_id.startsWith("L")
													? " - " + this.props.lot_package_total + " colis"
													: ""}
											</div>
											{/* 1kg par défaut */}
										</Col>
									</Row>

									<Row>
										<Col
											xs={6}
											className="d-flex flex-column justify-content-around"
										>
											<div>
												<div>Expéditeur : {this.props.expediteur}</div>
												<div>Téléphone : {this.props.telephone}</div>
												{/* <div>Type d'envoi : Colis ou Palette</div> */}
											</div>
											<h3 className="black-font">Destinataire</h3>
										</Col>
										<Col
											xs={6}
											className="d-flex justify-content-center align-items-flex-end"
										>
											<Barcode value={data.colis_id} />
										</Col>
									</Row>

									<div className="destinataire-frame">
										<Row>
											<Col xs={12}>
												<Table>
													<tbody>
														<tr>
															<td className="w-50 black-font">
																Nom : {data.nomdest}
															</td>
															<td className="black-font">
																Prénom : {data.prenomdest}
															</td>
														</tr>
														<tr>
															<td className="tall-td black-font">
																Adresse : {data.adressedest}
															</td>
															<td className="tall-td black-font"></td>
														</tr>
														<tr>
															<td className="black-font">
																Code postal : {data.codepostaldest}
															</td>
															<td className="black-font">
																Ville : {data.villedest}
															</td>
														</tr>
														<tr>
															<td className="black-font">
																Téléphone : {data.telephonedest}
															</td>
															<td></td>
														</tr>
														<tr>
															<td className="tall-td black-font">
																Informations particulières :{" "}
																{data.infosParticulieres}
															</td>
															<td className="tall-td black-font"></td>
														</tr>
													</tbody>
												</Table>
											</Col>
										</Row>
										<Row>
											<Col xs={6}>
												<div>{data.groupement_id}</div>
											</Col>
											<Col xs={6}>
												<div
													style={{
														float: "right",
													}}
												>
													{data.groupement_id}
												</div>
											</Col>
										</Row>
									</div>
								</div>
							);
						});
					})}
					{etiquette_list_items}
				</Container>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.EtiquetteThermique />
			</div>
		);
	}
}

export default withRouter(EtiquetteThermique);
