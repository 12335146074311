import React, { Component } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { NavAdmin } from "components/nav/NavAdmin";
import * as config from "config/config";

/*CSS*/
import "../../../../asset/css/add.css";

class AddLivreur extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agence: [],
			data: [],
		};
	}
	componentDidMount() {
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		if (user_role == "Admin") {
			config
				.apiFetch("/agences")
				.then((response) => response.json())
				.then((data) => {
					let agences = [];
					for (let number = 0; number < data["data"].length; number++) {
						agences.push(
							<option
								key={"option_agences_" + number}
								value={data["data"][number]["agence_id"]}
							>
								{data["data"][number]["agence_name"]}
							</option>
						);
					}
					this.setState({
						agences: [agences],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						data: data["data"][0],
					});
				});
		}
	}
	render() {
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Ajouter un livreur</h1>
							<Formik
								initialValues={{
									nom: "",
									prenom: "",
									portable: "",
									tournee: "",
									agence: "",
								}}
								onSubmit={(values, { setSubmitting }) => {
									config
										.apiFetch(config.Api_Url + "/new/livreurs", {
											method: "POST",
											body: JSON.stringify({
												nom: values.nom,
												prenom: values.prenom,
												portable: values.portable,
												tournee: values.tournee,
												agence:
													user_role == "Admin"
														? values.agence
															? values.agence
															: "NULL"
														: this.state.data.agence_id,
											}),
											headers: {
												"Content-Type": "application/json",
											},
										})
										.then((response) => response.json())
										.then((data) => {
											this.props.history.goBack();
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit}>
										<Form.Label className="required">Nom :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="nom"
											value={values.nom}
											onChange={handleChange}
										/>
										<Form.Label className="required">Prénom :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="prenom"
											value={values.prenom}
											onChange={handleChange}
										/>
										<Form.Label className="required">Portable :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="portable"
											value={values.portable}
											onChange={handleChange}
										/>
										<Form.Label className="required">Tournée :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="tournee"
											value={values.tournee}
											onChange={handleChange}
										/>
										<Form.Label className="required">Agence :</Form.Label>

										{user_role == "Admin" ? (
											<Form.Control
												required
												as="select"
												name="agence"
												value={values.agence || ""}
												onChange={handleChange}
											>
												<option></option>
												{this.state.agences}
											</Form.Control>
										) : (
											<div>
												<Form.Control
													readOnly
													placeholder={this.state.data.agence_name}
												></Form.Control>
											</div>
										)}
										<Button
											className="my-4"
											size="sm"
											type="submit"
											disabled={isSubmitting}
										>
											Enregistrer
										</Button>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(AddLivreur);
