function getTrackingLink(code) {
	let sdg_landnr = code.substr(0, 3);
	let sdg_mandnr = "";
	let sdg_lfdnr = "";
	switch (sdg_landnr) {
		case "250":
			if (code.length == 18) {
				code = code.substr(0, code.length - 4);
			}
			sdg_mandnr = code.substr(3, 3);
			sdg_lfdnr = code.substr(6);
			return (
				process.env.REACT_APP_ETRACE_URL +
				"exa-webtrace/webtrace.aspx?sdg_landnr=" +
				sdg_landnr +
				"&sdg_mandnr=" +
				sdg_mandnr +
				"&sdg_lfdnr=" +
				sdg_lfdnr +
				"&cmd=SDG_SEARCH_EXACT#"
			);
		case "814":
			if (code.length == 15) {
				code = code.substr(0, code.length - 4);
			}
			sdg_mandnr = code.substr(0, 3);
			sdg_lfdnr = code.substr(3);
			return (
				process.env.REACT_APP_ETRACE_URL +
				"exa-webtrace/webtrace.aspx?sdg_landnr=" +
				sdg_landnr +
				"&sdg_mandnr=" +
				sdg_mandnr +
				"&sdg_lfdnr=" +
				sdg_lfdnr +
				"&cmd=SDG_SEARCH_EXACT#"
			);
	}
}

function getTrackingProofLink(code) {
	let sdg_landnr = code.substr(0, 3);
	let sdg_mandnr = "";
	let sdg_lfdnr = "";
	switch (sdg_landnr) {
		case "250":
			if (code.length == 18) {
				code = code.substr(0, code.length - 4);
			}
			sdg_mandnr = code.substr(3, 3);
			sdg_lfdnr = code.substr(6);
			return (
				process.env.REACT_APP_ETRACE_URL +
				"exa-webtrace/webtrace.aspx?sdg_landnr=" +
				sdg_landnr +
				"&sdg_mandnr=" +
				sdg_mandnr +
				"&sdg_lfdnr=" +
				sdg_lfdnr +
				"&cmd=SDG_SEARCH_IMAGE_DIRECT#"
			);
		case "814":
			if (code.length == 15) {
				code = code.substr(0, code.length - 4);
			}
			sdg_mandnr = code.substr(0, 3);
			sdg_lfdnr = code.substr(3);
			return (
				process.env.REACT_APP_ETRACE_URL +
				"exa-webtrace/webtrace.aspx?sdg_landnr=" +
				sdg_landnr +
				"&sdg_mandnr=" +
				sdg_mandnr +
				"&sdg_lfdnr=" +
				sdg_lfdnr +
				"&cmd=SDG_SEARCH_IMAGE_DIRECT#"
			);
	}
}

exports.getTrackingLink = getTrackingLink;
exports.getTrackingProofLink = getTrackingProofLink;
