import React, { Component, useState } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
	Collapse,
	Container,
} from "react-bootstrap";
import * as config from "config/config";

class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.Search = this.Search.bind(this);
		this.state = {
			filter_array: [],
			cible_filter_name: "",
			origine_filter_name: "",
			lesMois: config.lesMois,
		};
	}

	componentDidMount() {
		config
			.apiFetch(
				config.Api_Url +
					"/colis/getAllColisbyDateAndOrigine/" +
					this.props.match.params.agence_origine +
					"/" +
					this.props.match.params.month +
					"/" +
					this.props.match.params.year
			)
			.then((response) => response.json())
			.then((colis) => {
				let filter_array = [];
				let agence_cible_list = [];
				colis["data"].forEach((c) => {
					if (
						!agence_cible_list.includes(c.agence_cible) &&
						c.agence_cible != this.props.match.params.agence_cible
					) {
						agence_cible_list.push(c.agence_cible);
						filter_array.push(
							<option
								key={"option_filter_" + c.agence_cible}
								value={c.agence_cible}
							>
								{c.cible}
							</option>
						);
					} else if (c.agence_cible == this.props.match.params.agence_cible) {
						this.setState({ cible_filter_name: c.cible });
					}
					this.setState({ origine_filter_name: c.origine });
				});
				this.setState({ filter_array: filter_array });
			});
	}

	Search() {
		return (
			<div>
				<Formik
					initialValues={{
						cible: "",
					}}
					enableReinitialize={true}
					onSubmit={(values) => {
						this.props.history.push(
							"/admin/facturationhorssite/affichagefacturationoriginepercible/" +
								this.props.match.params.agence_origine +
								"/" +
								values.cible +
								"/" +
								this.props.match.params.month +
								"/" +
								this.props.match.params.year
						);
						window.location.reload();
					}}
				>
					{(props) => {
						const {
							status,
							handleChange,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						} = props;
						return (
							<Form
								onSubmit={handleSubmit}
								autoComplete="off"
								className="no-print"
							>
								<Row>
									<Col md={4}></Col>
									<Col md={4}>
										<Form.Label className="required">Filtre :</Form.Label>
										<Form.Control
											required
											as="select"
											name="cible"
											onChange={handleChange}
										>
											<option></option>
											{this.state.filter_array}
										</Form.Control>
										<Button
											size="sm"
											type="submit"
											disabled={isSubmitting}
											variant="primary"
											className="mt-3"
										>
											Valider
										</Button>
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
	render() {
		return (
			<div>
				<h1>Facturation</h1>
				<h2>
					Récapitulatif de{" "}
					{this.state.lesMois[this.props.match.params.month - 1] +
						" " +
						this.props.match.params.year}{" "}
					de {this.state.origine_filter_name}
					<br />
					Les commandes sont traitées par {this.state.cible_filter_name}
				</h2>
				<Button
					size="sm"
					variant="secondary"
					className="no-print"
					onClick={() => window.print()}
				>
					Imprimer cette facture
				</Button>
				<this.Search />
			</div>
		);
	}
}

export default withRouter(SearchForm);
