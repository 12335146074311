/* eslint-disable import/first */
import React, { Component } from "react";
import { Table, Form, Button } from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import { withRouter } from "react-router-dom";
const dateFormatConvert = require("helpers/dateFormatConvert");
import Checkbox from "helpers/checkbox";
import { Formik } from "formik";
const calculFacturation = require("helpers/calculFacturation");

export class CalculSumAgence extends React.Component {
	constructor(props) {
		super(props);
		this.CalculSumAgence = this.CalculSumAgence.bind(this);
	}

	getZipCode(code) {
		return code.substring(0, 2);
	}

	CalculSumAgence() {
		//let details = this.props.details[0]["data"];
		let details = this.props.details;

		if (
			!this.props.colis ||
			!details ||
			!this.props.nb_total ||
			!this.props.sum_total ||
			!this.props.array_interval
		) {
			return null;
		} else {
			let nb_total_init = this.props.nb_total;
			let sum_total_init = this.props.sum_total;
			let array_interval_init = [];
			details.forEach(function (d, i) {
				array_interval_init.push(d.max);
				nb_total_init[i] = 0;
				sum_total_init[i] = 0;
			});

			nb_total_init.push(0);
			sum_total_init.push(0);

			return (
				<div className="hidden">
					{this.props.colis.map((data) => {
						let prix = 0;
						let result_getPoidInterval = [];
						let min = 0;
						let max = 0;
						//get price range correspond
						result_getPoidInterval = calculFacturation.getPoidInterval(
							data.poidscolis,
							this.props.array_interval
						);

						let index = result_getPoidInterval[0];

						min = result_getPoidInterval[1];

						max = result_getPoidInterval[2];
						//get package total number array and price sum total array
						let nb_total = this.props.nb_total;
						let sum_total = this.props.sum_total;
						//copy package total number value of the price range
						let temp_nb_total = nb_total[index];
						//add 1 to it
						temp_nb_total++;
						//update package total number for this particular price range
						nb_total[index] = temp_nb_total;
						//if oder (hors site), we need to add a sup amount
						if (data.cible) {
							//copy package total number with sup value of the price range (always the last one)
							let temp_nb_total_sup_hors_site = nb_total[nb_total.length - 1];
							//add 1 to it
							temp_nb_total_sup_hors_site++;
							//update....
							nb_total[nb_total.length - 1] = temp_nb_total_sup_hors_site;
						}

						//this.setState({ nb_total: nb_total });
						//calcul the price
						prix = calculFacturation.getPrixFacturation(data, details, index);

						let sup_hors_site = 0;
						if (data.cible) {
							sup_hors_site = this.props.sup_hors_site;
						}
						let temp_sum_total = sum_total[index];
						let temp_sum_sup_hors_site_total = sum_total[sum_total.length - 1];
						temp_sum_total = temp_sum_total + prix;
						temp_sum_sup_hors_site_total =
							temp_sum_sup_hors_site_total + sup_hors_site;
						sum_total[index] = temp_sum_total;
						sum_total[sum_total.length - 1] = temp_sum_sup_hors_site_total;

						//Get number of colis on a "lot"
						//Because we already count the "lot" itself as a "colis"
						//We remove one from the total number of "colis" on a "lot"
						let colis_dans_lot = this.props.colis_dans_lot;
						let temp_colis_dans_lot = colis_dans_lot[index];

						if (data.colis_dans_lot) {
							temp_colis_dans_lot += data.colis_dans_lot - 1;
						}
						colis_dans_lot[index] = temp_colis_dans_lot;
						return null;
					})}
				</div>
			);
		}
	}
	render() {
		return (
			<div>
				<this.CalculSumAgence />
			</div>
		);
	}
}

export default withRouter(CalculSumAgence);
