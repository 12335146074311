var moment = require("moment"); // require

function getFormattedDateWithHms(date) {
	if (!date || date == "0000-00-00 00:00:00") {
		return "";
	}
	return moment(date).format("DD-MM-YYYY à HH:mm:ss");
}
function getFormattedDate(date) {
	if (!date || date == "0000-00-00 00:00:00") {
		return "";
	}

	return moment(date).format("DD-MM-YYYY");
}

function convertDateForSql(date) {
	date =
		date.getFullYear() +
		"-" +
		("00" + (date.getMonth() + 1)).slice(-2) +
		"-" +
		("00" + date.getDate()).slice(-2);
	return date;
}

function convertDateTimeForSql(date) {
	date = new Date(date);
	date =
		date.getFullYear() +
		"-" +
		("00" + (date.getMonth() + 1)).slice(-2) +
		"-" +
		("00" + date.getDate()).slice(-2) +
		" " +
		("00" + date.getHours()).slice(-2) +
		":" +
		("00" + date.getMinutes()).slice(-2) +
		":" +
		("00" + date.getSeconds()).slice(-2);
	return date;
}

function convertDateHolderForSql(date) {
	var parts = date.split("/");

	var date = parts[2] + "-" + parts[1] + "-" + parts[0];

	return date;
}

exports.getFormattedDate = getFormattedDate;
exports.getFormattedDateWithHms = getFormattedDateWithHms;
exports.convertDateForSql = convertDateForSql;
exports.convertDateHolderForSql = convertDateHolderForSql;
exports.convertDateTimeForSql = convertDateTimeForSql;
