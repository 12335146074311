/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

/*Css*/

export class Livreurs extends React.Component {
	constructor() {
		super();
		this.state = {
			livreurs: [],
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;

		if (user_role == "Admin") {
			config
				.apiFetch("/livreurs")
				.then((response) => response.json())
				.then((livreurs) => {
					this.setState({
						livreurs: [livreurs],
					});
				});
		} else {
			config
				.apiFetch("/livreursAgence/" + user_id)
				.then((response) => response.json())
				.then((livreurs) => {
					this.setState({
						livreurs: [livreurs],
					});
				});
		}
	}

	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<h1>Livreurs</h1>

					<Button
						className="mb-2 mt-4"
						size="sm"
						variant="outline-primary"
						type="submit"
						href="/admin/livreurs/add"
					>
						<i className="fas fa-plus mr-1"></i>
						Ajouter un livreur
					</Button>
					<div>
						<Table responsive bordered hover>
							<thead>
								<tr>
									<th>ID</th>
									<th>Nom Prenom</th>
									<th>Portable</th>
									<th>Tournée</th>
									<th>Agence</th>
									<th>Modification / Activation</th>
								</tr>
							</thead>
							<tbody>
								{this.state.livreurs.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;
									return d[keys].map((data) => {
										let modify_link =
											"/admin/livreurs/modify/" + data.livreur_id;
										let delete_link =
											"/admin/livreurs/delete/" + data.livreur_id;
										return (
											<tr key={data.livreur_id}>
												<td>{data.livreur_id}</td>
												<td>
													{data.nom} {data.prenom}
												</td>
												<td>{data.portable}</td>
												<td>{data.tournee}</td>
												<td>{data.agence_name}</td>
												<td>
													<a
														href={delete_link}
														onClick={(e) => {
															if (
																!window.confirm(
																	"Supprimez " +
																		data.nom +
																		" " +
																		data.prenom +
																		"?"
																)
															)
																e.preventDefault();
														}}
													>
														<i className="fas fa-trash-alt mr-1"></i>
														Supprimer
													</a>
													<a href={modify_link}>
														<i className="fas fa-pencil-alt mr-1"></i>
														Modifier
													</a>
												</td>
											</tr>
										);
									});
								})}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Livreurs);
