/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import { Route, withRouter } from "react-router-dom";
import logo from "asset/images/citycolis-holding-logo.svg";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	Container,
} from "react-bootstrap";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");

var Barcode = require("react-barcode");

export class EtiquetteNormal extends React.Component {
	constructor() {
		super();
		this.state = {
			demande: [],
			client: [],
			expediteur: "",
			telephone: "",
			date: "",
			pagination_fixed: 20,
			nb_colis: 0,
			colis: [],
			id_bordereau: "",
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let date = dateFormatConvert.getFormattedDate(new Date());
		this.setState({ date: date, nb_colis: this.props.match.params.nombre });
		config
			.apiFetch(config.Api_Url + "/new/Etiquette", {
				method: "POST",
				body: JSON.stringify({ id_client: this.props.match.params.id_client }),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => response.json())
			.then(async (data) => {
				if (data.groupement_id && data.id_bordereau) {
					this.setState({
						id_bordereau: data.id_bordereau,
					});
					let counter = this.props.match.params.nombre;
					while (counter > 0) {
						await config
							.apiFetch(config.Api_Url + "/add/addColisFromPublic", {
								method: "POST",
								body: JSON.stringify({
									groupement_id: data.groupement_id,
									poidscolis: 1,
									nomdest: "",
									prenomdest: "",
									adressedest: "",
									codepostaldest: "",
									villedest: "",
									telephonedest: "",
									type: "",
									infosParticulieres: "",
									values: 0,
									typeEnvoi: "",
								}),

								headers: {
									"Content-Type": "application/json",
								},
							})
							.then((response) => response.json())
							.then((data) => {
								let colis = this.state.colis;
								colis.push(data.colis_id);
								this.setState({ colis: colis });
								counter = counter - 1;
							});
					}
				}
			});

		config
			.apiFetch(
				config.Api_Url + "/findbyid/client/" + this.props.match.params.id_client
			)
			.then((response) => response.json())
			.then((client) => {
				let src =
					"data:image/jpg;base64," +
					new Buffer(client["data"][0].agence_logo).toString("base64");

				this.setState({
					expediteur: client["data"][0].nom + client["data"][0].prenom,
					telephone: client["data"][0].telephone,
					logo: <img src={src} style={{ maxHeight: 150, maxWidth: 150 }}></img>,
					agence_address: client["data"][0].agence_address,
					agence_phone: client["data"][0].agence_phone,
					agence_name: client["data"][0].agence_name,
					agence_ville: client["data"][0].agence_ville,
				});
			});
		/*	
    config.apiFetch(
      config.Api_Url +
        "/demande/getGroupementColis/" +
        this.props.match.params.groupement_id
    )
      .then(response => response.json())
      .then(demande => {
        
        this.setState({
          expediteur:
            demande["data"][0].nomExpediteur +
            " " +
            demande["data"][0].prenomExpediteur,
          telephone: demande["data"][0].telephoneExpediteur,
          datereleve: dateFormatConvert.getFormattedDate(
            demande["data"][0].datereleve
          )
        });
      });*/
	}

	render() {
		let borderau_list_items = [];
		let borderau_counter = 0;
		let etiquette_list_items = [];
		let etiquetteCounter = 1;

		return (
			<div>
				<NavAdmin />
				<Container>
					{this.state.colis.forEach((code) => {
						etiquette_list_items.push(
							<div
								className={
									"expediteur-infos " +
									(etiquetteCounter++ % 2 === 0 ? "page-break " : "")
								}
								key={"partie_1_" + code}
							>
								<Row>
									<Col xs={4}>{this.state.logo}</Col>
									<Col
										xs={4}
										className="d-flex justify-content-center align-items-flex-start"
									>
										<Barcode value={code} />
									</Col>
									<Col xs={4} className="etiquettes-citycolis-infos pl-5 pt-2">
										<div>{this.state.agence_name}</div>
										<div>{this.state.agence_address}</div>
										<div>{this.state.agence_ville}</div>
										<div>Tél:{this.state.agence_phone}</div>
									</Col>
								</Row>

								<Row>
									<Col
										xs={4}
										className="d-flex flex-column justify-content-center"
									>
										<div>Expéditeur : {this.state.expediteur}</div>
										<div>Téléphone : {this.state.telephone}</div>
									</Col>
									<Col
										xs={4}
										className="date-poids flex-column justify-content-center"
									>
										<div>{this.state.date}</div>
										{/* TODO : Si > 1000 kg afficher t pour tonnes */}
										{/* <div>1 kg</div> */}
									</Col>
								</Row>

								<div className="destinataire-frame">
									<Row>
										<Col xs={6}>
											<h2>Destinataire</h2>
											<Table>
												<tbody>
													<tr>
														<td className="text-left">Nom :</td>
													</tr>
													<tr>
														<td className="text-left">Prénom :</td>
													</tr>
													<tr>
														<td className="text-left">Adresse :</td>
													</tr>
													<tr>
														<td className="text-left">Code postal :</td>
													</tr>
													<tr>
														<td className="text-left">Ville :</td>
													</tr>
													<tr>
														<td className="text-left">Téléphone :</td>
													</tr>
												</tbody>
											</Table>
										</Col>
										<Col
											xs={6}
											className="d-flex flex-column justify-content-end"
										>
											<div className="informations-particulieres text-left">
												Informations particulières :
											</div>
										</Col>
									</Row>
								</div>
							</div>
						);
					})}
					{etiquette_list_items}

					<h1
						style={{
							pageBreakBefore: "always",
						}}
					>
						Bordereau n°{this.state.id_bordereau}
					</h1>
					<h3 className="text-center pb-4">
						{this.state.expediteur}
						<br></br>
						Enlèvement le : {this.state.date}
					</h3>

					<Table responsive bordered hover>
						<thead>
							<tr>
								<th>N°</th>
								<th>Référence</th>
								<th>Destinataire</th>
								<th>Type</th>
							</tr>
						</thead>
						{this.state.colis.forEach((code) => {
							borderau_list_items.push(
								<tr key={code}>
									<td>{borderau_counter++}</td>
									<td>
										<Barcode value={code} />
									</td>
									<td></td>
									<td></td>
								</tr>
							);
						})}
						{borderau_list_items ? <tbody>{borderau_list_items}</tbody> : null}
					</Table>

					<Row className="flex-column align-items-center justify-content-center my-5">
						<strong className="">Signature du livreur</strong>
						<Col
							md={3}
							className="my-3"
							style={{
								border: "3px solid #888",
								minHeight: "150px",
								minWidth: "280px",
								maxWidth: "280px",
							}}
						></Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(EtiquetteNormal);
