/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import * as config from "config/config";
import NavPublic from "components/nav/NavPublic";
const dateFormatConvert = require("helpers/dateFormatConvert");
import SearchForm from "./searchForm";
import Checkbox from "helpers/checkbox";
import { isPartially } from "services/status/partialStatus";
const getGroupementColisInfos = require("helpers/getGroupementColisInfos");

/*CSS*/
import "asset/css/demandes.scss";
import dayjs from "dayjs";

class Demandes extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      groupementColis: [],
      groupementColisMDPR: [],
      totalMDPR: 25,
      total: 5,
      groupementColis_array_checkbox_state: [],
      id_bordereau: "",
      id_client: "",
    };
  }

  componentDidMount() {
    let page_id = this.props.match.params.page_id;
    let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
      .aut_old_client_id
      ? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
          .aut_old_client_id
      : JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
    this.setState({ id_client: id_client });
    config
      .apiFetch(
        config.Api_Url +
          "/demande/getGroupementColisPublic/liste/" +
          page_id +
          "/" +
          id_client,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => response.json())
      .then((data) => {
        let groupementColis_array_checkbox_state = [];
        data["data"].forEach((d) => {
          if (d.etathex != "34")
            groupementColis_array_checkbox_state.push(d.groupement_id);
        });
        this.setState({
          groupementColis: [data],
          groupementColis_array_checkbox_state:
            groupementColis_array_checkbox_state,
        });
      });
    config
      .apiFetch(
        config.Api_Url + "/demande/getTotalGroupementColisPublic/" + id_client,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => response.json())
      .then((total) => {
        this.setState({
          total: total["data"][0]["total"],
        });
      });
    config
      .apiFetch(config.Api_Url + "/bordereau/getTodayBordreauId/" + id_client, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => response.json())
      .then((bordereau) => {
        console.log("bordereau:", bordereau);
        if (bordereau["data"].length > 0) {
          this.setState({
            id_bordereau: bordereau["data"][0]["id_bordereau"],
          });
        }
      });
  }
  render() {
    let active = parseInt(this.props.match.params.page_id);
    let classNamePagination = "";
    let items = [];
    let first_pagination = 1;
    if (
      active != 1 &&
      active != 2 &&
      active != 3 &&
      active != 4 &&
      active != 5
    ) {
      for (
        let number = active - 4;
        number <= this.state.total / 15 + 1;
        number++
      ) {
        if (number > active + 4) {
          break;
        }
        if (active === number) {
          classNamePagination = "page-item active";
        } else {
          classNamePagination = "page-item";
        }
        items.push(
          <li key={"pagination_" + number} className={classNamePagination}>
            <a className="page-link" href={number}>
              {number}
            </a>
          </li>
        );
      }
    } else {
      for (
        let number = first_pagination;
        number <= this.state.total / 15 + 1;
        number++
      ) {
        if (number > active + 4) {
          break;
        }
        if (active === number) {
          classNamePagination = "page-item active";
        } else {
          classNamePagination = "page-item";
        }
        items.push(
          <li key={"pagination_" + number} className={classNamePagination}>
            <a className="page-link" href={number}>
              {number}
            </a>
          </li>
        );
      }
    }
    let urlDropdownHide = "/public/demande/filterWithoutBordereau/page/1";
    let urlDropdownShow = "/public/demande/filterHasBordereau/page/1";
    let monthFilter = "filter";
    let dateFilter = "filterDate";
    return (
      <div>
        <NavPublic />
        <div className="container-fluid">
          <h1>Suivi des demandes d'enlèvements</h1>
          <h2 className="text-center">Tous les résultats</h2>
          <SearchForm
            urlDropdownHide={urlDropdownHide}
            urlDropdownShow={urlDropdownShow}
            groupementColis_array_checkbox_state={
              this.state.groupementColis_array_checkbox_state
            }
            dateFilter={dateFilter}
            monthFilter={monthFilter}
            id_bordereau={this.state.id_bordereau}
            id_client={this.state.id_client}
          />
          <Formik
            enableReinitialize={true}
            initialValues={{ checked: [], changing_state: "30" }}
            onSubmit={(values, { setSubmitting }) => {
              //Remove all existed profil's line under the same name
              config
                .apiFetch(config.Api_Url + "/demande/updateMultiGroupement", {
                  method: "POST",
                  body: JSON.stringify({
                    groupement_id: values.checked,
                    etat: values.changing_state,
                  }),
                  headers: { "Content-Type": "application/json" },
                })
                .then((response) => response.json())
                .then((response_details) => {
                  window.location.reload();
                });
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Table responsive bordered hover>
                  <thead>
                    <tr>
                      <th></th>
                      <th>N°demande</th>
                      <th>Type</th>
                      <th>Date demande</th>
                      <th>Expéditeur</th>
                      <th>Destinataire</th>
                      <th>Suivi du colis</th>
                      <th>Statut final</th>
                      <th>Étiquettes</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.groupementColis.map((dynamicData, Key) => {
                      let keys = Object.keys(dynamicData);
                      let d = dynamicData;
                      return d[keys].map((data) => {
                        let etat =
                          getGroupementColisInfos.getGroupementColisStateHex(
                            data.etathex
                          );
                        let css_class_etat =
                          getGroupementColisInfos.getGroupementColisStateHexCss(
                            data.etathex
                          );
                        let traite =
                          getGroupementColisInfos.getGroupementColisTraite(
                            data.traitehex
                          );
                        let css_class_traite =
                          getGroupementColisInfos.getGroupementColisTraiteCss(
                            data.traitehex
                          );

                        const colisByGroupement =
                          this.state.groupementColis[0].data?.filter(
                            (x) => x.groupement_id === data.groupement_id
                          );

                        let estLot = colisByGroupement[0]?.colis[0]?.estLot

                        const isPartiallyCollected = isPartially("collected", colisByGroupement, estLot);

                        const isPartiallyDelivered = isPartially("delivered", colisByGroupement, estLot);

                        const isPartiallyEAL = isPartially("EAL", colisByGroupement, estLot);

                        let detailt_url =
                          "/public/demande/details/" + data.groupement_id;
                        let etiquette_url =
                          "/public/demande/visualiseretiquette/" +
                          data.groupement_id;

                        return (
                          <tr
                            key={data.groupement_id}
                            className={css_class_etat}
                          >
                            <td>
                              <Checkbox
                                id={data.groupement_id}
                                name="checked"
                                value={data.groupement_id}
                                disabled={etat === "Livré"}
                              />
                            </td>
                            <td>{data.groupement_id}</td>
                            <td>{estLot ? "Lot" : "Colis"}</td>
                            <td>
                              {dayjs(data.datedemande).format(
                                "DD/MM/YYYY [à] H:mm"
                              )}
                            </td>
                            <td>
                              {data.nomExpediteur && data.prenomExpediteur
                                ? data.nomExpediteur +
                                  " " +
                                  data.prenomExpediteur
                                : ""}
                              <br />
                              {data.adresseExpediteur &&
                              data.codepostalExpediteur
                                ? data.adresseExpediteur +
                                  ", " +
                                  data.codepostalExpediteur
                                : ""}
                              <br />
                              {data.villeExpediteur}
                            </td>
                            <td>
                              {data.colis[0]?.nomdest || data.colis[0]?.prenomdest
                                ? data.colis[0]?.nomdest + " " + data.colis[0]?.prenomdest
                                : ""}
                              <br />
                              {data.colis[0]?.adressedest && data.colis[0]?.codepostaldest
                                ? data.colis[0]?.adressedest + ", " + data.colis[0]?.codepostaldest
                                : ""}
                              <br />
                              {data.colis[0]?.villedest}
                            </td>
                            <td>
                              {data.etathex === "30"
                                ? "En attente d'enlèvement"
                                : `${
                                    isPartiallyCollected
                                      ? "Partiellement pris en charge "
                                      : "Pris en charge "
                                  } ${
                                    data.nom &&
                                    data.nom !== undefined &&
                                    data.prenom &&
                                    data.prenom !== undefined
                                      ? `par ${data.nom} ${data.prenom}`
                                      : ""
                                  } ${
                                    data.datecharge !== undefined &&
                                    data.datecharge !== null
                                      ? "le " +
                                        dayjs(data.datecharge).format(
                                          "DD/MM/YYYY [à] H:mm"
                                        )
                                      : "le " +
                                        dayjs(data.datedemande).format(
                                          "DD/MM/YYYY [à] H:mm"
                                        )
                                  }`}
                            </td>
                            <td>
                              {data.etathex === "30"
                                ? ""
                                : `${
                                    isPartiallyDelivered
                                      ? "Partiellement livré"
                                      : isPartiallyEAL
                                      ? "En cours de livraison (Colis manquant)"
                                      : isPartiallyCollected
                                      ? "Partiellement collecté"
                                      : data.etathex === "33" ||
                                        data.etathex === "54"
                                      ? "En cours de livraison"
                                      : etat
                                  }` +
                                  " le " +
                                  dayjs(data.datereleve).format(
                                    "DD/MM/YYYY [à] H:mm"
                                  )}
                            </td>
                            <td>
                              <a href={etiquette_url}>
                                <i className="fas fa-barcode"></i>
                                Étiquettes
                              </a>
                            </td>
                            {/* <td className={data.cible ? "hors-site" : ""}>
															{data.cible}
														</td> */}

                            <td>
                              <a href={detailt_url}>
                                <i className="fas fa-info-circle"></i>
                                Détails
                              </a>
                            </td>
                            {/* <td className={css_class_traite}>{traite}</td> */}
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </Table>

                <Row>
                  <Col xs={4}>
                    <Row>
                      <Form.Group controlId="stateSelect" className="w-100">
                        <Col xs={12}>
                          <Form.Control
                            as="select"
                            name="changing_state"
                            onChange={handleChange}
                          >
                            <option value="30">
                              Changer pour : "En attente d'enlèvement"
                            </option>
                            <option value="32">Changer pour : "Annulée"</option>
                          </Form.Control>
                        </Col>
                        <Col xs={4}>
                          <Button
                            size="sm"
                            className="w-100 mt-2"
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Valider
                          </Button>
                        </Col>
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col xs={4}>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">{items}</ul>
                    </nav>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default withRouter(Demandes);
