/* eslint-disable import/first */
import React, { Component } from "react";
import { Table, Form, Button } from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import { withRouter } from "react-router-dom";
const dateFormatConvert = require("helpers/dateFormatConvert");
import Checkbox from "helpers/checkbox";
import { Formik } from "formik";

export class SummaryTable extends React.Component {
	constructor(props) {
		super(props);
		this.SummaryTableAgence = this.SummaryTableAgence.bind(this);
	}
	SummaryTableAgence() {
		let details = this.props.details;

		if (!details || !this.props.nb_total || !this.props.sum_total) {
			return null;
		} else {
			let sum = 0;
			let nb_colis = 0;
			let currentIntervalleMin = 0;
			let counter = -1;
			const reducer = (accumulator, currentValue) => accumulator + currentValue;
			if (this.props.sum_total.length != 0) {
				sum = this.props.sum_total.reduce(reducer);
			}
			if (this.props.nb_total.length != 0) {
				nb_colis = this.props.nb_total.reduce(reducer);
			}
			if (this.props.colis_dans_lot != 0) {
				nb_colis += this.props.colis_dans_lot.reduce(reducer);
			}
			nb_colis = nb_colis - this.props.nb_total[this.props.nb_total.length - 1];
			return (
				<div>
					<div className="text-center">
						{/*<hr className="myhrline" />*/}
						<h2>
							Client : {this.props.client.nom + " " + this.props.client.prenom}
							<br />
							Code postal :{this.props.client.codepostal}
						</h2>
					</div>

					<div className="d-flex justify-content-end">
						{" "}
						<div className="thick-border">
							Montant H.T : {Math.round(sum * 100) / 100}€ pour {nb_colis} colis{" "}
						</div>
					</div>
				</div>
			);
		}
	}
	render() {
		return (
			<div>
				<this.SummaryTableAgence />
			</div>
		);
	}
}

export default withRouter(SummaryTable);
