/* eslint-disable import/first */
import React, { Component, useState } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
	Collapse,
	Container,
	Dropdown,
	Toast,
} from "react-bootstrap";
import DatePickerFieldRequired from "helpers/datePickerFieldRequired";
const dateFormatConvert = require("helpers/dateFormatConvert");
import * as config from "config/config";
import Legends from "components/public/demande/components/legends";

class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.Search = this.Search.bind(this);
		this.allChecked = this.allChecked.bind(this);
		this.allUnchecked = this.allUnchecked.bind(this);
		this.addTodayGroupementToNewBordereau = this.addTodayGroupementToNewBordereau.bind(
			this
		);
		this.state = {
			isSubmitted: false,
		};
	}
	allChecked() {
		this.props.groupementColis_array_checkbox_state.forEach((d) => {
			if (document.getElementById(d).checked != true) {
				setTimeout(function () {
					document.getElementById(d).click();
				}, 10);
			}
		});
	}
	allUnchecked() {
		this.props.groupementColis_array_checkbox_state.forEach((d) => {
			if (document.getElementById(d).checked == true) {
				setTimeout(function () {
					document.getElementById(d).click();
				}, 10);
			}
		});
	}

	addTodayGroupementToNewBordereau() {
		this.setState({
			isSubmitted: true,
		});
		if (this.props.id_bordereau) {
			config
				.apiFetch(
					config.Api_Url + "/bordereau/addTodayGroupementToExistedBordereau",
					{
						method: "POST",
						body: JSON.stringify({
							id_client: this.props.id_client,
							id_bordereau: this.props.id_bordereau,
						}),
						headers: { "Content-Type": "application/json" },
					}
				)
				.then((response) => response.json())
				.then((response_details) => {
					console.log(response_details);
					let element = document.getElementById("toast");
					element.classList.remove("fade");
					element.classList.add("show");
				});
		} else {
			config
				.apiFetch(
					config.Api_Url + "/bordereau/addTodayGroupementToNewBordereau",
					{
						method: "POST",
						body: JSON.stringify({
							id_client: this.props.id_client,
						}),
						headers: { "Content-Type": "application/json" },
					}
				)
				.then((response) => response.json())
				.then((response_details) => {
					console.log(response_details);
					let element = document.getElementById("toast");
					element.classList.remove("fade");
					element.classList.add("show");
				});
		}
	}

	Search() {
		// Declare a new state variable, which we'll call "count"
		const [count1, setOpen1] = useState(true);
		const [count2, setOpen2] = useState(false);
		const [showToast, setShowToast] = useState(false);
		const toggleShowshowToast = () => {
			let element = document.getElementById("toast");
			element.classList.remove("show");
			element.classList.add("fade");
		};

		const lesMois = config.lesMois;
		let month = [];
		for (let number = 0; number < lesMois.length; number++) {
			month.push(
				<option key={"option_month_" + number} value={number}>
					{lesMois[number]}
				</option>
			);
		}
		return (
			<div>
				<Row className="my-4">
					<Col xs={4}>
						<ButtonToolbar>
							<Button
								size="sm"
								className="mr-1"
								variant="outline-secondary"
								onClick={() => {
									setOpen1(!count1);
									setOpen2(false);
								}}
								aria-controls="search_by_date"
								aria-expanded={count1}
								active={count1}
							>
								Recherche par mois
							</Button>
							<Button
								size="sm"
								className="mr-1"
								variant="outline-secondary"
								onClick={() => {
									setOpen2(!count2);
									setOpen1(false);
								}}
								aria-controls="search_by_month"
								aria-expanded={count2}
								active={count2}
							>
								Recherche par période
							</Button>
						</ButtonToolbar>
						<Collapse in={count1}>
							<div id="search_by_month">
								<Formik
									initialValues={{
										month: "0",
										year: "",
									}}
									onSubmit={(values) => {
										let month = parseInt(values.month) + 1;
										this.props.history.push(
											"/public/demande/" +
												this.props.monthFilter +
												"/" +
												month.toString() +
												"/" +
												values.year +
												"/page/1"
										);
										window.location.reload();
									}}
								>
									{(props) => {
										const {
											status,
											handleChange,
											handleSubmit,
											isSubmitting,
											/* and other goodies */
										} = props;
										return (
											<Form onSubmit={handleSubmit} autoComplete="off">
												<Row>
													<Col>
														<Row>
															<Col md>
																{" "}
																<Form.Label className="required">
																	Mois :
																</Form.Label>
																<Form.Control
																	required
																	as="select"
																	name="month"
																	onChange={handleChange}
																>
																	{month}
																</Form.Control>
															</Col>
															<Col md>
																{" "}
																<Form.Label className="required">
																	Année :
																</Form.Label>
																<Form.Control
																	required
																	type="number"
																	aria-describedby="inputGroupPrepend"
																	name="year"
																	onChange={handleChange}
																></Form.Control>
															</Col>
														</Row>
														<Row>
															<Col md>
																{" "}
																<Button
																	size="sm"
																	className="mb-4"
																	type="submit"
																	disabled={isSubmitting}
																>
																	Rechercher
																</Button>
															</Col>
														</Row>
													</Col>
												</Row>

												{status && (
													<div className={"alert alert-danger"}>{status}</div>
												)}
											</Form>
										);
									}}
								</Formik>
							</div>
						</Collapse>
						<Collapse in={count2}>
							<div id="search_by_date">
								<Formik
									initialValues={{
										startDate: "",
										endDate: "",
									}}
									onSubmit={(values) => {
										let startDate =
											values.startDate.getFullYear() +
											"-" +
											(values.startDate.getMonth() + 1) +
											"-" +
											values.startDate.getDate();
										let endDate =
											values.endDate.getFullYear() +
											"-" +
											(values.endDate.getMonth() + 1) +
											"-" +
											values.endDate.getDate();
										this.props.history.push(
											"/public/demande/" +
												this.props.dateFilter +
												"/" +
												startDate +
												"/" +
												endDate +
												"/page/1"
										);
										window.location.reload();
									}}
								>
									{(props) => {
										const {
											handleSubmit,
											isSubmitting,

											/* and other goodies */
										} = props;
										return (
											<Form onSubmit={handleSubmit} autoComplete="off">
												<Row>
													<Col>
														<Row>
															<Col md>
																<Form.Label>Période du : </Form.Label>

																<DatePickerFieldRequired
																	name="startDate"
																	className="form-control"
																/>
															</Col>

															<Col md>
																<Form.Label>Au :</Form.Label>
																<DatePickerFieldRequired
																	name="endDate"
																	className="form-control"
																/>
															</Col>
														</Row>
														<Row>
															<Col md>
																<Button
																	size="sm"
																	type="submit"
																	disabled={isSubmitting}
																	className="mb-4"
																>
																	Rechercher
																</Button>
															</Col>
														</Row>
													</Col>
												</Row>
											</Form>
										);
									}}
								</Formik>
							</div>
						</Collapse>
					</Col>
					<Col xs={4} className="d-flex flex-column justify-content-center">
						<Dropdown className="text-center">
							<Dropdown.Toggle
								size="sm"
								className="d-block w-100 mb-2"
								variant="secondary"
								id="dropdown-Affichage"
							>
								Afficher
							</Dropdown.Toggle>

							<Dropdown.Menu className="text-center w-100 m-0">
								<Dropdown.Item href="/public/demande/filterHasBordereau/page/1">
									Afficher les demandes incluses dans un bordereau
								</Dropdown.Item>
								<Dropdown.Item href="/public/demande/filterWithoutBordereau/page/1">
									Masquer les demandes incluses dans un bordereau
								</Dropdown.Item>
								<Dropdown.Item href="/public/demande/page/1" disabled={true}>
									Afficher tout
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>

						<Button
							size="sm"
							className=""
							variant="secondary"
							onClick={this.addTodayGroupementToNewBordereau}
							disabled={this.state.isSubmitted}
						>
							{/* This button only on index */}
							Créer les bordereaux d'aujourd'hui
						</Button>
						<Toast
							className="mt-2"
							show={showToast}
							onClose={toggleShowshowToast}
							id="toast"
						>
							<Toast.Header>
								<img
									src="holder.js/20x20?text=%20"
									className="rounded mr-2"
									alt=""
								/>
								<strong className="mx-auto">
									Les bordereaux d'aujourd'hui ont bien été créés.
								</strong>
							</Toast.Header>
							{/* <Toast.Body></Toast.Body> */}
						</Toast>
					</Col>
					<Legends />
				</Row>

				<Row>
					<Col>
						<Button
							size="sm"
							className="mr-2"
							variant="outline-secondary"
							onClick={this.allChecked}
						>
							Tout cocher
						</Button>
						<Button
							size="sm"
							className="mr-2"
							variant="outline-secondary"
							onClick={this.allUnchecked}
						>
							Tout décocher
						</Button>
					</Col>
				</Row>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.Search />
			</div>
		);
	}
}

export default withRouter(SearchForm);
