/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router-dom";
import * as config from "config/config";
import DemandeTable from "./filter/demandeTable";

class Demandes extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      groupementColis: [],
      colisByGroupement: [],
      groupementColisMDPR: [],
      colisMDPRByGroupement: [],
      agence: [],
      groupementColis: [],
      MDPRactive: false,
      clients_list: [],
      array_groupement_id: [],
      isLoading: true,
      isLoadingMDPR: true,
    };
  }

  componentDidMount() {
    let page_id = this.props.match.params.page_id;
    /*AGENCE AREA */
    let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
      .aut_role;
    let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
      .aut_id;
    //IF ADMIN, we provide agence modify
    //If not we set agence by defaut of the account
    if (user_role == "Admin") {
      this.setState({
        isLoading: true,
        isLoadingMDPR: true,
      });
      config
        .apiFetch(config.Api_Url + "/demande/getTotalGroupementColis", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => response.json())
        .then((total) => {
          this.setState({
            total: total["data"][0]["total"],
          });
        });

      config
        .apiFetch(config.Api_Url + "/demande/getTotalGroupementColisMDPR", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => response.json())
        .then((total) => {
          this.setState({
            totalMDPR: total["data"][0]["total"],
          });
        });
      config
        .apiFetch(
          config.Api_Url + "/demande/getGroupementColis/liste/" + page_id,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response) => response.json())
        .then((data) => {
          //get array of all groupement_id (not MDPR)
          let array_groupement_id = data.data
            .filter((x) => x.groupement_id != "")
            .map((x) => x.groupement_id);

          this.setState({
            groupementColis: [data],
            isLoading: false,
          });

          config
            .apiFetch(
              config.Api_Url +
                "/demande/getGroupementColisMDPR/liste/" +
                page_id,
              {
                method: "GET",
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((response) => response.json())
            .then((data) => {
              //get array of all groupement_id MDPR
              //concat it with the previous array of groupement_id
              array_groupement_id = array_groupement_id.concat(
                data.data
                  .filter((x) => x.groupement_id != "")
                  .map((x) => x.groupement_id)
              );

              this.setState({
                groupementColisMDPR: [data],
                MDPRactive: true,
                array_groupement_id: array_groupement_id,
                isLoadingMDPR: false,
                isLoading: false,
              });

              //get the colis_id of all groupement_id
              this.getAllColisByArrayOfGroupmentId();
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
                isLoadingMDPR: false,
              });
            });
        });

      config
        .apiFetch(config.Api_Url + "/clients")
        .then((response) => response.json())
        .then((clients) => {
          let clients_list = [];
          for (let number = 0; number < clients["data"].length; number++) {
            clients_list.push(
              <option
                key={"clients_" + number}
                value={clients["data"][number]["id_client"]}
              >
                {clients["data"][number]["nom"]}{" "}
                {clients["data"][number]["prenom"]}
              </option>
            );
          }
          this.setState({
            clients_list: [clients_list],
          });
        });
    } else {
      config
        .apiFetch("/getAgenceByAccount/" + user_id)
        .then((response) => response.json())
        .then((agence) => {
          this.setState({
            agence: agence["data"][0],
          });
          config
            .apiFetch(
              config.Api_Url +
                "/demande/getGroupementColisAgence/liste/" +
                this.state.agence.agence_id +
                "/" +
                page_id,
              {
                method: "GET",
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((response) => response.json())
            .then((data) => {
              let array_groupement_id = data.data
                .filter((x) => x.groupement_id != "")
                .map((x) => x.groupement_id);
              this.setState({
                groupementColis: [data],
              });

              if (
                agence["data"][0].agence_id == 1 ||
                agence["data"][0].agence_name == "Citycolis 14"
              ) {
                config
                  .apiFetch(
                    config.Api_Url +
                      "/demande/getGroupementColisMDPR/liste/" +
                      page_id,
                    {
                      method: "GET",
                      headers: { "Content-Type": "application/json" },
                    }
                  )
                  .then((response) => response.json())
                  .then((data) => {
                    array_groupement_id = array_groupement_id.concat(
                      data.data
                        .filter((x) => x.groupement_id != "")
                        .map((x) => x.groupement_id)
                    );

                    this.setState({
                      groupementColisMDPR: [data],
                      MDPRactive: true,
                      array_groupement_id: array_groupement_id,
                    });
                    this.getAllColisByArrayOfGroupmentId();
                  });
              } else {
                this.setState({
                  array_groupement_id: array_groupement_id,
                });
                this.getAllColisByArrayOfGroupmentId();
              }
            });

          config
            .apiFetch(
              config.Api_Url + "/clients/agence/" + this.state.agence.agence_id
            )
            .then((response) => response.json())
            .then((clients) => {
              let clients_list = [];
              for (let number = 0; number < clients["data"].length; number++) {
                clients_list.push(
                  <option
                    key={"clients_" + number}
                    value={clients["data"][number]["id_client"]}
                  >
                    {clients["data"][number]["nom"]}{" "}
                    {clients["data"][number]["prenom"]}
                  </option>
                );
              }
              this.setState({
                clients_list: [clients_list],
              });
            })
            .catch((err) => {
              console.log(err);
              // some error handling
            });
        });
    }
  }

  getAllColisByArrayOfGroupmentId() {
    if (this.state.array_groupement_id.length != 0) {
      config
        .apiFetch(config.Api_Url + "/colis/getAllColisByArrayOfGroupmentId", {
          method: "POST",
          body: JSON.stringify({
            array_groupement_id: this.state.array_groupement_id,
          }),
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => response.json())
        .then((colis) => {
          this.setState({
            colisByGroupement: colis.data,
            isLoading: false,
            isLoadingMDPR: false,
          });
        });
    }
  }

  render() {
    return (
      <DemandeTable
        groupementColis={this.state.groupementColis}
        colisByGroupement={this.state.colisByGroupement}
        groupementColisMDPR={this.state.groupementColisMDPR}
        colisMDPRByGroupement={this.state.colisMDPRByGroupement}
        array_groupement_id={this.state.array_groupement_id}
        total={this.state.total}
        totalMDPR={this.state.totalMDPR}
        MDPRactive={this.state.MDPRactive}
        clients_list={this.state.clients_list}
        isLoading={this.state.isLoading}
        isLoadingMDPR={this.state.isLoadingMDPR}
        mainPage={true}
      />
    );
  }
}

export default withRouter(Demandes);
