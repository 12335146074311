import React, { Component } from "react";

//css
import "./draganddrop.scss";
class DragAndDrop extends Component {
	state = {
		drag: false,
		dragCounter: 0,
	};
	dropRef = React.createRef();
	handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
	handleDragIn = (e) => {
		console.log("in counter", this.state.dragCounter);
		e.preventDefault();
		e.stopPropagation();
		this.state.dragCounter++;
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState({ drag: true });
		}
	};
	handleDragOut = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.state.dragCounter--;
		console.log("out counter", this.state.dragCounter);
		if (this.state.dragCounter === 0) {
			this.setState({ drag: false });
		}
	};
	handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ drag: false });
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this.props.handleDrop(e.dataTransfer.files);
			e.dataTransfer.clearData();
			this.state.dragCounter = 0;
		}
	};
	componentDidMount() {
		let div = this.dropRef.current;
		div.addEventListener("dragenter", this.handleDragIn);
		div.addEventListener("dragleave", this.handleDragOut);
		div.addEventListener("dragover", this.handleDrag);
		div.addEventListener("drop", this.handleDrop);
	}
	componentWillUnmount() {
		let div = this.dropRef.current;
		div.removeEventListener("dragenter", this.handleDragIn);
		div.removeEventListener("dragleave", this.handleDragOut);
		div.removeEventListener("dragover", this.handleDrag);
		div.removeEventListener("drop", this.handleDrop);
	}
	render() {
		return (
			<div
				className="dragandropbox"
				style={{ display: "inline-block", position: "relative" }}
				ref={this.dropRef}
			>
				{this.state.drag && (
					<div>
						<div
							style={{
								position: "absolute",
								top: "50%",
								right: 0,
								left: 0,
								textAlign: "center",
								color: "grey",
								fontSize: 36,
							}}
						>
							<div>drop here :)</div>
						</div>
					</div>
				)}
				{this.props.children}
			</div>
		);
	}
}
export default DragAndDrop;
