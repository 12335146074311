/* eslint-disable import/first */
import React, { Component } from "react";
import NavPublic from "components/nav/NavPublic";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";
import { Formik } from "formik";
import BordereauTr from "./bordereauTr";
/*Css*/

export class Bordereau extends React.Component {
	constructor() {
		super();
		this.state = {
			bordereau: [],
			total: 0,
			linesperpage: 20,
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}

		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		let page_id = this.props.match.params.page_id;
		config
			.apiFetch(
				config.Api_Url +
					"/bordereau/getBordereauxByClient/" +
					id_client +
					"/" +
					page_id
			)
			.then((response) => response.json())
			.then((bordereau) => {
				console.log(bordereau);
				this.setState({
					bordereau: [bordereau],
				});
				config
					.apiFetch(
						config.Api_Url +
							"/bordereau/getTotalBordereauxByClient/" +
							id_client
					)
					.then((response) => response.json())
					.then((total) => {
						console.log(total);
						this.setState({
							total: total["data"][0]["total"],
						});
					});
			});
	}

	render() {
		let active = parseInt(this.props.match.params.page_id);
		let classNamePagination = "";
		let items = [];
		let first_pagination = 1;
		if (
			active != 1 &&
			active != 2 &&
			active != 3 &&
			active != 4 &&
			active != 5
		) {
			for (
				let number = active - 4;
				number <= this.state.total / this.state.linesperpage + 1;
				number++
			) {
				if (number > active + 4) {
					break;
				}
				if (active === number) {
					classNamePagination = "page-item active";
				} else {
					classNamePagination = "page-item";
				}
				items.push(
					<li key={"pagination_" + number} className={classNamePagination}>
						<a className="page-link" href={number}>
							{number}
						</a>
					</li>
				);
			}
		} else {
			for (
				let number = first_pagination;
				number <= this.state.total / this.state.linesperpage + 1;
				number++
			) {
				if (number > active + 4) {
					break;
				}
				if (active === number) {
					classNamePagination = "page-item active";
				} else {
					classNamePagination = "page-item";
				}
				items.push(
					<li key={"pagination_" + number} className={classNamePagination}>
						<a className="page-link" href={number}>
							{number}
						</a>
					</li>
				);
			}
		}
		return (
			<div>
				<NavPublic />
				<div className="container">
					<h1>Gestion des bordereaux</h1>
					<div>
						<Table responsive bordered hover>
							<thead>
								<tr>
									<th>Date</th>
									<th>Numéro</th>
									<th>Demandes concernées</th>
									<th>Bordereaux</th>
								</tr>
							</thead>
							<tbody key="tbody">
								{this.state.bordereau.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;
									let temp_bordereau = "";
									let temp_date = "";
									let option_list = [];
									const arrayLen = d[keys].length;
									let counter = 0;
									return d[keys].map((data, index) => {
										//loop data
										//when we are at the end of data list,
										//the list have more than one row
										if (arrayLen === index + 1 && index != 0) {
											console.log("index", index);
											//if same bordereau
											//we add the last "demande" on the current list and we return the last collection of "demande"
											if (temp_bordereau == data.id_bordereau) {
												option_list.push(
													<option
														key={"option_bordereau_" + data.groupement_id}
													>
														{data.groupement_id}
													</option>
												);
												counter++;
												let content = (
													<BordereauTr
														key={counter}
														temp_bordereau={temp_bordereau}
														temp_date={temp_date}
														option_list={option_list}
														counter={counter}
													/>
												);
												return content;
											}
											//if not we create the previous list + another list with only the last one demand
											else {
												counter++;
												let content = (
													<BordereauTr
														key={counter}
														temp_bordereau={temp_bordereau}
														temp_date={temp_date}
														option_list={option_list}
														counter={counter}
													/>
												);
												temp_bordereau = data.id_bordereau;
												option_list = [];
												option_list.push(
													<option
														key={"option_bordereau_" + data.groupement_id}
													>
														{data.groupement_id}
													</option>
												);
												counter++;
												let content1 = (
													<BordereauTr
														key={counter}
														temp_bordereau={temp_bordereau}
														temp_date={temp_date}
														option_list={option_list}
														counter={counter}
													/>
												);
												return [content, content1];
											}
										}
										//the list have exactly one row
										else if (arrayLen === index + 1 && index == 0) {
											temp_bordereau = data.id_bordereau;
											temp_date = data.datedemande;
											option_list.push(
												<option key={"option_bordereau_" + data.groupement_id}>
													{data.groupement_id}
												</option>
											);
											counter++;
											let content = (
												<BordereauTr
													key={counter}
													temp_bordereau={temp_bordereau}
													temp_date={temp_date}
													option_list={option_list}
													counter={counter}
												/>
											);
											return content;
										} else {
											//we collect the first "bordereau"
											if (!temp_bordereau) {
												temp_bordereau = data.id_bordereau;
												temp_date = data.datedemande;
											}
											//group all "demande" under one "bordereau"
											if (temp_bordereau == data.id_bordereau) {
												option_list.push(
													<option
														key={"option_bordereau_" + data.groupement_id}
													>
														{data.groupement_id}
													</option>
												);
												//we return the list of "demande" if we are no longer on the same "bordereau"
												//then add current "demande" on new list
											} else {
												counter++;
												let content = (
													<BordereauTr
														key={counter}
														temp_bordereau={temp_bordereau}
														temp_date={temp_date}
														option_list={option_list}
														counter={counter}
													/>
												);
												temp_bordereau = data.id_bordereau;
												temp_date = data.datedemande;
												option_list = [];
												option_list.push(
													<option
														key={"option_bordereau_" + data.groupement_id}
													>
														{data.groupement_id}
													</option>
												);
												return content;
											}
										}
									});
								})}
							</tbody>
						</Table>
					</div>
					<div>
						<nav aria-label="Page navigation example">
							<ul className="pagination">{items}</ul>
						</nav>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Bordereau);
