import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { NavAdmin } from "components/nav/NavAdmin";

class ErrorPackage extends Component {
	constructor() {
		super();
	}
	render() {
		return (
			<div className="container-fluid">
				<NavAdmin />
				<div className="text-center">
					<h2>
						Votre recherche comporte trop de résultats, nous vous invitons à
						préciser votre demande
					</h2>
				</div>
			</div>
		);
	}
}

export default withRouter(ErrorPackage);
