/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { NavAdmin } from "components/nav/NavAdmin";
import * as config from "config/config";
import DatePickerField from "helpers/datePickerField";
const dateFormatConvert = require("helpers/dateFormatConvert");

/*CSS*/
import "../../../../asset/css/add.css";

class ModifyTournee extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tournee: {
				tournee_id: "",
				datetournee: "",
				zonetournee: "",
				livreur_id: "",
				etat: "",
			},
			livreurs: [],
		};
	}
	componentDidMount() {
		config
			.apiFetch(
				config.Api_Url + "/findbyid/tournee/" + this.props.match.params.id
			)
			.then((response) => response.json())
			.then((data) => {
				if (data["data"].length != 0) {
					this.setState({
						tournee: data["data"][0],
					});
				} else {
					this.props.history.push("/404");
				}
			});
		config
			.apiFetch(config.Api_Url + "/tournee/getEtat/")
			.then((response) => response.json())
			.then((data) => {
				let etat = [];
				for (let number = 0; number < data["data"].length; number++) {
					switch (data["data"][number]["etat"]) {
						case "30":
							etat.push(
								<option key={"option_etat_" + number} value="30">
									Non terminée
								</option>
							);
							break;
						case "32":
							etat.push(
								<option key={"option_serviceCode_" + number} value="32">
									Terminée
								</option>
							);
							break;
					}
				}
				this.setState({
					etat: [etat],
				});
			});
		config
			.apiFetch(config.Api_Url + "/livreurs")
			.then((response) => response.json())
			.then((data) => {
				let livreurs = [];

				for (let number = 0; number < data["data"].length; number++) {
					livreurs.push(
						<option
							key={"option_livreurs_" + number}
							value={data["data"][number]["livreur_id"]}
						>
							{data["data"][number]["nom"] + " "}{" "}
							{data["data"][number]["prenom"]}
						</option>
					);
				}
				this.setState({
					livreurs: [livreurs],
				});
			});
	}
	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Modifier la tournée</h1>
							<Formik
								initialValues={this.state.tournee}
								enableReinitialize
								onSubmit={(values, { setSubmitting }) => {
									let date = "";
									if (!values.date) {
										date = dateFormatConvert.convertDateHolderForSql(
											this.state.tournee.datetournee
										);
									} else {
										date = dateFormatConvert.convertDateForSql(values.date);
									}

									config
										.apiFetch(config.Api_Url + "/update/tournee", {
											method: "POST",
											body: JSON.stringify({
												tournee_id: this.props.match.params.id,
												date: date,
												zone: values.zonetournee,
												//Get last string so  32 => 2 and 30 => 0
												etat: values.etat.slice(values.etat.length - 1),
												livreur: values.livreur_id,
											}),
											headers: {
												"Content-Type": "application/json",
											},
										})
										.then((response) => response.json())
										.then((data) => {
											window.location.reload();
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Form.Label className="required">Date Tournée :</Form.Label>
										<DatePickerField
											className="form-control"
											name="date"
											placeholderText={values.datetournee?.replaceAll("/", "-")}
										/>
										<div>
											<Form.Label>Zone :</Form.Label>
											<Form.Control
												type="text"
												aria-describedby="inputGroupPrepend"
												name="zonetournee"
												value={values.zonetournee}
												onChange={handleChange}
											/>

											<Form.Label className="required">Etat :</Form.Label>
											<Form.Control
												required
												as="select"
												name="etat"
												value={values.etat}
												onChange={handleChange}
											>
												{this.state.etat}
											</Form.Control>
											<Form.Label className="required">Livreur :</Form.Label>
											<Form.Control
												required
												as="select"
												name="livreur_id"
												value={values.livreur_id}
												onChange={handleChange}
											>
												{this.state.livreurs}
											</Form.Control>
										</div>
										<Button
											className="my-4"
											size="sm"
											type="submit"
											disabled={isSubmitting}
										>
											Enregistrer
										</Button>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(ModifyTournee);
