/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router-dom";
import { Table, Alert } from "react-bootstrap";
import * as config from "config/config";
import NavAdmin from "components/nav/NavAdmin";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getColisInfos = require("helpers/getColisInfos");
const geolocalisation = require("helpers/geolocalisation");
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import MarkerWithInfoWindow from "helpers/markerWithInfoWindow";
import { getGroupementColisState } from "helpers/getGroupementColisInfos";
/*CSS*/
import "asset/css/demandes.scss";

class Details extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      colis: [],
      geolocalisation: [],
      centerLon: 0,
      centerLat: 0,
      title: "",
      showMap: false,
    };
  }

  componentDidMount() {
    let groupement_id = this.props.match.params.groupement_id;
    //let startDate = this.props.match.params.startDate;
    //let endDate = this.props.match.params.endDate;
    config
      .apiFetch(
        config.Api_Url + "/demande/getColisbyGroupementID/" + groupement_id,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          colis: [data],
        });
      });
    config
      .apiFetch(
        config.Api_Url +
          "/gps/getColisGeolocalisationByGroupementId/" +
          groupement_id,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => response.json())
      .then((data) => {
        if (data["data"].length) {
          this.setState({
            geolocalisation: [data],
            centerLon: data["data"][0].cg_lon,
            centerLat: data["data"][0].cg_lat,
            showMap: true,
          });
        }
      });
  }

  checkLivraisonEnCours() {
    const colis = this.state.colis;

    for (let i = 0; i < colis.length; i++) {
      const keys = Object.keys(colis[i]);
      const d = colis[i][keys];
      for (let j = 0; j < d.length; j++) {
        const etat = getColisInfos.getColisState(d[j].etatlivraison);
        if (etat === "En cours de livraison") {
          return true;
        }
      }
    }

    return false;
  }

  render() {
    return (
      <div>
        <NavAdmin />
        <div className="container-fluid">
          <h1>Demande d'enlèvement {this.props.match.params.groupement_id}</h1>
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th>N°Colis</th>
                <th>Code barre sous-traitant</th>
                <th>Expediteur</th>
                <th>Poids (Kg)</th>
                <th>Nom Prénom Destinataire</th>
                <th>Adresse Destinataire</th>
                <th>Description</th>
                <th>Type colis</th>
                <th>Etat</th>
                <th>Date livraison</th>
                <th>Modifier</th>
                <th>Informations complémentaires</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.colis.map((dynamicData, Key) => {
                let keys = Object.keys(dynamicData);
                let d = dynamicData;
                return d[keys].map((data) => {
                  let detailt_url = "../details/" + data.groupement_id;
                  let etat = getColisInfos.getColisState(data.etatlivraison);
                  let raison = getColisInfos.getColisReason(data.raison);
                  let css_class_raison = getColisInfos.getColisReasonCss(
                    data.raison
                  );
                  let modify_URL = "/admin/colis/modify/" + data.colis_id;

                  return (
                    <tr key={data.colis_id} className={css_class_raison}>
                      <td className={data.statut}>{data.colis_id}</td>
                      <td>{data.colis_id_ciblex}</td>
                      <td>
                        {data.nomExpediteur + "\n"}
                        {data.prenomExpediteur}
                      </td>
                      <td>{data.poidscolis}</td>
                      <td>
                        {data.nomdest + "\n"}
                        {data.prenomdest}
                      </td>
                      <td>
                        {data.adressedest +
                          ", " +
                          data.codepostaldest +
                          " " +
                          data.villedest}

                        <br></br>
                        {data.telephonedest}
                      </td>
                      <td>{data.description}</td>
                      <td>{data.typeEnvoi}</td>
                      <td>{etat}</td>
                      <td>
                        {(etat === "Livré" || etat === "Refusé") &&
                        data.datereleve
                          ? dateFormatConvert.getFormattedDateWithHms(
                              data.datereleve
                            )
                          : null}
                        <br />
                        {(etat === "Livré" || etat === "Refusé") &&
                        (data.nomliveur || data.prenomlivreur)
                          ? "par " + data.prenomlivreur + " " + data.nomliveur
                          : null}
                      </td>
                      <td>
                        <a href={modify_URL}>Modifier</a>
                      </td>
                      <td>
                        {" "}
                        {etat === "En attente d’enlèvement" &&
                        this.checkLivraisonEnCours()
                          ? "Non mis à disposition"
                          : etat === "Refusé"
                          ? raison
                          : data.infosParticulieres}
                      </td>
                      <td></td>
                      <td>
                        {data.colis_id_ciblex ? (
                          <a
                            href={
                              "/admin/colis/tracking/" + data.colis_id_ciblex
                            }
                          >
                            En savoir plus
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        </div>

        {this.state.showMap ? (
          <LoadScript googleMapsApiKey={config.googleMapsApiKey}>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "500px" }}
              center={{ lng: this.state.centerLon, lat: this.state.centerLat }}
              zoom={22}
            >
              {this.state.geolocalisation.map((dynamicData, Key) => {
                let keys = Object.keys(dynamicData);
                let d = dynamicData;
                let total = d[keys].length;
                return d[keys].map((data, index) => {
                  let test = geolocalisation.generateOverlappingMarker(
                    data.cg_lon,
                    data.cg_lat,
                    index,
                    total
                  );
                  return (
                    <MarkerWithInfoWindow
                      key={data.cg_colis_id}
                      position={{
                        lng: test[0],
                        lat: test[1] + 0.000005,
                      }}
                      cg_colis_id={data.cg_colis_id}
                      info={data.cg_add}
                    />
                  );
                });
              })}
            </GoogleMap>
          </LoadScript>
        ) : (
          <div>
            <Alert variant="warning">
              Pas de position GPS disponible au moment de la livraison.
            </Alert>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Details);
