/* eslint-disable import/first */
import React from "react";
import NavPublic from "components/nav/NavPublic";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");

var Barcode = require("react-barcode");
/*CSS*/
import "./imprimerEtiquette.scss";
import EtiquetteThermique from "./etiquetteThermique";
import EtiquetteNormal from "./etiquetteNormal";

export class ImprimerEtiquetteLotUnite extends React.Component {
	constructor() {
		super();
		this.state = {
			demande: [],
			colis: [],
			colisJson: [],
			expediteur: "",
			telephone: "",
			datedemande: "",
			pagination_fixed: 20,
			lot_package_total: 0,
			id_client: [],
		};
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		this.setState({ id_client: id_client });
		this.state.formatEtiquettes = localStorage.getItem("formatEtiquettes")
			? localStorage.getItem("formatEtiquettes")
			: "normal";
		config
			.apiFetch(config.Api_Url + "/findbyid/client/" + id_client)
			.then((response) => response.json())
			.then((client) => {
				let src =
					"data:image/jpg;base64," +
					new Buffer(client["data"][0].agence_logo).toString("base64");

				this.setState({
					expediteur: client["data"][0].nom + client["data"][0].prenom,
					telephone: client["data"][0].telephone,
					logo: <img src={src} style={{ maxHeight: 150, maxWidth: 150 }}></img>,
					agence_address: client["data"][0].agence_address,
					agence_phone: client["data"][0].agence_phone,
					agence_name: client["data"][0].agence_name,
					agence_ville: client["data"][0].agence_ville,
				});
			});

		config
			.apiFetch(
				config.Api_Url +
					"/lot/getUnclosedLot/" +
					id_client +
					"/" +
					this.props.match.params.a_id
			)
			.then((response) => response.json())
			.then((colis) => {
				colis["data"].forEach((c, index) => {
					c["colis_id"] = "L" + c.lo_barcode + index;
				});
				let lc_id = this.props.match.params.lc_id;
				let print_colis_index = colis["data"].findIndex(
					(c) => c.lc_id == lc_id
				);

				if (print_colis_index != -1) {
					this.setState({
						colis: [{ data: [colis["data"][print_colis_index]] }],
						colisJson: colis["data"][print_colis_index],
					});
					config
						.apiFetch(config.Api_Url + "/lot/updatePrintedColis", {
							method: "PATCH",
							body: JSON.stringify({
								lc_id: this.props.match.params.lc_id,
							}),
							headers: { "Content-Type": "application/json" },
						})
						.then((response) => response.json())
						.then((update) => {
							config
								.apiFetch(config.Api_Url + "/lot/addColisToLot", {
									method: "POST",
									body: JSON.stringify({
										colis_id: this.state.colisJson.colis_id,
										poidscolis: this.state.colisJson.lc_poids,
										nomdest: this.state.colisJson.nomdest,
										prenomdest: this.state.colisJson.prenomdest,
										adressedest: this.state.colisJson.adressedest,
										codepostaldest: this.state.colisJson.codepostaldest,
										villedest: this.state.colisJson.villedest,
										telephonedest: this.state.colisJson.telephonedest,
										type: this.state.colisJson.type,
										infosParticulieres: this.state.colisJson.infosParticulieres,
										values: 0,
										typeEnvoi: this.state.colisJson.typeEnvoi,
										groupement_id: this.state.colisJson.groupement_id,
										lc_id: this.state.colisJson.lc_id,
										lo_barcode: this.state.colisJson.lo_barcode,
									}),
									headers: {
										"Content-Type": "application/json",
									},
								})
								.then((response) => response.json())
								.then((data) => {
									console.log(data);
									config
										.apiFetch(config.Api_Url + "/lot/updateLotPoids", {
											method: "POST",
											body: JSON.stringify({
												lo_barcode: this.state.colisJson.lo_barcode,
											}),
											headers: {
												"Content-Type": "application/json",
											},
										})
										.then((response) => response.json())
										.then((data) => {
											window.print();
											console.log(data);
										});
								});
						});
				} else {
					this.props.history.push("/404");
				}
			});
	}

	render() {
		let etiquetteCounter = 1;
		return (
			<div>
				<NavPublic />
				<Container>
					{this.state.formatEtiquettes == "thermique" ? (
						<EtiquetteThermique
							logo={this.state.logo}
							colis={this.state.colis}
							agence_name={this.state.agence_name}
							agence_address={this.state.agence_address}
							agence_ville={this.state.agence_ville}
							agence_phone={this.state.agence_phone}
							expediteur={this.state.expediteur}
							telephone={this.state.telephone}
							datedemande={this.state.datedemande}
							lot_package_total={this.state.lot_package_total}
						/>
					) : (
						<EtiquetteNormal
							logo={this.state.logo}
							colis={this.state.colis}
							agence_name={this.state.agence_name}
							agence_address={this.state.agence_address}
							agence_ville={this.state.agence_ville}
							agence_phone={this.state.agence_phone}
							expediteur={this.state.expediteur}
							telephone={this.state.telephone}
							datedemande={this.state.datedemande}
							lot_package_total={this.state.lot_package_total}
						/>
					)}
				</Container>
			</div>
		);
	}
}

export default withRouter(ImprimerEtiquetteLotUnite);
