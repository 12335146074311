/* eslint-disable import/first */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
const dateFormatConvert = require("helpers/dateFormatConvert");
/*CSS*/

export class BordereauTr extends React.Component {
	constructor(props) {
		super(props);
		this.BordereauTr = this.BordereauTr.bind(this);
	}

	BordereauTr() {
		let temp_bordereau = this.props.temp_bordereau;
		let temp_date = this.props.temp_date;
		let option_list = this.props.option_list;
		let counter = this.props.counter;
		return (
			<tr key={"bordereautr " + counter}>
				<td>{dateFormatConvert.getFormattedDateWithHms(temp_date)}</td>
				<td>{temp_bordereau}</td>
				<td className="d-flex  align-items-center">
					<select id={temp_bordereau} className="form-control w-25 mx-3">
						{option_list}
					</select>
					<Button
						variant="secondary"
						value={temp_bordereau}
						size="sm"
						onClick={(e) => {
							this.props.history.push(
								"/public/bordereau/demande/" +
									document.getElementById(e.target.value).value
							);
						}}
					>
						Voir
					</Button>
					<Button
						className=" mx-3"
						variant="primary"
						value={temp_bordereau}
						size="sm"
						onClick={(e) => {
							if (
								!window.confirm(
									"Supprimez la demande d'enlèvement " +
										document.getElementById(e.target.value).value +
										"?"
								)
							) {
								e.preventDefault();
							} else {
								this.props.history.push(
									"/public/bordereau/demande/delete/" +
										document.getElementById(e.target.value).value
								);
							}
						}}
					>
						Supprimer cette demande
					</Button>
				</td>
				<td>
					<Button
						className="mx-3"
						variant="secondary"
						value={temp_bordereau}
						size="sm"
						onClick={(e) => {
							this.props.history.push(
								"/public/bordereau/visualiserbordereau/" + e.target.value
							);
						}}
					>
						Voir Bordereau
					</Button>
					<Button
						variant="primary"
						value={temp_bordereau}
						size="sm"
						onClick={(e) => {
							if (
								!window.confirm(
									"Supprimez le bordereau " + e.target.value + "?"
								)
							) {
								e.preventDefault();
							} else {
								this.props.history.push(
									"/public/bordereau/delete/" + e.target.value
								);
							}
						}}
					>
						Supprimer ce bordereau
					</Button>
				</td>
			</tr>
		);
	}
	render() {
		return <this.BordereauTr />;
	}
}

export default withRouter(BordereauTr);
