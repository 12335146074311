/* eslint-disable import/first */
import React, { Component, Input, useState } from "react";
import NavAdmin from "components/nav/NavAdmin";

import { Formik, submitForm, useFormikContext } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	Toast,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";
import * as Papa from "papaparse";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getDateReleve = require("helpers/getDateReleve");
/*Css*/
import "./import.css";
export class ImporterColis extends React.Component {
	constructor() {
		super();
		this.state = {
			csvfile: undefined,
			client: [],
			id_client: "",
			file_uploaded: false,
			uploaded_file_name: ".csv",
			csv_array: [],
			toast_show: false,
			array_added_colis: [],
		};
		this.updateData = this.updateData.bind(this);
		this.toggleShowToast = this.toggleShowToast.bind(this);
		this.importCSV = this.importCSV.bind(this);
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		config
			.apiFetch(config.Api_Url + "/clients")
			.then((response) => response.json())
			.then((clients) => {
				let clients_list = [];
				for (let number = 0; number < clients["data"].length; number++) {
					clients_list.push(
						<option
							key={"clients_" + number}
							value={clients["data"][number]["id_client"]}
						>
							{clients["data"][number]["nom"]}{" "}
							{clients["data"][number]["prenom"]}
						</option>
					);
				}
				this.setState({
					clients_list: [clients_list],
					clients: clients["data"],
					id_client: clients["data"][0]["id_client"],
				});
			});
	}

	handleChange = (event) => {
		this.setState({
			csvfile: event.target.files[0],
			file_uploaded: true,
			uploaded_file_name: event.target.files[0].name,
		});
	};

	importCSV = () => {
		const { csvfile } = this.state;

		if (csvfile) {
			Papa.parse(csvfile, {
				complete: this.updateData,
				delimiter: ";",
				escapeChar: '"',
			});
		}
	};

	updateData(result) {
		var data = result.data;
		this.setState({ csv_array: data });
		let client_index = this.state.clients.findIndex(
			(c) => c.id_client == this.state.id_client
		);
		let datereleve = getDateReleve.getDateReleve();
		config
			.apiFetch(config.Api_Url + "/new/demande", {
				method: "POST",
				body: JSON.stringify({
					id_client: this.state.id_client,
					datedemande: dateFormatConvert.convertDateTimeForSql(new Date()),
					datereleve: dateFormatConvert.convertDateForSql(datereleve),
					nomExpediteur: this.state.clients[client_index].nom,
					prenomExpediteur: this.state.clients[client_index].prenom,
					adresseExpediteur: this.state.clients[client_index].adresse,
					codepostalExpediteur: this.state.clients[client_index].codepostal,
					villeExpediteur: this.state.clients[client_index].ville,
					telephoneExpediteur: this.state.clients[client_index].telephone,
					etat: "30",
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => response.json())
			.then((data) => {
				let array_added_colis = [];
				let counter = 0;
				this.state.csv_array.forEach((line) => {
					setTimeout(() => {
						let sql_line = line[0].split(";;").join(';"";');
						if (sql_line.substring(sql_line.length - 1) == ";") {
							sql_line = sql_line.replace(/.$/, ';""');
						}
						if (sql_line.charAt(0) == ";") {
							sql_line = sql_line.replace(";", '"";');
						}
						sql_line = sql_line.split(";").join(",");

						config
							.apiFetch(config.Api_Url + "/add/addColisFromCsv", {
								method: "POST",
								body: JSON.stringify({
									groupement_id: data["data"].insertId,
									csv_info: sql_line,
								}),
								headers: {
									"Content-Type": "application/json",
								},
							})
							.then((response) => response.json())
							.then((data) => {
								if (data.errno) {
									array_added_colis.push(
										<li>
											<strong>
												Erreur à l'import de ce colis. La ligne du .csv est
												invalide
											</strong>
										</li>
									);
								} else {
									array_added_colis.push(
										<li key={data["colis_id"]}>{data["colis_id"]}</li>
									);
								}

								this.setState({
									array_added_colis: array_added_colis,
								});
							});
					}, 2000 * counter++);
				});
				this.setState({
					toast_show: true,
				});
			});
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	handleFiles = (files) => {
		var reader = new FileReader();

		reader.onload = function (e) {
			var text = reader.result;
		};

		reader.readAsText(files);
	};

	toggleShowToast() {
		let toast_show = this.state.toast_show;
		this.setState({
			toast_show: !toast_show,
		});
	}

	render() {
		return (
			<div>
				<NavAdmin />

				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Importer des colis</h1>
							<Form>
								{" "}
								<Form.Label>Choisissez un fichier au format CSV :</Form.Label>
								<div className="input-group">
									<div className="custom-file mb-4">
										<input
											type="file"
											className="custom-file-input"
											id="inputGroupFile01"
											aria-describedby="inputGroupFileAddon01"
											ref={(input) => {
												this.filesInput = input;
											}}
											name="file"
											placeholder={null}
											onChange={this.handleChange}
											accept=".csv"
										/>
										<label
											className="custom-file-label"
											htmlFor="inputGroupFile01"
										>
											{this.state.uploaded_file_name}
										</label>
									</div>
								</div>
								<Form.Group controlId="formBasicCheckbox">
									<Form.Control
										as="select"
										name="client"
										onChange={(e) => {
											this.setState({
												id_client: document.getElementById("formBasicCheckbox")
													.value,
											});
										}}
									>
										{this.state.clients_list}
									</Form.Control>
								</Form.Group>
								<Button
									onClick={this.importCSV}
									size="sm"
									variant="primary"
									disabled={!this.state.file_uploaded}
								>
									Importer
								</Button>
							</Form>
							<div className="d-flex justify-content-center mt-3 w-100">
								{" "}
								<Toast
									className="w-100"
									show={this.state.toast_show}
									onClose={this.toggleShowToast}
								>
									<Toast.Header>
										{/* <img
                      src="holder.js/20x20?text=%20"
                      className="rounded mr-2"
                      alt=""
                    /> */}
										<div className="mx-auto">
											<i className="fas fa-file-csv mr-1"></i>
											{this.state.uploaded_file_name}
										</div>
									</Toast.Header>
									<Toast.Body className="p-3">
										<div className="pb-3">
											Les colis suivants ont été importés :
										</div>
										<ul>{this.state.array_added_colis}</ul>
									</Toast.Body>
								</Toast>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(ImporterColis);
