/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router-dom";
import * as config from "config/config";
/*CSS*/
import "asset/css/demandes.scss";
import DemandeTable from "./demandeTable";

class FilterMonthDateClient extends Component {
	constructor() {
		super();
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			groupementColis: [],
			colisByGroupement: [],
			groupementColisMDPR: [],
			colisMDPRByGroupement: [],
			totalMDPR: 0,
			total: 0,
			lesMois: config.lesMois,
			MDPRactive: false,
			clients_list: [],
		};
	}

	componentDidMount() {
		let page_id = this.props.match.params.page_id;
		//let startDate = this.props.match.params.startDate;
		//let endDate = this.props.match.params.endDate;

		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		if (user_role == "Admin") {
			config
				.apiFetch(
					config.Api_Url +
						"/demande/getGroupementColisAdminWithMonthClientFilter/liste/" +
						page_id +
						"/" +
						this.props.match.params.month +
						"/" +
						this.props.match.params.year +
						"/" +
						this.props.match.params.id_client,
					{
						method: "GET",
						headers: { "Content-Type": "application/json" },
					}
				)
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						groupementColis: [data],
					});

					let array_groupement_id = data.data
						.filter((x) => x.groupement_id != "")
						.map((x) => x.groupement_id);

					config
						.apiFetch(
							config.Api_Url +
								"/demande/getGroupementColisMDPRWithMonthFilter/liste/" +
								page_id +
								"/" +
								this.props.match.params.month +
								"/" +
								this.props.match.params.year,

							{
								method: "GET",
								headers: { "Content-Type": "application/json" },
							}
						)
						.then((response) => response.json())
						.then((data) => {
							array_groupement_id = array_groupement_id.concat(
								data.data
									.filter((x) => x.groupement_id != "")
									.map((x) => x.groupement_id)
							);

							this.setState({
								groupementColisMDPR: [data],
								array_groupement_id: array_groupement_id,
							});

							config
								.apiFetch(
									config.Api_Url + "/colis/getAllColisByArrayOfGroupmentId",
									{
										method: "POST",
										body: JSON.stringify({
											array_groupement_id: this.state.array_groupement_id,
										}),
										headers: { "Content-Type": "application/json" },
									}
								)
								.then((response) => response.json())
								.then((colis) => {
									this.setState({
										colisByGroupement: colis.data,
									});
								});
						});
				});
			config
				.apiFetch(
					config.Api_Url +
						"/demande/getTotalGroupementColisAdminWithMonthClientFilter/" +
						this.props.match.params.month +
						"/" +
						this.props.match.params.year +
						"/" +
						this.props.match.params.id_client,
					{
						method: "GET",
						headers: { "Content-Type": "application/json" },
					}
				)
				.then((response) => response.json())
				.then((total) => {
					this.setState({
						total: total["data"][0]["total"],
					});
				});

			config
				.apiFetch(
					config.Api_Url +
						"/demande/getTotalGroupementColisMDPRWithMonthFilter/" +
						this.props.match.params.month +
						"/" +
						this.props.match.params.year,
					{
						method: "GET",
						headers: { "Content-Type": "application/json" },
					}
				)
				.then((response) => response.json())
				.then((total) => {
					this.setState({
						totalMDPR: total["data"][0]["total"],
					});
				});
			config
				.apiFetch(config.Api_Url + "/clients")
				.then((response) => response.json())
				.then((clients) => {
					let clients_list = [];
					for (let number = 0; number < clients["data"].length; number++) {
						clients_list.push(
							<option
								key={"clients_" + number}
								value={clients["data"][number]["id_client"]}
							>
								{clients["data"][number]["nom"]}{" "}
								{clients["data"][number]["prenom"]}
							</option>
						);
					}
					this.setState({
						clients_list: [clients_list],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((agence) => {
					this.setState({
						agence: agence["data"][0],
					});

					config
						.apiFetch(
							config.Api_Url +
								"/demande/getGroupementColisAgenceWithMonthClientFilter/liste/" +
								this.state.agence.agence_id +
								"/" +
								page_id +
								"/" +
								this.props.match.params.month +
								"/" +
								this.props.match.params.year +
								"/" +
								this.props.match.params.id_client,
							{
								method: "GET",
								headers: { "Content-Type": "application/json" },
							}
						)
						.then((response) => response.json())
						.then((data) => {
							let array_groupement_id = data.data
								.filter((x) => x.groupement_id != "")
								.map((x) => x.groupement_id);
							this.setState({
								groupementColis: [data],
								array_groupement_id: array_groupement_id,
							});
							config
								.apiFetch(
									config.Api_Url + "/colis/getAllColisByArrayOfGroupmentId",
									{
										method: "POST",
										body: JSON.stringify({
											array_groupement_id: this.state.array_groupement_id,
										}),
										headers: { "Content-Type": "application/json" },
									}
								)
								.then((response) => response.json())
								.then((colis) => {
									this.setState({
										colisByGroupement: colis.data,
									});
								});
						});
					config
						.apiFetch(
							config.Api_Url +
								"/demande/getTotalGroupementColisAgenceWithMonthClientFilter/" +
								this.state.agence.agence_id +
								"/" +
								this.props.match.params.month +
								"/" +
								this.props.match.params.year +
								"/" +
								this.props.match.params.id_client,
							{
								method: "GET",
								headers: { "Content-Type": "application/json" },
							}
						)
						.then((response) => response.json())
						.then((total) => {
							this.setState({
								total: total["data"][0]["total"],
							});
						});
					config
						.apiFetch(
							config.Api_Url + "/clients/agence/" + this.state.agence.agence_id
						)
						.then((response) => response.json())
						.then((clients) => {
							let clients_list = [];
							for (let number = 0; number < clients["data"].length; number++) {
								clients_list.push(
									<option
										key={"clients_" + number}
										value={clients["data"][number]["id_client"]}
									>
										{clients["data"][number]["nom"]}{" "}
										{clients["data"][number]["prenom"]}
									</option>
								);
							}
							this.setState({
								clients_list: [clients_list],
							});
						})
						.catch((err) => {
							console.log(err);
							// some error handling
						});
				});
		}
	}

	render() {
		return (
			<DemandeTable
				groupementColis={this.state.groupementColis}
				colisByGroupement={this.state.colisByGroupement}
				groupementColisMDPR={this.state.groupementColisMDPR}
				colisMDPRByGroupement={this.state.colisMDPRByGroupement}
				array_groupement_id={this.state.array_groupement_id}
				total={this.state.total}
				totalMDPR={this.state.totalMDPR}
				MDPRactive={this.state.MDPRactive}
				clients_list={this.state.clients_list}
				filterMonth={true}
				lesMois={this.state.lesMois}
				url={"/admin/demande/FilterAdminDemandeMonthDateClient/page/"}
			/>
		);
	}
}

export default withRouter(FilterMonthDateClient);
