import React, { Component, Input, useState } from "react";
import NavPublic from "components/nav/NavPublic";

import { Formik, submitForm, useFormikContext } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
	Collapse,
	Container,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";
import SearchForm from "./searchForm";

/*Css*/

export class Expedition extends React.Component {
	constructor() {
		super();
		this.state = {
			clients: [],
			first_client: "",
		};
	}

	//interval Min var
	currentIntervalleMin = 0;

	handleClick = () => {
		this.props.history.push("/admin/profil");
	};
	componentDidMount() {
		config
			.apiFetch(config.Api_Url + "/profil/liste")
			.then((response) => response.json())
			.then((profil) => {
				let profil_list = [];
				for (let number = 0; number < profil["data"].length; number++) {
					profil_list.push(
						<option key={"profil_" + number}>
							{profil["data"][number]["nom"]}
						</option>
					);
				}
				this.setState({
					profil_list: [profil_list],
					first_profil: profil["data"][0].nom,
				});
			});
	}
	render() {
		return (
			<div>
				<NavPublic />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1 className="pb-5">Détail des expéditions</h1>

							<SearchForm
								clients_list={this.state.clients_list}
								profil_list={this.state.profil_list}
								first_client={this.state.first_client}
								first_profil={this.state.first_profil}
							/>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(Expedition);
