/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router-dom";
import { Table } from "react-bootstrap";
import * as config from "config/config";
import NavPublic from "components/nav/NavPublic";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getGroupementColisInfos = require("helpers/getGroupementColisInfos");

/*CSS*/
import "asset/css/demandes.scss";

class DetailsDemande extends Component {
	constructor() {
		super();
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			groupementColis: [],
			groupementColisMDPR: [],
			totalMDPR: 25,
			total: 5,
		};
	}

	componentDidMount() {
		config
			.apiFetch(
				config.Api_Url +
					"/demande/getGroupementColis/" +
					this.props.match.params.groupement_id,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((data) => {
				if (data["data"].length != 0) {
					this.setState({
						groupementColis: [data],
					});
				} else {
					this.props.history.push("/404");
				}
			});
	}
	render() {
		return (
			<div>
				<NavPublic />
				<div className="container-fluid">
					<h1>Demandes d'enlèvements</h1>

					<Table responsive bordered hover>
						<thead>
							<tr>
								<th>N°demande</th>
								<th>Date demande</th>
								<th>Date Releve</th>
								<th>Nom Client</th>
								<th>Adresse</th>
								<th>Etat</th>
								<th>Etiquettes</th>
								<th>Détail</th>
							</tr>
						</thead>
						<tbody>
							{this.state.groupementColis.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								return d[keys].map((data) => {
									let detailt_url = "../demande/details/" + data.groupement_id;
									let etiquette_url =
										"../visualiseretiquette/" + data.groupement_id;
									let etat = getGroupementColisInfos.getGroupementColisStateHex(
										data.etathex
									);
									let css_class_etat = getGroupementColisInfos.getGroupementColisStateHexCss(
										data.etathex
									);
									let traite = getGroupementColisInfos.getGroupementColisTraite(
										data.traitehex
									);
									let css_class_traite = getGroupementColisInfos.getGroupementColisTraiteCss(
										data.traitehex
									);
									return (
										<tr key={data.groupement_id} className={css_class_etat}>
											<td>{data.groupement_id}</td>
											<td>
												{dateFormatConvert.getFormattedDateWithHms(
													data.datedemande
												)}
											</td>
											<td>
												{dateFormatConvert.getFormattedDate(data.datereleve)}
											</td>
											<td>
												{data.nomExpediteur + " " + data.prenomExpediteur}
											</td>
											<td>
												{data.codepostalExpediteur + "\n"}
												{data.adresseExpediteur}
											</td>
											<td>{etat}</td>
											<td>
												<a href={etiquette_url}>
													<i className="fas fa-barcode"></i>
													Étiquettes
												</a>
											</td>
											<td>
												<a href={detailt_url}>
													<i className="fas fa-info-circle"></i>
													Détails
												</a>
											</td>
										</tr>
									);
								});
							})}
						</tbody>
					</Table>
				</div>
			</div>
		);
	}
}

export default withRouter(DetailsDemande);
