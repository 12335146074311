/* eslint-disable import/first */
import React from "react";
import * as config from "config/config";
import { withRouter } from "react-router-dom";
import SearchTable from "./searchTable";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
const geolocalisation = require("helpers/geolocalisation");
import MarkerWithInfoWindow from "helpers/markerWithInfoWindow";
import { Alert } from "react-bootstrap";

export class searchByPackageCode extends React.Component {
	constructor() {
		super();
		this.state = {
			agence: [],
			colis: [],
			geolocalisation: [],
			centerLon: 0,
			centerLat: 0,
			title: "",
			showMap: false,
		};
	}
	componentDidMount() {
		let colis_id = this.props.match.params.colis_id;
		//let startDate = this.props.match.params.startDate;
		//let endDate = this.props.match.params.endDate;
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		if (user_role == "Admin") {
			config
				.apiFetch(config.Api_Url + "/colis/getColisbyCodeAdmin/" + colis_id, {
					method: "GET",
					headers: { "Content-Type": "application/json" },
				})
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					this.setState({
						colis: [data],
					});
				});
		} else {
			config
				.apiFetch(config.Api_Url + "/colis/getColisbyCodeAdmin/" + colis_id, {
					method: "GET",
					headers: { "Content-Type": "application/json" },
				})
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						colis: [data],
					});
				});
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((agence) => {
					this.setState({
						agence: agence["data"][0],
					});
				});
		}
		config
			.apiFetch(
				config.Api_Url + "/gps/getColisOrLotGeolocalisationById/" + colis_id,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((data) => {
				if (data["data"].length) {
					this.setState({
						geolocalisation: [data],
						centerLon: data["data"][0].cg_lon,
						centerLat: data["data"][0].cg_lat,
						showMap: true,
					});
				}
			});
	}
	render() {
		return (
			<div>
				<SearchTable
					colis={this.state.colis}
					hasPagination={false}
					methodPackageCode={true}
					agence={this.state.agence}
				/>
				{this.state.showMap ? (
					<LoadScript googleMapsApiKey={config.googleMapsApiKey}>
						<GoogleMap
							mapContainerStyle={{ width: "100%", height: "500px" }}
							center={{ lng: this.state.centerLon, lat: this.state.centerLat }}
							zoom={22}
						>
							{this.state.geolocalisation.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								let total = d[keys].length;
								return d[keys].map((data, index) => {
									let test = geolocalisation.generateOverlappingMarker(
										data.cg_lon,
										data.cg_lat,
										index,
										total
									);
									return (
										<MarkerWithInfoWindow
											key={data.cg_colis_id}
											position={{
												lng: test[0],
												lat: test[1] + 0.000005,
											}}
											cg_colis_id={data.cg_colis_id}
											info={data.cg_add}
										/>
									);
								});
							})}
						</GoogleMap>
					</LoadScript>
				) : (
					<div>
						<Alert variant="warning">
							Pas de position GPS disponible au moment de la livraison.
						</Alert>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(searchByPackageCode);
