/* eslint-disable import/first */
import React from "react";
import * as config from "config/config";
import { withRouter } from "react-router-dom";
import SearchTable from "./searchTable";

export class searchByDateAndDestination extends React.Component {
	constructor() {
		super();
		this.state = {
			colis: [],
			total: 0,
		};
	}
	componentDidMount() {
		//check if old client or brand new
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;

		let endDate = this.props.match.params.end;
		let starDate = this.props.match.params.start;
		let page = this.props.match.params.page;
		let nom = this.props.match.params.nom;
		let ville = this.props.match.params.ville;

		config
			.apiFetch(
				config.Api_Url +
					"/colis/getCountColisbyDateAndDestinationPublic/" +
					starDate +
					"/" +
					endDate +
					"/" +
					nom +
					"/" +
					ville +
					"/" +
					id_client,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((total) => {
				console.log(total);
				if (total["data"][0]["total"] > 1000) {
					this.props.history.push("/admin/colis/erreur");
				} else {
					this.setState({
						total: [total["data"][0]["total"]],
					});
					config
						.apiFetch(
							config.Api_Url +
								"/colis/getColisbyDateAndDestinationPublic/" +
								starDate +
								"/" +
								endDate +
								"/" +
								nom +
								"/" +
								ville +
								"/" +
								page +
								"/" +
								id_client,
							{
								method: "GET",
								headers: { "Content-Type": "application/json" },
							}
						)
						.then((response) => response.json())
						.then((data) => {
							console.log(data);
							this.setState({
								colis: [data],
							});
						});
				}
			});
	}
	render() {
		return (
			<SearchTable
				colis={this.state.colis}
				total={this.state.total}
				methodDateDestination={true}
				hasPagination={true}
			/>
		);
	}
}

export default withRouter(searchByDateAndDestination);
