import React, { Component, useState } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
	Collapse,
	Container,
} from "react-bootstrap";
import * as config from "config/config";
class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.Search = this.Search.bind(this);
	}

	Search() {
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		// Declare a new state variable, which we'll call "count"
		const [count1, setOpen1] = useState(true);
		const [count2, setOpen2] = useState(false);

		const lesMois = config.lesMois;
		let month = [];
		for (let number = 0; number < lesMois.length; number++) {
			month.push(
				<option key={"option_month_" + number} value={number}>
					{lesMois[number]}
				</option>
			);
		}
		return (
			<div>
				<ButtonToolbar>
					<Button
						size="sm"
						className="mr-1"
						variant="outline-secondary"
						onClick={() => {
							setOpen1(!count1);
							setOpen2(false);
						}}
						aria-controls="search_by_date"
						aria-expanded={count1}
						active={count1}
					>
						Demande hors site commandé
					</Button>
					<Button
						size="sm"
						className="mr-1"
						variant="outline-secondary"
						onClick={() => {
							setOpen2(!count2);
							setOpen1(false);
						}}
						aria-controls="search_by_package"
						aria-expanded={count2}
						active={count2}
					>
						Demande hors site traité
					</Button>
				</ButtonToolbar>

				<Collapse in={count1}>
					<div id="search_origine">
						<Formik
							initialValues={{
								month: 0,
								client: this.props.first_client,
							}}
							enableReinitialize={true}
							onSubmit={(values) => {
								let month = parseInt(values.month) + 1;
								let agence =
									user_role == "Admin"
										? values.agence
										: this.props.data.agence_id;
								if (this.state.button == "Facturation") {
									this.props.history.push(
										"/admin/facturationhorssite/affichagefacturationorigine/" +
											agence +
											"/" +
											month +
											"/" +
											values.year
									);
								} else if (this.state.button == "CA") {
									this.props.history.push(
										"/admin/facturationhorssite/affichagecaorigine/" +
											agence +
											"/" +
											month +
											"/" +
											values.year
									);
								}
							}}
						>
							{(props) => {
								const {
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Row>
											<Col md>
												<Form.Label className="required">Agence :</Form.Label>

												{user_role == "Admin" ? (
													<Form.Control
														required
														as="select"
														name="agence"
														value={values.agence || ""}
														onChange={handleChange}
													>
														<option></option>
														{this.props.agences}
													</Form.Control>
												) : (
													<div>
														<Form.Control
															readOnly
															placeholder={this.props.data.agence_name}
														></Form.Control>
													</div>
												)}
											</Col>
										</Row>
										<Row>
											<Col md>
												{" "}
												<Form.Label className="required">Mois :</Form.Label>
												<Form.Control
													required
													as="select"
													name="month"
													onChange={handleChange}
												>
													{month}
												</Form.Control>
											</Col>
											<Col md>
												{" "}
												<Form.Label className="required">Année :</Form.Label>
												<Form.Control
													required
													type="number"
													aria-describedby="inputGroupPrepend"
													name="year"
													onChange={handleChange}
												></Form.Control>
											</Col>
										</Row>
										<Row>
											<Col md>
												<Button
													size="sm"
													type="submit"
													onClick={(e) => {
														this.setState({ button: "Facturation" });
													}}
													disabled={isSubmitting}
													variant="primary"
												>
													Afficher facture
												</Button>
												<Button
													size="sm"
													className="ml-3"
													type="submit"
													onClick={(e) => {
														this.setState({ button: "CA" });
													}}
													disabled={isSubmitting}
													variant="primary"
												>
													CA Mensuel
												</Button>
											</Col>
										</Row>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>

				<Collapse in={count2}>
					<div id="search_cible">
						<Formik
							initialValues={{
								month: 0,
								client: this.props.first_client,
							}}
							enableReinitialize={true}
							onSubmit={(values) => {
								let month = parseInt(values.month) + 1;
								let agence =
									user_role == "Admin"
										? values.agence
										: this.props.data.agence_id;
								if (this.state.button == "Facturation") {
									this.props.history.push(
										"/admin/facturationhorssite/affichagefacturationcible/" +
											agence +
											"/" +
											month +
											"/" +
											values.year
									);
								} else if (this.state.button == "CA") {
									this.props.history.push(
										"/admin/facturationhorssite/affichagecacible/" +
											agence +
											"/" +
											month +
											"/" +
											values.year
									);
								}
							}}
						>
							{(props) => {
								const {
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Row>
											<Col md>
												<Form.Label className="required">Agence :</Form.Label>

												{user_role == "Admin" ? (
													<Form.Control
														required
														as="select"
														name="agence"
														value={values.agence || ""}
														onChange={handleChange}
													>
														<option></option>
														{this.props.agences}
													</Form.Control>
												) : (
													<div>
														<Form.Control
															readOnly
															placeholder={this.props.data.agence_name}
														></Form.Control>
													</div>
												)}
											</Col>
										</Row>
										<Row>
											<Col md>
												{" "}
												<Form.Label className="required">Mois :</Form.Label>
												<Form.Control
													required
													as="select"
													name="month"
													onChange={handleChange}
												>
													{month}
												</Form.Control>
											</Col>
											<Col md>
												{" "}
												<Form.Label className="required">Année :</Form.Label>
												<Form.Control
													required
													type="number"
													aria-describedby="inputGroupPrepend"
													name="year"
													onChange={handleChange}
												></Form.Control>
											</Col>
										</Row>
										<Row>
											<Col md>
												<Button
													size="sm"
													type="submit"
													onClick={(e) => {
														this.setState({ button: "Facturation" });
													}}
													disabled={isSubmitting}
													variant="primary"
												>
													Afficher facture
												</Button>
												<Button
													size="sm"
													className="ml-3"
													type="submit"
													onClick={(e) => {
														this.setState({ button: "CA" });
													}}
													disabled={isSubmitting}
													variant="primary"
												>
													CA Mensuel
												</Button>
											</Col>
										</Row>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.Search />
			</div>
		);
	}
}

export default withRouter(SearchForm);
