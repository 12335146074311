/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
	BrowserRouter,
	Route,
	Switch,
	Link,
	Redirect,
	withRouter,
} from "react-router-dom";
import { Formik } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	Dropdown,
} from "react-bootstrap";
import DatePickerField from "helpers/datePickerField";
import * as config from "config/config";
import NavPublic from "components/nav/NavPublic";
const dateFormatConvert = require("helpers/dateFormatConvert");
import SearchForm from "./searchForm";
import Checkbox from "helpers/checkbox";
const getGroupementColisInfos = require("helpers/getGroupementColisInfos");
import DemandeTable from "./../components/demandeTable";

/*CSS*/
import "asset/css/demandes.scss";

class FilterWithoutBordereau extends Component {
	constructor() {
		super();
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			groupementColis: [],
			groupementColisMDPR: [],
			groupementColis_array_checkbox_state: [],
			totalMDPR: 25,
			total: 5,
		};
	}

	componentDidMount() {
		let page_id = this.props.match.params.page_id;
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;

		config
			.apiFetch(
				config.Api_Url +
					"/demande/getGroupementColisPublicWithoutBordereau/liste/" +
					page_id +
					"/" +
					id_client,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((data) => {
				let groupementColis_array_checkbox_state = [];
				data["data"].forEach((d) => {
					if (d.etathex != "34")
						groupementColis_array_checkbox_state.push(d.groupement_id);
				});
				this.setState({
					groupementColis: [data],
					groupementColis_array_checkbox_state: groupementColis_array_checkbox_state,
				});
			});
		config
			.apiFetch(
				config.Api_Url +
					"/demande/getTotalGroupementColisPublicWithoutBordereau/" +
					id_client,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((total) => {
				this.setState({
					total: total["data"][0]["total"],
				});
			});
	}
	render() {
		let urlDropdownShow = "/public/demande/filterHasBordereau/page/1";
		let monthFilter = "FilterWithoutBordereauFilterMonthYear";
		let dateFilter = "FilterWithoutBordereauFilterDate";
		return (
			<div>
				<NavPublic />
				<div className="container-fluid">
					<h1>Suivi des demandes d'enlèvements</h1>
					<h2 className="text-center">
						Demandes d'enlèvement <strong>non</strong> incluses dans un
						bordereau
					</h2>
					<SearchForm
						urlDropdownShow={urlDropdownShow}
						groupementColis_array_checkbox_state={
							this.state.groupementColis_array_checkbox_state
						}
						dateFilter={dateFilter}
						monthFilter={monthFilter}
					/>
					<DemandeTable
						groupementColis={this.state.groupementColis}
						total={this.state.total}
						withoutBordereau={true}
					/>
				</div>
			</div>
		);
	}
}

export default withRouter(FilterWithoutBordereau);
