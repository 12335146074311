function getDateReleve() {
	let now = new Date();
	let deadline = new Date();
	deadline.setHours(15, 30, 0, 0);
	if (deadline < now) {
		let tomorrow = new Date();
		tomorrow.setDate(new Date().getDate() + 1);
		return tomorrow;
	} else {
		now.setHours(0, 0, 0, 0);
		return now;
	}
}

exports.getDateReleve = getDateReleve;
