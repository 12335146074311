/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";

/*CSS*/
import "../../../../asset/css/add.css";

class AddClient extends Component {
	constructor() {
		super();
		this.state = {
			formatEtiquettes: [],
			profil: [],
			casUPS: [],
			serviceCode: [],
			agences: [],
			data: [],
		};
	}
	componentDidMount() {
		config
			.apiFetch(config.Api_Url + "/clients/formatEtiquettes/")
			.then((response) => response.json())
			.then((data) => {
				let formatEtiquettes = [];
				for (let number = 0; number < data["data"].length; number++) {
					formatEtiquettes.push(
						<option key={"option_formatEtiquettes_" + number}>
							{data["data"][number]["formatEtiquettes"]}
						</option>
					);
				}
				this.setState({
					formatEtiquettes: [formatEtiquettes],
				});
			});
		config
			.apiFetch(config.Api_Url + "/clients/profil/")
			.then((response) => response.json())
			.then((data) => {
				let profil = [];
				for (let number = 0; number < data["data"].length; number++) {
					profil.push(
						<option key={"option_profil_" + number}>
							{data["data"][number]["nom"]}
						</option>
					);
				}
				this.setState({
					profil: [profil],
				});
			});
		config
			.apiFetch(config.Api_Url + "/clients/casUPS/")
			.then((response) => response.json())
			.then((data) => {
				let casUPS = [];
				for (let number = 0; number < data["data"].length; number++) {
					casUPS.push(
						<option key={"option_casUPS_" + number}>
							{data["data"][number]["casUPS"]}
						</option>
					);
				}
				this.setState({
					casUPS: [casUPS],
				});
			});
		config
			.apiFetch(config.Api_Url + "/clients/serviceCode/")
			.then((response) => response.json())
			.then((data) => {
				let serviceCode = [];
				for (let number = 0; number < data["data"].length; number++) {
					serviceCode.push(
						<option key={"option_serviceCode_" + number}>
							{data["data"][number]["serviceCode"]}
						</option>
					);
				}
				this.setState({
					serviceCode: [serviceCode],
				});
			});
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		//IF ADMIN, we provide agence modify
		//If not we set agence by defaut of the account
		if (user_role == "Admin") {
			config
				.apiFetch("/agences")
				.then((response) => response.json())
				.then((data) => {
					let agences = [];
					for (let number = 0; number < data["data"].length; number++) {
						agences.push(
							<option
								key={"option_agences_" + number}
								value={data["data"][number]["agence_id"]}
							>
								{data["data"][number]["agence_name"]}
							</option>
						);
					}
					this.setState({
						agences: [agences],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						data: data["data"][0],
					});
				});
		}
	}
	render() {
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Ajouter un client</h1>
							<h2>Général</h2>
							<Formik
								initialValues={{
									nom: "",
									prenom: "",
									vchNumFacturation: "",
									id_clientParent: "",
									formatEtiquettes: "",
									profil: "",
									login: "",
									password: "",
									adresse: "",
									codepostal: "",
									ville: "",
									telephone: "",
									fax: "",
									mail: "",
									profession: "",
									casUPS: "",
									serviceCode: "",
									heureLimite: "",
									nomPoint: "",
									adressePoint: "",
									heurePoint: "",
									agence: "",
								}}
								onSubmit={(values, { setSubmitting }) => {
									config
										.apiFetch(config.Api_Url + "/new/clients", {
											method: "POST",
											body: JSON.stringify({
												id_clientParent: values.id_clientParent || 0,
												vchNumFacturation: values.vchNumFacturation,
												nom: values.nom,
												prenom: values.prenom,
												login: values.login,
												password: values.password,
												adresse: values.adresse,
												codepostal: values.codepostal,
												ville: values.ville,
												telephone: values.telephone,
												fax: values.fax,
												mail: values.mail,
												profession: values.profession,
												profil: values.profil,
												formatEtiquettes: values.formatEtiquettes,
												heureLimite: values.heureLimite,
												casUPS: values.casUPS || 0,
												nomPoint: values.nomPoint,
												adressePoint: values.adressePoint,
												heurePoint: values.heurePoint,
												serviceCode: values.serviceCode,
												agence:
													user_role == "Admin"
														? values.agence
															? values.agence
															: "NULL"
														: this.state.data.agence_id,
											}),
											headers: {
												"Content-Type": "application/json",
											},
										})
										.then((response) => response.json())
										.then((data) => {
											if ("data" in data) {
												this.props.history.push("/admin/clients/1");
											} else {
												alert(
													"Il y a une erreur ou ce couple identifiant/mot de passe existe déjà"
												);
											}
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit}>
										<Form.Label className="required">Nom :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="nom"
											value={values.nom}
											onChange={handleChange}
										/>
										<Form.Label className="required">Prénom :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="prenom"
											value={values.prenom}
											onChange={handleChange}
										/>
										<Form.Label>Numéro Facturation :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="vchNumFacturation"
											value={values.vchNumFacturation}
											onChange={handleChange}
										/>
										<Form.Label>Compte parent :</Form.Label>
										<Form.Control
											type="number"
											aria-describedby="inputGroupPrepend"
											name="id_clientParent"
											value={values.id_clientParent}
											onChange={handleChange}
										/>
										<Form.Label>Profil :</Form.Label>
										<Form.Control
											as="select"
											name="profil"
											value={values.profil}
											onChange={handleChange}
										>
											<option></option>
											{this.state.profil}
										</Form.Control>
										<Form.Label className="required">Login :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="login"
											value={values.login}
											onChange={handleChange}
										/>
										<Form.Label className="required">Mot de Passe : </Form.Label>
										<Form.Control
											required
											type="password"
											aria-describedby="inputGroupPrepend"
											name="password"
											value={values.password}
											onChange={handleChange}
										/>
										<Form.Label className="required">Adresse :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="adresse"
											value={values.adresse}
											onChange={handleChange}
										/>
										<Form.Label className="required">Code Postal :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="codepostal"
											value={values.codepostal}
											onChange={handleChange}
											maxlength="5"
										/>
										<Form.Label className="required">Ville :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="ville"
											value={values.ville}
											onChange={handleChange}
										/>
										<Form.Label className="required">Téléphone :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="telephone"
											value={values.telephone}
											onChange={handleChange}
										/>
										<Form.Label>Fax :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="fax"
											value={values.fax}
											onChange={handleChange}
										/>
										<Form.Label>Mail :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="mail"
											value={values.mail}
											onChange={handleChange}
										/>
										<Form.Label>Profession :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="profession"
											value={values.profession}
											onChange={handleChange}
										/>
										<Form.Label className="required">
											Type d'enlèvement :
										</Form.Label>
										<Form.Control
											as="select"
											required
											name="casUPS"
											value={values.casUPS}
											onChange={handleChange}
										>
											<option></option>
											{this.state.casUPS}
										</Form.Control>
										<Form.Label className="required">Agence :</Form.Label>

										{user_role == "Admin" ? (
											<Form.Control
												required
												as="select"
												name="agence"
												value={values.agence || ""}
												onChange={handleChange}
											>
												<option></option>
												{this.state.agences}
											</Form.Control>
										) : (
											<div>
												<Form.Control
													readOnly
													placeholder={this.state.data.agence_name}
												></Form.Control>
											</div>
										)}

										<Form.Label className="required">
											Format Etiquettes :
										</Form.Label>
										<Form.Control
											required
											as="select"
											name="formatEtiquettes"
											value={values.formatEtiquettes}
											onChange={handleChange}
										>
											<option></option>
											{this.state.formatEtiquettes}
										</Form.Control>

										<h2 className="mt-5">UPS</h2>
										<Form.Label>Type de service UPS :</Form.Label>
										<Form.Control
											as="select"
											name="serviceCode"
											value={values.serviceCode}
											onChange={handleChange}
										>
											<option></option>
											{this.state.serviceCode}
										</Form.Control>
										<Form.Label>Heure limite de demande :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="heureLimite"
											value={values.heureLimite}
											onChange={handleChange}
										/>
										<Form.Label>Nom UPS access point :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="nomPoint"
											value={values.nomPoint}
											onChange={handleChange}
										/>
										<Form.Label>Adresse UPS access point :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="adressePoint"
											value={values.adressePoint}
											onChange={handleChange}
										/>
										<Form.Label>Heure limite de dépôt :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="heurePoint"
											value={values.heurePoint}
											onChange={handleChange}
										/>
										<Button
											className="my-4"
											size="sm"
											type="submit"
											disabled={isSubmitting}
										>
											Enregistrer
										</Button>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(AddClient);
