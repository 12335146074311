import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

class DeleteAgence extends Component {
	constructor() {
		super();
	}
	componentDidMount() {
		config
			.apiFetch(config.Api_Url + "/delete/agence", {
				method: "POST",
				body: JSON.stringify({
					agence_id: this.props.match.params.agence_id,
				}),
				headers: { "Content-Type": "application/json" },
			})
			.then((response) => response.json())
			.then((data) => {
				this.props.history.goBack();
			});
	}
	render() {
		return null;
	}
}

export default withRouter(DeleteAgence);
