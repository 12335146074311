import React, { Component, useState } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import {
  Form,
  Button,
  Row,
  Col,
  Table,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import * as config from "config/config";

import Checkbox from "helpers/checkbox";
import NavAdmin from "components/nav/NavAdmin";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getGroupementColisInfos = require("helpers/getGroupementColisInfos");
import Legends from "components/admin/demandes/component/legends";
import Search from "components/admin/demandes/component/search";
import ChangeState from "components/admin/demandes/component/changeState";
const getColisInfos = require("helpers/getColisInfos");
const checkUnusualColis = require("helpers/checkUnusualColis");
/*CSS*/
import "asset/css/demandes.scss";
import { isPartially } from "services/status/partialStatus";
import dayjs from "dayjs";

class DemandeTable extends Component {
  constructor(props) {
    super(props);
    this.DemandeTable = this.DemandeTable.bind(this);
  }

  componentDidMount() {
    config
      .apiFetch(config.Api_Url + "/colis/getAllColisState", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => response.json())
      .then((data) => {
        let packageState = [];
        for (let number = 0; number < data["data"].length; number++) {
          let etat = getColisInfos.getColisState(
            data["data"][number]["etatlivraison"]
          );

          packageState.push(
            <option
              key={"option_state_" + number}
              value={data["data"][number]["etatlivraison"]}
            >
              {etat}
            </option>
          );
        }
        this.setState({
          packageState: [packageState],
        });
      });
  }

  DemandeTable() {
    let active = parseInt(this.props.match.params.page_id);
    let classNamePagination = "";
    let items = [];
    let first_pagination = 1;
    if (
      active != 1 &&
      active != 2 &&
      active != 3 &&
      active != 4 &&
      active != 5
    ) {
      for (
        let number = active - 4;
        number <= this.props.total / 15 + 1;
        number++
      ) {
        if (number > active + 4) {
          break;
        }
        if (active === number) {
          classNamePagination = "page-item active";
        } else {
          classNamePagination = "page-item";
        }
        items.push(
          <li key={"pagination_" + number} className={classNamePagination}>
            <a className="page-link" href={number}>
              {number}
            </a>
          </li>
        );
      }
    } else {
      for (
        let number = first_pagination;
        number <= this.props.total / 15 + 1;
        number++
      ) {
        if (number > active + 4) {
          break;
        }
        if (active === number) {
          classNamePagination = "page-item active";
        } else {
          classNamePagination = "page-item";
        }
        items.push(
          <li key={"pagination_" + number} className={classNamePagination}>
            <a className="page-link" href={number}>
              {number}
            </a>
          </li>
        );
      }
    }
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{ checked: [], changing_state: "30" }}
        onSubmit={(values, { setSubmitting }) => {
          //Remove all existed profil's line under the same name
          config
            .apiFetch(config.Api_Url + "/demande/updateMultiGroupement", {
              method: "POST",
              body: JSON.stringify({
                groupement_id: values.checked,
                etat: values.changing_state,
              }),
              headers: { "Content-Type": "application/json" },
            })
            .then((response) => response.json())
            .then((response_details) => {
              window.location.reload();
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Table responsive bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>N°demande</th>
                  <th>Type</th>
                  <th>Date demande</th>
                  <th>Expéditeur</th>
                  <th>Destinataire</th>
                  <th>Suivi du colis</th>
                  <th>Statut final</th>
                  <th>Étiquettes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.groupementColis.map((dynamicData, Key) => {
                  let keys = Object.keys(dynamicData);
                  let d = dynamicData;
                  return d[keys].map((data) => {
                    let etat =
                      getGroupementColisInfos.getGroupementColisStateHex(
                        data.etathex
                      );
                    let css_class_etat =
                      getGroupementColisInfos.getGroupementColisStateHexCss(
                        data.etathex
                      );
                    let traite =
                      getGroupementColisInfos.getGroupementColisTraite(
                        data.traitehex
                      );
                    let css_class_traite =
                      getGroupementColisInfos.getGroupementColisTraiteCss(
                        data.traitehex
                      );
                    let detailt_url =
                      "/public/demande/details/" + data.groupement_id;
                    let etiquette_url =
                      "/public/demande/visualiseretiquette/" +
                      data.groupement_id;

                    const colisByGroupement =
                      this.props.groupementColis[0]?.data.filter(
                        (x) => x.groupement_id === data.groupement_id
                      );

                    let estLot = colisByGroupement[0]?.colis[0]?.estLot

                    const isPartiallyCollected = isPartially("collected", colisByGroupement, estLot);

                    const isPartiallyDelivered = isPartially("delivered", colisByGroupement, estLot);

                    const isPartiallyEAL = isPartially("EAL", colisByGroupement, estLot);

                    return (
                      <tr key={data.groupement_id} className={css_class_etat}>
                        <td>
                          <Checkbox
                            id={data.groupement_id}
                            name="checked"
                            value={data.groupement_id}
                            disabled={etat === "Livré"}
                          />
                        </td>
                        <td>{data.groupement_id}</td>
                        <td>{estLot ? "Lot" : "Colis"}</td>
                        <td>
                          {dayjs(data.datedemande).format(
                            "DD/MM/YYYY [à] H:mm"
                          )}
                        </td>
                        <td>
                          {data.nomExpediteur && data.prenomExpediteur
                            ? data.nomExpediteur + " " + data.prenomExpediteur
                            : ""}
                          <br />
                          {data.adresseExpediteur && data.codepostalExpediteur
                            ? data.adresseExpediteur +
                              ", " +
                              data.codepostalExpediteur
                            : ""}
                          <br />
                          {data.villeExpediteur}
                        </td>
                        <td>
                          {data.colis[0]?.nomdest || data.colis[0]?.prenomdest
                            ? data.colis[0]?.nomdest + " " + data.colis[0]?.prenomdest
                            : ""}
                          <br />
                          {data.colis[0]?.adressedest && data.colis[0]?.codepostaldest
                            ? data.colis[0]?.adressedest + ", " + data.colis[0]?.codepostaldest
                            : ""}
                          <br />
                          {data.colis[0]?.villedest}
                        </td>
                        <td>
                          {data.etathex === "30"
                            ? "En attente d'enlèvement"
                            : `${
                                isPartiallyCollected
                                  ? "Partiellement pris en charge "
                                  : "Pris en charge "
                              } ${
                                data.nom &&
                                data.nom !== undefined &&
                                data.prenom &&
                                data.prenom !== undefined
                                  ? `par ${data.nom} ${data.prenom}`
                                  : ""
                              } ${
                                data.datecharge !== undefined &&
                                data.datecharge !== null
                                  ? "le " +
                                    dayjs(data.datecharge).format(
                                      "DD/MM/YYYY [à] H:mm"
                                    )
                                  : "le " +
                                    dayjs(data.datedemande).format(
                                      "DD/MM/YYYY [à] H:mm"
                                    )
                              }`}
                        </td>
                        <td>
                          {data.etathex === "30"
                            ? ""
                            : `${
                                isPartiallyDelivered
                                  ? "Partiellement livré"
                                  : isPartiallyEAL
                                  ? "En cours de livraison (Colis manquant)"
                                  : isPartiallyCollected
                                  ? "Partiellement collecté"
                                  : data.etathex === "33" ||
                                    data.etathex === "54"
                                  ? "En cours de livraison"
                                  : etat
                              }` +
                              " le " +
                              dayjs(data.datereleve).format(
                                "DD/MM/YYYY [à] H:mm"
                              )}
                        </td>
                        <td>
                          <a href={etiquette_url}>
                            <i className="fas fa-barcode"></i>
                            Étiquettes
                          </a>
                        </td>
                        {/* <td className={data.cible ? "hors-site" : ""}>
															{data.cible}
														</td> */}

                        <td>
                          <a href={detailt_url}>
                            <i className="fas fa-info-circle"></i>
                            Détails
                          </a>
                        </td>
                        {/* <td className={css_class_traite}>{traite}</td> */}
                      </tr>
                    );
                  });
                })}
              </tbody>
            </Table>
            <Row>
              <Col xs={4}>
                <Row>
                  <Form.Group controlId="stateSelect" className="w-100">
                    <Col xs={12}>
                      <Form.Control
                        as="select"
                        name="changing_state"
                        onChange={handleChange}
                      >
                        <option value="30">
                          Changer pour : "En attente d'enlèvement"
                        </option>
                        <option value="32">Changer pour : "Annulée"</option>
                        {/* {this.props.withoutBordereau ? (
													<option value="99">
														Créer des bordereaux à partir des demandes
														sélectionnées
													</option>
												) : null} */}
                      </Form.Control>
                    </Col>
                    <Col xs={4}>
                      <Button
                        size="sm"
                        className="w-100 mt-2"
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Valider
                      </Button>
                    </Col>
                  </Form.Group>
                </Row>
              </Col>
              <Col xs={4}>
                <nav aria-label="Page navigation example">
                  <ul className="pagination">{items}</ul>
                </nav>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
  render() {
    return (
      <div>
        <this.DemandeTable />
      </div>
    );
  }
}

export default withRouter(DemandeTable);
