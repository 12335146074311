import React, { Component, useState } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Button, Collapse, ButtonToolbar, Form } from "react-bootstrap";
import DatePickerFieldRequired from "helpers/datePickerFieldRequired";
import * as config from "config/config";
const getColisInfos = require("helpers/getColisInfos");

class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.Search = this.Search.bind(this);
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			packageState: [],
		};
	}

	componentDidMount() {
		config
			.apiFetch(config.Api_Url + "/colis/getAllColisState", {
				method: "GET",
				headers: { "Content-Type": "application/json" },
			})
			.then((response) => response.json())
			.then((data) => {
				let packageState = [];
				for (let number = 0; number < data["data"].length; number++) {
					let etat = getColisInfos.getColisState(
						data["data"][number]["etatlivraison"]
					);

					packageState.push(
						<option
							key={"option_state_" + number}
							value={data["data"][number]["etatlivraison"]}
						>
							{etat}
						</option>
					);
				}
				this.setState({
					packageState: [packageState],
				});
			});
	}

	Search() {
		// Declare a new state variable, which we'll call "show"
		const [showPeriodeStatut, setOpenPeriodeStatut] = useState(true);
		const [showPackageNumber, setOpenPackageNumber] = useState(false);
		const [showPeriodeDestination, setOpenPeriodeDestination] = useState(false);

		return (
			<div>
				<ButtonToolbar>
					<Button
						size="sm"
						variant="secondary"
						className="mr-2"
						onClick={() => {
							setOpenPeriodeStatut(!showPeriodeStatut);
							if (showPackageNumber) {
								setOpenPackageNumber(!showPackageNumber);
							}

							if (showPeriodeDestination) {
								setOpenPeriodeDestination(!showPeriodeDestination);
							}
						}}
						aria-controls="search_by_date_and_state"
						aria-expanded={showPeriodeStatut}
						disabled={showPeriodeStatut}
					>
						Par période et statut
					</Button>
					<Button
						size="sm"
						variant="secondary"
						className="mr-2"
						onClick={() => {
							setOpenPackageNumber(!showPackageNumber);
							if (showPeriodeStatut) {
								setOpenPeriodeStatut(!showPeriodeStatut);
							}
							{
								if (showPeriodeDestination) {
									setOpenPeriodeDestination(!showPeriodeDestination);
								}
							}
						}}
						aria-controls="search_by_package"
						aria-expanded={showPackageNumber}
						disabled={showPackageNumber}
					>
						Par numéro de colis
					</Button>
					<Button
						size="sm"
						variant="secondary"
						className="mr-2"
						onClick={() => {
							setOpenPeriodeDestination(!showPeriodeDestination);
							{
								if (showPeriodeStatut) {
									setOpenPeriodeStatut(!showPeriodeStatut);
								}
							}
							if (showPackageNumber) {
								setOpenPackageNumber(!showPackageNumber);
							}
						}}
						aria-controls="search_by_date_and_state"
						aria-expanded={showPeriodeDestination}
						disabled={showPeriodeDestination}
					>
						Par période et destinataire
					</Button>
				</ButtonToolbar>

				<Collapse in={showPeriodeStatut}>
					<div id="search_by_date_and_state">
						<Formik
							initialValues={{
								startDate: "",
								endDate: "",
								state: "",
							}}
							onSubmit={(values) => {
								let state = values.state ? values.state : "all";
								let startDate =
									values.startDate.getFullYear() +
									"-" +
									(values.startDate.getMonth() + 1) +
									"-" +
									values.startDate.getDate();
								let endDate =
									values.endDate.getFullYear() +
									"-" +
									(values.endDate.getMonth() + 1) +
									"-" +
									values.endDate.getDate();
								this.props.history.push(
									"/admin/colis/dateandstate/" +
										startDate +
										"/" +
										endDate +
										"/" +
										state +
										"/page/1"
								);
							}}
						>
							{(props) => {
								const {
									values,
									status,
									handleChange,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Form.Label>Période du : </Form.Label>
										<DatePickerFieldRequired
											name="startDate"
											className="form-control"
										/>
										<Form.Label>Au :</Form.Label>
										<DatePickerFieldRequired
											name="endDate"
											className="form-control"
										/>
										<Form.Label className="required">Statut :</Form.Label>
										<Form.Control
											required
											as="select"
											name="state"
											value={values.state || ""}
											onChange={handleChange}
										>
											<option>Tous états</option>
											{this.state.packageState}
										</Form.Control>
										<Button
											size="sm"
											className="mt-3"
											type="submit"
											disabled={isSubmitting}
											variant="primary"
										>
											Rechercher
										</Button>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>
				<Collapse in={showPackageNumber}>
					<div id="search_by_package">
						<Formik
							initialValues={{ colisCode: "" }}
							onSubmit={(values) => {
								window.location.href = "/admin/colis/" + values.colisCode;
							}}
						>
							{(propss) => {
								const {
									handleChange,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = propss;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Form.Label className="required">
											Numéro de colis :
										</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="colisCode"
											onChange={handleChange}
										/>
										<Button
											size="sm"
											className="mt-3"
											type="submit"
											disabled={isSubmitting}
											variant="primary"
										>
											Rechercher
										</Button>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>
				<Collapse in={showPeriodeDestination}>
					<div id="search_by_date_and_state">
						<Formik
							initialValues={{
								startDate: "",
								endDate: "",
								nom: "",
								ville: "",
							}}
							onSubmit={(values) => {
								let startDate =
									values.startDate.getFullYear() +
									"-" +
									(values.startDate.getMonth() + 1) +
									"-" +
									values.startDate.getDate();
								let endDate =
									values.endDate.getFullYear() +
									"-" +
									(values.endDate.getMonth() + 1) +
									"-" +
									values.endDate.getDate();

								this.props.history.push(
									"/admin/colis/dateanddestination/" +
										startDate +
										"/" +
										endDate +
										"/" +
										values.nom +
										"/" +
										values.ville +
										"/page/1"
								);
							}}
						>
							{(props) => {
								const {
									values,
									status,
									handleChange,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Form.Label>Période du : </Form.Label>
										<DatePickerFieldRequired
											name="startDate"
											className="form-control"
										/>
										<Form.Label>Au :</Form.Label>
										<DatePickerFieldRequired
											name="endDate"
											className="form-control"
										/>
										<Form.Label className="required">
											Nom Destinataire :{" "}
										</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="nom"
											onChange={handleChange}
										/>
										<Form.Label className="required">
											Ville Destinataire :{" "}
										</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="ville"
											onChange={handleChange}
										/>
										<Button
											size="sm"
											className="mt-3"
											type="submit"
											disabled={isSubmitting}
											variant="primary"
										>
											Rechercher
										</Button>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.Search />
			</div>
		);
	}
}

export default withRouter(SearchForm);
