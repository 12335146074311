/* eslint-disable import/first */
import React from "react";
import NavAdmin from "components/nav/NavAdmin";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import * as config from "config/config";
import SummaryTable from "./summaryTable";
import ColisbyAgenceTable from "./colisbyAgenceTable";
import CalculSum from "./calculSum";
import SearchForm from "./searchForm";

/*Css*/

export class AffichageFacturationOriginePerCible extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			colisclient: {},
			profils: {},
			colis: [],
			array_interval: {},
			nb_total: {},
			sum_total: {},
			client_list: [],
			filter_array: [],
			lesMois: config.lesMois,
			messages: "En cours de chargement...",
			sup_hors_site: config.hors_site_sup_value,
			cible_filter_name: "",
			origine_filter_name: "",
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		//get user name
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		config
			.apiFetch(
				config.Api_Url +
					"/colis/getAllColisbyDateAndOrigineAndCible/" +
					this.props.match.params.agence_origine +
					"/" +
					this.props.match.params.agence_cible +
					"/" +
					this.props.match.params.month +
					"/" +
					this.props.match.params.year
			)
			.then((response) => response.json())
			.then((colis) => {
				if (colis.data.length == 0) {
					this.setState({
						messages:
							"Aucun colis ou il n'y a pas de profils de facturation correspondant pour ce client",
					});
				} else {
					if (user_role == "Agence") {
						config
							.apiFetch("/getAgenceByAccount/" + user_id)
							.then((response) => {
								if (response.status == "401") {
									window.location.reload();
								} else {
									return response.json();
								}
							})
							.then((agence) => {
								let src =
									"data:image/jpg;base64," +
									new Buffer(agence["data"][0].agence_logo).toString("base64");
								this.setState({
									agence: agence["data"][0],
									logo: (
										<img
											src={src}
											style={{ maxHeight: 150, maxWidth: 150 }}
										></img>
									),
								});
							});
					}
					let temp_client = "";
					let temp_array = [];
					let temp_client_obj = {};
					let client_list = [];
					colis["data"].forEach((c) => {
						if (temp_client == c.id_client) {
							//adding colis to the array of the current client
							temp_array.push(c);
						} else {
							//adding to client_id list
							client_list.push(temp_client);
							//adding the current colis array to the object
							temp_client_obj[temp_client] = temp_array;
							//update client_id
							temp_client = c.id_client;
							//starting a new array of colius for the next client
							temp_array = [];
							temp_array.push(c);
						}
					});
					//add the last colis array to the object, then delete the empty one
					temp_client_obj[temp_client] = temp_array;
					delete temp_client_obj[""];

					client_list.push(temp_client);
					client_list.shift();

					this.setState({
						colisclient: temp_client_obj,
						client_list: client_list,
					});

					config
						.apiFetch(config.Api_Url + "/profil/getProfilbyIdClientList", {
							method: "POST",
							body: JSON.stringify({
								client_list: this.state.client_list,
							}),
							headers: {
								"Content-Type": "application/json",
							},
						})
						.then((response) => response.json())
						.then((data) => {
							let temp_client = "";
							let temp_array = [];
							let client_list = [];
							let temp_array_interval = [];
							let temp_nb_total = [];
							let temp_sum_total = [];
							let temp_client_obj = {};
							let temp_array_interval_obj = {};
							let temp_nb_total_obj = {};
							let temp_sum_total_obj = {};
							if (data["data"]) {
								data["data"].forEach((c) => {
									if (temp_client == c.id_client) {
										//adding colis to the array of the current client
										temp_array.push(c);
										temp_array_interval.push(c.max);
										temp_nb_total.push(0);
										temp_sum_total.push(0);
									} else {
										//adding to client_id list
										client_list.push(temp_client);
										//adding the current colis array to the object
										temp_client_obj[temp_client] = temp_array;
										temp_array_interval_obj[temp_client] = temp_array_interval;
										temp_nb_total_obj[temp_client] = temp_nb_total;
										temp_sum_total_obj[temp_client] = temp_sum_total;
										//update client_id
										temp_client = c.id_client;
										//starting a new array of colius for the next client
										temp_array = [];
										temp_array_interval = [];
										temp_nb_total = [];
										temp_sum_total = [];
										temp_array.push(c);
										temp_array_interval.push(c.max);
										temp_nb_total.push(0);
										temp_sum_total.push(0);
									}
								});
								//add the last colis array to the object, then delete the empty one
								temp_client_obj[temp_client] = temp_array;
								temp_array_interval_obj[temp_client] = temp_array_interval;
								temp_nb_total_obj[temp_client] = temp_nb_total;
								temp_sum_total_obj[temp_client] = temp_sum_total;
								delete temp_client_obj[""];
								delete temp_array_interval_obj[""];
								delete temp_nb_total_obj[""];
								delete temp_sum_total_obj[""];
								this.setState({
									profils: temp_client_obj,
									array_interval: temp_array_interval_obj,
									sum_total: temp_sum_total_obj,
									nb_total: temp_nb_total_obj,
								});
							}
						});
				}
			});
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	count_array(array) {
		let count = 0;
		for (let i = 0; i < array.length; ++i) {
			if (array[i] == 0) count++;
		}
		return count;
	}

	render() {
		if (Object.keys(this.state.colisclient).length == 0)
			return (
				<div>
					<NavAdmin />
					<div className="container-fluid">
						<div className="text-center">
							<h2>{this.state.messages}</h2>
						</div>
					</div>
				</div>
			);
		else {
			let item = [];
			for (var col in this.state.colisclient) {
				item.push(
					<div key={"div_" + col}>
						<CalculSum
							details={this.state.profils[col]}
							array_interval={this.state.array_interval[col]}
							nb_total={this.state.nb_total[col]}
							sum_total={this.state.sum_total[col]}
							colis={this.state.colisclient[col]}
							sup_hors_site={this.state.sup_hors_site}
						/>

						<SummaryTable
							details={this.state.profils[col]}
							array_interval={this.state.array_interval[col]}
							nb_total={this.state.nb_total[col]}
							sum_total={this.state.sum_total[col]}
							client={this.state.colisclient[col][0]}
							sup_hors_site={this.state.sup_hors_site}
							lesMois={this.state.lesMois}
							logo={this.state.logo}
						/>

						<ColisbyAgenceTable
							details={this.state.profils[col]}
							client={this.state.client}
							array_interval={this.state.array_interval[col]}
							nb_total={this.state.nb_total[col]}
							sum_total={this.state.sum_total[col]}
							lesMois={this.state.lesMois}
							colis={this.state.colisclient[col]}
							col={col}
							sup_hors_site={this.state.sup_hors_site}
						/>
						<div
							style={{
								height: "300px",
							}}
						></div>
					</div>
				);
			}

			return (
				<div>
					<NavAdmin />
					<div className="container-fluid">
						<div className="text-center">
							<SearchForm />
						</div>
						<div>
							{item}

							<Button
								onClick={this.handleClick}
								size="sm"
								className="pr-3 back-button mb-5 no-print"
							>
								<i className="fas fa-angle-left mr-2"></i>
								Retour
							</Button>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(AffichageFacturationOriginePerCible);
