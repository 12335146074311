/* eslint-disable import/first */
import React from "react";
import NavPublic from "components/nav/NavPublic";
import { withRouter } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import * as config from "config/config";

var Barcode = require("react-barcode");
/*CSS*/
import "./imprimerEtiquette.scss";
import EtiquetteThermique from "./etiquetteThermique";
import EtiquetteNormal from "./etiquetteNormal";

export class ImprimerEtiquetteLot extends React.Component {
	constructor() {
		super();
		this.state = {
			demande: [],
			colis: [],
			expediteur: "",
			telephone: "",
			datedemande: "",
			pagination_fixed: 20,
			lot_package_total: 0,
			id_client: [],
			msgError: "",
			colisJson: [],
			loading: true,
		};
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		this.setState({ id_client: id_client });
		this.state.formatEtiquettes = localStorage.getItem("formatEtiquettes")
			? localStorage.getItem("formatEtiquettes")
			: "normal";
		config
			.apiFetch(config.Api_Url + "/findbyid/client/" + id_client)
			.then((response) => response.json())
			.then((client) => {
				let src =
					"data:image/jpg;base64," +
					new Buffer(client["data"][0].agence_logo).toString("base64");

				this.setState({
					expediteur: client["data"][0].nom + client["data"][0].prenom,
					telephone: client["data"][0].telephone,
					logo: <img src={src} style={{ maxHeight: 150, maxWidth: 150 }}></img>,
					agence_address: client["data"][0].agence_address,
					agence_phone: client["data"][0].agence_phone,
					agence_name: client["data"][0].agence_name,
					agence_ville: client["data"][0].agence_ville,
				});
			});

		config
			.apiFetch(
				config.Api_Url +
					"/lot/getColisToPrint/" +
					id_client +
					"/" +
					this.props.match.params.a_id
			)
			.then((response) => response.json())
			.then((colis) => {
				console.log(colis);
				if (colis["data"].length) {
					colis["data"].forEach((c, index) => {
						c["colis_id"] = "L" + c.lo_barcode + index;
					});
					let unprintedColis = [];
					let data = colis["data"].filter(function (c) {
						return c.lc_is_printed === 0;
					});
					unprintedColis["data"] = data;
					if (unprintedColis["data"].length) {
						this.setState({
							colis: [unprintedColis],
							colisJson: unprintedColis["data"],
							lot_package_total: unprintedColis["data"].length - 1,
						});

						config
							.apiFetch(config.Api_Url + "/lot/updatePrintedColiss", {
								method: "PATCH",
								body: JSON.stringify({
									id_client: id_client,
									a_id: this.props.match.params.a_id,
								}),
								headers: { "Content-Type": "application/json" },
							})
							.then((response) => response.json())
							.then((update) => {
								let counter = 0;
								let counterprint = 0;
								this.state.colisJson.forEach((colis, index) => {
									setTimeout(() => {
										config
											.apiFetch(config.Api_Url + "/lot/addColisToLot", {
												method: "POST",
												body: JSON.stringify({
													colis_id: colis.colis_id,
													poidscolis: colis.poidscolis,
													nomdest: colis.nomdest,
													prenomdest: colis.prenomdest,
													adressedest: colis.adressedest,
													codepostaldest: colis.codepostaldest,
													villedest: colis.villedest,
													telephonedest: colis.telephonedest,
													type: colis.type,
													infosParticulieres: colis.infosParticulieres,
													values: 0,
													typeEnvoi: colis.typeEnvoi,
													groupement_id: colis.groupement_id,
													lc_id: colis.lc_id,
													lo_barcode: colis.lo_barcode,
												}),
												headers: {
													"Content-Type": "application/json",
												},
											})
											.then((response) => response.json())
											.then((data) => {
												console.log(data);
												counterprint++;
												if (unprintedColis["data"].length == counterprint) {
													config
														.apiFetch(config.Api_Url + "/lot/updateLotPoids", {
															method: "POST",
															body: JSON.stringify({
																lo_barcode: colis.lo_barcode,
															}),
															headers: {
																"Content-Type": "application/json",
															},
														})
														.then((response) => response.json())
														.then((data) => {
															console.log(data);
															this.setState({ loading: false });
															window.print();
														});
												}
											})
											.catch((err) => {
												this.setState({
													msgError: "Une erreur est survenue, veuillez réessayer",
												});
												this.sleep(3000).then(() => {
													this.props.history.goBack();
												});
											});
									}, 1000 * counter++);
								});
							});
					} else {
						this.setState({
							msgError: "Toutes les étiquettes ont été imprimées",
						});
						this.sleep(3000).then(() => {
							this.props.history.goBack();
						});
					}
				} else {
					this.setState({
						msgError: "Toutes les étiquettes ont été imprimées",
					});
					this.sleep(3000).then(() => {
						this.props.history.goBack();
					});
				}
			});
	}

	render() {
		return (
			<div>
				<NavPublic />
				<Container>
					{this.state.msgError ? <h1>{this.state.msgError}</h1> : null}
					{this.state.loading ? (
						<div className="no-print text-center p-5">
							<Spinner animation="grow" variant="dark" size="lg" />
							<Spinner animation="grow" variant="dark" size="lg" />
							<Spinner animation="grow" variant="dark" size="lg" />
							<p>En chargement, merci de patienter</p>
						</div>
					) : null}
					{this.state.formatEtiquettes == "thermique" ? (
						<EtiquetteThermique
							logo={this.state.logo}
							colis={this.state.colis}
							agence_name={this.state.agence_name}
							agence_address={this.state.agence_address}
							agence_ville={this.state.agence_ville}
							agence_phone={this.state.agence_phone}
							expediteur={this.state.expediteur}
							telephone={this.state.telephone}
							datedemande={this.state.datedemande}
							lot_package_total={this.state.lot_package_total}
						/>
					) : (
						<EtiquetteNormal
							logo={this.state.logo}
							colis={this.state.colis}
							agence_name={this.state.agence_name}
							agence_address={this.state.agence_address}
							agence_ville={this.state.agence_ville}
							agence_phone={this.state.agence_phone}
							expediteur={this.state.expediteur}
							telephone={this.state.telephone}
							datedemande={this.state.datedemande}
							lot_package_total={this.state.lot_package_total}
						/>
					)}
				</Container>
			</div>
		);
	}
}

export default withRouter(ImprimerEtiquetteLot);
