import React, { Component, useState } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
	Collapse,
	Container,
} from "react-bootstrap";
import DatePickerField from "helpers/datePickerField";
const dateFormatConvert = require("helpers/dateFormatConvert");
import * as config from "config/config";
class SearchForm extends Component {
	constructor() {
		super();
	}
	Search() {
		// Declare a new state variable, which we'll call "count"
		const [count1, setOpen1] = useState(true);
		const [count2, setOpen2] = useState(false);
		const lesMois = config.lesMois;
		let month = [];
		for (let number = 0; number < lesMois.length; number++) {
			month.push(
				<option key={"option_month_" + number} value={number}>
					{lesMois[number]}
				</option>
			);
		}
		return (
			<div>
				<ButtonToolbar>
					<Button
						size="sm"
						className="mr-1"
						variant="outline-secondary"
						onClick={() => {
							setOpen1(!count1);
							if (count2) {
								setOpen2(!count2);
							}
						}}
						aria-controls="search_by_date"
						aria-expanded={count1}
						active={count1}
					>
						Filtrer par mois et année
					</Button>
					<Button
						size="sm"
						className="mr-1"
						variant="outline-secondary"
						onClick={() => {
							setOpen2(!count2);
							if (count1) {
								setOpen1(!count1);
							}
						}}
						aria-controls="search_by_package"
						aria-expanded={count2}
						active={count2}
					>
						Filtrer par période
					</Button>
				</ButtonToolbar>

				<Collapse in={count1}>
					<div id="search_by_date">
						<Formik
							initialValues={{ month: 0 }}
							onSubmit={(values) => {
								let month = parseInt(values.month) + 1;

								window.location.href =
									"/admin/tournee/filtrebymonthyear/" +
									month.toString() +
									"/" +
									values.year +
									"/page/1";
							}}
						>
							{(props) => {
								const {
									status,
									handleChange,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Row>
											<Col md={6}>
												<Row>
													<Col md>
														{" "}
														<Form.Label className="required">Mois :</Form.Label>
														<Form.Control
															required
															as="select"
															name="month"
															onChange={handleChange}
														>
															{month}
														</Form.Control>
													</Col>
													<Col md>
														{" "}
														<Form.Label className="required">
															Année :
														</Form.Label>
														<Form.Control
															required
															type="number"
															aria-describedby="inputGroupPrepend"
															name="year"
															onChange={handleChange}
														></Form.Control>
													</Col>
												</Row>
												<Row>
													<Col md>
														<Button
															size="sm"
															type="submit"
															disabled={isSubmitting}
															variant="primary"
														>
															Rechercher
														</Button>
													</Col>
												</Row>
											</Col>
										</Row>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>

				<Collapse in={count2}>
					<div id="search_by_date">
						<Formik
							initialValues={{ startDate: "", endDate: "" }}
							onSubmit={(values) => {
								let startDate =
									values.startDate.getFullYear() +
									"-" +
									(values.startDate.getMonth() + 1) +
									"-" +
									values.startDate.getDate();
								let endDate =
									values.endDate.getFullYear() +
									"-" +
									(values.endDate.getMonth() + 1) +
									"-" +
									values.endDate.getDate();
								window.location.href =
									"/admin/tournee/filtrebyperiodtime/" +
									startDate +
									"/" +
									endDate +
									"/page/1";
							}}
						>
							{(props) => {
								const {
									status,
									handleChange,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Row>
											<Col md={6}>
												<Row>
													<Col md>
														{" "}
														<div>Du :</div>
														<DatePickerField
															name="startDate"
															className="form-control"
														/>
													</Col>
													<Col md>
														{" "}
														<div>Au :</div>
														<DatePickerField
															name="endDate"
															className="form-control"
														/>
													</Col>
												</Row>
												<Row>
													<Col md>
														{" "}
														<Button
															size="sm"
															type="submit"
															disabled={isSubmitting}
															variant="primary"
														>
															Rechercher
														</Button>
													</Col>
												</Row>
											</Col>
										</Row>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.Search />
			</div>
		);
	}
}

export default withRouter(SearchForm);
