import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
	BrowserRouter,
	Route,
	Switch,
	Link,
	Redirect,
	withRouter,
} from "react-router-dom";
import { Formik } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
import SearchForm from "./searchForm";
import NavAdmin from "components/nav/NavAdmin";

class Profil extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
		};
	}

	handleChange = (date) => {
		this.setState({
			startDate: date,
		});
	};
	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Profil facturation</h1>

							<SearchForm />
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(Profil);
