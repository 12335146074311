/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";

import { Formik, submitForm, useFormikContext } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

/*Css*/

export class RenameProfil extends React.Component {
	constructor() {
		super();
		this.state = {
			details: [],
		};
	}

	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Renommer le profil {this.props.match.params.nom}</h1>
							<Formik
								initialValues={{ new: "" }}
								onSubmit={(values) => {
									config
										.apiFetch(
											config.Api_Url +
												"/profil/changeProfilName/" +
												this.props.match.params.nom,
											{
												method: "POST",
												body: JSON.stringify({
													new: values.new,
												}),
												headers: { "Content-Type": "application/json" },
											}
										)
										.then((response) => response.json())
										.then((details) => {
											this.props.history.push("/admin/profil");
										});
								}}
							>
								{(props) => {
									const {
										status,
										handleChange,
										handleSubmit,
										isSubmitting,
										/* and other goodies */
									} = props;
									return (
										<Form onSubmit={handleSubmit} autoComplete="off">
											<Form.Label className="required">Nom :</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="new"
												onChange={handleChange}
											></Form.Control>
											<Button
												size="sm"
												className="mt-3"
												type="submit"
												disabled={isSubmitting}
												variant="primary"
											>
												Enregistrer les modifications
											</Button>
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(RenameProfil);
