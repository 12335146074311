import React from "react";
import NavAdmin from "components/nav/NavAdmin";
import { withRouter } from "react-router-dom";
import { Row, Col, Table, Container } from "react-bootstrap";
import * as config from "config/config";

const dateFormatConvert = require("helpers/dateFormatConvert");
const getColisInfos = require("helpers/getColisInfos");

var Barcode = require("react-barcode");

export class VisualiserEtiquette extends React.Component {
	constructor() {
		super();
		this.state = {
			demande: [],
			colis: [],
			expediteur: "",
			telephone: "",
			datedemande: "",
			pagination_fixed: 20,
			logo: "",
			address: "",
			lot_package_total: 0,
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		config
			.apiFetch(
				config.Api_Url +
					"/demande/getColisbyGroupementID/" +
					this.props.match.params.groupement_id
			)
			.then((response) => response.json())
			.then((colis) => {
				console.log(colis);
				this.setState({
					colis: [colis],
					lot_package_total: colis["data"].length - 1,
				});
			});

		config
			.apiFetch(
				config.Api_Url +
					"/demande/getGroupementColis/" +
					this.props.match.params.groupement_id
			)
			.then((response) => response.json())
			.then((demande) => {
				let src =
					"data:image/jpg;base64," +
					new Buffer(demande["data"][0].agence_logo).toString("base64");
				this.setState({
					expediteur:
						demande["data"][0].nomExpediteur +
						" " +
						demande["data"][0].prenomExpediteur,
					telephone: demande["data"][0].telephoneExpediteur,
					datedemande: dateFormatConvert.getFormattedDate(
						demande["data"][0].datedemande
					),
					logo: <img src={src} style={{ maxHeight: 150, maxWidth: 150 }}></img>,
					agence_address: demande["data"][0].agence_address,
					agence_phone: demande["data"][0].agence_phone,
					agence_name: demande["data"][0].agence_name,
					agence_ville: demande["data"][0].agence_ville,
				});
			});
	}

	render() {
		let etiquetteCounter = 1;
		return (
			<div>
				<NavAdmin />
				<Container>
					{this.state.colis.map((dynamicData, Key) => {
						let keys = Object.keys(dynamicData);
						let d = dynamicData;
						let lotId = "";
						return d[keys].map((data, index) => {
							if (index === 0 && getColisInfos.checkLotPrincipalId(data)) {
								lotId = data.colis_id;
							}
							return (
								<div
									className={
										"expediteur-infos " +
										(etiquetteCounter++ % 2 === 0 ? "page-break " : "")
									}
									key={"partie_1_" + data.colis_id}
								>
									<Row>
										<Col xs={6}>
											<div>{data.groupement_id}</div>
										</Col>
										<Col xs={6}>
											<div
												style={{
													float: "right",
												}}
											>
												{data.groupement_id}
											</div>
										</Col>
									</Row>
									<Row>
										<Col xs={4}>{this.state.logo}</Col>
										<Col
											xs={4}
											className="d-flex justify-content-center align-items-flex-start"
										>
											<Barcode value={data.colis_id} />
										</Col>
										<Col
											xs={4}
											className="etiquettes-citycolis-infos pl-5 pt-2"
										>
											<div>{this.state.agence_name}</div>
											<div>{this.state.agence_address}</div>
											<div>{this.state.agence_ville}</div>
											<div>Tél:{this.state.agence_phone}</div>
										</Col>
									</Row>

									<Row>
										<Col
											xs={4}
											className="d-flex flex-column justify-content-center"
										>
											<div>Expéditeur : {this.state.expediteur}</div>
											<div>Téléphone : {this.state.telephone}</div>
										</Col>
										<Col
											xs={4}
											className="date-poids flex-column justify-content-center"
										>
											<div>{this.state.datedemande}</div>
											{/* TODO : Si > 1000 kg afficheer t pour tonnes */}
											<div>
												{data.poidscolis} kg{" "}
												{data.estLot
													? " - " + this.state.lot_package_total + " colis"
													: ""}
											</div>
										</Col>
										<Col
											xs={4}
											className="d-flex justify-content-center align-items-flex-start"
										>
											{lotId ? <Barcode value={lotId} /> : null}
										</Col>
									</Row>

									<div className="destinataire-frame">
										<Row>
											<Col xs={6}>
												<h2>Destinataire</h2>
												<Table>
													<tbody>
														<tr>
															<td>Nom :</td>
															<td>{data.nomdest}</td>
														</tr>
														<tr>
															<td>Prénom :</td>
															<td>{data.prenomdest}</td>
														</tr>
														<tr>
															<td>Adresse :</td>
															<td>{data.adressedest}</td>
														</tr>
														<tr>
															<td>Code postal :</td>
															<td>{data.codepostaldest}</td>
														</tr>
														<tr>
															<td>Ville :</td>
															<td>{data.villedest}</td>
														</tr>
														<tr>
															<td>Téléphone :</td>
															<td>{data.telephonedest}</td>
														</tr>
													</tbody>
												</Table>
											</Col>
											<Col
												xs={6}
												className="d-flex flex-column justify-content-end"
											>
												<div className="informations-particulieres">
													Informations particulières :<br></br>
													<br></br>
													{data.infosParticulieres}{" "}
												</div>
											</Col>
										</Row>
										<Row>
											<Col xs={6}>
												<div>{data.groupement_id}</div>
											</Col>
											<Col xs={6}>
												<div
													style={{
														float: "right",
													}}
												>
													{data.groupement_id}
												</div>
											</Col>
										</Row>
									</div>
								</div>
							);
						});
					})}
				</Container>

				<Table responsive>
					<thead>
						<tr>
							<th>N°</th>
							<th>Référence</th>
							<th>Destinataire</th>
							<th>Type</th>
						</tr>
					</thead>
					<tbody>
						{this.state.colis.map((dynamicData, Key) => {
							let keys = Object.keys(dynamicData);
							let d = dynamicData;
							let counter = 1;
							return d[keys].map((data) => {
								return (
									<tr key={data.colis_id}>
										<th>{counter++}</th>
										<th>
											<Barcode value={data.colis_id} />
										</th>
										<th>
											<div>{data.nomdest}</div>
											<div>{data.prenomdest}</div>
											<div>{data.adressedest}</div>
											<div>{data.codepostaldest}</div>
											<div>{data.villedest}</div>
											<div>{data.telephonedest}</div>
										</th>
										<th>
											<div>Type {data.type}</div>
										</th>
									</tr>
								);
							});
						})}
					</tbody>
				</Table>
			</div>
		);
	}
}

export default withRouter(VisualiserEtiquette);
