/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
	BrowserRouter,
	Route,
	Switch,
	Link,
	Redirect,
	withRouter,
} from "react-router-dom";
import { Formik } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
import SearchForm from "./searchForm";
import NavPublic from "components/nav/NavPublic";

class Lot extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
		};
	}

	handleChange = (date) => {
		this.setState({
			startDate: date,
		});
	};
	render() {
		return (
			<div>
				<NavPublic />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={10}>
							<h1>Rechercher dans un lot</h1>
							<h2>
								Choisissez un état, entrez un numéro du lot ou un code société
							</h2>
							<SearchForm />
						</Col>
					</Row>
					<Table responsive bordered hover></Table>
					<div>
						<nav aria-label="Page navigation example">
							<ul className="pagination"></ul>
						</nav>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Lot);
