/* eslint-disable import/first */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import Checkbox from "helpers/checkbox";
import * as yup from "yup";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getDateReleve = require("helpers/getDateReleve");
import ModalLotclosedInfo from "./modalLotclosedInfo";

class ClosingLot extends Component {
	constructor(props) {
		super(props);
		this.ClosingLot = this.ClosingLot.bind(this);
		this.allChecked = this.allChecked.bind(this);
		this.allUnchecked = this.allUnchecked.bind(this);
		this.state = {
			allPrinted: true,
			modalInfo: [],
			openModal: false,
		};
	}

	ClosingLot() {
		let allPrinted = this.props.unclosedLot[0]?.data?.filter(
			(x) => x.toPrint != 0
		).length;

		return (
			<div className="lot-infos mt-5">
				<Formik
					initialValues={{ checked: [] }}
					validationSchema={yup.object().shape({
						checked: yup.array().required("Choisissez au moins un lot"),
					})}
					enableReinitialize
					onSubmit={(values, { setSubmitting }) => {
						this.props.updateSubmitting();
						let now = new Date();
						let datereleve = getDateReleve.getDateReleve();
						config
							.apiFetch(config.Api_Url + "/lot/closeMultiLot", {
								method: "POST",
								body: JSON.stringify({
									lo_a_id: values.checked,
									datedemande: dateFormatConvert.convertDateTimeForSql(now),
									datereleve: dateFormatConvert.convertDateTimeForSql(
										datereleve
									),
								}),
								headers: {
									"Content-Type": "application/json",
								},
							})
							.then((response) => response.json())
							.then((data) => {
								console.log(data);
								if (data["data"]) {
									this.getModalInfos(data["data"]);
								}
							});
					}}
				>
					{({
						errors,
						touched,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						/* and other goodies */
					}) => (
						<Form onSubmit={handleSubmit}>
							{this.props.loading ? (
								<div>
									<Spinner animation="grow" variant="dark" size="sm" />
									<Spinner animation="grow" variant="dark" size="sm" />
									<Spinner animation="grow" variant="dark" size="sm" />
								</div>
							) : null}
							<Row>
								{this.props.unclosedLot.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;

									return d[keys].map((data) => {
										let warn =
											data.toPrint != 0
												? `Il reste ${data.toPrint} étiquettes(s) à imprimer`
												: "";
										return (
											<Col xs={4} key={data.a_id}>
												<Checkbox
													id={data.a_id}
													name="checked"
													value={data.a_id}
													disabled={data.toPrint != 0}
												/>
												<Form.Label className="ml-1">{data.nom}</Form.Label>
												<p style={{ color: "red" }}>{warn}</p>
											</Col>
										);
									});
								})}
							</Row>
							{errors.checked && touched.checked && (
								<p style={{ color: "red" }}>{errors.checked}</p>
							)}
							<Row>
								<Button
									size="sm"
									className="mt-2 ml-2"
									variant="primary"
									type="submit"
									disabled={isSubmitting}
								>
									Fermer les lots choisis
								</Button>
							</Row>
							<Row>
								<Button
									size="sm"
									className="mt-2 ml-2"
									variant="outline-secondary"
									onClick={this.allChecked}
								>
									Tout cocher
								</Button>
								<Button
									size="sm"
									className="mt-2 ml-2"
									variant="outline-secondary"
									onClick={this.allUnchecked}
								>
									Tout décocher
								</Button>
							</Row>
						</Form>
					)}
				</Formik>
				<ModalLotclosedInfo
					modalInfo={this.state.modalInfo}
					openModal={this.state.openModal}
				/>
			</div>
		);
	}

	allChecked() {
		let checkBoxIdArray = this.props.unclosedLot[0]?.data?.map((x) => x.a_id);

		checkBoxIdArray.forEach((d) => {
			if (document.getElementById(d).checked != true) {
				setTimeout(function () {
					document.getElementById(d).click();
				}, 100);
			}
		});
	}
	allUnchecked() {
		let checkBoxIdArray = this.props.unclosedLot[0]?.data?.map((x) => x.a_id);

		checkBoxIdArray.forEach((d) => {
			if (document.getElementById(d).checked == true) {
				setTimeout(function () {
					document.getElementById(d).click();
				}, 100);
			}
		});
	}

	getModalInfos(data) {
		let groupementArray = data.map((x) => x.groupement_id);
		console.log(groupementArray);
		config
			.apiFetch(config.Api_Url + "/lot/getLotsInfos/", {
				method: "POST",
				body: JSON.stringify({
					groupementArray: groupementArray,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setState({ modalInfo: [data], openModal: true });
			});
	}

	render() {
		return (
			<div>
				<this.ClosingLot />
			</div>
		);
	}
}

export default withRouter(ClosingLot);
