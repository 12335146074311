/* eslint-disable import/first */
import React, { Component } from "react";
import { Table, Form, Button } from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import { withRouter } from "react-router-dom";
const dateFormatConvert = require("helpers/dateFormatConvert");
import Checkbox from "helpers/checkbox";
import { Formik } from "formik";
const calculFacturation = require("helpers/calculFacturation");

export class ColisbyAgenceTable extends React.Component {
	constructor(props) {
		super(props);
		this.ColisbyAgenceTable = this.ColisbyAgenceTable.bind(this);
	}

	getPoidInterval(poid) {
		let result = Math.max.apply(
			null,
			this.props.array_interval.filter(function (v) {
				return v <= poid;
			})
		);
		if (result == "-Infinity") {
			return 0;
		} else {
			if (
				result >=
				this.props.array_interval[this.props.array_interval.length - 1]
			) {
				return this.props.array_interval.length - 1;
			} else {
				result = this.props.array_interval.indexOf(result) + 1;
				return result;
			}
		}
	}
	getZipCode(code) {
		return code.substring(0, 2);
	}

	ColisbyAgenceTable() {
		//let details = this.props.details[0]["data"];
		let details = this.props.details;

		if (
			!this.props.colis ||
			!details ||
			!this.props.nb_total ||
			!this.props.sum_total
		) {
			return (
				<div>
					<h3 className="page-break-before">
						Il n'y a pas de profils de facturation correspondant pour ce client
					</h3>
				</div>
			);
		} else {
			let nb_total_init = this.props.nb_total;
			let sum_total_init = this.props.sum_total;
			let array_interval_init = [];
			details.forEach(function (d, i) {
				array_interval_init.push(d.max);
				nb_total_init[i] = 0;
				sum_total_init[i] = 0;
			});
			let count_row = 0;
			return (
				<div className="page-break-after">
					<h3 className="page-break-before">Détail des colis</h3>
					<Table bordered hover>
						<thead>
							<tr className="print-text-black">
								<th>N°</th>
								<th>ID Colis</th>
								<th>Date</th>
								<th>Type</th>
								<th>Poids</th>
								<th>Tranche</th>
								<th>Nom du destinataire</th>
								<th>Destination</th>
								<th>Origine</th>
								<th>Cible</th>
								<th>Prix</th>
								<th>Supplément</th>
							</tr>
						</thead>
						<tbody>
							{this.props.colis.map((data) => {
								count_row++;
								let prix = 0;
								let result_getPoidInterval = [];
								let min = 0;
								let max = 0;
								//get price range correspond
								result_getPoidInterval = calculFacturation.getPoidInterval(
									data.poidscolis,
									this.props.array_interval
								);

								let index = result_getPoidInterval[0];

								min = result_getPoidInterval[1];

								max = result_getPoidInterval[2];
								let nb_total = this.props.nb_total;
								let sum_total = this.props.sum_total;
								let temp_nb_total = nb_total[index];
								temp_nb_total++;
								nb_total[index] = temp_nb_total;
								//
								//.log(details[index]);
								//this.setState({ nb_total: nb_total });
								//calcul the price
								prix = calculFacturation.getPrixFacturation(
									data,
									details,
									index
								);

								let temp_sum_total = sum_total[index];
								temp_sum_total = temp_sum_total + prix;
								sum_total[index] = temp_sum_total;
								return (
									<tr
										key={data.colis_id}
										className={data.origine && data.cible ? "hors-site" : ""}
									>
										<td>{count_row}</td>
										<td>{data.colis_id}</td>
										<td>
											{dateFormatConvert.getFormattedDateWithHms(
												data.datedemande
											)}
										</td>
										<td>{data.estLot ? "Lot" : "Colis"}</td>
										<td>{data.poidscolis}</td>
										<td>
											{min} {typeof min == "number" ? " à " : ""}
											{max} Kg{" "}
										</td>
										<td>{data.nomdest}</td>
										<td>
											{data.adressedest + " "}
											{data.codepostaldest + " "}
											{data.villedest}
										</td>
										<td>{data.origine}</td>
										<td>{data.cible}</td>
										<td>{prix}</td>
										<td>{data.cible ? config.hors_site_sup_value : ""}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			);
		}
	}
	render() {
		return (
			<div>
				<this.ColisbyAgenceTable />
			</div>
		);
	}
}

export default withRouter(ColisbyAgenceTable);
