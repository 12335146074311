import React from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";

class MarkerWithInfoWindow extends React.Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.onToggleOpen = this.onToggleOpen.bind(this);
	}

	onToggleOpen() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}

	render() {
		return (
			<Marker
				position={this.props.position}
				onClick={this.onToggleOpen}
				icon={{
					url: require("asset/images/package.png"),
				}}
			>
				{this.state.isOpen && (
					<InfoWindow onCloseClick={this.onToggleOpen}>
						<h5>
							{this.props.cg_colis_id}
							<br></br>
							{this.props.info}
						</h5>
					</InfoWindow>
				)}
			</Marker>
		);
	}
}

export default MarkerWithInfoWindow;
