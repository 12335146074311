/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import {
	BrowserRouter,
	Route,
	Switch,
	Link,
	Redirect,
	withRouter,
} from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { NavAdmin } from "components/nav/NavAdmin";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");

/*CSS*/

class ModifyColis extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: [],
			TypeEnvoi: [],
			colis: {},
		};
	}
	componentDidMount() {
		//Set available type for package
		let type_list = ["Colis", "Palette", "Enveloppe"];
		let type = [];
		for (let number = 0; number < type_list.length; number++) {
			type.push(
				<option key={"option_type_" + number}>{type_list[number]}</option>
			);
		}
		this.setState({
			type: [type],
		});

		config
			.apiFetch(config.Api_Url + "/colis/getTypeEnvoi/")
			.then((response) => response.json())
			.then((data) => {
				let TypeEnvoi = [];
				for (let number = 0; number < data["data"].length; number++) {
					TypeEnvoi.push(
						<option key={"option_TypeEnvoi_" + number}>
							{data["data"][number].typeEnvoi}
						</option>
					);
				}
				this.setState({
					TypeEnvoi: [TypeEnvoi],
				});
			});

		config
			.apiFetch(
				config.Api_Url +
					"/colis/getColisbyCodeAdmin/" +
					this.props.match.params.colis_id
			)
			.then((response) => response.json())
			.then((data) => {
				if (data["data"].length != 0) {
					this.setState({
						colis: data["data"][0],
					});
				} else {
					this.props.history.push("/404");
				}
			});
	}
	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Modifier le colis</h1>
							<Formik
								initialValues={this.state.colis}
								enableReinitialize
								onSubmit={(values) => {
									if (values.dateScan) {
										values.dateScan = dateFormatConvert.convertDateTimeForSql(
											values.dateScan
										);
									}
									config
										.apiFetch(config.Api_Url + "/colis/update", {
											method: "POST",
											body: JSON.stringify({
												c_id: this.state.colis.c_id,
												colis_id_ciblex: values.colis_id_ciblex,
												groupement_id: values.groupement_id,
												poidscolis: values.poidscolis,
												nomdest: values.nomdest,
												prenomdest: values.prenomdest,
												adressedest: values.adressedest,
												codepostaldest: values.codepostaldest,
												villedest: values.villedest,
												telephonedest: values.telephonedest,
												maildest: values.maildest,
												etatlivraison: values.etatlivraison,
												description: values.description,
												type: values.type,
												infosParticulieres: values.infosParticulieres,
												valeur: values.valeur,
												dateScan: values.dateScan,
												typeEnvoi: values.typeEnvoi,
												volume: values.volume,
												contreRemboursement: values.contreRemboursement,
												devise: values.devise,
												colisGLS: values.colisGLS,
												dateScan: values.dateScan,
											}),
											headers: {
												"Content-Type": "application/json",
											},
										})
										.then((response) => response.json())
										.then((data) => {
											window.location.reload();
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit}>
										<Form.Label>Conditionnement de votre envoi :</Form.Label>
										<Form.Control
											as="select"
											name="type"
											value={values.type || ""}
											onChange={handleChange}
										>
											{this.state.type}
										</Form.Control>
										<Form.Label>Type de votre envoi :</Form.Label>
										<Form.Control
											as="select"
											name="typeEnvoi"
											value={values.typeEnvoi || ""}
											onChange={handleChange}
										>
											{this.state.TypeEnvoi}
										</Form.Control>
										<Form.Label>Description :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="description"
											value={values.description || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Poids (Kg) :</Form.Label>
										<Form.Control
											required
											type="number"
											min="0"
											aria-describedby="inputGroupPrepend"
											name="poidscolis"
											value={values.poidscolis || ""}
											onChange={handleChange}
										/>
										<Form.Label>Valeur (€uros) :</Form.Label>
										<Form.Control
											type="number"
											min="0"
											name="valeur"
											value={values.valeur || ""}
											onChange={handleChange}
										>
											{this.state.profil}
										</Form.Control>
										<Form.Label>Volume (m3):</Form.Label>
										<Form.Control
											type="number"
											min="0"
											aria-describedby="inputGroupPrepend"
											name="volume"
											value={values.volume || ""}
											onChange={handleChange}
										/>
										<Form.Label>Contre Remboursement (€uros) :</Form.Label>
										<Form.Control
											type="number"
											min="0"
											aria-describedby="inputGroupPrepend"
											name="contreRemboursement"
											value={values.contreRemboursement || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">
											Nom ou société:
										</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="nomdest"
											value={values.nomdest || ""}
											onChange={handleChange}
										/>
										<Form.Label>Prénom :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="prenomdest"
											value={values.prenomdest || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Adresse :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="adressedest"
											value={values.adressedest || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Code Postal :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="codepostaldest"
											value={values.codepostaldest || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Ville :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="villedest"
											value={values.villedest || ""}
											onChange={handleChange}
										/>
										<Form.Label>Telephone :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="telephonedest"
											value={values.telephonedest || ""}
											onChange={handleChange}
										/>
										<Form.Label>Informations Particulières :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="infosParticulieres"
											value={values.infosParticulieres || ""}
											onChange={handleChange}
										/>
										<Button
											size="sm"
											className="mt-4 mb-5"
											type="submit"
											disabled={isSubmitting}
										>
											Enregistrer les modifications
										</Button>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(ModifyColis);
