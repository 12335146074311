/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import { Route, withRouter, Redirect } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");

/*Css*/

export class DetailsVehicules extends React.Component {
	constructor() {
		super();
		this.state = {
			vehicules: [],
			total: 0,
			agence: [],
		};
	}

	componentDidMount() {
		//update if back from another page

		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		config
			.apiFetch(config.Api_Url + "/vehicules/")
			.then((response) => response.json())
			.then((vehicules) => {
				this.setState({
					vehicules: [vehicules],
				});
			});

		config
			.apiFetch(config.Api_Url + "/vehicules/" + this.props.match.params.agence)
			.then((response) => response.json())
			.then((vehicules) => {
				this.setState({
					vehicules: [vehicules],
				});
			});
		config
			.apiFetch(
				config.Api_Url + "/findbyid/agence/" + this.props.match.params.agence
			)
			.then((response) => response.json())
			.then((agence) => {
				this.setState({
					agence: agence["data"][0],
				});
			});
	}

	render() {
		return (
			<div id="gestion-des-vehicules">
				<NavAdmin />
				<div className="container-fluid">
					<h1>
						Gestion des véhicules de la société propriétaire :{" "}
						{this.state.agence.agence_name}
					</h1>

					<Button
						size="sm"
						className="my-3"
						variant="outline-primary"
						type="submit"
						href={"../add/" + this.state.agence.agence_id}
					>
						<i className="fas fa-plus mr-1"></i>
						Ajouter un nouveau véhicule
					</Button>

					<div>
						<h3>Pneus</h3>
						<Table responsive hover bordered className="tableau-pneus">
							<thead>
								<tr>
									<th>Immatriculation</th>
									<th>Chauffeur</th>
									<th>Dépôt</th>
									<th>Mise circulation</th>
									<th>Dernier relevé</th>
									<th>Kilométrage</th>
									<th>Pneus AV</th>
									<th>Pneus AR</th>
									<th>Alertes</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.state.vehicules.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;

									return d[keys].map((data) => {
										let etat_pneu_AV =
											data.kilometrage - data.dernierRemplacementPneusAV >
											data.intervalleRemplacementPneusAV;
										let etat_pneu_AR =
											data.kilometrage - data.dernierRemplacementPneusAR >
											data.intervalleRemplacementPneusAR;
										let etat_alerte = etat_pneu_AV || etat_pneu_AR;
										let modify_url =
											"/admin/vehicules/modify/" + data.id_vehicule;
										let fix_url = "/admin/vehicules/fix/" + data.id_vehicule;
										let delete_url =
											"/admin/vehicules/delete/" + data.id_vehicule;
										let historique_url =
											"/admin/vehicules/historique/" + data.immatriculation;

										return (
											<tr
												key={"pneu_" + data.codeVehicule}
												className={etat_alerte ? "vehicule-alerte" : ""}
											>
												<td className={data.statut}>{data.immatriculation}</td>
												<td>{data.chauffeur}</td>
												<td>{data.depot}</td>
												<td>
													{dateFormatConvert.getFormattedDate(
														data.anneeImmatriculation
													)}
												</td>
												<td>
													{dateFormatConvert.getFormattedDate(
														data.dernierReleve
													)}
												</td>
												<td>{data.kilometrage}</td>
												<td>{data.dernierRemplacementPneusAV}</td>
												<td>{data.dernierRemplacementPneusAR}</td>
												<td>
													<a
														href={fix_url}
														className={etat_alerte ? "show" : "hidden"}
													>
														Nouveaux Évenements
													</a>
												</td>
												<td>
													<a href={historique_url}>
														<i className="fas fa-history"></i>
														Historique
													</a>
													<a href={modify_url}>
														<i className="fas fa-pencil-alt"></i>
														Modifier
													</a>
													<a href={delete_url}>
														<i className="fas fa-trash-alt"></i>
														Supprimer
													</a>
												</td>
											</tr>
										);
									});
								})}
							</tbody>
						</Table>
					</div>
					<div>
						<h3>Vidange</h3>
						<Table responsive bordered hover className="tableau-vidange">
							<thead>
								<tr>
									<th>Immatriculation</th>
									<th>Chauffeur</th>
									<th>Dépôt</th>
									<th>Mise circulation</th>
									<th>Dernier relevé</th>
									<th>Kilométrage</th>
									<th>Vidange</th>
									<th>Alertes</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.state.vehicules.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;
									return d[keys].map((data) => {
										let etat_alerte =
											data.kilometrage - data.intervalleVidange >
											data.derniereVidange;
										let modify_url =
											"/admin/vehicules/modify/" + data.id_vehicule;
										let fix_url = "/admin/vehicules/fix/" + data.id_vehicule;
										let delete_url =
											"/admin/vehicules/delete/" + data.id_vehicule;
										let historique_url =
											"/admin/vehicules/historique/" + data.immatriculation;

										return (
											<tr
												key={"vidange_" + data.codeVehicule}
												className={etat_alerte ? "vehicule-alerte" : ""}
											>
												<td className={data.statut}>{data.immatriculation}</td>
												<td>{data.chauffeur}</td>
												<td>{data.depot}</td>
												<td>
													{dateFormatConvert.getFormattedDate(
														data.anneeImmatriculation
													)}
												</td>
												<td>
													{dateFormatConvert.getFormattedDate(
														data.dernierReleve
													)}
												</td>
												<td>{data.kilometrage}</td>
												<td>{data.derniereVidange}</td>
												<td>
													<a
														href={fix_url}
														className={etat_alerte ? "show" : "hidden"}
													>
														Nouveaux Évenements
													</a>
												</td>
												<td>
													<a href={historique_url}>
														<i className="fas fa-history"></i>
														Historique
													</a>
													<a href={modify_url}>
														<i className="fas fa-pencil-alt"></i>
														Modifier
													</a>
													<a href={delete_url}>
														<i className="fas fa-trash-alt"></i>
														Supprimer
													</a>
												</td>
											</tr>
										);
									});
								})}
							</tbody>
						</Table>
					</div>
					<div>
						<h3>Freins</h3>
						<Table responsive bordered hover className="tableau-freins">
							<thead>
								<tr>
									<th>Immatriculation</th>
									<th>Chauffeur</th>
									<th>Dépôt</th>
									<th>Mise circulation</th>
									<th>Dernier relevé</th>
									<th>Kilométrage</th>
									<th>Freins AV</th>
									<th>Freins AR</th>
									<th>Alertes</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.state.vehicules.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;
									return d[keys].map((data) => {
										let etat_Freins_AV =
											data.kilometrage - data.dernierRemplacementFreinsAV >
											data.intervalleRemplacementFreinsAV;
										let etat_Freins_AR =
											data.kilometrage - data.dernierRemplacementFreinsAR >
											data.intervalleRemplacementFreinsAR;
										let etat_alerte = etat_Freins_AV || etat_Freins_AR;
										let modify_url =
											"/admin/vehicules/modify/" + data.id_vehicule;
										let fix_url = "/admin/vehicules/fix/" + data.id_vehicule;
										let delete_url =
											"/admin/vehicules/delete/" + data.id_vehicule;
										let historique_url =
											"/admin/vehicules/historique/" + data.immatriculation;

										return (
											<tr
												key={data.codeVehicule}
												className={etat_alerte ? "vehicule-alerte" : ""}
											>
												<td className={data.statut}>{data.immatriculation}</td>
												<td>{data.chauffeur}</td>
												<td>{data.depot}</td>
												<td>
													{dateFormatConvert.getFormattedDate(
														data.anneeImmatriculation
													)}
												</td>
												<td>
													{dateFormatConvert.getFormattedDate(
														data.dernierReleve
													)}
												</td>
												<td>{data.kilometrage}</td>
												<td>{data.dernierRemplacementFreinsAV}</td>
												<td>{data.dernierRemplacementFreinsAR}</td>
												<td>
													<a
														href={fix_url}
														className={etat_alerte ? "show" : "hidden"}
													>
														Nouveaux Évenements
													</a>
												</td>
												<td>
													<a href={historique_url}>
														<i className="fas fa-history"></i>
														Historique
													</a>
													<a href={modify_url}>
														<i className="fas fa-pencil-alt"></i>
														Modifier
													</a>
													<a href={delete_url}>
														<i className="fas fa-trash-alt"></i>
														Supprimer
													</a>
												</td>
											</tr>
										);
									});
								})}
							</tbody>
						</Table>
					</div>
					<div>
						<h3>Disques</h3>
						<Table responsive bordered hover className="tableau-disques">
							<thead>
								<tr>
									<th>Immatriculation</th>
									<th>Chauffeur</th>
									<th>Dépôt</th>
									<th>Mise circulation</th>
									<th>Dernier relevé</th>
									<th>Kilométrage</th>
									<th>Disques AV</th>
									<th>Disques AR</th>
									<th>Alertes</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{this.state.vehicules.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;
									return d[keys].map((data) => {
										let etat_disque_AV =
											data.kilometrage - data.dernierRemplacementDisqueAV >
											data.intervalleRemplacementDisqueAV;
										let etat_disque_AR =
											data.kilometrage - data.dernierRemplacementDisqueAR >
											data.intervalleRemplacementDisqueAR;
										let etat_alerte = etat_disque_AV || etat_disque_AR;
										let modify_url =
											"/admin/vehicules/modify/" + data.id_vehicule;
										let fix_url = "./admin/vehicules/fix/" + data.id_vehicule;
										let delete_url =
											"/admin/vehicules/delete/" + data.id_vehicule;
										let historique_url =
											"/admin/vehicules/historique/" + data.immatriculation;

										return (
											<tr
												key={data.codeVehicule}
												className={etat_alerte ? "vehicule-alerte" : ""}
											>
												<td className={data.statut}>{data.immatriculation}</td>
												<td>{data.chauffeur}</td>
												<td>{data.depot}</td>
												<td>
													{dateFormatConvert.getFormattedDate(
														data.anneeImmatriculation
													)}
												</td>
												<td>
													{dateFormatConvert.getFormattedDate(
														data.dernierReleve
													)}
												</td>
												<td>{data.kilometrage}</td>
												<td>{data.dernierRemplacementDisqueAV}</td>
												<td>{data.dernierRemplacementDisqueAR}</td>
												<td>
													<a
														href={fix_url}
														className={etat_alerte ? "show" : "hidden"}
													>
														Nouveaux Évenements
													</a>
												</td>
												<td>
													<a href={historique_url}>
														<i className="fas fa-history"></i>
														Historique
													</a>
													<a href={modify_url}>
														<i className="fas fa-pencil-alt"></i>
														Modifier
													</a>
													<a href={delete_url}>
														<i className="fas fa-trash-alt"></i>
														Supprimer
													</a>
												</td>
											</tr>
										);
									});
								})}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DetailsVehicules);
