/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
	BrowserRouter,
	Route,
	Switch,
	Link,
	Redirect,
	withRouter,
} from "react-router-dom";
import { Formik } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
import NavPublic from "components/nav/NavPublic";
const getTrackingLink = require("helpers/getTrackingLink");

class TrackingPackage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tablecode: "",
			wrongcode: "",
			imgsrc: "",
		};
	}

	componentDidMount() {
		let colis_id_ciblex = this.props.match.params.colis_id_ciblex;
		var url = getTrackingLink.getTrackingLink(colis_id_ciblex);
		var urlimg = getTrackingLink.getTrackingProofLink(colis_id_ciblex);

		fetch(process.env.REACT_APP_CORS_URL + url)
			.then(function (response) {
				if (response.ok) {
					return response.text();
				}
				throw new Error("Error message.");
			})
			.then(
				function (data) {
					let tabletag = data.split("<table");
					if (tabletag.length >= 2) {
						let tablecode = tabletag[2];
						tablecode = tablecode.split("</table>");
						let table = tablecode[0];
						table = table.substring(1);
						//tablecode += "</table>";
						var htmlInput =
							"<table id='table' class='table table-bordered table-hover'>" +
							table +
							"</table>";
						document.getElementById("table-div").innerHTML = htmlInput;

						fetch(process.env.REACT_APP_CORS_URL + urlimg)
							.then(function (response) {
								if (response.ok) {
									return response.text();
								}
								throw new Error("Error message.");
							})
							.then(
								function (data) {
									let imgtag = data.split('<img src="');
									if (imgtag.length >= 2) {
										let imgsrc = imgtag[2];
										imgsrc = imgsrc.split('"');
										let img = process.env.REACT_APP_ETRACE_URL + imgsrc[0];
										fetch(process.env.REACT_APP_CORS_URL + img)
											.then(function (response) {
												console.log("res", response);
												if (response.ok) {
													return response.blob();
												}
												throw new Error("Error message.");
											})
											.then((images) => {
												console.log(images);
												// Then create a local URL for that image and print it
												const objectURL = URL.createObjectURL(images);
												console.log(objectURL);
												this.setState({
													imgsrc: objectURL,
												});
											})
											.catch(function (err) {
												console.log("failed to load ", urlimg, err.message);
											});
									}
								}.bind(this)
							)
							.catch(function (err) {
								console.log("failed to load ", url, err.message);
							});
					} else {
						this.setState({
							wrongcode: "Aucune correspondance n'a été trouvée",
						});
					}
				}.bind(this)
			)
			.catch(function (err) {
				console.log("failed to load ", url, err.message);
			});
		if (url || this.state.imgsrc) {
		} else {
			this.setState({ wrongcode: "Code-barres sous-traitant incorrect" });
		}
	}
	render() {
		return (
			<div>
				<NavPublic />
				<div className="container" id="frameBody">
					<h1>Informations sur votre colis</h1>

					<div id="table-div"></div>
					<div id="wrongcode">
						<h2>{this.state.wrongcode}</h2>
					</div>
					<img src={this.state.imgsrc} width="1000px"></img>
				</div>
			</div>
		);
	}
}

export default withRouter(TrackingPackage);
