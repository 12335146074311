/* eslint-disable import/first */
import React, { Component } from "react";
import NavPublic from "components/nav/NavPublic";
import { Route, withRouter } from "react-router-dom";
import logo from "asset/images/citycolis-holding-logo.svg";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	Container,
} from "react-bootstrap";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");

const UpsService = require("helpers/upsService");

/*CSS*/
import "asset/css/demandes.scss";

export class ImprimerEtiquetteUPSPublic extends React.Component {
	constructor() {
		super();
		this.state = {
			demande: [],
			colis: [],
			expediteur: "",
			telephone: "",
			datereleve: "",
			res: "",
		};
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		config
			.apiFetch(
				config.Api_Url +
					"/demande/getColisUpsbyGroupementID/" +
					this.props.match.params.groupement_id
			)
			.then((response) => response.json())
			.then((colis) => {
				this.setState({
					colis: [colis],
				});
				this.sleep(2000).then(() => {
					window.print();
				});
			});
	}

	render() {
		return (
			<div>
				<NavPublic />
				<Container>
					{this.state.colis.map((dynamicData, Key) => {
						let keys = Object.keys(dynamicData);
						let d = dynamicData;
						return d[keys].map((data) => {
							let imagePath = "data:image/jpg;base64," + data.upse_etiquette;

							return (
								<img
									key={data.upse_c_id}
									className="ups-etiquette"
									src={imagePath}
								/>
							);
						});
					})}
				</Container>
			</div>
		);
	}
}

export default withRouter(ImprimerEtiquetteUPSPublic);
