/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { NavAdmin } from "components/nav/NavAdmin";
import * as config from "config/config";

/*CSS*/
import { withRouter } from "react-router-dom";

class HistoriqueVehicule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			historique: [],
		};
	}
	componentDidMount() {
		config
			.apiFetch(
				config.Api_Url +
					"/historique/vehicules/" +
					this.props.match.params.immatriculation
			)
			.then((response) => response.json())
			.then((data) => {
				if (data["data"].length != 0) {
					this.setState({
						historique: [data],
					});
				}
			});
	}
	render() {
		let pneu = [];
		let vidange = [];
		let frein = [];
		let disque = [];
		this.state.historique.map((dynamicData, Key) => {
			let keys = Object.keys(dynamicData);
			let d = dynamicData;
			return d[keys].map((data) => {
				switch (data.categorie) {
					case "Pneus AR":
					case "Pneus AV":
						pneu.push(
							<tr key={"pneu_" + data.id}>
								<td>{data.id}</td>
								<td>{data.dateReparation}</td>
								<td>{data.kilometrage}</td>
								<td>{data.categorie}</td>
								<td>{data.nbChange}</td>
								<td>{!!(data.categorie === 'Pneus AR' ? data.isWornPneusAR : data.isWornPneusAV) ? 'oui' : 'non'}</td>
								<td>{!!(data.categorie === 'Pneus AR' ? data.isDamagedPneusAR : data.isDamagedPneusAV) ? 'oui' : 'non'}</td>
								<td>{!!(data.categorie === 'Pneus AR' ? data.hasWheelPneusAR : data.hasWheelPneusAV) ? 'oui' : 'non'}</td>
							</tr>
						);
						break;
					case "Vidange":
						vidange.push(
							<tr key={"vidange_" + data.id}>
								<td>{data.id}</td>
								<td>{data.dateReparation}</td>
								<td>{data.kilometrage}</td>
								<td>{data.categorie}</td>
							</tr>
						);
						break;
					case "Freins AV":
					case "Freins AR":
						frein.push(
							<tr key={"frein_" + data.id}>
								<td>{data.id}</td>
								<td>{data.dateReparation}</td>
								<td>{data.kilometrage}</td>
								<td>{data.categorie}</td>
							</tr>
						);
						break;
					case "Disques AV":
					case "Disques AR":
						disque.push(
							<tr key={"disque" + data.id}>
								<td>{data.id}</td>
								<td>{data.dateReparation}</td>
								<td>{data.kilometrage}</td>
								<td>{data.categorie}</td>
							</tr>
						);
						break;
				}
			});
		});
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<h1>
						Historique des réparations du véhicule immatriculé{" "}
						{this.props.match.params.immatriculation}
					</h1>
					<h3>Pneus</h3>
					<Table responsive bordered hover className="tableau-pneus">
						<thead>
							<tr>
								<th>Id</th>
								<th>Date de réparation</th>
								<th>Kilométrage</th>
								<th>Catégorie</th>
								<th>Nb changé</th>
								<th>Usure normale</th>
								<th>Abimé / Crevé</th>
								<th>Remplacé par roue de secours</th>
							</tr>
						</thead>
						<tbody>{pneu}</tbody>
					</Table>

					<h3>Vidange</h3>
					<Table responsive bordered hover className="tableau-vidange">
						<thead>
							<tr>
								<th>Id</th>
								<th>Date de réparation</th>
								<th>Kilométrage</th>
								<th>Catégorie</th>
							</tr>
						</thead>
						<tbody>{vidange}</tbody>
					</Table>
					<h3>Freins</h3>
					<Table responsive bordered hover className="tableau-freins">
						<thead>
							<tr>
								<th>Id</th>
								<th>Date de réparation</th>
								<th>Kilométrage</th>
								<th>Catégorie</th>
							</tr>
						</thead>
						<tbody>{frein}</tbody>
					</Table>
					<h3>Disques</h3>
					<Table responsive bordered hover className="tableau-disques">
						<thead>
							<tr>
								<th>Id</th>
								<th>Date de réparation</th>
								<th>Kilométrage</th>
								<th>Catégorie</th>
							</tr>
						</thead>
						<tbody>{disque}</tbody>
					</Table>
				</div>
			</div>
		);
	}
}

export default withRouter(HistoriqueVehicule);
