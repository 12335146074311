import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class Legends extends Component {
	render() {
		return (
			<Col xs={4}>
				<div className="inline-legends d-flex flex-column align-items-start justify-content-center h-100">
					<div>
						<div className="square-legend square-legend-green"></div>
						<div className="inline-legend">
							Demandes incluses dans un bordereau
						</div>
					</div>
					<div>
						<div className="square-legend square-legend-yellow"></div>
						<div className="inline-legend">
							Demandes non incluses dans un bordereau
						</div>
					</div>
					<div>
						<div className="square-legend square-legend-red"></div>
						<div className="inline-legend">Demandes annulées</div>
					</div>

					<div>
						<div className="square-legend square-legend-lightblue"></div>
						<div className="inline-legend">Demandes collectées</div>
					</div>

					<div>
						<div className="square-legend square-legend-magenta"></div>
						<div className="inline-legend">Colis manquant</div>
					</div>
					<div>
						<div className="square-legend square-legend-blue"></div>
						<div className="inline-legend">Demandes hors sites</div>
					</div>
				</div>
			</Col>
		);
	}
}

export default withRouter(Legends);
