/* eslint-disable import/first */
import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { authenticationService } from "services/authentication/authenticationService";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "asset/fontawesome/css/all.css";
import "asset/fontawesome/css/fontawesome.css";
import "asset/css/custom.scss";
import bandeau from "asset/images/bandeau.jpg";
import * as config from "config/config";

export class NavAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;

		config
			.apiFetch("/getAgenceByClientAccount/" + id_client)
			.then((response) => {
				if (response.status == "401") {
					window.location.reload();
				} else {
					return response.json();
				}
			})
			.then((agence) => {
				let src =
					"data:image/jpg;base64," +
					new Buffer(agence["data"][0].agence_logo).toString("base64");
				this.setState({
					agence: agence["data"][0],
					logo: (
						<img
							src={src}
							style={{ maxHeight: 150 }}
							className="top-header-logo"
						></img>
					),
				});
			});
	}
	handleOpen = () => {
		this.setState({ isOpen: true });
	};

	handleClose = () => {
		this.setState({ isOpen: false });
	};

	render() {
		//get user name
		let login_client = JSON.parse(localStorage.getItem("currentUser"))[
			"data"
		][0].aut_login.toUpperCase();
		return (
			<div className="clients_container">
				<div className="top-header no-print d-flex">
					<div className="client-infos ml-5 mt-3 d-flex flex-column align-items-center">
						<strong>Contact Agence</strong>
						{this.state.agence ? (
							<strong>Téléphone : {this.state.agence.agence_phone}</strong>
						) : (
							""
						)}
					</div>
					<img
						src={bandeau}
						className="d-block bandeau"
						alt="CityColis Logo"
					></img>
				</div>
				<Navbar
					bg="dark"
					expand="lg"
					variant="pills"
					className="d-flex flex-column no-print"
				>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
							<Nav.Link href="/public/demande/adddemande">
								NOUVELLE DEMANDE D'ENLÈVEMENT
							</Nav.Link>
							<Nav.Link href="/public/demande/adddemandeparlot">
								NOUVELLE DEMANDE D'ENLÈVEMENT PAR LOT
							</Nav.Link>
							<Nav.Link href="/public/demande/page/1">
								SUIVI DEMANDES D'ENLÈVEMENT
							</Nav.Link>
							<Nav.Link href="/public/bordereau/page/1">
								GESTION DES BORDEREAUX
							</Nav.Link>
							<Nav.Link href="/public/expedition">
								DÉTAIL DES EXPÉDITIONS
							</Nav.Link>
							<NavDropdown title="SUIVI" id="gestion-nav-dropdown">
								<NavDropdown.Item href="/public/colis">
									SUIVI DE COLIS
								</NavDropdown.Item>
								<NavDropdown.Item href="/public/lot">
									SUIVI DE LOT
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown
								title={
									<span>
										<i className="fas fa-user-circle"> </i> {login_client}
									</span>
								}
							>
								<NavDropdown.Item href="">
									<div
										onClick={() => {
											authenticationService.logout();
										}}
									>
										<i className="fas fa-sign-out-alt mr-1"></i>
										Déconnexion
									</div>
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		);
	}
}

export default NavAdmin;
