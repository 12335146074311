import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table } from "react-bootstrap";
import * as config from "config/config";

import NavAdmin from "components/nav/NavAdmin";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getColisInfos = require("helpers/getColisInfos");

class SearchTable extends Component {
	constructor(props) {
		super(props);
		this.SearchTable = this.SearchTable.bind(this);
	}

	componentDidMount() {
		config
			.apiFetch(config.Api_Url + "/colis/getAllColisState", {
				method: "GET",
				headers: { "Content-Type": "application/json" },
			})
			.then((response) => response.json())
			.then((data) => {
				let packageState = [];
				for (let number = 0; number < data["data"].length; number++) {
					let etat = getColisInfos.getColisState(
						data["data"][number]["etatlivraison"]
					);

					packageState.push(
						<option
							key={"option_state_" + number}
							value={data["data"][number]["etatlivraison"]}
						>
							{etat}
						</option>
					);
				}
				this.setState({
					packageState: [packageState],
				});
			});
	}

	SearchTable() {
		let active = parseInt(this.props.match.params.page);
		let classNamePagination = "";
		let items = [];
		let first_pagination = 1;
		if (this.props.hasPagination) {
			if (
				active != 1 &&
				active != 2 &&
				active != 3 &&
				active != 4 &&
				active != 5
			) {
				for (
					let number = active - 4;
					number <= this.props.total / 20 + 1;
					number++
				) {
					if (number > active + 4) {
						break;
					}
					if (active === number) {
						classNamePagination = "page-item active";
					} else {
						classNamePagination = "page-item";
					}
					items.push(
						<li key={"pagination_" + number} className={classNamePagination}>
							<a className="page-link" href={number}>
								{number}
							</a>
						</li>
					);
				}
			} else {
				for (
					let number = first_pagination;
					number <= this.props.total / 20 + 1;
					number++
				) {
					if (number > active + 4) {
						break;
					}
					if (active === number) {
						classNamePagination = "page-item active";
					} else {
						classNamePagination = "page-item";
					}
					items.push(
						<li key={"pagination_" + number} className={classNamePagination}>
							<a className="page-link" href={number}>
								{number}
							</a>
						</li>
					);
				}
			}
		}
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					{this.props.methodDateState ? (
						<h1>
							Résultat de votre recherche du{" "}
							{dateFormatConvert.getFormattedDate(
								this.props.match.params.start
							)}{" "}
							au{" "}
							{dateFormatConvert.getFormattedDate(this.props.match.params.end)}
						</h1>
					) : null}

					{this.props.methodPackageCode ? (
						<h1>
							Résultat de votre recherche par numéro de colis
							{" " + this.props.match.params.colis_id}
						</h1>
					) : null}

					{this.props.methodSociety ? (
						<h1>
							Résultat de votre recherche par le code société :{" "}
							{this.props.match.params.societyCode}
						</h1>
					) : null}

					{this.props.methodDateDestination ? (
						<div>
							<h1>
								Résultat de votre recherche du{" "}
								{dateFormatConvert.getFormattedDate(
									this.props.match.params.start
								)}{" "}
								au{" "}
								{dateFormatConvert.getFormattedDate(
									this.props.match.params.end
								)}
							</h1>
							<h2 className="text-center">
								Nom : {this.props.match.params.nom + " "}
								Ville : {this.props.match.params.ville}
							</h2>
						</div>
					) : null}
					<Table responsive bordered hover>
						<thead>
							<tr>
								<th>N°Colis</th>
								<th>Code barre sous-traitant</th>
								<th>Date Demande</th>
								<th>Poids (Kg)</th>
								<th>Nom Prénom Destinataire</th>
								<th>Adresse Destinataire</th>
								<th>Ville</th>
								<th>Téléphone</th>
								<th>Description</th>
								<th>Valeur assurée</th>
								<th>Infos Particulières</th>
								<th>Etat</th>
								<th>Date livraison</th>
								<th>Type colis</th>
								<th>Modifier</th>
								<th></th>
								<th>List des colis</th>
							</tr>
						</thead>
						<tbody>
							{this.props.colis.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								return d[keys].map((data) => {
									let list_colis_URL =
										"/admin/colis/listcolisinlot/" + data.colis_id;
									let modify_URL = "/admin/colis/modify/" + data.colis_id;
									let etat = getColisInfos.getColisState(data.etatlivraison);
									let raison = getColisInfos.getColisReason(data.raison);
									let css_class_raison = getColisInfos.getColisReasonCss(
										data.raison
									);
									return (
										<tr key={data.colis_id} className={css_class_raison}>
											<td>{data.colis_id}</td>
											<td>{data.colis_id_ciblex}</td>
											<td>
												{dateFormatConvert.getFormattedDateWithHms(
													data.datedemande
												)}
											</td>
											<td>{data.poidscolis}</td>
											<td>
												{data.nomdest + "\n"}
												{data.prenomdest}
											</td>
											<td>{data.adressedest}</td>
											<td>
												{data.codepostaldest + "\n"}
												{data.villedest}
											</td>
											<td>{data.telephonedest}</td>
											<td>{data.description}</td>
											<td>{data.valeur}</td>
											<td>{data.infosParticulieres}</td>
											<td>
												{etat} {raison ? "(" + raison + ")" : ""}
											</td>
											<td>
												{etat === "Livré" && data.datereleve
													? dateFormatConvert.getFormattedDateWithHms(
															data.datereleve
													  )
													: null}
												<br />
												{etat === "Livré" &&
												(data.nomliveur || data.prenomlivreur)
													? "par " + data.prenomlivreur + " " + data.nomliveur
													: null}
											</td>
											<td>{data.typeEnvoi}</td>
											<td>
												<a href={modify_URL}>
													<i className="fas fa-pencil-alt mr-1"></i>
													Modifier
												</a>
											</td>
											<td>
												{data.colis_id_ciblex ? (
													<a
														href={
															"/admin/colis/tracking/" + data.colis_id_ciblex
														}
													>
														En savoir plus
													</a>
												) : (
													""
												)}
											</td>
											<td>
												{data.estLot ? (
													<a href={list_colis_URL}>
														<i className="fa fa-list" aria-hidden="true"></i>
													</a>
												) : (
													"Pas un lot"
												)}
											</td>
										</tr>
									);
								});
							})}
						</tbody>
					</Table>
					<div>
						<nav aria-label="Page navigation example">
							<ul className="pagination">{items}</ul>
						</nav>
					</div>
				</div>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.SearchTable />
			</div>
		);
	}
}

export default withRouter(SearchTable);
