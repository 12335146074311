/* eslint-disable import/first */
import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import * as config from "config/config";
import NavPublic from "components/nav/NavPublic";
import { withRouter } from "react-router-dom";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getColisInfos = require("helpers/getColisInfos");

export class ListColisInLot extends React.Component {
	constructor() {
		super();
		this.state = {
			colis: [],
			ncolis: "",
		};
	}
	componentDidMount() {
		let endDate = this.props.match.params.end;
		let starDate = this.props.match.params.start;
		let page = this.props.match.params.page;
		//check if old client or brand new
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		config
			.apiFetch(
				config.Api_Url +
					"/lot/getlistColisInLot/" +
					this.props.match.params.colis_id,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					colis: [data],
					ncolis: data["data"].length,
				});
			});
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	render() {
		return (
			<div>
				<NavPublic />
				<div className="container-fluid">
					<h1>La liste des colis du lot {this.props.match.params.colis_id}</h1>
					<h6>Nombre de colis : {this.state.ncolis}</h6>

					<Table responsive bordered hover>
						<thead>
							<tr>
								<th>N°Colis</th>
								<th>Date Demande</th>
								<th>Poids (Kg)</th>
								<th>Nom Prénom Destinataire</th>
								<th>Adresse Destinataire</th>
								<th>Ville</th>
								<th>Téléphone</th>
								<th>Description</th>
								<th>Valeur assurée</th>
								<th>Infos Particulières</th>
								<th>Etat</th>
								<th>Date livraison</th>
								<th>Type colis</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.state.colis.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								return d[keys].map((data) => {
									let modify_URL = "/admin/colis/modify/" + data.colis_id;
									let etat = getColisInfos.getColisState(data.etatlivraison);
									let raison = getColisInfos.getColisReason(data.raison);
									let css_class_raison = getColisInfos.getColisReasonCss(
										data.raison
									);
									return (
										<tr key={data.colis_id} className={css_class_raison}>
											<td>{data.colis_id}</td>
											<td>
												{dateFormatConvert.getFormattedDateWithHms(
													data.datedemande
												)}
											</td>
											<td>{data.poidscolis}</td>
											<td>
												{data.nomdest + "\n"}
												{data.prenomdest}
											</td>
											<td>{data.adressedest}</td>
											<td>
												{data.codepostaldest + "\n"}
												{data.villedest}
											</td>
											<td className="text-nowrap">{data.telephonedest}</td>

											<td>{data.description}</td>
											<td>{data.valeur}</td>
											<td>{data.infosParticulieres}</td>
											<td>
												{" "}
												{etat} {raison ? "(" + raison + ")" : ""}
											</td>

											<td>
												{etat === "Livré" && data.datereleve
													? dateFormatConvert.getFormattedDateWithHms(
															data.datereleve
													  )
													: null}
												<br />
												{etat === "Livré" &&
												(data.nomliveur || data.prenomlivreur)
													? "par " + data.prenomlivreur + " " + data.nomliveur
													: null}
											</td>
											<td>{data.typeEnvoi}</td>
											<td>
												{data.colis_id_ciblex ? (
													<a
														href={
															"/public/colis/tracking/" + data.colis_id_ciblex
														}
													>
														En savoir plus
													</a>
												) : (
													""
												)}
											</td>
										</tr>
									);
								});
							})}
						</tbody>
					</Table>
					<Button
						onClick={this.handleClick}
						size="sm"
						className="pr-3 back-button mb-5 no-print"
					>
						<i className="fas fa-angle-left mr-2"></i>
						Retour
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouter(ListColisInLot);
