/* eslint-disable import/first */
import React from "react";
import * as config from "config/config";
import { withRouter } from "react-router-dom";
import SearchTable from "./searchTable";

export class SearchByDateAndState extends React.Component {
	constructor() {
		super();
		this.state = {
			colis: [],
			total: 0,
			ncolis: "",
		};
	}
	componentDidMount() {
		let endDate = this.props.match.params.end;
		let starDate = this.props.match.params.start;
		let page = this.props.match.params.page;
		let etatlivraison = this.props.match.params.etatlivraison;

		//check if old client or brand new
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		config
			.apiFetch(
				config.Api_Url +
					"/colis/getCountColisbyDateAndStatePublic/" +
					starDate +
					"/" +
					endDate +
					"/" +
					etatlivraison +
					"/" +
					id_client,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((total) => {
				this.setState({
					total: total["data"][0]["total"],
				});
			});
		config
			.apiFetch(
				config.Api_Url +
					"/colis/getColisbyDateAndStatePublic/" +
					starDate +
					"/" +
					endDate +
					"/" +
					etatlivraison +
					"/" +
					page +
					"/" +
					id_client,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					colis: [data],
					ncolis: data["data"].length,
				});
			});
	}
	render() {
		return (
			<SearchTable
				colis={this.state.colis}
				total={this.state.total}
				methodDateState={true}
				hasPagination={true}
			/>
		);
	}
}

export default withRouter(SearchByDateAndState);
