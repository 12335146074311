/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import * as config from "config/config";
import { authenticationService } from "services/authentication/authenticationService";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "asset/fontawesome/css/all.css";
import "asset/fontawesome/css/fontawesome.css";
import "asset/css/custom.scss";
import logo from "asset/images/citycolis-holding-logo.svg";
import bandeau from "asset/images/bandeau.jpg";

class Authentication extends Component {
	constructor() {
		super();
		this.state = {
			status: "",
		};
	}
	componentDidMount() {
		//Set available type for package
		localStorage.clear();
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
	}
	render() {
		return (
			<div
				className="container-fluid login-page"
				style={{ paddingTop: "5rem" }}
			>
				<div className="d-flex flex-column align-items-center w-100">
					{" "}
					<img
						src={bandeau}
						width="75%"
						className=""
						alt="CityColis Logo"
					></img>
				</div>
				<Row className="text-center justify-content-center mt-5 pt-5">
					<Col md={4}>
						<Formik
							initialValues={{ login: "", pwd: "" }}
							onSubmit={(values, { setStatus, setSubmitting }) => {
								authenticationService.login(values.login, values.pwd).then(
									(user) => {
										if (typeof user == "object") {
											//If came from a legit url => return
											/*
											const from = this.props.location.state;
											if (
												(from &&
													from.from.pathname.split("/")[1] == "admin" &&
													user["data"][0].aut_role != "Client") ||
												(from &&
													from.from.pathname.split("/")[1] == "public" &&
													user["data"][0].aut_role == "Client")
											) {
												this.props.history.push(from.from.pathname);
											}
											*/
											//if not redirect to proper interface
											if (
												user["data"][0].aut_role == "Admin" ||
												user["data"][0].aut_role == "Agence"
											) {
												this.props.history.push("/admin");
											} else if (user["data"][0].aut_role == "Client") {
												config
													.apiFetch(
														config.Api_Url +
															"/clients/getClientActive/" +
															user["data"][0].aut_old_client_id
													)
													.then((response) => response.json())
													.then(async (data) => {
														if (data["data"][0].formatEtiquettes) {
															let saveFormatEtiquettes = await localStorage.setItem(
																"formatEtiquettes",
																data["data"][0].formatEtiquettes
															);
														}
														if (data["data"][0].active == 1) {
															this.props.history.push("/public");
														} else {
															setSubmitting(false);
															setStatus("Ce compte est désactivé");
														}
													});
												//this.props.history.push("/public");
											} else if (user["data"][0].aut_role == "EDI") {
												this.props.history.push("/deposerfichieredi");
											}
										} else {
											setSubmitting(false);
											setStatus(
												"Nom d'utilisateur ou mot de passe invalide. Veuillez réessayer"
											);
										}
									},
									(error) => {
										setSubmitting(false);
										setStatus(error);
									}
								);
							}}
						>
							{({
								status,
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								/* and other goodies */
							}) => (
								<Form onSubmit={handleSubmit}>
									<Form.Label className="required pt-4">
										Nom d'utilisateur :
									</Form.Label>
									<Form.Control
										required
										type="text"
										aria-describedby="inputGroupPrepend"
										name="login"
										onChange={handleChange}
									/>
									<Form.Label className="required pt-4">
										Mot de passe :
									</Form.Label>
									<Form.Control
										required
										type="password"
										aria-describedby="inputGroupPrepend"
										name="pwd"
										onChange={handleChange}
									/>
									<Button
										className="mt-4"
										size=""
										type="submit"
										disabled={isSubmitting}
									>
										Connexion
									</Button>
									{status && (
										<div className={"alert alert-danger"}>{status}</div>
									)}
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Authentication;
