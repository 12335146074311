import { BehaviorSubject } from "rxjs";
import * as config from "config/config";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
/* eslint-disable import/first */
import React, { Component } from "react";
const currentUserSubject = new BehaviorSubject(
	JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
	login,
	logout,
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue() {
		return currentUserSubject.value;
	},
};

function login(login, pwd) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ login, pwd }),
	};

	return fetch(config.Api_Url + "/authentication", requestOptions)
		.then((response) => response.json())
		.then(async (user) => {
			if (user["data"].length) {
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				var value = await localStorage.setItem(
					"currentUser",
					JSON.stringify(user)
				);
				currentUserSubject.next(user);
				return user;
			}

			return "Nom d'utilisateur ou mot de passe invalide. Veuillez réessayer";
		});
}

function logout() {
	// remove user from local storage to log user out
	localStorage.clear();
	currentUserSubject.next(null);
	window.location.reload();
}
