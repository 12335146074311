/* eslint-disable import/first */
import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import {
	Form,
	Button,
	Row,
	Col,
	Spinner,
	Modal,
	ListGroup,
} from "react-bootstrap";
import { Formik } from "formik";
import Checkbox from "helpers/checkbox";
import * as yup from "yup";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getDateReleve = require("helpers/getDateReleve");

class ModalLotClosedInfo extends Component {
	constructor(props) {
		super(props);
		this.ModalLotClosedInfo = this.ModalLotClosedInfo.bind(this);
	}

	ModalLotClosedInfo() {
		return (
			<Row>
				<Modal show={this.props.openModal}>
					<Modal.Header>
						<Modal.Title>Fiche récapitulative</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ListGroup>
							{this.props.modalInfo.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								return d[keys].map((data) => {
									return (
										<ListGroup.Item key={data.nomdest}>
											{data.nomdest} : {data.total} colis
										</ListGroup.Item>
									);
								});
							})}
						</ListGroup>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => {
								this.props.history.push("/public");
							}}
						>
							Page d'accueil 
						</Button>
					</Modal.Footer>
				</Modal>
			</Row>
		);
	}

	render() {
		return (
			<div>
				<this.ModalLotClosedInfo />
			</div>
		);
	}
}

export default withRouter(ModalLotClosedInfo);
