/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import DatePickerField from "helpers/datePickerField";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getDateReleve = require("helpers/getDateReleve");
import * as yup from "yup";

/*CSS*/

class AddDemande extends Component {
	constructor() {
		let datereleve = getDateReleve.getDateReleve();
		super();
		this.state = {
			groupementcolis: {
				nomExpediteur: "",
				prenomExpediteur: "",
				adresseExpediteur: "",
				codepostalExpediteur: "",
				villeExpediteur: "",
				telephoneExpediteur: "",
				datedemande: new Date(),
				datereleve: datereleve,
				etat: "30",
				agence_origine: "",
				agence_cible: "",
			},
			clients: [],
			clients_list: [],
			idClient: "",
			agences: [],
			agence: [],
			data: [],
		};
	}
	componentDidMount() {
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;

		if (user_role == "Admin") {
			config
				.apiFetch(config.Api_Url + "/clients")
				.then((response) => response.json())
				.then((clients) => {
					let clients_list = [];
					for (let number = 0; number < clients["data"].length; number++) {
						clients_list.push(
							<option
								key={"clients_" + number}
								value={clients["data"][number]["id_client"]}
							>
								{clients["data"][number]["nom"]}{" "}
								{clients["data"][number]["prenom"]}
							</option>
						);
					}
					this.setState({
						clients: clients["data"],
						clients_list: [clients_list],
						idClient: clients["data"][0]["id_client"],
					});
				})
				.catch((err) => {
					console.log(err);
					// some error handling
				});
			config
				.apiFetch("/agences")
				.then((response) => response.json())
				.then((data) => {
					let agences = [];
					for (let number = 0; number < data["data"].length; number++) {
						agences.push(
							<option
								key={"option_agences_" + number}
								value={data["data"][number]["agence_id"]}
							>
								{data["data"][number]["agence_name"]}
							</option>
						);
					}
					this.setState({
						agences: [agences],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						data: data["data"][0],
					});
					config
						.apiFetch("/agences")
						.then((response) => response.json())
						.then((data) => {
							let agences = [];
							let agence = [];
							for (let number = 0; number < data["data"].length; number++) {
								agences.push(
									<option
										key={"option_agences_" + number}
										value={data["data"][number]["agence_id"]}
									>
										{data["data"][number]["agence_name"]}
									</option>
								);
								if (
									this.state.data.agence_id ===
									data["data"][number]["agence_id"]
								) {
									agence.push(
										<option
											key={"option_agences_" + number}
											value={data["data"][number]["agence_id"]}
										>
											{data["data"][number]["agence_name"]}
										</option>
									);
								}
							}
							this.setState({
								agences: [agences],
								agence: [agence],
							});
						});
					config
						.apiFetch(
							config.Api_Url + "/clients/agence/" + this.state.data.agence_id
						)
						.then((response) => response.json())
						.then((clients) => {
							let clients_list = [];
							for (let number = 0; number < clients["data"].length; number++) {
								clients_list.push(
									<option
										key={"clients_" + number}
										value={clients["data"][number]["id_client"]}
									>
										{clients["data"][number]["nom"]}{" "}
										{clients["data"][number]["prenom"]}
									</option>
								);
							}
							this.setState({
								clients: clients["data"],
								clients_list: [clients_list],
								idClient: clients["data"][0]["id_client"],
							});
						})
						.catch((err) => {
							console.log(err);
							// some error handling
						});
				});
		}
	}
	render() {
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		const schema = yup.object().shape(
			{
				agence_cible: yup.string().when("agence_origine", {
					is: (agence_origine) => agence_origine && agence_origine.length != 0,
					then: yup
						.string()
						.required(
							"Ce champ est obligatoire si c'est une demande hors site"
						),
					otherwise: yup.string(),
				}),
				agence_origine: yup.string().when("agence_cible", {
					is: (agence_cible) => agence_cible && agence_cible.length != 0,
					then: yup
						.string()
						.required(
							"Ce champ est obligatoire si c'est une demande hors site"
						),
					otherwise: yup.string(),
				}),
			},
			[["agence_cible", "agence_origine"]]
		);
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Nouvelle demande</h1>
							<Formik
								validationSchema={schema}
								initialValues={this.state.groupementcolis}
								enableReinitialize
								onSubmit={(values, { setSubmitting }) => {
									config
										.apiFetch(config.Api_Url + "/new/demande", {
											method: "POST",
											body: JSON.stringify({
												id_client: this.state.idClient,
												datedemande: dateFormatConvert.convertDateTimeForSql(
													values.datedemande
												),
												datereleve: dateFormatConvert.convertDateForSql(
													values.datereleve
												),
												nomExpediteur: values.nomExpediteur,
												prenomExpediteur: values.prenomExpediteur,
												adresseExpediteur: values.adresseExpediteur.replace(
													/'/g,
													"\\'"
												),
												codepostalExpediteur: values.codepostalExpediteur,
												villeExpediteur: values.villeExpediteur,
												telephoneExpediteur: values.telephoneExpediteur,
												etat: values.etat,
												agence_origine: values.agence_origine
													? values.agence_origine
													: null,
												agence_cible: values.agence_cible
													? values.agence_cible
													: null,
											}),
											headers: {
												"Content-Type": "application/json",
											},
										})
										.then((response) => response.json())
										.then((data) => {
											if (data["data"] && data["data"].insertId) {
												config
													.apiFetch(
														config.Api_Url + "/add/addColisFromPublic",
														{
															method: "POST",
															body: JSON.stringify({
																groupement_id: data["data"].insertId,
																poidscolis: 1,
																nomdest: "",
																prenomdest: "",
																adressedest: "",
																codepostaldest: "",
																villedest: "",
																telephonedest: "",
																type: "",
																infosParticulieres: "",
																values: 0,
																typeEnvoi: "",
															}),

															headers: {
																"Content-Type": "application/json",
															},
														}
													)
													.then((response) => response.json())
													.then((data) => {
														this.props.history.push("/admin/demande/page/1");
													});
											}
										})
										.catch((err) => {
											// some error handling
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit}>
										<Form.Label className="required">Date demande :</Form.Label>

										<DatePickerField
											className="form-control"
											name="datedemande"
											value={values.datedemande}
										/>

										<Form.Label className="required">
											Date d'enlèvement :
										</Form.Label>
										<DatePickerField
											className="form-control"
											name="datereleve"
											value={values.datereleve}
										/>

										<Row>
											<Col md={8}>
												<Form.Label>Client :</Form.Label>
												<Form.Control
													as="select"
													name="client"
													onChange={(e) =>
														this.setState({ idClient: e.target.value })
													}
												>
													{this.state.clients_list}
												</Form.Control>
											</Col>
											<Col
												md={4}
												className="d-flex flex-column justify-content-end"
											>
												<Button
													size=""
													disabled={!this.state.idClient}
													onClick={() => {
														let client_index = this.state.clients.findIndex(
															(c) => c.id_client == this.state.idClient
														);
														this.setState({
															groupementcolis: {
																nomExpediteur: this.state.clients[client_index]
																	.nom,
																prenomExpediteur: this.state.clients[
																	client_index
																].prenom,
																adresseExpediteur: this.state.clients[
																	client_index
																].adresse,
																codepostalExpediteur: this.state.clients[
																	client_index
																].codepostal,
																villeExpediteur: this.state.clients[
																	client_index
																].ville,
																telephoneExpediteur: this.state.clients[
																	client_index
																].telephone,
																datereleve: values.datereleve,
																datedemande: values.datedemande,
																etat: values.etat,
																agence_cible: values.agence_cible,
																agence_origine: values.agence_origine,
															},
														});
													}}
												>
													Afficher
												</Button>
											</Col>
										</Row>

										<Form.Label className="required">Nom :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="nomExpediteur"
											value={values.nomExpediteur || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Prénom :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="prenomExpediteur"
											value={values.prenomExpediteur}
											onChange={handleChange}
										/>

										<Form.Label className="required">Adresse :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="adresseExpediteur"
											value={values.adresseExpediteur || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Code Postal :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="codepostalExpediteur"
											value={values.codepostalExpediteur || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Ville :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="villeExpediteur"
											value={values.villeExpediteur || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Téléphone :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="telephoneExpediteur"
											value={values.telephoneExpediteur || ""}
											onChange={handleChange}
										/>

										<Form.Label className="required">Etat :</Form.Label>
										<Form.Control
											as="select"
											required
											name="etat"
											value={values.etat}
											onChange={handleChange}
										>
											<option value="30">En attente d’enlèvement</option>
											<option value="32">Enlevé</option>
										</Form.Control>
										<Form.Label>
											Veuillez choisir une agence origin si c'est une demande
											hors site :
										</Form.Label>

										{user_role == "Admin" ? (
											<Form.Control
												as="select"
												name="agence_origine"
												value={values.agence_origine || ""}
												onChange={handleChange}
												isInvalid={!!errors.agence_origine}
											>
												<option></option>
												{this.state.agences}
											</Form.Control>
										) : (
											<Form.Control
												as="select"
												name="agence_origine"
												value={values.agence_origine || ""}
												onChange={handleChange}
												isInvalid={!!errors.agence_origine}
											>
												<option></option>
												{this.state.agence}
											</Form.Control>
										)}
										<Form.Control.Feedback className="FeedBack" type="invalid">
											{errors.agence_origine}
										</Form.Control.Feedback>
										<Form.Label>
											Veuillez choisir une agence cible si c'est une demande
											hors site :
										</Form.Label>

										<Form.Control
											as="select"
											name="agence_cible"
											value={values.agence_cible || ""}
											onChange={handleChange}
											isInvalid={!!errors.agence_cible}
										>
											<option></option>
											{this.state.agences}
										</Form.Control>
										<Form.Control.Feedback className="FeedBack" type="invalid">
											{errors.agence_cible}
										</Form.Control.Feedback>
										<Button
											className="my-4"
											size="sm"
											type="submit"
											//disabled={isSubmitting}
										>
											Enregistrer
										</Button>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(AddDemande);
