import React, { Component } from "react";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import SearchForm from "../searchForm";
import { withRouter } from "react-router";

export class SearchResults extends React.Component {
	constructor() {
		super();
		this.state = {
			data: [],
			items: [],
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.match.params.ville !== prevProps.match.params.ville ||
			this.props.match.params.nom !== prevProps.match.params.nom
		) {
			window.location.reload();
		}
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let nom = this.props.match.params.nom;
		let prenom = this.props.match.params.prenom;
		let ville = this.props.match.params.ville;
		let page = this.props.match.params.page_id;

		config
			.apiFetch(
				config.Api_Url +
					"/find/clients/" +
					nom +
					"/" +
					prenom +
					"/" +
					ville +
					"/" +
					page
			)
			.then((response) => response.json())
			.then((clients) => {
				this.setState({
					data: [clients],
				});
			});
		config
			.apiFetch(
				config.Api_Url +
					"/find/total/clients/" +
					nom +
					"/" +
					prenom +
					"/" +
					ville +
					"/" +
					page
			)
			.then((response) => response.json())
			.then((clients) => {
				this.setState({
					total: [clients["data"][0]["total"]],
				});
			});
	}
	render() {
		let active = parseInt(this.props.match.params.page_id);
		let classNamePagination = "";

		let items = [];
		for (let number = 1; number <= this.state.total / 20 + 1; number++) {
			if (active === number) {
				classNamePagination = "page-item active";
			} else {
				classNamePagination = "page-item";
			}
			items.push(
				<li key={"pagination_" + number} className={classNamePagination}>
					<a className="page-link" href={number}>
						{number}
					</a>
				</li>
			);
		}
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<h1>Résultats de la recherche des Clients</h1>
					<SearchForm />
					<Table responsive bordered hover>
						<thead>
							<tr>
								<th>Id</th>
								<th>Nom Prenom</th>
								<th>Adresse</th>
								<th>Ville</th>
								<th>Telephone</th>
								<th>Mail</th>
								<th>Profil</th>
								<th>Type de client</th>
								<th>Agence</th>
								<th>Modification / Activation</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;

								return d[keys].map((data) => {
									let link = "/admin/clients/modify/" + data.id_client;
									let enable = "/admin/clients/enable/" + data.id_client;
									let disable = "/admin/clients/disable/" + data.id_client;
									let enable_disable_link = "";
									if (data.active == 1) {
										enable_disable_link = (
											<a href={disable}>
												<i className="fas fa-toggle-on"></i>
												Désactiver
											</a>
										);
									} else {
										enable_disable_link = (
											<a href={enable}>
												<i className="fas fa-toggle-off"></i>
												Activer
											</a>
										);
									}
									return (
										<tr key={data.id_client}>
											<td className={data.statut}>{data.id_client}</td>
											<td>
												{data.nom} {data.prenom}
											</td>
											<td>{data.adresse}</td>
											<td>{data.ville}</td>
											<td>{data.telephone}</td>
											<td>{data.mail}</td>
											<td>{data.profil}</td>
											<td>{data.casUPS}</td>
											<td>{data.agence_name}</td>
											<td>
												<a href={link}>
													<i className="fas fa-pencil-alt"></i>
													Modifier
												</a>
												{enable_disable_link}
											</td>
										</tr>
									);
								});
							})}
						</tbody>
					</Table>
					<div>
						<nav aria-label="Page navigation example">
							<ul className="pagination">{items}</ul>
						</nav>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(SearchResults);
