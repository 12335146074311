/* eslint-disable import/first */
import React from "react";
import NavAdmin from "components/nav/NavAdmin";
import { withRouter } from "react-router-dom";
import { Button, Row, Col, Table, Container } from "react-bootstrap";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getColisInfos = require("helpers/getColisInfos");
var Barcode = require("react-barcode");

export class FicheTournee extends React.Component {
	constructor() {
		super();
		this.state = {
			tournee: [],
			fiche: [],
			zonetournee: "",
			pagination_fixed: 20,
			total: 0,
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		config
			.apiFetch(
				config.Api_Url + "/tournee/fiche/" + this.props.match.params.tournee_id
			)
			.then((response) => response.json())
			.then((fiche) => {
				if (fiche["data"][0]) {
					let src =
						"data:image/jpg;base64," +
						new Buffer(fiche["data"][0].agence_logo).toString("base64");
					this.setState({
						zonetournee: "Tournée : " + fiche["data"][0].zonetournee,
						agence_address: fiche["data"][0].agence_address,
						agence_phone: fiche["data"][0].agence_phone,
						agence_name: fiche["data"][0].agence_name,
						agence_ville: fiche["data"][0].agence_ville,
						logo: (
							<img src={src} style={{ maxHeight: 150, maxWidth: 150 }}></img>
						),
					});
				}
				this.setState({
					tournee: [fiche],
				});
			});

		config
			.apiFetch(
				config.Api_Url +
					"/tournee/ficheDetails/" +
					this.props.match.params.tournee_id
			)
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					fiche: [data],
					total: this.countColis(data),
				});
			});
	}

	countColis(data) {
		if (data["data"]) {
			let count = 0;
			data["data"].forEach((x) => {
				if (getColisInfos.checkLotPrincipalId(x)) {
					count++;
				}
			});
			return data["data"].length - count;
		} else {
			return 0;
		}
	}

	render() {
		return (
			<div>
				<NavAdmin />
				<Container>
					{this.state.tournee.map((dynamicData, Key) => {
						let keys = Object.keys(dynamicData);
						let d = dynamicData;
						return d[keys].map((data) => {
							return (
								<h1 key={data.tournee_id}>
									Bordereau de livraison
									<br></br>
									{/* {this.state.zonetournee} */}
									<Button
										size="sm"
										variant="primary"
										className="no-print"
										onClick={() => window.print()}
									>
										Imprimer
									</Button>
								</h1>
							);
						});
					})}
					<Row>
						<Col className="d-flex justify-content-center">
							{this.state.logo}
						</Col>
						<Col
							xs={6}
							className="d-flex flex-column justify-content-center align-items-center text-center"
						>
							{this.state.tournee.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								return d[keys].map((data) => {
									return (
										<div key={data.tournee_id}>
											<h3>
												{/* Date de la tournée :{" "} */}
												{dateFormatConvert.getFormattedDate(data.datetournee)} -
												Tournée {data.tournee_id}
												<br></br>
												{data.prenom + " " + data.nom}
												<br></br>
												{this.state.total + " colis"}
											</h3>
										</div>
									);
								});
							})}
						</Col>
						<Col className="text-center">
							<div>{this.state.agence_name}</div>
							<div>{this.state.agence_address}</div>
							<div>{this.state.agence_ville}</div>
							<div>Tél:{this.state.agence_phone}</div>
						</Col>
					</Row>
				</Container>

				<Table responsive bordered>
					<thead>
						<tr>
							<th>Référence</th>
							<th>Expediteur</th>
							<th>Destinataire</th>
							<th>Problème</th>
							<th>{/* Signature du client */}</th>
						</tr>
					</thead>
					<tbody>
						{this.state.fiche.map((dynamicData, Key) => {
							let keys = Object.keys(dynamicData);
							let d = dynamicData;
							return d[keys].map((data) => {
								return (
									<tr key={data.colis_id}>
										<th>
											<Barcode value={data.colis_id} />
										</th>
										<th>
											<div>{data.nomExpediteur}</div>
											<div>{data.prenomExpediteur}</div>
											<div>{data.adresseExpediteur}</div>
											<div>{data.codepostalExpediteur}</div>
											<div>{data.villeExpediteur}</div>
											<div>{data.telephoneExpediteur}</div>
										</th>
										<th>
											<div>{data.nomdest}</div>
											<div>{data.prenomdest}</div>
											<div>{data.adressedest}</div>
											<div>{data.codepostaldest}</div>
											<div>{data.villedest}</div>
											<div>{data.telephonedest}</div>
										</th>
										<th>
											<div>
												<i className="far fa-square mr-1"></i> Refus de
												signature
											</div>
											<div>
												<i className="far fa-square mr-1"></i> Emballage abîmé
											</div>
											<div>
												<i className="far fa-square mr-1"></i> Emballage correct
												mais contenu abîmé
											</div>
											<div>
												<i className="far fa-square mr-1"></i> Lot incomplet
											</div>
											<div>
												<i className="far fa-square mr-1"></i> Non commandé
											</div>
											<div>
												<i className="far fa-square mr-1"></i> Absence de bon de
												livraison
											</div>
											<div>
												<i className="far fa-square mr-1"></i> Refusé
											</div>
										</th>
										<th className="d-flex flex-column justify-content-start align-items-center">
											<div>Signature du client :</div>
											<div className="signature"></div>
										</th>
									</tr>
								);
							});
						})}
					</tbody>
				</Table>
			</div>
		);
	}
}

export default withRouter(FicheTournee);
