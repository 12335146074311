/* eslint-disable import/first */
import React, { Component } from "react";
import NavPublic from "../../../nav/NavPublic";
import { render } from "@testing-library/react";
import {
	Navbar,
	Nav,
	NavDropdown,
	Card,
	CardColumns,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import FormDemandeParLot from "../add/formDemandeParLot";

class AddDemandeParLotPublic extends Component {
	constructor() {
		super();
		this.user = JSON.parse(localStorage.getItem("currentUser"))[
			"data"
		][0].aut_login;
	}
	render() {
		return (
			<div>
				<NavPublic />
				<h1 className="pt-5">Nouvelle demande d'enlèvement par lot</h1>
				{/* {this.user} */}
				<div className="container public-demande-enlevement">
					<Row className="justify-content-center">
						<Col>
							<FormDemandeParLot />
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default AddDemandeParLotPublic;
