/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "../nav/NavAdmin";
import NavPublic from "../nav/NavPublic";

import { render } from "@testing-library/react";
import $ from "jquery";

/*CSS*/
import "./404.css";

class NotFound extends Component {
	constructor() {
		super();
	}

	componentDidMount() {
		if (!localStorage.getItem("currentUser")) {
			this.props.history.push("/");
		}
	}

	componentDidMount() {
		$(function () {
			var liWidth = $("li").css("width");
			$("li").css("height", liWidth);
			$("li").css("lineHeight", liWidth);
			var totalHeight = $("#wordsearch").css("width");
			$("#wordsearch").css("height", totalHeight);
		});
		let causeRepaintsOn = $("h1, h2, h3, p");
		$(window).resize(function () {
			causeRepaintsOn.css("z-index", 1);
		});
		$(window).on("resize", function () {
			var liWidth = $(".one").css("width");
			$("li").css("height", liWidth);
			$("li").css("lineHeight", liWidth);
			var totalHeight = $("#wordsearch").css("width");
			$("#wordsearch").css("height", totalHeight);
		});

		$(function () {
			/* 4 */
			$(this)
				.delay(1500)
				.queue(function () {
					$(".one").addClass("selected");
					$(this).dequeue();
				})
				/* 0 */
				.delay(500)
				.queue(function () {
					$(".two").addClass("selected");
					$(this).dequeue();
				})
				/* 4 */
				.delay(500)
				.queue(function () {
					$(".three").addClass("selected");
					$(this).dequeue();
				})
				/* P */
				.delay(500)
				.queue(function () {
					$(".four").addClass("selected");
					$(this).dequeue();
				})
				/* A */
				.delay(500)
				.queue(function () {
					$(".five").addClass("selected");
					$(this).dequeue();
				})
				/* G */
				.delay(500)
				.queue(function () {
					$(".six").addClass("selected");
					$(this).dequeue();
				})
				/* E */
				.delay(500)
				.queue(function () {
					$(".seven").addClass("selected");
					$(this).dequeue();
				})
				/* N */
				.delay(500)
				.queue(function () {
					$(".eight").addClass("selected");
					$(this).dequeue();
				})
				/* O */
				.delay(500)
				.queue(function () {
					$(".nine").addClass("selected");
					$(this).dequeue();
				})
				/* T */
				.delay(500)
				.queue(function () {
					$(".ten").addClass("selected");
					$(this).dequeue();
				})
				/* F */
				.delay(500)
				.queue(function () {
					$(".eleven").addClass("selected");
					$(this).dequeue();
				})
				/* O */
				.delay(500)
				.queue(function () {
					$(".twelve").addClass("selected");
					$(this).dequeue();
				})
				/* U */
				.delay(500)
				.queue(function () {
					$(".thirteen").addClass("selected");
					$(this).dequeue();
				})
				/* N */
				.delay(500)
				.queue(function () {
					$(".fourteen").addClass("selected");
					$(this).dequeue();
				})
				/* D */
				.delay(500)
				.queue(function () {
					$(".fifteen").addClass("selected");
					$(this).dequeue();
				});
		});
	}
	render() {
		let user_role = "";
		if (localStorage.getItem("currentUser")) {
			user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
				.aut_role;
			return (
				<div>
					{user_role == "Admin" || user_role == "Agence" ? (
						<NavAdmin />
					) : (
						<NavPublic />
					)}
					<div id="wrap">
						<div id="wordsearch">
							<ul>
								<li>k</li>

								<li>v</li>

								<li>n</li>

								<li>z</li>

								<li>i</li>

								<li>x</li>

								<li>m</li>

								<li>e</li>

								<li>t</li>

								<li>a</li>

								<li>x</li>

								<li>l</li>

								<li className="one">4</li>

								<li className="two">0</li>

								<li className="three">4</li>

								<li>y</li>

								<li>y</li>

								<li>w</li>

								<li>v</li>

								<li>b</li>

								<li>o</li>

								<li>q</li>

								<li>d</li>

								<li>y</li>

								<li>p</li>

								<li>a</li>

								<li className="four">p</li>

								<li className="five">a</li>

								<li className="six">g</li>

								<li className="seven">e</li>

								<li>v</li>

								<li>j</li>

								<li>a</li>

								<li className="eight">n</li>

								<li className="nine">o</li>

								<li className="ten">t</li>

								<li>s</li>

								<li>c</li>

								<li>e</li>

								<li>w</li>

								<li>v</li>

								<li>x</li>

								<li>e</li>

								<li>p</li>

								<li>c</li>

								<li>f</li>

								<li>h</li>

								<li>q</li>

								<li>e</li>

								<li className="eleven">f</li>

								<li className="twelve">o</li>

								<li className="thirteen">u</li>

								<li className="fourteen">n</li>

								<li className="fifteen">d</li>

								<li>s</li>

								<li>w</li>

								<li>q</li>

								<li>v</li>

								<li>o</li>

								<li>s</li>

								<li>m</li>

								<li>v</li>

								<li>f</li>

								<li>u</li>
							</ul>
						</div>

						<div id="main-content">
							<h1>Page introuvable</h1>

							<p>
								Le serveur n'a pas trouvé l'adresse indiquée. C'est peut-être dû
								à une faute de frappe, à une indisponibilité du serveur, à
								l'absence de la page ou au fait que vous n'avez pas
								l'autorisation de visiter cette page.
							</p>

							<p>
								Dirigez-vous vers la page d'accueil ou contactez-nous en cas de
								questions...
							</p>
						</div>
					</div>
				</div>
			);
		} else {
			return <div></div>;
		}
	}
}

export default NotFound;
