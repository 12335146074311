import React, { Component } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class ChangeState extends Component {
  constructor(props) {
    super(props);
    //this.ChangeState = this.ChangeState.bind(this);
  }
  render() {
    return (
      <Col md={3}>
        <Form.Control
          as="select"
          name="changing_state"
          onChange={this.props.handleChange}
        >
          <option value="30">En attente d’enlèvement</option>
          <option value="34">Livré</option>
          <option value="35">Reporté</option>
          <option value="99">Traité</option>
          <option value="32">Annulé</option>
          <option value="37">Refusé</option>
          <option value="38">Avisé</option>
          <option value="36">Colis manquant</option>
        </Form.Control>
        <Button
          className="w-100"
          variant="outline-secondary"
          type="submit"
          disabled={this.props.isSubmitting}
        >
          Valider le changement d'état
        </Button>
      </Col>
    );
  }
}

export default withRouter(ChangeState);
