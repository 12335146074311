/* eslint-disable import/first */
import React from "react";
import * as config from "config/config";
import { withRouter } from "react-router-dom";
import SearchTable from "./searchTable";

export class searchByDateAndState extends React.Component {
	constructor() {
		super();
		this.state = {
			colis: [],
			total: 0,
		};
	}
	componentDidMount() {
		let endDate = this.props.match.params.end;
		let starDate = this.props.match.params.start;
		let page = this.props.match.params.page;
		let etatlivraison = this.props.match.params.etatlivraison;

		config
			.apiFetch(
				config.Api_Url +
					"/colis/getCountColisbyDateAndState/" +
					starDate +
					"/" +
					endDate +
					"/" +
					etatlivraison,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((total) => {
				console.log(total);
				if (total["data"][0]["total"] > 1000) {
					this.props.history.push("/admin/colis/erreur");
				} else {
					this.setState({
						total: [total["data"][0]["total"]],
					});
					config
						.apiFetch(
							config.Api_Url +
								"/colis/getColisbyDateAndState/" +
								starDate +
								"/" +
								endDate +
								"/" +
								etatlivraison +
								"/" +
								page,
							{
								method: "GET",
								headers: { "Content-Type": "application/json" },
							}
						)
						.then((response) => response.json())
						.then((data) => {
							console.log(data);
							this.setState({
								colis: [data],
							});
						});
				}
			});
	}
	render() {
		let active = parseInt(this.props.match.params.page);
		let classNamePagination = "";
		let items = [];

		let first_pagination = 1;
		if (
			active != 1 &&
			active != 2 &&
			active != 3 &&
			active != 4 &&
			active != 5
		) {
			for (
				let number = active - 4;
				number <= this.state.total / 20 + 1;
				number++
			) {
				if (number > active + 4) {
					break;
				}
				if (active === number) {
					classNamePagination = "page-item active";
				} else {
					classNamePagination = "page-item";
				}
				items.push(
					<li key={"pagination_" + number} className={classNamePagination}>
						<a className="page-link" href={number}>
							{number}
						</a>
					</li>
				);
			}
		} else {
			for (
				let number = first_pagination;
				number <= this.state.total / 20 + 1;
				number++
			) {
				if (number > active + 4) {
					break;
				}
				if (active === number) {
					classNamePagination = "page-item active";
				} else {
					classNamePagination = "page-item";
				}
				items.push(
					<li key={"pagination_" + number} className={classNamePagination}>
						<a className="page-link" href={number}>
							{number}
						</a>
					</li>
				);
			}
		}

		return (
			<SearchTable
				colis={this.state.colis}
				total={this.state.total}
				methodDateState={true}
				hasPagination={true}
			/>
		);
	}
}

export default withRouter(searchByDateAndState);
