//TO DO INFO
function getOffSiteAgency(codepostal, agenceorigine) {
	let department = codepostal.substring(0, 2);
	switch (department) {
		case "14":
			return 1;
		case "50":
			return 3;
		case "53":
			return 5;
		case "61":
			return 9;
		default:
			return agenceorigine;
	}
}

exports.getOffSiteAgency = getOffSiteAgency;
