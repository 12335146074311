/* eslint-disable import/first */
import React from "react";
import { Table, Row, Col } from "react-bootstrap";
import * as config from "config/config";
import { withRouter } from "react-router-dom";

export class SummaryTable extends React.Component {
	constructor(props) {
		super(props);
		this.SummaryTable = this.SummaryTable.bind(this);
	}
	SummaryTable() {
		let details = this.props.details;

		if (!details || !this.props.nb_total || !this.props.sum_total) {
			return (
				<div>
					<div className="text-center">
						{/*<hr className="myhrline" />*/}
						<h2>
							Client : {this.props.client.nom + " " + this.props.client.prenom}
							<br />
							Code postal :{this.props.client.codepostal}
						</h2>
					</div>
				</div>
			);
		} else {
			let sum = 0;
			let nb_colis = 0;
			let currentIntervalleMin = 0;
			let counter = -1;
			const reducer = (accumulator, currentValue) => accumulator + currentValue;
			if (this.props.sum_total.length != 0) {
				sum = this.props.sum_total.reduce(reducer);
			}
			if (this.props.nb_total.length != 0) {
				nb_colis = this.props.nb_total.reduce(reducer);
			}
			nb_colis = nb_colis - this.props.nb_total[this.props.nb_total.length - 1];

			return (
				<div>
					<div className="p-5">
						<Row>
							<Col xs={6}>{this.props.logo}</Col>
						</Row>
						<div className="text-left">
							<h3>
								<b>Récapitulatif de : </b>
								{this.props.lesMois[this.props.match.params.month - 1] +
									" " +
									this.props.match.params.year}
								<br />
								<b>Client : </b>
								{this.props.client.nom + " " + this.props.client.prenom}
								<br />
								<b>Code postal : </b>
								{this.props.client.codepostal}
							</h3>
						</div>
						<div className="d-flex justify-content-end">
							{" "}
							<div /* className="thick-border" */>
								<h3>
									<b> Total colis : </b>
									{nb_colis}
								</h3>
								<h3>
									<b>Montant H.T : </b>
									{Math.round(sum * 100) / 100}€
								</h3>
							</div>
						</div>
						<h3>Tarifs en vigueur</h3>
						<Table bordered hover>
							<thead>
								<tr className="print-text-black">
									<th>Intervalle poids (kg)</th>
									<th>Dép. 14</th>
									<th>Dép. 28</th>
									<th>Dép. 30</th>
									<th>Dép. 34</th>
									<th>Dép. 44</th>
									<th>Dép. 50</th>
									<th>Dép. 53</th>
									<th>Dép. 61</th>
									<th>Dép. 72</th>
									<th>National</th>
									<th>Spécifique</th>
									<th>Palette</th>
									<th>En Lot</th>
									{/* <th>Nb Colis</th>
									<th>Total (€)</th> */}
								</tr>
							</thead>
							<tbody>
								{this.props.details.map((data) => {
									counter++;
									let nb_total = this.props.nb_total[counter];
									let sum_total = this.props.sum_total[counter];
									//update interval Min
									let min = currentIntervalleMin;
									currentIntervalleMin = data.max;
									if (counter < this.props.details.length - 1)
										return (
											<tr key={data.max}>
												<td>
													De {min} à {data.max}
												</td>
												<td>{data.prix14}</td>
												<td>{data.prix28}</td>
												<td>{data.prix30}</td>
												<td>{data.prix34}</td>
												<td>{data.prix44}</td>
												<td>{data.prix50}</td>
												<td>{data.prix53}</td>
												<td>{data.prix61}</td>
												<td>{data.prix72}</td>
												<td>{data.prixHors14}</td>
												<td>{data.prixSpec}</td>
												<td>{data.prixPalette}</td>
												<td>{data.prixLot}</td>
												{/* <td>{nb_total}</td>
												<td>{Math.round(sum_total * 100) / 100}</td> */}
											</tr>
										);
									else {
										counter++;
										let nb_sup_hors_site = this.props.nb_total[counter];
										let sum_total_sup_hors_site = this.props.sum_total[counter];

										return (
											<tr key={data.max}>
												<td>
													De {min} à {data.max}
												</td>
												<td>{data.prix14}</td>
												<td>{data.prix30}</td>
												<td>{data.prix34}</td>
												<td>{data.prix44}</td>
												<td>{data.prix50}</td>
												<td>{data.prix53}</td>
												<td>{data.prix61}</td>
												<td>{data.prix72}</td>
												<td>{data.prixHors14}</td>
												<td>{data.prix28}</td>
												<td>{data.prixSpec}</td>
												<td>{data.prixPalette}</td>
												<td>{data.prixLot}</td>
												{/* <td>{nb_total}</td>
												<td>{Math.round(sum_total * 100) / 100}</td> */}
											</tr>
										);
									}
								})}
								<tr key="sup">
									<td>Hors Site</td>
									<td className="text-center" colSpan="13">
										{config.hors_site_sup_value}€
									</td>
									{/* 
									<td>{this.props.nb_total[this.props.nb_total.length - 1]}</td>
									<td>
										{this.props.sum_total[this.props.sum_total.length - 1]}
									</td> */}
								</tr>
							</tbody>
						</Table>
					</div>
				</div>
			);
		}
	}
	render() {
		return (
			<div>
				<this.SummaryTable />
			</div>
		);
	}
}

export default withRouter(SummaryTable);
