/* eslint-disable import/first */
import React, { Component } from "react";
import NavPublic from "components/nav/NavPublic";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");
const calculFacturation = require("helpers/calculFacturation");
/*Css*/

export class AffichageDetailExpedition extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			client: [],
			colis: [],
			array_interval: [],
			nb_total: [],
			sum_total: [],
			clientNomPrenom: "",
			lesMois: config.lesMois,
			messages: "En cours de chargement...",
		};
	}

	getPoidInterval(poid) {
		let result = Math.max.apply(
			null,
			this.state.array_interval.filter(function (v) {
				return v <= poid;
			})
		);
		if (result == "-Infinity") {
			return 0;
		} else {
			if (
				result >=
				this.state.array_interval[this.state.array_interval.length - 1]
			) {
				return this.state.array_interval.length - 1;
			} else {
				result = this.state.array_interval.indexOf(result) + 1;
				return result;
			}
		}
	}

	getZipCode(code) {
		return code.substring(0, 2);
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		config
			.apiFetch(config.Api_Url + "/profil/getProfilbyIdClient/" + id_client)
			.then((response) => response.json())
			.then((details) => {
				let array_interval = [];
				let nb_total = [];
				let sum_total = [];
				details["data"].forEach((d) => {
					array_interval.push(d.max);
					nb_total.push(0);
					sum_total.push(0);
				});
				this.setState({
					details: [details],
					array_interval: array_interval,
					nb_total: nb_total,
					sum_total: sum_total,
				});
			});
		config
			.apiFetch(config.Api_Url + "/findbyid/client/" + id_client)
			.then((response) => response.json())
			.then((client) => {
				if (client["data"].length != 0) {
					this.setState({
						client: [client],
						clientNomPrenom:
							client["data"][0].nom + " " + client["data"][0].prenom,
						clientZipCode: client["data"][0].codepostal,
					});
					config
						.apiFetch(
							config.Api_Url +
								"/colis/getAllColisbyDateAndClient/" +
								client["data"][0].id_client +
								"/" +
								this.props.match.params.month +
								"/" +
								this.props.match.params.year
						)
						.then((response) => response.json())
						.then((colis) => {
							if (colis.data.length == 0) {
								this.setState({ messages: "Erreur, ou aucune donnée" });
							}
							this.setState({
								colis: [colis],
							});
						});
				}
			});
	}

	handleClick = () => {
		this.props.history.push("/public/expedition");
	};

	render() {
		if (!this.state.details || !this.state.colis)
			return (
				<div>
					<NavPublic />
					<div className="container-fluid">
						<div className="text-center">
							<h2>{this.state.messages}</h2>
						</div>
					</div>
				</div>
			);
		else {
			return (
				<div>
					<NavPublic />
					<div className="container-fluid">
						<h1>Détail des expéditions</h1>

						<div>
							<h2 className="text-center">
								{this.state.lesMois[this.props.match.params.month - 1] +
									" " +
									this.props.match.params.year}
							</h2>
							<Table bordered hover>
								<thead>
									<tr className="print-text-black">
										<th>ID Colis</th>
										<th>Date</th>
										<th>Type</th>
										<th>Poids</th>
										<th>Tranche</th>
										<th>Destination</th>
										<th>Prix</th>
										<th>Supplément</th>
									</tr>
								</thead>
								<tbody>
									{this.state.colis.map((dynamicData, Key) => {
										let keys = Object.keys(dynamicData);
										let d = dynamicData;
										let details = this.state.details[0]["data"];
										return d[keys].map((data) => {
											let prix = 0;
											let result_getPoidInterval = [];
											let min = 0;
											let max = 0;
											//get price range correspond
											result_getPoidInterval = calculFacturation.getPoidInterval(
												data.poidscolis,
												this.state.array_interval
											);

											let index = result_getPoidInterval[0];

											min = result_getPoidInterval[1];

											max = result_getPoidInterval[2];
											let nb_total = this.state.nb_total;
											let sum_total = this.state.sum_total;
											let temp_nb_total = nb_total[index];
											temp_nb_total++;
											nb_total[index] = temp_nb_total;
											//if oder (hors site), we need to add a sup amount
											if (data.agence_cible) {
												//copy package total number with sup value of the price range (always the last one)
												let temp_nb_total_sup_hors_site =
													nb_total[nb_total.length - 1];
												//add 1 to it
												temp_nb_total_sup_hors_site++;
												//update....
												nb_total[
													nb_total.length - 1
												] = temp_nb_total_sup_hors_site;
											}
											//this.setState({ nb_total: nb_total });
											//calcul the price
											prix = calculFacturation.getPrixFacturation(
												data,
												details,
												index
											);
											let sup_hors_site = 0;
											if (data.agence_cible) {
												sup_hors_site = this.state.sup_hors_site;
											}
											let temp_sum_total = sum_total[index];
											let temp_sum_sup_hors_site_total =
												sum_total[sum_total.length - 1];
											temp_sum_total = temp_sum_total + prix;
											temp_sum_sup_hors_site_total =
												temp_sum_sup_hors_site_total + sup_hors_site;
											sum_total[index] = temp_sum_total;
											sum_total[
												sum_total.length - 1
											] = temp_sum_sup_hors_site_total;
											return (
												<tr
													key={data.colis_id}
													className={
														data.agence_origine && data.agence_cible
															? "hors-site"
															: ""
													}
												>
													<td>{data.colis_id}</td>
													<td>
														{dateFormatConvert.getFormattedDateWithHms(
															data.datedemande
														)}
													</td>
													<td>{data.estLot ? "Lot" : "Colis"}</td>
													<td>{data.poidscolis}</td>
													<td>
														{min} {typeof min == "number" ? " à " : ""}
														{max} Kg{" "}
													</td>
													<td>
														{data.adressedest + " "}
														{data.codepostaldest + " "}
														{data.villedest}
													</td>
													<td>{prix}</td>
													<td>
														{data.agence_cible
															? config.hors_site_sup_value
															: ""}
													</td>
												</tr>
											);
										});
									})}
								</tbody>
							</Table>
							<Button
								onClick={this.handleClick}
								size="sm"
								className="pr-3 back-button mb-5 no-print"
							>
								<i className="fas fa-angle-left mr-2"></i>
								Retour
							</Button>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(AffichageDetailExpedition);
