/* eslint-disable import/first */
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

class DeleteVehicule extends Component {
	constructor() {
		super();
	}
	componentDidMount() {
		config
			.apiFetch(config.Api_Url + "/delete/vehicules", {
				method: "POST",
				body: JSON.stringify({
					id_vehicule: this.props.match.params.id_vehicule,
				}),
				headers: { "Content-Type": "application/json" },
			})
			.then((response) => response.json())
			.then((data) => {
				this.props.history.goBack();
			});
	}
	render() {
		return null;
	}
}

export default withRouter(DeleteVehicule);
