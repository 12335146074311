function getPrixFacturation(data, details, index) {
	if (details.length == 0) {
		return 0;
	}
	let prix = 0;
	if (data.estLot) {
		prix = details[index].prixLot;
	} else {
		if (data.type == "Palette") {
			prix = details[index].prixPalette;
		} else if (data.typeEnvoi != "Normal") {
			//TODO Cleaning
			switch (data.typeEnvoi) {
				case "Express H9":
					prix = details[index].prixExpressH9;
					break;
				case "Europe Z1":
					prix = details[index].prixExpressH9;
					break;
				case "Express H9":
					prix = details[index].prixExpressH9;
					break;
				case "Express H12":
					prix = details[index].prixExpressH12;
					break;
				case "Europe Z2":
					prix = details[index].prixExpressH12;
					break;
				case "Europe Z3":
					prix = details[index].prixEuropeZ3;
					break;
				case "Europe Z4":
					prix = details[index].prixEuropeZ4;
					break;
			}
		} else {
			switch (getZipCode(data.codepostaldest)) {
				case "14":
					prix = details[index].prix14;
					break;
				case "28":
					prix = details[index].prix28;
					break;
				case "30":
					prix = details[index].prix30;
					break;
				case "34":
					prix = details[index].prix34;
					break;
				case "44":
					prix = details[index].prix44;
					break;
				case "50":
					prix = details[index].prix50;
					break;
				case "53":
					prix = details[index].prix53;
					break;
				case "61":
					prix = details[index].prix61;
					break;
				case "72":
					prix = details[index].prix72;
					break;
				default:
					prix = details[index].prixHors14;
			}
		}
	}
	return prix;
}

function getZipCode(code) {
	code = code.replace(/\D/g, "");
	return code.substring(0, 2);
}

function getPoidInterval(poid, array_interval) {
	let result = Math.max.apply(
		null,
		array_interval.filter(function (v) {
			return v <= poid;
		})
	);
	if (result == "-Infinity") {
		return [0, "Moins de ", array_interval[0]];
	} else {
		if (result >= array_interval[array_interval.length - 1]) {
			return [
				array_interval.length - 1,
				"Plus de ",
				array_interval[array_interval.length - 1],
			];
		} else {
			result = array_interval.indexOf(result) + 1;
			return [result, array_interval[result - 1], array_interval[result]];
		}
	}
}

exports.getPrixFacturation = getPrixFacturation;
exports.getPoidInterval = getPoidInterval;
