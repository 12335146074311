import React, { Component } from "react";
import {
	Collapse,
	Form,
	Button,
	Row,
	Col,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
} from "react-bootstrap";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "asset/fontawesome/css/all.css";
import "asset/fontawesome/css/fontawesome.css";
import "asset/css/custom.scss";
import DragAndDrop from "../DrapAndDrop/DragAndDrop";
import * as Papa from "papaparse";
import * as config from "config/config";
import { authenticationService } from "services/authentication/authenticationService";

class Edi extends React.Component {
	constructor() {
		super();
		this.state = {
			colis: [],
		};
	}

	handleDrop = (event) => {
		let dropfile = event[0];
		if (dropfile.name.split(".").pop() != "exp") {
		} else {
			Papa.parse(dropfile, {
				complete: this.processEdiFile.bind(this),
				delimiter: ";",
				escapeChar: '"',
			});
		}
		/*
		this.setState({
			csvfile: event.file[0],
			file_uploaded: true,
			uploaded_file_name: event.file[0].name,
		});*/
	};

	sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	}

	processEdiFile(parsedFile) {
		console.log("🏃 Processing EDI file... 🏃");
		console.log(JSON.parse(localStorage.getItem("currentUser"))["token"]);
		let fileData = parsedFile.data;
		let currentpackageline = [];
		let currentAddressline = [];
		let finalData = [];
		fileData.forEach((line) => {
			//for (const line of fileData) {
			//we only read line with number 5 on the second place
			switch (parseInt(line[1])) {
				//Address line
				case 3:
					currentAddressline = [];
					//get client'name
					currentAddressline.push(line[9]);
					//get Address
					currentAddressline.push(line[10]);
					//get zipcode
					currentAddressline.push(line[13]);
					//get city
					currentAddressline.push(line[14]);
					break;
				//package line
				case 5:
					//get package id
					currentpackageline = currentAddressline.slice();
					currentpackageline.push(line[5]);
					finalData.push(currentpackageline);
			}
		});
		this.setState({ colis: finalData });
		config
			.apiFetch(config.Api_Url + "/edi/deposit", {
				method: "POST",
				body: JSON.stringify({
					ediContents: this.state.colis,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => response.json())
			.then((clients) => {
				console.log(clients);
				window.alert("Le fichier a été déposé");
			});
	}

	render() {
		return (
			<div className="container">
				<h1>Fichier EDI</h1>
				<Button
					className="mt-4"
					size=""
					onClick={() => {
						authenticationService.logout();
					}}
				>
					Déconnexion
				</Button>
				<div className="justify-content-center row">
					<DragAndDrop handleDrop={this.handleDrop}>
						<div style={{ height: 300, width: 250 }}></div>
					</DragAndDrop>
				</div>
				<Table responsive bordered hover>
					<thead>
						<tr>
							<th>N°Colis</th>
							<th>Nom Prénom Destinataire</th>
							<th>Adresse Destinataire</th>
						</tr>
					</thead>
					<tbody>
						{this.state.colis.map((colis) => {
							return (
								<tr key={colis[4]}>
									<td>{colis[4]}</td>
									<td>{colis[0]}</td>
									<td>{colis[1] + ", " + colis[2] + " " + colis[3]}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		);
	}
}

export default Edi;
