function createUpsShippementJson(
	formdata,
	colis,
	serviceCode,
	groupementcolis
) {
	let config = require("config/config");

	let config_ups = config.ups_json_shipping;
	let shipfrom = config_ups["ShipmentRequest"]["Shipment"]["ShipFrom"];
	let shipto = config_ups["ShipmentRequest"]["Shipment"]["ShipTo"];
	let service = config_ups["ShipmentRequest"]["Shipment"]["Service"];
	config_ups["ShipmentRequest"]["Shipment"]["ReferenceNumber"]["Value"] =
		"N°demande : " + groupementcolis.toString();

	switch (serviceCode) {
		case "UPS Express Saver":
			service["Code"] = "65";
			break;
		case "UPS Standard":
			service["Code"] = "11";
			break;
	}
	config_ups["ShipmentRequest"]["Shipment"]["Service"] = service;

	shipfrom["Name"] = formdata.nom;
	shipto["Name"] = formdata.nomdest;

	let phoneFrom = {};
	phoneFrom["Number"] = formdata.telephone;
	shipfrom["Phone"] = phoneFrom;

	let phoneTo = {};
	phoneTo["Number"] = formdata.telephonedest;
	shipto["Phone"] = phoneTo;

	let addressFrom = {};
	let addressTo = {};

	addressTo["AddressLine"] = formdata.adressedest.replace(/'/g, "\\'");
	addressTo["City"] = formdata.villedest;
	addressTo["PostalCode"] = formdata.codepostaldest;
	addressTo["CountryCode"] = "FR";

	shipto["Address"] = addressTo;
	config_ups["ShipmentRequest"]["Shipment"]["ShipTo"] = shipto;

	addressFrom["AddressLine"] = formdata.adresse.replace(/'/g, "\\'");
	addressFrom["City"] = formdata.ville;
	addressFrom["PostalCode"] = formdata.codepostal;
	addressFrom["CountryCode"] = "FR";

	shipfrom["Address"] = addressFrom;
	config_ups["ShipmentRequest"]["Shipment"]["ShipFrom"] = shipfrom;
	let temp_colis = [];

	colis.forEach((c, index) => {
		temp_colis["package" + index] = {
			Description: "ECHANTILLON LABORATOIRE",
			Packaging: {
				Code: "02",
			},
			PackageWeight: {
				UnitOfMeasurement: {
					Code: "KGS",
				},
				Weight: "1",
			},
		};
		temp_colis["package" + index]["PackageWeight"]["Weight"] = colis[
			index
		].toString();
		config_ups["ShipmentRequest"]["Shipment"]["Package"][index] =
			temp_colis["package" + index];
	});

	return config_ups;
}

function UpsShip(json) {
	let config = require("config/config");

	//TO DO PROD REMOVE HEROKU
	return config.apiFetch(process.env.REACT_APP_CORS_URL + config.UPS_Endpoint, {
		method: "POST",
		body: JSON.stringify(json),
		headers: {
			"Content-Type": "application/json",
		},
	});
}

function UpsAddColisAndEtiquette(c_id, upscode, etiquette) {
	let config = require("config/config");

	return config.apiFetch(config.Api_Url + "/ups/addUpsCode", {
		method: "POST",
		body: JSON.stringify({
			c_id: c_id,
			upscode: upscode,
			etiquette: etiquette,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	});
}

exports.createUpsShippementJson = createUpsShippementJson;
exports.UpsShip = UpsShip;
exports.UpsAddColisAndEtiquette = UpsAddColisAndEtiquette;
