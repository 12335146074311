/* eslint-disable import/first */
import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { authenticationService } from "services/authentication/authenticationService";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "asset/fontawesome/css/all.css";
import "asset/fontawesome/css/fontawesome.css";
import "asset/css/custom.scss";
import logo from "asset/images/citycolis-holding-logo.svg";
import bandeau from "asset/images/bandeau.jpg";
import * as config from "config/config";

export class NavAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			agence: {},
			logo: "",
		};
	}
	handleOpen = () => {
		this.setState({ isOpen: true });
	};

	handleClose = () => {
		this.setState({ isOpen: false });
	};

	componentDidMount() {
		//get user name
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		if (user_role == "Agence") {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => {
					if (response.status == "401") {
						window.location.reload();
					} else {
						return response.json();
					}
				})
				.then((agence) => {
					let src =
						"data:image/jpg;base64," +
						new Buffer(agence["data"][0].agence_logo).toString("base64");
					this.setState({
						agence: agence["data"][0],
						logo: (
							<img src={src} style={{ maxHeight: 150, maxWidth: 150 }}></img>
						),
					});
				});
		}
	}

	render() {
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let login_client = JSON.parse(localStorage.getItem("currentUser"))[
			"data"
		][0].aut_login.toUpperCase();
		return (
			<div className="clients_container">
				{user_role == "Agence" ? (
					<div className="top-header no-print d-flex">
						<div className="client-infos ml-5 mt-3 d-flex flex-column align-items-center">
							{this.state.logo}
							<strong>Contact Agence</strong>
							{this.state.agence ? (
								<strong>Téléphone : {this.state.agence.agence_phone}</strong>
							) : (
								""
							)}
						</div>

						<img
							src={bandeau}
							className="d-block bandeau"
							alt="CityColis Logo"
						></img>
					</div>
				) : (
					<div className="top-header-center no-print d-flex">
						<div className="client-infos ml-5 mt-3 d-flex flex-column align-items-center">
							{this.state.logo}
						</div>

						<img
							src={bandeau}
							className="d-block bandeau"
							alt="CityColis Logo"
						></img>
					</div>
				)}
				<Navbar
					bg="light"
					expand="lg"
					variant="pills"
					className="d-flex flex-column no-print"
				>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto" fill variant="">
							<NavDropdown title="GESTION" id="gestion-nav-dropdown">
								<NavDropdown.Item href="/admin/clients/1">
									CLIENTS{" "}
								</NavDropdown.Item>
								<NavDropdown.Item href="/admin/tournee/page/1">
									BORDEREAUX DE LIVRAISONS{" "}
								</NavDropdown.Item>
								<NavDropdown.Item href="/admin/livreurs/">
									LIVREURS{" "}
								</NavDropdown.Item>
								<NavDropdown.Item href="/admin/vehicules">
									VEHICULE
								</NavDropdown.Item>
								<NavDropdown.Item
									href="/admin/agence"
									className={user_role == "Admin" ? "" : "none"}
								>
									AGENCE
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="DEMANDE D'ENLEVEMENT" id="basic-nav-dropdown">
								<NavDropdown.Item href="/admin/demande/page/1">
									DÉTAILS
								</NavDropdown.Item>
								<NavDropdown.Item href="/admin/demande/add">
									CREER DEMANDE POUR TIERS
								</NavDropdown.Item>
								<NavDropdown.Item href="/admin/demande/genereretiquette">
									CREER ETIQUETTES POUR TIERS
								</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link href="/admin/colis/">SUIVI COLIS</Nav.Link>
							<Nav.Link href="/admin/importer-colis">IMPORTER COLIS</Nav.Link>
							<Nav.Link href="/admin/importer-adresse">
								IMPORTER ADRESSE
							</Nav.Link>
							<Nav.Link href="/admin/profil">PROFIL FACTURATION</Nav.Link>
							<Nav.Link href="/admin/facturation">FACTURATION</Nav.Link>
							<Nav.Link href="/admin/facturationhorssite">
								FACTURATION HORS SITE
							</Nav.Link>
							<NavDropdown
								title={
									<span>
										<i className="fas fa-user-circle"> </i> {login_client}
									</span>
								}
							>
								<NavDropdown.Item href="">
									<div
										onClick={() => {
											authenticationService.logout();
										}}
									>
										<i className="fas fa-sign-out-alt mr-1"></i>
										Déconnexion
									</div>
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		);
	}
}

export default NavAdmin;
