import React, { Component, useState } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import {
  Form,
  Button,
  Row,
  Col,
  Table,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import * as config from "config/config";
import { isPartially } from "services/status/partialStatus";

import Checkbox from "helpers/checkbox";
import NavAdmin from "components/nav/NavAdmin";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getGroupementColisInfos = require("helpers/getGroupementColisInfos");
import Legends from "components/admin/demandes/component/legends";
import Search from "components/admin/demandes/component/search";
import ChangeState from "components/admin/demandes/component/changeState";
const getColisInfos = require("helpers/getColisInfos");
const checkUnusualColis = require("helpers/checkUnusualColis");
/*CSS*/
import "asset/css/demandes.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

class DemandeTable extends Component {
  constructor(props) {
    super(props);
    this.DemandeTable = this.DemandeTable.bind(this);
  }

  componentDidMount() {
    config
      .apiFetch(config.Api_Url + "/colis/getAllColisState", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => response.json())
      .then((data) => {
        let packageState = [];
        for (let number = 0; number < data["data"].length; number++) {
          let etat = getColisInfos.getColisState(
            data["data"][number]["etatlivraison"]
          );

          packageState.push(
            <option
              key={"option_state_" + number}
              value={data["data"][number]["etatlivraison"]}
            >
              {etat}
            </option>
          );
        }
        this.setState({
          packageState: [packageState],
        });
      });
  }

  DemandeTable() {
    //PAGINTATION
    let active = parseInt(this.props.match.params.page_id);
    let classNamePagination = "";
    let items = [];
    let total =
      this.props.total >= this.props.totalMDPR
        ? this.props.total
        : this.props.totalMDPR;

    //URL PARAMS
    let url_param1 = "";
    let url_param2 = "";
    let url_param3 = "";

    this.props.filterDate
      ? (url_param1 = this.props.match.params.startDate)
      : (url_param1 = this.props.match.params.month);
    this.props.filterDate
      ? (url_param2 = this.props.match.params.endDate)
      : (url_param2 = this.props.match.params.year);
    this.props.match.params.id_client
      ? (url_param3 = this.props.match.params.id_client)
      : (url_param3 = "");

    let first_pagination = 1;
    if (
      active != 1 &&
      active != 2 &&
      active != 3 &&
      active != 4 &&
      active != 5
    ) {
      for (let number = active - 4; number <= total / 50 + 1; number++) {
        if (number > active + 4) {
          break;
        }
        if (active === number) {
          classNamePagination = "page-item active";
        } else {
          classNamePagination = "page-item";
        }
        items.push(
          <li key={"pagination_" + number} className={classNamePagination}>
            {this.props.url && url_param1 && url_param2 && url_param3 ? (
              <a
                className="page-link"
                href={
                  this.props.url +
                  number +
                  "/" +
                  url_param1 +
                  "/" +
                  url_param2 +
                  "/" +
                  url_param3
                }
              >
                {number}
              </a>
            ) : (
              <a className="page-link" href={number}>
                {number}
              </a>
            )}
          </li>
        );
      }
    } else {
      for (let number = first_pagination; number <= total / 50 + 1; number++) {
        if (number > active + 4) {
          break;
        }
        if (active === number) {
          classNamePagination = "page-item active";
        } else {
          classNamePagination = "page-item";
        }

        items.push(
          <li key={"pagination_" + number} className={classNamePagination}>
            {this.props.url && url_param1 && url_param2 && url_param3 ? (
              <a
                className="page-link"
                href={
                  this.props.url +
                  number +
                  "/" +
                  url_param1 +
                  "/" +
                  url_param2 +
                  "/" +
                  url_param3
                }
              >
                {number}
              </a>
            ) : (
              <a className="page-link" href={number}>
                {number}
              </a>
            )}
          </li>
        );
      }
    }
    return (
      <div>
        <NavAdmin />
        <div className="container-fluid">
          <h1>
            Demandes d'enlèvements
            <br></br>
            {this.props.mainPage
              ? "Les demandes traitées ne sont pas affichées"
              : "Les demandes traitées sont affichées dans ces résultats"}
          </h1>
          <h2 className="text-center">
            {this.props.filterDate
              ? dateFormatConvert.getFormattedDate(
                  this.props.match.params.startDate
                ) +
                " au " +
                dateFormatConvert.getFormattedDate(
                  this.props.match.params.endDate
                )
              : null}
          </h2>
          {this.props.filterMonth ? (
            <h2 className="text-center">
              {this.props.lesMois[this.props.match.params.month - 1] +
                " " +
                this.props.match.params.year}
            </h2>
          ) : null}

          <Search clients_list={this.props.clients_list} />

          <Formik
            enableReinitialize={true}
            initialValues={{ checked: [], changing_state: "30" }}
            onSubmit={(values, { setSubmitting }) => {
              //Remove all existed profil's line under the same name
              config
                .apiFetch(config.Api_Url + "/demande/updateMultiGroupement", {
                  method: "POST",
                  body: JSON.stringify({
                    groupement_id: values.checked,
                    etat: values.changing_state,
                  }),
                  headers: { "Content-Type": "application/json" },
                })
                .then((response) => response.json())
                .then((response_details) => {
                  window.location.reload();
                });
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                {this.props.isLoading ? (
                  <p className="mt-5">
                    Chargement des demandes d'enlèvement en cours...
                  </p>
                ) : (
                  <>
                    <Row>
                      <Legends />
                      <ChangeState
                        handleChange={handleChange}
                        isSubmitting={isSubmitting}
                      />
                    </Row>
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>N°demande</th>
                          <th>Type</th>
                          <th>Date demande</th>
                          <th>Expéditeur</th>
                          <th>Destinataire</th>
                          <th>Suivi du colis</th>
                          <th>Statut final</th>
                          <th>Étiquettes</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.groupementColis.map((dynamicData, Key) => {
                          let keys = Object.keys(dynamicData);
                          let d = dynamicData;
                          return d[keys]
                            .reduce((acc, curr) => {
                              if (
                                !acc.find(
                                  (element) =>
                                    element.groupement_id === curr.groupement_id
                                )
                              ) {
                                acc.push(curr);
                              }
                              return acc;
                            }, [])
                            .map((data) => {
                              let etat =
                                getGroupementColisInfos.getGroupementColisStateHex(
                                  data.etathex
                                );
                              let css_class_etat =
                                getGroupementColisInfos.getGroupementColisStateHexCss(
                                  data.etathex
                                );
                              let traite =
                                getGroupementColisInfos.getGroupementColisTraite(
                                  data.traitehex
                                );
                              let css_class_traite =
                                getGroupementColisInfos.getGroupementColisTraiteCss(
                                  data.traitehex
                                );
                              let detailt_url =
                                "/admin/demande/details/" + data.groupement_id;
                              let etiquette_url =
                                "/admin/demande/visualiseretiquette/" +
                                data.groupement_id;

                              const colisByGroupement =
                                this.props.groupementColis[0]?.data.filter(
                                  (x) => x.groupement_id === data.groupement_id
                                );

                              let estLot = colisByGroupement[0]?.colis[0]?.estLot

                              const isPartiallyCollected = isPartially("collected", colisByGroupement, estLot);

                              const isPartiallyDelivered = isPartially("delivered", colisByGroupement, estLot);

                              const isPartiallyEAL = isPartially("EAL", colisByGroupement, estLot);

                              let arrayColisByGroupement =
                                this.props.colisByGroupement
                                  .filter(
                                    (x) =>
                                      x.groupement_id === data.groupement_id
                                  )
                                  .map((x) => x.colis_id);

                              let haveUnusualColis =
                                checkUnusualColis.checkUnusualColis(
                                  this.props.colisByGroupement
                                    .filter(
                                      (x) =>
                                        x.groupement_id === data.groupement_id
                                    )
                                    .map((x) => x.etatlivraison)
                                );

                              let item = [];

                              arrayColisByGroupement.forEach((colis_id) => {
                                item.push(
                                  <Col lg={4} key={colis_id}>
                                    {colis_id}
                                  </Col>
                                );
                              });

                              let popover = (
                                <Popover
                                  id="popover-basic"
                                  style={{ margin: 0 }}
                                >
                                  <Popover.Title as="h3">
                                    N°d'étiquettes de la demande{" "}
                                    {data.groupement_id}{" "}
                                  </Popover.Title>
                                  <Popover.Content>
                                    <Row>{item}</Row>
                                  </Popover.Content>
                                </Popover>
                              );

                              return (
                                <tr
                                  key={data.groupement_id}
                                  className={css_class_etat}
                                >
                                  <td>
                                    <Checkbox
                                      name="checked"
                                      value={data.groupement_id}
                                    />
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="right"
                                      overlay={popover}
                                    >
                                      <Button>{data.groupement_id}</Button>
                                    </OverlayTrigger>
                                  </td>
                                  <td>{estLot ? "Lot" : "Colis"}</td>
                                  <td>
                                    {dayjs(data.datedemande).format(
                                      "DD/MM/YYYY [à] H:mm"
                                    )}
                                  </td>
                                  <td>
                                    {data.nomExpediteur && data.prenomExpediteur
                                      ? data.nomExpediteur +
                                        " " +
                                        data.prenomExpediteur
                                      : ""}
                                    <br />
                                    {data.adresseExpediteur &&
                                    data.codepostalExpediteur
                                      ? data.adresseExpediteur +
                                        ", " +
                                        data.codepostalExpediteur
                                      : ""}
                                    <br />
                                    {data.villeExpediteur}
                                  </td>
                                  <td>
                                    {data.colis[0]?.nomdest || data.colis[0]?.prenomdest
                                      ? data.colis[0]?.nomdest + " " + data.colis[0]?.prenomdest
                                      : ""}
                                    <br />
                                    {data.colis[0]?.adressedest && data.colis[0]?.codepostaldest
                                      ? data.colis[0]?.adressedest +
                                        ", " +
                                        data.colis[0]?.codepostaldest
                                      : ""}
                                    <br />
                                    {data.colis[0]?.villedest}
                                  </td>
                                  <td>
                                    {data.etathex === "30"
                                      ? "En attente d'enlèvement"
                                      : `${
                                          isPartiallyCollected
                                            ? "Partiellement pris en charge "
                                            : "Pris en charge "
                                        } ${
                                          data.nom &&
                                          data.nom !== undefined &&
                                          data.prenom &&
                                          data.prenom !== undefined
                                            ? `par ${data.nom} ${data.prenom}`
                                            : ""
                                        } ${
                                          data.datecharge !== undefined &&
                                          data.datecharge !== null
                                            ? "le " +
                                              dayjs(data.datecharge).format(
                                                "DD/MM/YYYY [à] H:mm"
                                              )
                                            : "le " +
                                              dayjs(data.datedemande).format(
                                                "DD/MM/YYYY [à] H:mm"
                                              )
                                        }`}
                                  </td>
                                  <td>
                                    {data.etathex === "30"
                                      ? ""
                                      : `${
                                          isPartiallyDelivered
                                            ? "Partiellement livré"
                                            : isPartiallyEAL
                                            ? "En cours de livraison (Colis manquant)"
                                            : isPartiallyCollected
                                            ? "Partiellement collecté"
                                            : data.etathex === "33" ||
                                              data.etathex === "54"
                                            ? "En cours de livraison"
                                            : etat
                                        }` +
                                        " le " +
                                        dayjs(data.datereleve).format(
                                          "DD/MM/YYYY [à] H:mm"
                                        )}
                                  </td>
                                  <td>
                                    <a href={etiquette_url}>
                                      <i className="fas fa-barcode"></i>
                                      Étiquettes
                                    </a>
                                  </td>

                                  <td>
                                    <a href={detailt_url}>
                                      <i className="fas fa-info-circle"></i>
                                      Détails
                                      {haveUnusualColis ? (
                                        <i className="fas fa-exclamation-triangle"></i>
                                      ) : (
                                        <i className="fas fa-fw"></i>
                                      )}
                                    </a>
                                  </td>
                                </tr>
                              );
                            });
                        })}
                      </tbody>
                    </Table>
                  </>
                )}

                <Row className={!this.props.isLoading ? "" : "hidden"}>
                  <Col md={12}>
                    <div>
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">{items}</ul>
                      </nav>
                    </div>
                  </Col>
                </Row>
                {/*************MDPR SECTION****************/}
                <div className={this.props.MDPRactive ? "" : "hidden"}>
                  <h2 className="mt-5">MDPR</h2>
                  {this.props.isLoadingMDPR ? (
                    <p>Chargement des demandes d'enlèvement en cours...</p>
                  ) : (
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>N°demande</th>
                          <th>Type</th>
                          <th>Date demande</th>
                          <th>Expéditeur</th>
                          <th>Destinataire</th>
                          <th>Suivi du colis</th>
                          <th>Statut final</th>
                          <th>Étiquettes</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.groupementColisMDPR.map(
                          (dynamicData, Key) => {
                            let keys = Object.keys(dynamicData);
                            let d = dynamicData;
                            return d[keys].map((data) => {
                              let etat =
                                getGroupementColisInfos.getGroupementColisStateHex(
                                  data.etathex
                                );
                              let css_class_etat =
                                getGroupementColisInfos.getGroupementColisStateHexCss(
                                  data.etathex
                                );
                              let traite =
                                getGroupementColisInfos.getGroupementColisTraite(
                                  data.traitehex
                                );
                              let css_class_traite =
                                getGroupementColisInfos.getGroupementColisTraiteCss(
                                  data.traitehex
                                );
                              let detailt_url =
                                "/admin/demande/details/" + data.groupement_id;
                              let etiquette_url =
                                "/admin/demande/visualiseretiquette/" +
                                data.groupement_id;

                              const colisByGroupement =
                                this.props.groupementColisMDPR[0]?.data.filter(
                                  (x) => x.groupement_id === data.groupement_id
                                );
                              
                              let estLot = colisByGroupement[0]?.colis[0]?.estLot

                              const isPartiallyCollected = isPartially("collected", colisByGroupement, estLot);

                              const isPartiallyDelivered = isPartially("delivered", colisByGroupement, estLot);

                              const isPartiallyEAL = isPartially("EAL", colisByGroupement, estLot);

                              let arrayColisByGroupement =
                                this.props.colisByGroupement
                                  .filter(
                                    (x) =>
                                      x.groupement_id === data.groupement_id
                                  )
                                  .map((x) => x.colis_id);


                              let haveUnusualColis =
                                checkUnusualColis.checkUnusualColis(
                                  this.props.colisByGroupement
                                    .filter(
                                      (x) =>
                                        x.groupement_id === data.groupement_id
                                    )
                                    .map((x) => x.etatlivraison)
                                );

                              let item = [];

                              arrayColisByGroupement.forEach((colis_id) => {
                                item.push(
                                  <Col lg={4} key={colis_id}>
                                    {colis_id}
                                  </Col>
                                );
                              });

                              let popover = (
                                <Popover
                                  id="popover-basic"
                                  style={{ margin: 0 }}
                                >
                                  <Popover.Title as="h3">
                                    N°d'étiquettes de la demande{" "}
                                    {data.groupement_id}{" "}
                                  </Popover.Title>
                                  <Popover.Content>
                                    <Row>{item}</Row>
                                  </Popover.Content>
                                </Popover>
                              );

                              return (
                                <tr
                                  key={data.groupement_id}
                                  className={css_class_etat}
                                >
                                  <td>
                                    <Checkbox
                                      name="checked"
                                      value={data.groupement_id}
                                    />
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="right"
                                      overlay={popover}
                                    >
                                      <Button>{data.groupement_id}</Button>
                                    </OverlayTrigger>
                                  </td>
                                  <td>{estLot ? "Lot" : "Colis"}</td>
                                  <td>
                                    {dayjs(data.datedemande).format(
                                      "DD/MM/YYYY [à] H:mm"
                                    )}
                                  </td>
                                  <td>
                                    {data.nomExpediteur && data.prenomExpediteur
                                      ? data.nomExpediteur +
                                        " " +
                                        data.prenomExpediteur
                                      : ""}
                                    <br />
                                    {data.adresseExpediteur &&
                                    data.codepostalExpediteur
                                      ? data.adresseExpediteur +
                                        ", " +
                                        data.codepostalExpediteur
                                      : ""}
                                    <br />
                                    {data.villeExpediteur}
                                  </td>
                                  <td>
                                    {data.colis[0]?.nomdest || data.colis[0]?.prenomdest
                                      ? data.colis[0]?.nomdest + " " + data.colis[0]?.prenomdest
                                      : ""}
                                    <br />
                                    {data.colis[0]?.adressedest && data.colis[0]?.codepostaldest
                                      ? data.colis[0]?.adressedest +
                                        ", " +
                                        data.colis[0]?.codepostaldest
                                      : ""}
                                    <br />
                                    {data.colis[0]?.villedest}
                                  </td>
                                  <td>
                                    {data.etathex === "30"
                                      ? "En attente d'enlèvement"
                                      : `${
                                          isPartiallyCollected
                                            ? "Partiellement pris en charge "
                                            : "Pris en charge "
                                        } ${
                                          data.nom &&
                                          data.nom !== undefined &&
                                          data.prenom &&
                                          data.prenom !== undefined
                                            ? `par ${data.nom} ${data.prenom}`
                                            : ""
                                        }  ${
                                          data.datecharge !== undefined &&
                                          data.datecharge !== null
                                            ? "le " +
                                              dayjs(data.datecharge).format(
                                                "DD/MM/YYYY [à] H:mm"
                                              )
                                            : "le " +
                                              dayjs(data.datedemande).format(
                                                "DD/MM/YYYY [à] H:mm"
                                              )
                                        }`}
                                  </td>
                                  <td>
                                    {data.etathex === "30"
                                      ? ""
                                      : `${
                                          isPartiallyDelivered
                                            ? "Partiellement livré"
                                            : isPartiallyEAL
                                            ? "En cours de livraison (Colis manquant)"
                                            : isPartiallyCollected
                                            ? "Partiellement collecté"
                                            : data.etathex === "33" ||
                                              data.etathex === "54"
                                            ? "En cours de livraison"
                                            : etat
                                        }` +
                                        " le " +
                                        dayjs(data.datereleve).format(
                                          "DD/MM/YYYY [à] H:mm"
                                        )}
                                  </td>
                                  <td>
                                    <a href={etiquette_url}>
                                      <i className="fas fa-barcode"></i>
                                      Étiquettes
                                    </a>
                                  </td>

                                  <td>
                                    <a href={detailt_url}>
                                      <i className="fas fa-info-circle"></i>
                                      Détails
                                      {haveUnusualColis ? (
                                        <i className="fas fa-exclamation-triangle"></i>
                                      ) : (
                                        <i className="fas fa-fw"></i>
                                      )}
                                    </a>
                                  </td>
                                </tr>
                              );
                            });
                          }
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
                <Row className={!this.props.isLoading ? "" : "hidden"}>
                  <Col md={12}>
                    <div>
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">{items}</ul>
                      </nav>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <this.DemandeTable />
      </div>
    );
  }
}

export default withRouter(DemandeTable);
