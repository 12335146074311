/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router-dom";

import * as config from "config/config";
import NavPublic from "components/nav/NavPublic";
import SearchForm from "./searchForm";
import DemandeTable from "../components/demandeTable";
/*CSS*/
import "asset/css/demandes.scss";

class FilterMonthDate extends Component {
	constructor() {
		super();
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			groupementColis: [],
			groupementColisMDPR: [],
			groupementColis_array_checkbox_state: [],
			totalMDPR: 25,
			total: 5,
		};
	}

	componentDidMount() {
		let page_id = this.props.match.params.page_id;
		let month = this.props.match.params.month;
		let year = this.props.match.params.year;
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;

		config
			.apiFetch(
				config.Api_Url +
					"/demande/getGroupementColisPublicWithMonthYearFilter/liste/" +
					page_id +
					"/" +
					id_client +
					"/" +
					month +
					"/" +
					year,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((data) => {
				let groupementColis_array_checkbox_state = [];
				data["data"].forEach((d) => {
					if (d.etathex != "34")
						groupementColis_array_checkbox_state.push(d.groupement_id);
				});
				this.setState({
					groupementColis: [data],
					groupementColis_array_checkbox_state: groupementColis_array_checkbox_state,
				});
			});
		config
			.apiFetch(
				config.Api_Url +
					"/demande/getTotalGroupementColisPublicWithMonthYearFilter/" +
					id_client +
					"/" +
					month +
					"/" +
					year,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((total) => {
				this.setState({
					total: total["data"][0]["total"],
				});
			});
	}
	render() {
		let urlDropdownHide =
			"/public/demande/FilterWithoutBordereauFilterMonthYear/" +
			this.props.match.params.month +
			"/" +
			this.props.match.params.year +
			"/page/1";
		let urlDropdownShow =
			"/public/demande/FilterHasBordereauFilterMonthYear/" +
			this.props.match.params.month +
			"/" +
			this.props.match.params.year +
			"/page/1";
		let monthFilter = "filter";
		let dateFilter = "filterDate";
		return (
			<div>
				<NavPublic />
				<div className="container-fluid">
					<h1>Suivi des demandes d'enlèvements</h1>
					<SearchForm
						urlDropdownHide={urlDropdownHide}
						urlDropdownShow={urlDropdownShow}
						groupementColis_array_checkbox_state={
							this.state.groupementColis_array_checkbox_state
						}
						dateFilter={dateFilter}
						monthFilter={monthFilter}
					/>
					<DemandeTable
						groupementColis={this.state.groupementColis}
						total={this.state.total}
					/>
				</div>
			</div>
		);
	}
}

export default withRouter(FilterMonthDate);
