import React, { Component, Input } from "react";
import NavAdmin from "components/nav/NavAdmin";

import { Formik, submitForm, useFormikContext } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

/*Css*/

export class GenererEtiquette extends React.Component {
	constructor() {
		super();
		this.state = {
			clients: [],
			first_client: "",
		};
	}

	//interval Min var
	currentIntervalleMin = 0;

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		config
			.apiFetch(config.Api_Url + "/clients")
			.then((response) => response.json())
			.then((clients) => {
				let clients_list = [];
				for (let number = 0; number < clients["data"].length; number++) {
					clients_list.push(
						<option
							key={"clients_" + number}
							value={clients["data"][number]["id_client"]}
						>
							{clients["data"][number]["nom"]}{" "}
							{clients["data"][number]["prenom"]}
						</option>
					);
				}

				this.setState({
					clients: [clients_list],
					first_client: clients["data"][0]["id_client"],
				});
			});
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	render() {
		return (
			<div>
				<NavAdmin />

				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Génération d'étiquettes vierges A6</h1>
							<Formik
								initialValues={{
									nombre: 0,
									format: "Thermique",
									client: this.state.first_client,
								}}
								enableReinitialize
								onSubmit={(values, { setSubmitting }) => {
									if (values.format == "Thermique") {
										this.props.history.push(
											"./EtiquetteThermique/" +
												values.client +
												"/" +
												values.nombre
										);
									} else {
										this.props.history.push(
											"./EtiquetteNormal/" + values.client + "/" + values.nombre
										);
									}
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit}>
										<Form.Label className="required">
											Nombre d'étiquettes :
										</Form.Label>
										<Form.Control
											required
											type="number"
											aria-describedby="inputGroupPrepend"
											name="nombre"
											onChange={handleChange}
										/>
										<Form.Label className="required">Format :</Form.Label>
										<Form.Control
											required
											as="select"
											name="format"
											onChange={handleChange}
										>
											<option>Thermique</option>
											<option>Normal</option>
										</Form.Control>
										<Form.Label className="required">Clients :</Form.Label>
										<Form.Control
											required
											as="select"
											name="client"
											onChange={handleChange}
										>
											{this.state.clients}
										</Form.Control>
										<Button
											size="sm"
											className="mt-3"
											type="submit"
											disabled={isSubmitting}
										>
											Générer les étiquettes
										</Button>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(GenererEtiquette);
