import React, { Component, useState } from "react";
import { Formik, submitForm, useFormikContext } from "formik";
import { withRouter } from "react-router-dom";
import { Button, Collapse, ButtonToolbar, Form } from "react-bootstrap";
import DatePickerField from "helpers/datePickerField";
import * as config from "config/config";
class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			profils: [],
			button: "",
		};
	}
	componentDidMount() {
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;

		if (user_role == "Admin") {
			config
				.apiFetch("/agences")
				.then((response) => response.json())
				.then((data) => {
					let agences = [];
					for (let number = 0; number < data["data"].length; number++) {
						agences.push(
							<option
								key={"option_agences_" + number}
								value={data["data"][number]["agence_id"]}
							>
								{data["data"][number]["agence_name"]}
							</option>
						);
					}
					this.setState({
						agences: [agences],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((data) => {
					this.props.history.push(
						"/admin/vehicules/details/" + data["data"][0].agence_id
					);
				});
		}
	}

	render() {
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		return (
			<div>
				<Formik
					enableReinitialize={true}
					initialValues={this.state.profils}
					onSubmit={(values, { setSubmitting }) => {
						this.props.history.push(
							"/admin/vehicules/details/" + values.agence
						);
					}}
				>
					{({
						values,
						handleChange,
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<Form onSubmit={handleSubmit} autoComplete="off">
							<Form.Label>Agence :</Form.Label>

							<Form.Control
								as="select"
								name="agence"
								value={values.agence || ""}
								onChange={handleChange}
							>
								<option></option>
								{this.state.agences}
							</Form.Control>

							<Button
								className="my-4"
								size="sm"
								type="submit"
								disabled={isSubmitting}
							>
								Voir
							</Button>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

export default withRouter(SearchForm);
