import React from "react";
import NavPublic from "components/nav/NavPublic";
import { withRouter } from "react-router-dom";
import { Button, Row, Col, Table, Container, Spinner } from "react-bootstrap";
import * as config from "config/config";

const dateFormatConvert = require("helpers/dateFormatConvert");
var Barcode = require("react-barcode");
const getColisInfos = require("helpers/getColisInfos");

export class VisualiserBordereau extends React.Component {
	constructor() {
		super();
		this.state = {
			bordereau: [],
			expediteur: "",
			telephone: "",
			datereleve: "",
			loading: true,
			total: 0,
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		config
			.apiFetch(config.Api_Url + "/findbyid/client/" + id_client)
			.then((response) => response.json())
			.then((client) => {
				let src =
					"data:image/jpg;base64," +
					new Buffer(client["data"][0].agence_logo).toString("base64");
				this.setState({
					agence_address: client["data"][0].agence_address,
					agence_phone: client["data"][0].agence_phone,
					agence_name: client["data"][0].agence_name,
					agence_ville: client["data"][0].agence_ville,
					logo: <img src={src} style={{ maxHeight: 150, maxWidth: 150 }}></img>,
				});
			});

		config
			.apiFetch(
				config.Api_Url +
					"/bordereau/getBordereauxById/" +
					this.props.match.params.id_bordereau
			)
			.then((response) => response.json())
			.then((bordereau) => {
				console.log(bordereau);
				this.state.loading = false;
				if (bordereau["data"].length != 0) {
					this.setState({
						bordereau: [bordereau],
						expediteur:
							bordereau["data"][0].nomExpediteur +
							" " +
							bordereau["data"][0].prenomExpediteur,
						telephone: bordereau["data"][0].telephoneExpediteur,
						datereleve: dateFormatConvert.getFormattedDate(
							bordereau["data"][0].datereleve
						),
						total: this.countColis(bordereau),
					});
				} else {
					this.props.history.push("/404");
				}
			});
	}

	countColis(data) {
		if (data["data"]) {
			let count = 0;
			data["data"].forEach((x) => {
				if (getColisInfos.checkLotPrincipalId(x)) {
					count++;
				}
			});
			return data["data"].length - count;
		} else {
			return 0;
		}
	}

	render() {
		return (
			<div>
				<NavPublic />
				<Container id="divToPrint">
					<h1>
						Bordereau de livraison
						<br></br>
						{/* {this.state.zonetournee} */}
						<Button
							size="sm"
							variant="primary"
							className="no-print"
							onClick={() => window.print()}
						>
							Imprimer
						</Button>
					</h1>
					<Row>
						<Col sm={8}>{this.state.logo}</Col>
						<Col sm={4} className="etiquettes-citycolis-infos pl-5 pt-4">
							<div>{this.state.agence_name}</div>
							<div>{this.state.agence_address}</div>
							<div>{this.state.agence_ville}</div>
							<div>Tél:{this.state.agence_phone}</div>
						</Col>
					</Row>
					<Row>
						<Col sm={4} className="d-flex flex-column justify-content-center">
							<div>Bordereau n° {this.props.match.params.id_bordereau}</div>
							<div>Client : {this.state.expediteur}</div>
						</Col>
						<Col sm={4} className="">
							<h3>Date d'enlèvement : {this.state.datereleve}</h3>
							{this.state.loading ? (
								<div>
									<Spinner animation="grow" variant="dark" size="sm" />
									<Spinner animation="grow" variant="dark" size="sm" />
									<Spinner animation="grow" variant="dark" size="sm" />
								</div>
							) : null}
						</Col>
						<Col sm={4} className="etiquettes-citycolis-infos pl-5 pt-4">
							<div>{this.state.total} colis</div>
						</Col>
					</Row>
					<Table responsive bordered hover>
						<thead>
							<tr>
								<th>N°</th>
								<th>Référence</th>
								<th>Destinataire</th>
								<th>Type</th>
							</tr>
						</thead>
						<tbody>
							{this.state.bordereau.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								let counter = 1;
								return d[keys].map((data) => {
									return (
										<tr key={data.colis_id}>
											<th>{counter++}</th>
											<th>
												<Barcode value={data.colis_id} />
											</th>
											<th>
												<div>{data.nomdest}</div>
												<div>{data.prenomdest}</div>
												<div>{data.adressedest}</div>
												<div>{data.codepostaldest}</div>
												<div>{data.villedest}</div>
												<div>{data.telephonedest}</div>
											</th>
											<th>
												<div>{data.estLot ? "Lot" : "Colis"}</div>
											</th>
										</tr>
									);
								});
							})}
						</tbody>
					</Table>
				</Container>
			</div>
		);
	}
}

export default withRouter(VisualiserBordereau);
