/* eslint-disable import/first */
import React, { Component } from "react";
import { Table, Form, Button } from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import { withRouter } from "react-router-dom";
const dateFormatConvert = require("helpers/dateFormatConvert");
import Checkbox from "helpers/checkbox";
import { Formik } from "formik";

export class SummaryTableCA extends React.Component {
	constructor(props) {
		super(props);
		this.SummaryTableCA = this.SummaryTableCA.bind(this);
	}
	SummaryTableCA() {
		let details = this.props.details;

		if (!details || !this.props.nb_total || !this.props.sum_total) {
			return (
				<div>
					<div className="text-center">
						{/*<hr className="myhrline" />*/}
						<h2>
							Client : {this.props.client.nom + " " + this.props.client.prenom}
							<br />
							Code postal :{this.props.client.codepostal}
						</h2>
					</div>
					<div>
						<h3 className="page-break-before">
							Il n'y a pas de profils de facturation correspondant pour ce
							client
						</h3>
					</div>
				</div>
			);
		} else {
			let sum = 0;
			let nb_colis = 0;
			let currentIntervalleMin = 0;
			let counter = -1;
			const reducer = (accumulator, currentValue) => accumulator + currentValue;
			if (this.props.sum_total.length != 0) {
				sum = this.props.sum_total.reduce(reducer);
			}
			if (this.props.nb_total.length != 0) {
				nb_colis = this.props.nb_total.reduce(reducer);
			}
			nb_colis = nb_colis - this.props.nb_total[this.props.nb_total.length - 1];

			return (
				<div>
					<div className="text-center">
						{/*<hr className="myhrline" />*/}
						<h2>
							Client : {this.props.client.nom + " " + this.props.client.prenom}
							<br />
							Code postal :{this.props.client.codepostal}
						</h2>
					</div>

					{this.props.details.map((data) => {
						counter++;
						let nb_total = this.props.nb_total[counter];
						let sum_total = this.props.sum_total[counter];
						//update interval Min
						let min = currentIntervalleMin;
						currentIntervalleMin = data.max;
						if (counter < this.props.details.length - 1) return null;
						else {
							counter++;
							let nb_sup_hors_site = this.props.nb_total[counter];
							let sum_total_sup_hors_site = this.props.sum_total[counter];

							return null;
						}
					})}

					<div className="d-flex justify-content-end">
						{" "}
						<div className="thick-border">
							Montant H.T : {Math.round(sum * 100) / 100}€ pour {nb_colis} colis{" "}
						</div>
					</div>
				</div>
			);
		}
	}
	render() {
		return (
			<div>
				<this.SummaryTableCA />
			</div>
		);
	}
}

export default withRouter(SummaryTableCA);
