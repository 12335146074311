import React, { Component } from 'react';
import Nav from '../nav/NavAdmin'


function Home(){
        return (
        <div>
            <Nav />
        </div>
        )
}

export default Home;

