/* eslint-disable import/first */
import React, { Component } from "react";
import NavPublic from "../nav/NavPublic";
import { render } from "@testing-library/react";
import {
	Navbar,
	Nav,
	NavDropdown,
	Card,
	CardColumns,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import AddDemande from "./demande/add/addDemande";

class Admin extends Component {
	constructor() {
		super();
		this.user = JSON.parse(localStorage.getItem("currentUser"))[
			"data"
		][0].aut_login;
	}
	render() {
		return (
			<div>
				<NavPublic />
				<h1 className="mt-5 pt-5">
					<strong>
						<i>Bonjour {this.user}</i>
					</strong>
				</h1>
				<h1>
					<i className="far fa-grin w-50"></i>
				</h1>
				<h2 className="text-center">Bienvenue sur votre interface client</h2>
			</div>
		);
	}
}

export default Admin;
