export const UPS_Endpoint = process.env.REACT_APP_UPS_ENDPOINT;

//api pre-prod
export const Api_Url = process.env.REACT_APP_API_URL;

//googleMapsApiKey
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_APIKEY;

//api dev
//export const Api_Url = "http://new.citycolis.neuva.fr:5000";
var fetchDefaults = require("fetch-defaults");
export let apiFetch = fetchDefaults(fetch, Api_Url, {
	headers: {
		Authorization:
			"Bearer " +
			(localStorage.getItem("currentUser")
				? JSON.parse(localStorage.getItem("currentUser"))["token"]
				: ""),
	},
});

export const axios_config = {
	headers: {
		Authorization:
			"Bearer " +
			(localStorage.getItem("currentUser")
				? JSON.parse(localStorage.getItem("currentUser"))["token"]
				: ""),
		"content-type": "multipart/form-data",
	},
};

export const hors_site_sup_value = parseInt(
	process.env.REACT_APP_HORS_SITE_SUP_VALUE
);

export let ups_json_shipping = {
	UPSSecurity: {
		UsernameToken: {
			Username: "citycolis",
			Password: "Web/14000",
		},
		ServiceAccessToken: {
			AccessLicenseNumber: "DD0DCF69D00F3D29",
		},
	},
	ShipmentRequest: {
		Request: {
			RequestOption: "nonvalidate",
		},
		Shipment: {
			Description: "hardware",
			ReferenceNumber: {
				Value: "9999700976733",
			},
			ReturnService: {
				Code: "9",
			},
			Shipper: {
				Name: "CITY COLIS",
				ShipperNumber: "3X0A32",
				AttentionName: "AttentionName",
				TaxIdentificationNumber: "TaxID",
				Phone: {
					Number: "08001002630",
				},
				Address: {
					AddressLine: "51 BOULEVARD ANTOINE BECQUEREL",
					City: "IFS",
					PostalCode: "14123",
					CountryCode: "FR",
				},
			},
			ShipTo: {
				Name: "DEPOT RESTAN",
				AttentionName: "CITYCOLIS",
				FaxNumber: "1234567999",
				TaxIdentificationNumber: "456999",
				Address: {
					AddressLine: "Z.I. DU MARTRAY",
					City: "GIBERVILLE",
					PostalCode: "14730",
					CountryCode: "FR",
				},
				Phone: {
					Number: "0601022820",
				},
			},
			ShipFrom: {
				Name: "",
				Phone: {
					Number: "",
				},
				Address: {
					AddressLine: "",
					City: " ",
					PostalCode: "",
					CountryCode: "FR",
				},
			},
			Service: {
				Code: "65",
			},
			PaymentInformation: {
				ShipmentCharge: {
					BillShipper: {
						AccountNumber: "3X0A32",
					},
					Type: "01",
				},
			},
			Package: [
				{
					Description: "ECHANTILLON LABORATOIRE",
					Packaging: {
						Code: "02",
					},
					PackageWeight: {
						UnitOfMeasurement: {
							Code: "KGS",
						},
						Weight: "1",
					},
				},
			],
		},
		LabelSpecification: {
			LabelImageFormat: {
				Code: "GIF",
			},
		},
	},
};

export let ups_colis_shipping_model = {
	Description: "ECHANTILLON LABORATOIRE",
	Packaging: {
		Code: "02",
	},
	PackageWeight: {
		UnitOfMeasurement: {
			Code: "KGS",
		},
		Weight: "1",
	},
};

export const lesMois = new Array(
	"Janvier",
	"Février",
	"Mars",
	"Avril",
	"Mai",
	"Juin",
	"Juillet",
	"Août",
	"Septembre",
	"Octobre",
	"Novembre",
	"Décembre"
);
