import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class Legends extends Component {
  render() {
    return (
      <Col md={9} className="d-flex align-items-end justify-content-start">
        <div className="inline-legends">
          <div>
            <div className="square-legend square-legend-green"></div>
            <div className="inline-legend">Demandes enlevées</div>
          </div>
          <div>
            <div className="square-legend square-legend-yellow"></div>
            <div className="inline-legend">
              Demandes en attente d'enlèvement
            </div>
          </div>
          <div>
            <div className="square-legend square-legend-red"></div>
            <div className="inline-legend">Demandes annulées</div>
          </div>
          <div>
            <div className="square-legend square-legend-magenta"></div>
            <div className="inline-legend">Colis manquant</div>
          </div>
          <div>
            <div className="square-legend square-legend-blue"></div>
            <div className="inline-legend">Demandes hors sites</div>
          </div>
		  <div>
            <div className="square-legend square-legend-lightblue"></div>
            <div className="inline-legend">Livraisons refusées</div>
          </div>
          {/*
<div>
<div className="square-legend square-legend-blue"></div>
<div className="inline-legend">Demandes traitées</div>
</div>*/}
        </div>
      </Col>
    );
  }
}

export default withRouter(Legends);
