import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import { Formik, submitForm, useFormikContext } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

/*Css*/

export class CreateForm extends React.Component {
	constructor() {
		super();
		this.state = {
			//exsited value line
			details: [],
			//index list of adding line, ex: if there was 5 lines, the list begin with 6
			child_array: [],
			//counter of adding line, ex: if there was 5 lines, counter = 6
			counter: 1,
			key: [
				"max",
				"prix14",
				"prix28",
				"prix30",
				"prix34",
				"prix44",
				"prix50",
				"prix53",
				"prix61",
				"prix72",
				"prixHors14",
				"prixSpec",
				"prixPalette",
				"prixLot",
			],
			data: [],
		};
	}
	//adding line
	addingLine() {
		//copy counter
		let counter = this.state.counter;
		//create the new line with new key base on counter
		let addingline = {};
		this.state.key.forEach((key) => {
			addingline[key + "_" + counter] = 0;
		});
		//copy slide value details
		let details = this.state.details[0];
		console.log("details:", details);
		//add the new lines to the slice value details
		details[counter] = addingline;
		//add the counter value to the index list of adding line
		let child_array = this.state.child_array;
		child_array.push(counter);
		counter++;
		//set new value for the counter and the index list of adding line
		//since the form is base on state, the view is updated automatically
		this.setState({ child_array: child_array });
		this.setState({ counter: counter });
		this.setState({ details: [details] });
	}

	//adding line
	deleteLine() {
		//copy counter
		let counter = this.state.counter;

		//copy slide value details
		let details = this.state.details[0];
		details.pop();
		let child_array = this.state.child_array;
		child_array.pop();
		counter--;
		//set new value for the counter and the index list of adding line
		//since the form is base on state, the view is updated automatically
		this.setState({ child_array: child_array });
		this.setState({ counter: counter });
		this.setState({ details: [details] });
	}

	//interval Min var
	currentIntervalleMin = 0;

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		//IF ADMIN, we provide agence modify
		//If not we set agence by defaut of the account
		if (user_role == "Admin") {
			config
				.apiFetch("/agences")
				.then((response) => response.json())
				.then((data) => {
					let agences = [];
					for (let number = 0; number < data["data"].length; number++) {
						agences.push(
							<option
								key={"option_agences_" + number}
								value={data["data"][number]["agence_id"]}
							>
								{data["data"][number]["agence_name"]}
							</option>
						);
					}
					this.setState({
						agences: [agences],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						data: data["data"][0],
					});
				});
		}
		let details = [
			{
				max_0: 5,
				prix14_0: 0,
				prix28_0: 0,
				prix30_0: 0,
				prix34_0: 0,
				prix44_0: 0,
				prix50_0: 0,
				prix53_0: 0,
				prix61_0: 0,
				prix72_0: 0,
				prixHors14_0: 0,
				prixSpec_0: 0,
				prixPalette_0: 0,
				prixLot_0: 0,
			},
		];

		this.setState({ details: [details] });
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	render() {
		let counter = -1;
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<h1>Créer un profil</h1>
					<Formik
						enableReinitialize={true}
						initialValues={Object.assign(
							{
								nom: "",
								agence: "",
							},
							this.state.details
						)}
						onSubmit={(values, { setSubmitting }) => {
							let promise_post_to_api = new Promise((resolve, reject) => {
								let counter = 0;
								user_role == "Admin"
									? ""
									: (values.agence = this.state.data.agence_id);
								let data = [];
								values[0].forEach((line) => {
									line["agence_" + counter] = values.agence;
									data.push(Object.values(line));
									counter++;

									if (counter == values[0].length) {
										config
											.apiFetch(config.Api_Url + "/profil/addProfilLine", {
												method: "POST",
												body: JSON.stringify({
													data: data,
													profil: values.nom,
												}),
												headers: {
													"Content-Type": "application/json",
												},
											})
											.then((response) => response.json())
											.then((details) => {
												console.log(details);
												this.props.history.push("/admin/profil");
												resolve();
											});
									}
								});
							});
						}}
					>
						{({
							values,
							handleChange,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<Form onSubmit={handleSubmit} autoComplete="off">
								<Row className="d-flex justify-content-center mb-2">
									<Col lg={3} className="mb-5">
										<Form.Label className="required">
											Nom du profil :{" "}
										</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="nom"
											values={values.nom}
											onChange={handleChange}
										></Form.Control>
									</Col>
									<Col lg={3} className="mb-5">
										<Form.Label className="required">Agence :</Form.Label>

										{user_role == "Admin" ? (
											<Form.Control
												required
												as="select"
												name="agence"
												value={values.agence || ""}
												onChange={handleChange}
											>
												<option></option>
												{this.state.agences}
											</Form.Control>
										) : (
											<div>
												<Form.Control
													readOnly
													placeholder={this.state.data.agence_name}
												></Form.Control>
											</div>
										)}
									</Col>
								</Row>

								<Table responsive borderless hover>
									<thead>
										<tr>
											<th>Intervalle poids (Kg)</th>
											<th>Département 14</th>
											<th>Département 28</th>
											<th>Département 30</th>
											<th>Département 34</th>
											<th>Département 44</th>
											<th>Département 50</th>
											<th>Département 53</th>
											<th>Département 61</th>
											<th>Département 72</th>
											<th>National</th>
											<th>Spécifique</th>
											<th>Palette</th>
											<th>En Lot</th>
										</tr>
									</thead>
									<tbody>
										{this.state.details.map((dynamicData, Key) => {
											let keys = Object.keys(dynamicData);
											let d = dynamicData;
											let counter = -1;
											return d.map((data) => {
												counter++;
												let currentcounter = counter;
												//update interval Min
												return (
													<tr key={counter}>
														<td>
															<Form.Control
																required
																type="number"
																name={"max_" + currentcounter}
																id={"max_" + currentcounter}
																defaultValue={data["max_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"max_" + currentcounter
																	] = document.getElementById(
																		"max_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix14_" + currentcounter}
																id={"prix14_" + currentcounter}
																defaultValue={data["prix14_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix14_" + currentcounter
																	] = document.getElementById(
																		"prix14_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix28_" + currentcounter}
																id={"prix28_" + currentcounter}
																defaultValue={data["prix28_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix28_" + currentcounter
																	] = document.getElementById(
																		"prix28_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix30_" + currentcounter}
																id={"prix30_" + currentcounter}
																defaultValue={data["prix30_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix30_" + currentcounter
																	] = document.getElementById(
																		"prix30_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix34_" + currentcounter}
																id={"prix34_" + currentcounter}
																defaultValue={data["prix34_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix34_" + currentcounter
																	] = document.getElementById(
																		"prix34_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix44_" + currentcounter}
																id={"prix44_" + currentcounter}
																defaultValue={data["prix44_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix44_" + currentcounter
																	] = document.getElementById(
																		"prix44_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix50_" + currentcounter}
																id={"prix50_" + currentcounter}
																defaultValue={data["prix50_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix50_" + currentcounter
																	] = document.getElementById(
																		"prix50_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix53_" + currentcounter}
																id={"prix53_" + currentcounter}
																defaultValue={data["prix53_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix53_" + currentcounter
																	] = document.getElementById(
																		"prix53_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix61_" + currentcounter}
																id={"prix61_" + currentcounter}
																defaultValue={data["prix61_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix61_" + currentcounter
																	] = document.getElementById(
																		"prix61_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prix72_" + currentcounter}
																id={"prix72_" + currentcounter}
																defaultValue={data["prix72_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prix72_" + currentcounter
																	] = document.getElementById(
																		"prix72_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prixHors14_" + currentcounter}
																id={"prixHors14_" + currentcounter}
																defaultValue={
																	data["prixHors14_" + currentcounter]
																}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prixHors14_" + currentcounter
																	] = document.getElementById(
																		"prixHors14_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prixSpec_" + currentcounter}
																id={"prixSpec_" + currentcounter}
																defaultValue={
																	data["prixSpec_" + currentcounter]
																}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prixSpec_" + currentcounter
																	] = document.getElementById(
																		"prixSpec_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prixPalette_" + currentcounter}
																id={"prixPalette_" + currentcounter}
																defaultValue={
																	data["prixPalette_" + currentcounter]
																}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prixPalette_" + currentcounter
																	] = document.getElementById(
																		"prixPalette_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
														<td>
															<Form.Control
																required
																step="0.01"
																type="number"
																name={"prixLot_" + currentcounter}
																id={"prixLot_" + currentcounter}
																defaultValue={data["prixLot_" + currentcounter]}
																onChange={(e) => {
																	values[0][currentcounter][
																		"prixLot_" + currentcounter
																	] = document.getElementById(
																		"prixLot_" + currentcounter
																	).value;
																}}
															></Form.Control>
														</td>
													</tr>
												);
											});
										})}
									</tbody>
								</Table>
								<Row>
									<Col>
										{" "}
										<Button
											size="sm"
											variant="outline-secondary"
											onClick={(e) => {
												this.addingLine();
											}}
										>
											<i className="fas fa-plus mr-1"></i>
											Ajouter une ligne
										</Button>
										<Button
											size="sm"
											variant="outline-secondary"
											className="mx-2"
											onClick={(e) => {
												this.deleteLine();
											}}
										>
											<i className="fas fa-minus mr-2"></i>
											Supprimer ligne
										</Button>
									</Col>
								</Row>
								<Row>
									<Col className="d-flex justify-content-center mt-3">
										<Button
											size="sm"
											variant="primary"
											type="submit"
											disabled={isSubmitting}
										>
											Enregistrer les modifications
										</Button>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		);
	}
}

export default withRouter(CreateForm);
