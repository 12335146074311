/* eslint-disable import/first */
import React, { Component } from "react";
import { Table, Form, Button } from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import { withRouter } from "react-router-dom";
const dateFormatConvert = require("helpers/dateFormatConvert");
import Checkbox from "helpers/checkbox";
import { Formik } from "formik";

export class SummaryTable extends React.Component {
	constructor(props) {
		super(props);
		this.SummaryTable = this.SummaryTable.bind(this);
	}
	SummaryTable() {
		let sum = 0;
		let nb_colis = 0;
		const reducer = (accumulator, currentValue) => accumulator + currentValue;
		if (this.props.sum_total.length != 0) {
			sum = this.props.sum_total.reduce(reducer);
		}
		if (this.props.nb_total.length != 0) {
			nb_colis = this.props.nb_total.reduce(reducer);
		}
		if (this.props.colis_dans_lot != 0) {
			nb_colis += this.props.colis_dans_lot.reduce(reducer);
		}
		nb_colis = nb_colis - this.props.nb_total[this.props.nb_total.length - 1];
		return (
			<div>
				<div className="text-center">
					<h2>
						Récapitulatif de{" "}
						{this.props.lesMois[this.props.match.params.month - 1] +
							" " +
							this.props.match.params.year}
						<br />
						Profil : {this.props.profil}
					</h2>
				</div>
				{this.props.details.map((dynamicData, Key) => {
					let keys = Object.keys(dynamicData);
					let d = dynamicData;
					//interval Min var
					let currentIntervalleMin = 0;
					let counter = -1;

					return d[keys].map((data) => {
						counter++;
						let nb_total = this.props.nb_total[counter];
						let sum_total = this.props.sum_total[counter];
						//update interval Min
						let min = currentIntervalleMin;
						currentIntervalleMin = data.max;
					});
				})}
				<div className="d-flex justify-content-center">
					{" "}
					<div className="thick-border">
						Montant HT : {Math.round(sum * 100) / 100}€ pour {nb_colis} colis{" "}
					</div>
				</div>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.SummaryTable />
			</div>
		);
	}
}

export default withRouter(SummaryTable);
