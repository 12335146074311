/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
import NavPublic from "components/nav/NavPublic";
import DatePickerField from "helpers/datePickerField";

/*CSS*/

class AddAdresse extends Component {
	constructor() {
		super();
		this.state = {
			clients: [],
		};
	}

	render() {
		return (
			<div>
				<NavPublic />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Ajouter une Adresse</h1>
							<h2>Caractères autorisés : e-z, E-Z, 0-9.</h2>
							<Formik
								initialValues={{
									telephonedest: "",
									villedest: "",
									codepostaldest: "",
									adressedest: "",
									prenomdest: "",
									nomdest: "",
									codesociete: "",
								}}
								onSubmit={(values, { setSubmitting }) => {
									let id_client = JSON.parse(
										localStorage.getItem("currentUser")
									)["data"][0].aut_old_client_id
										? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
												.aut_old_client_id
										: JSON.parse(localStorage.getItem("currentUser"))["data"][0]
												.aut_id;
									config
										.apiFetch(config.Api_Url + "/adresse/addAdresse", {
											method: "POST",
											body: JSON.stringify({
												id_client: id_client,
												telephonedest: values.telephonedest,
												villedest: values.villedest,
												codepostaldest: values.codepostaldest,
												adressedest: values.adressedest,
												prenomdest: values.prenomdest,
												nomdest: values.nomdest,
												codesociete: values.codesociete,
											}),
											headers: {
												"Content-Type": "application/json",
											},
										})
										.then((response) => response.json())
										.then((data) => {
											this.props.history.push("/public");
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit}>
										<Form.Label>Code société: (Ex: MBC44A) :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="codesociete"
											value={values.codesociete}
											onChange={handleChange}
										/>

										<Form.Label className="required">
											Nom ou société :
										</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="nomdest"
											value={values.nomdest}
											onChange={handleChange}
										/>
										<Form.Label className="">Prénom :</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="prenomdest"
											value={values.prenomdest}
											onChange={handleChange}
										/>

										<Form.Label className="required">Adresse :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="adressedest"
											value={values.adressedest}
											onChange={handleChange}
										/>
										<Form.Label className="required">Code Postal :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="codepostaldest"
											value={values.codepostaldest}
											onChange={handleChange}
										/>
										<Form.Label className="required">Ville :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="villedest"
											value={values.villedest}
											onChange={handleChange}
										/>
										<Form.Label className="required">Téléphone :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="telephonedest"
											value={values.telephonedest}
											onChange={handleChange}
										/>

										<Button
											className="my-4"
											size="sm"
											type="submit"
											disabled={isSubmitting}
										>
											Enregistrer
										</Button>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(AddAdresse);
