/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Form, Button, Row, Col, Alert, InputGroup } from "react-bootstrap";
import * as config from "config/config";
import { Typeahead } from "react-bootstrap-typeahead";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getDateReleve = require("helpers/getDateReleve");
import ClosingLot from "./closingLot";
/*CSS*/
import "./addDemande.css";

class formDemandeParLot extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clients: [],
			child_array: [],
			extra_fee_array: [],
			assure_states: false,
			adresses: [],
			typehead: [],
			typeheadCode: [],
			array_added_colis: [],
			demande: {
				type: "",
				typeEnvoi: "",
				infosParticulieres: "",
				date: "",
				nom: "",
				prenom: "",
				ville: "",
				codepostal: "",
				adresse: "",
				telephone: "",
				nomdest: "",
				prenomdest: "",
				adressedest: "",
				codepostaldest: "",
				villedest: "",
				telephonedest: "",
				agence_origine: "",
				agence_cible: "",
				value: "",
				poidslot: "",
				nbcolislot: "",
				codesociete: "",
			},
			horssite: false,
			alertState: false,
			groupement_alert: "",
			lot: [],
			data: [],
			print_button_label: "En chargement, merci de patienter",
			groupement_id: "",
			print_button_isdisable: true,
			lot_poids_total: 0,
			poids_colis_to_add: 1,
			addressWithUnclosedLot: [],
			mainlotpage: false,
			lo_barcode: null,
			button: "",
			a_id: "",
			lot_id: "",
			all_printed: false,
			unclosedLot: [],
			loading: true,
			isSubmitted: false,
		};
		this.updateSubmitting = this.updateSubmitting.bind(this);
	}
	componentDidMount() {
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		this.setState({ id_client: id_client });
		config
			.apiFetch(config.Api_Url + "/adresse/getAdresseByClient/" + id_client)
			.then((response) => {
				if (response.status != 200) {
					window.location.reload();
				}
				return response.json();
			})
			.then((adresses) => {
				let typehead = [];
				let typeheadCode = [];
				for (let number = 0; number < adresses["data"].length; number++) {
					if (adresses["data"][number]["codesociete"]) {
						typeheadCode.push(adresses["data"][number]["codesociete"]);
					}
					typehead.push(adresses["data"][number]["nomdest"]);
				}
				this.setState({
					adresses: adresses["data"],
					typehead: typehead,
					typeheadCode: typeheadCode,
				});
				if (this.props.match.params.a_id) {
					let a_id = this.props.match.params.a_id;
					this.setState({ a_id: a_id });
					const adresse_index = new Promise((resolutionFunc, rejectionFunc) => {
						resolutionFunc(
							this.state.adresses.findIndex((a) => a.a_id == a_id)
						);
					});
					adresse_index.then((val) => {
						if (val == -1) {
							this.props.history.push("/404");
						} else {
							this.setState((prevDemande) => ({
								demande: {
									...prevDemande.demande,
									adressedest: this.state.adresses[val].adressedest,
									nomdest: this.state.adresses[val].nomdest,
									prenomdest: this.state.adresses[val].prenomdest,
									codepostaldest: this.state.adresses[val].codepostaldest,
									villedest: this.state.adresses[val].villedest,
									telephonedest: this.state.adresses[val].telephonedest,
									codesociete: this.state.adresses[val].codesociete,
								},
							}));

							//get colis provisoire of the lot
							config
								.apiFetch(
									config.Api_Url +
										"/lot/getUnclosedLot/" +
										id_client +
										"/" +
										this.state.adresses[val].a_id
								)
								.then((response) => response.json())
								.then((data) => {
									if (data["data"][0]?.lo_barcode) {
										this.setState({
											lo_barcode: data["data"][0].lo_barcode,
											groupement_id: data["data"][0].groupement_id,
											//lot_id: data.colis_id,
										});
									}
									let lot = [];
									let lot_poids_total = 0;
									//count nb of colis
									//if its all printed, we remove the disabled from the closing button
									let lot_colis_nb = data["data"].length;
									let lot_colis_printed_counter = 0;
									for (let number = 0; number < data["data"].length; number++) {
										let colis = data["data"][number];
										colis["lc_is_printed"] ? lot_colis_printed_counter++ : null;
										lot_poids_total = lot_poids_total + colis["lc_poids"];
										lot.push(
											<div
												key={"package_" + number}
												className="colis-container col-sm-3"
											>
												<i
													className="fas fa-print fa-lg print-icon active
													"
													onClick={() => this.imprimerUnite(colis["lc_id"])}
												></i>

												{colis["lc_is_printed"] ? (
													<i className="fas fa-check fa-lg check-icon"></i>
												) : null}
												<div className="colis">{colis["lc_poids"]} kg</div>
												{colis["lc_is_printed"] ? null : (
													<i
														className="fas fa-times fa-lg remove-icon active"
														onClick={() => this.remove(colis["lc_id"])}
													></i>
												)}
											</div>
										);
										this.setState({
											all_printed:
												lot_colis_printed_counter == lot_colis_nb
													? true
													: false,
										});
									}

									this.setState({
										lot: lot,
										lot_poids_total: lot_poids_total,
										lot_provisional: data["data"],
									});
								});
						}
					});
				} else {
					this.setState({
						mainlotpage: true,
					});
				}
			});
		config
			.apiFetch(config.Api_Url + "/findbyid/client/" + id_client)
			.then((response) => response.json())
			.then((data) => {
				this.setState((prevDemande) => ({
					demande: {
						...prevDemande.demande,
						nom: data["data"][0].nom,
						prenom: data["data"][0].prenom,
						adresse: data["data"][0].adresse,
						ville: data["data"][0].ville,
						codepostal: data["data"][0].codepostal,
						telephone: data["data"][0].telephone,
						date: new Date(),
						type: "Colis",
						typeEnvoi: "Normal",
						infosParticulieres: "",
					},
				}));
			});
		config
			.apiFetch(
				config.Api_Url + "/lot/getAddressListWithUnclosedLot/" + id_client
			)
			.then((response) => response.json())
			.then((data) => {
				let addressWithUnclosedLot = [];
				for (let number = 0; number < data["data"].length; number++) {
					addressWithUnclosedLot.push(
						<option
							key={"option_AddressListWithUnclosedLot_" + number}
							value={data["data"][number]["a_id"]}
						>
							{data["data"][number]["nom"]}
						</option>
					);
				}
				this.setState({
					addressWithUnclosedLot: [addressWithUnclosedLot],
					unclosedLot: [data],
					loading: false,
				});
			});
	}

	componentWillReceiveProps() {
		window.location.reload();
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	remove(lc_id) {
		config
			.apiFetch(config.Api_Url + "/lot/removeColisFromUnclosedLot", {
				method: "POST",
				body: JSON.stringify({
					lc_id: lc_id,
					a_id: this.state.a_id,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => response.json())
			.then((data) => {
				window.location.reload();
			});
	}

	add(values) {
		console.log(values);
		if (this.state.lo_barcode) {
			this.addColisProvisoire();
		} else {
			let value = values.value ? values.value : 0;
			let datereleve = getDateReleve.getDateReleve();
			config
				.apiFetch(config.Api_Url + "/lot/addOpenLot", {
					method: "POST",
					body: JSON.stringify({
						id_client: this.state.id_client,
						a_id: this.state.a_id,
						nomdest: values.nomdest,
						prenomdest: values.prenomdest,
						adressedest: values.adressedest,
						codepostaldest: values.codepostaldest,
						villedest: values.villedest,
						telephonedest: values.telephonedest,
						type: values.type,
						infosParticulieres: values.infosParticulieres,
						typeEnvoi: values.typeEnvoi,
						datedemande: dateFormatConvert.convertDateTimeForSql(values.date),
						datereleve: dateFormatConvert.convertDateTimeForSql(datereleve),
						nomExpediteur: values.nom,
						prenomExpediteur: values.prenom,
						adresseExpediteur: values.adresse.replace(/'/g, "\\'"),
						codepostalExpediteur: values.codepostal,
						villeExpediteur: values.ville,
						telephoneExpediteur: values.telephone,
						etat: 30,
					}),
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response) => response.json())
				.then(async (data) => {
					this.setState({
						lot_id: data.lot_id,
						lo_barcode: data.barcode,
					});
					this.addColisProvisoire();
				});
		}
	}

	addColisProvisoire() {
		config
			.apiFetch(config.Api_Url + "/lot/addColisToUnclosedLot", {
				method: "POST",
				body: JSON.stringify({
					lc_poids: this.state.poids_colis_to_add,
					id_client: this.state.id_client,
					a_id: this.state.a_id,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => response.json())
			.then((data) => {
				window.location.reload();
			});
	}

	print_etiquette() {
		this.props.history.push(
			"/public/demande/imprimerEtiquetteLot/" + this.state.a_id
		);
	}

	print_etiquette_principal() {
		this.props.history.push(
			"/public/demande/ImprimerEtiquetteLotPrincipal/" +
				this.state.groupement_id
		);
	}

	imprimerUnite(lc_id) {
		window.open(
			"/public/demande/imprimerEtiquetteLotUnite/" +
				this.state.a_id +
				"/" +
				lc_id,
			"_blank"
		);
		this.sleep(2000).then(() => {
			window.location.reload();
		});
	}

	updateSubmitting() {
		this.setState({
			isSubmitted: true,
		});
	}

	closeLot(values) {
		let value = values.value ? values.value : 0;
		let datereleve = getDateReleve.getDateReleve();
		config
			.apiFetch(config.Api_Url + "/lot/updateandclosedemandeparlot", {
				method: "POST",
				body: JSON.stringify({
					groupement_id: this.state.groupement_id,
					id_client: this.state.id_client,
					datedemande: dateFormatConvert.convertDateTimeForSql(values.date),
					datereleve: dateFormatConvert.convertDateTimeForSql(datereleve),
					poidscolis: this.state.lot_poids_total,
					infosParticulieres: values.infosParticulieres,
					values: value,
					type: values.type,
					typeEnvoi: values.typeEnvoi,
					nbcolislot: this.state.lot.length,
					lot_id: this.state.lot_id,
					lo_barcode: this.state.lo_barcode,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => response.json())
			.then((data) => {
				console.log("🍀🍀🍀");
				if (data.groupement_id) {
					this.setState({
						groupement_alert:
							"Vous avez bien déposé la demande d'enlèvement numéro : " +
							this.state.groupement_id +
							".",
						alertState: true,
					});

					window.scrollTo(0, 0);
					let loading = 0;
					let counter_created_colis = 0;
					let array_added_colis = [];
					let array_c_id_colis = [];
					this.state.lot_provisional.forEach((lp, index) => {
						config
							.apiFetch(config.Api_Url + "/lot/addColisToLot", {
								method: "POST",
								body: JSON.stringify({
									lo_barcode: this.state.lo_barcode,
									lot_id: this.state.lo_barcode,
									poidscolis: lp.lc_poids,
									nomdest: values.nomdest,
									prenomdest: values.prenomdest,
									adressedest: values.adressedest,
									codepostaldest: values.codepostaldest,
									villedest: values.villedest,
									telephonedest: values.telephonedest,
									type: values.type,
									infosParticulieres: values.infosParticulieres,
									values: 0,
									typeEnvoi: values.typeEnvoi,
									index: index,
									groupement_id: this.state.groupement_id,
									lc_id: lp.lc_id,
								}),
								headers: {
									"Content-Type": "application/json",
								},
							})
							.then((response) => response.json())
							.then((data) => {
								counter_created_colis++;
								if (data["colis_id"] && data["c_id"]) {
									array_c_id_colis.push(data["c_id"]);
								}
								loading = Math.round(
									(parseInt(counter_created_colis) /
										parseInt(this.state.lot_provisional.length)) *
										100
								);
								this.setState({
									colis_loading: loading,
								});
								if (
									counter_created_colis == this.state.lot_provisional.length
								) {
									this.setState({
										print_button_isdisable: false,
										print_button_label:
											"Imprimer l'étiquette principale du lot",
									});
								}
								this.setState({
									array_added_colis: array_added_colis,
								});
							});
						this.setState({
							alertState: true,
						});
					});
				}
			});
	}

	render() {
		const normalFields = {};
		return (
			<div>
				<h2>Liste des lots ouverts</h2>

				<Form.Control
					as="select"
					name="addressWithUnclosedLot"
					value={""}
					onChange={(event) => {
						this.props.history.push(
							"/public/demande/adddemandeparlot/" + event.target.value
						);
					}}
				>
					<option></option>
					{this.state.addressWithUnclosedLot}
				</Form.Control>
				<ClosingLot
					unclosedLot={this.state.unclosedLot}
					loading={this.state.loading}
					updateSubmitting={this.updateSubmitting}
				/>
				<Formik
					initialValues={this.state.demande}
					enableReinitialize
					onSubmit={(values, { setSubmitting }) => {
						if (this.state.button == "imprimer") {
							this.print_etiquette();
						} else if (this.state.button == "fermer") {
							this.closeLot(values);
						}
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						/* and other goodies */
					}) => (
						<Form onSubmit={handleSubmit}>
							<h2>Le destinataire</h2>
							<Alert
								className={!this.state.alertState ? "hidden" : "alert-info"}
								key={"alert"}
							>
								<Row>
									<Col>
										{this.state.groupement_alert}
										<br></br>
										{this.state.colis_loading} %
									</Col>

									<Col>
										<Button
											disabled={this.state.print_button_isdisable}
											onClick={(e) => {
												this.print_etiquette_principal();
											}}
										>
											{this.state.print_button_label}
										</Button>
									</Col>
								</Row>
							</Alert>
							<Row>
								<Col lg={4}>
									<Row>
										{" "}
										<Col lg={12}>
											{" "}
											<Form.Label className="">Adresses</Form.Label>
										</Col>
										<Col lg={12}>
											<InputGroup>
												<Typeahead
													id="my-typeahead-id"
													labelKey="adresseTypeHead"
													value={values.adresse}
													multiple={false}
													options={this.state.typehead}
													placeholder="Recherche Dynamique..."
													onChange={(selected) => {
														setFieldValue("adresseTypeHead", selected);
														this.setState({ nomdest: selected[0] });
													}}
													onInputChange={(text, event) =>
														setFieldValue("adresseTypeHead", text)
													}
												/>
												<InputGroup.Append>
													<Button
														variant="outline-secondary"
														disabled={!this.state.nomdest}
														onClick={() => {
															const adresse_index = new Promise(
																(resolutionFunc, rejectionFunc) => {
																	resolutionFunc(
																		this.state.adresses.findIndex(
																			(a) => a.nomdest == this.state.nomdest
																		)
																	);
																}
															);

															adresse_index
																.then((val) => {
																	this.props.history.push(
																		"/public/demande/adddemandeparlot/" +
																			this.state.adresses[val].a_id
																	);
																})
																.then(() => window.location.reload());
														}}
													>
														<i className="fas fa-search"></i>
													</Button>
													<Button
														disabled={!this.state.nomdest}
														variant="outline-secondary"
														className=""
														onClick={(e) => {
															let adresse_index = this.state.adresses.findIndex(
																(a) => a.nomdest == this.state.nomdest
															);
															this.props.history.push(
																"/public/adresse/modify/" +
																	this.state.adresses[adresse_index].a_id
															);
														}}
													>
														<i className="far fa-edit"></i>
													</Button>
												</InputGroup.Append>
											</InputGroup>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											{" "}
											<Form.Label className="">Code société</Form.Label>
										</Col>
										<Col lg={12}>
											<InputGroup>
												<Typeahead
													id="my-typeaheadCode-id"
													labelKey="codeSocieteTypeHead"
													value={values.codesociete}
													multiple={false}
													options={this.state.typeheadCode}
													placeholder="Recherche Dynamique..."
													onChange={(selected) => {
														setFieldValue("codeSocieteTypeHead", selected);
														this.setState({ codesociete: selected[0] });
													}}
													onInputChange={(text, event) =>
														setFieldValue("codeSocieteTypeHead", text)
													}
												/>
												<InputGroup.Append>
													<Button
														variant="outline-secondary"
														disabled={!this.state.codesociete}
														onClick={() => {
															const adresse_index = new Promise(
																(resolutionFunc, rejectionFunc) => {
																	resolutionFunc(
																		this.state.adresses.findIndex(
																			(a) =>
																				a.codesociete == this.state.codesociete
																		)
																	);
																}
															);

															adresse_index
																.then((val) => {
																	this.props.history.push(
																		"/public/demande/adddemandeparlot/" +
																			this.state.adresses[val].a_id
																	);
																})
																.then(() => window.location.reload());
														}}
													>
														<i className="fas fa-search"></i>
													</Button>
													<Button
														disabled={!this.state.codesociete}
														variant="outline-secondary"
														className=""
														onClick={async (e) => {
															let adresse_index = await this.state.adresses.findIndex(
																(a) => a.codesociete == this.state.codesociete
															);
															if (this.state.adresses[adresse_index]) {
																this.props.history.push(
																	"/public/adresse/modify/" +
																		this.state.adresses[adresse_index].a_id
																);
															}
														}}
													>
														<i className="far fa-edit"></i>
													</Button>
												</InputGroup.Append>
											</InputGroup>
										</Col>
									</Row>

									<Row>
										<Col className="d-flex mt-3">
											<Button
												size="sm"
												variant="secondary"
												className="mx-2"
												href="/public/adresse/add"
												disabled={this.state.isSubmitted}
											>
												Ajouter
											</Button>
										</Col>
									</Row>
								</Col>
								<Col lg={4}>
									<Form.Label className="">Code société</Form.Label>
									<Form.Control
										readOnly={this.state.lo_barcode ? true : false}
										type="text"
										aria-describedby="inputGroupPrepend"
										name="codesociete"
										value={values.codesociete}
										onChange={handleChange}
									/>
									<Form.Label className="required">Nom ou société</Form.Label>
									<Form.Control
										required
										readOnly={this.state.lo_barcode ? true : false}
										type="text"
										aria-describedby="inputGroupPrepend"
										name="nomdest"
										value={values.nomdest || ""}
										onChange={handleChange}
									/>
									<Row>
										<Col lg={6}>
											{" "}
											<Form.Label className="">Prénom</Form.Label>
											<Form.Control
												readOnly={this.state.lo_barcode ? true : false}
												type="text"
												aria-describedby="inputGroupPrepend"
												name="prenomdest"
												value={values.prenomdest || ""}
												onChange={handleChange}
											/>
										</Col>
									</Row>
								</Col>
								<Col lg={4}>
									<Form.Label className="required">Adresse</Form.Label>
									<Form.Control
										required
										readOnly={this.state.lo_barcode ? true : false}
										type="text"
										aria-describedby="inputGroupPrepend"
										name="adressedest"
										value={values.adressedest || ""}
										onChange={handleChange}
									/>
									<Row>
										<Col lg={6}>
											{" "}
											<Form.Label className="required">Code Postal</Form.Label>
											<Form.Control
												required
												readOnly={this.state.lo_barcode ? true : false}
												type="text"
												aria-describedby="inputGroupPrepend"
												name="codepostaldest"
												value={values.codepostaldest || ""}
												onChange={handleChange}
											/>
										</Col>
										<Col lg={6}>
											{" "}
											<Form.Label className="required">Ville</Form.Label>
											<Form.Control
												readOnly={this.state.lo_barcode ? true : false}
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="villedest"
												value={values.villedest || ""}
												onChange={handleChange}
											/>
										</Col>
									</Row>

									<Form.Label className="">Téléphone</Form.Label>
									<Form.Control
										readOnly={this.state.lo_barcode ? true : false}
										type="text"
										aria-describedby="inputGroupPrepend"
										name="telephonedest"
										value={values.telephonedest || ""}
										onChange={handleChange}
									/>
								</Col>
							</Row>

							<Row></Row>

							<Row>
								<Col>
									<div
										className={this.state.mainlotpage ? "hidden" : "lot-infos"}
									>
										<h2 className="">Informations des colis dans le lot</h2>
										<h4 className="">
											Total : {this.state.lot.length} colis -{" "}
											{this.state.lot_poids_total} kg
										</h4>
										<div className="zone-informations-colis p-3 row">
											{this.state.lot}
										</div>
										<div className="zone-informations-colis p-3  row">
											<Col md={8}>
												<Form.Label className="">
													Le poids du colis pour ajouter dans le lot
												</Form.Label>
												<Form.Control
													type="number"
													name="poidscolis"
													value={this.state.poids_colis_to_add}
													min={1}
													onChange={(event) => {
														this.setState({
															poids_colis_to_add: event.currentTarget.value,
														});
													}}
												/>
											</Col>
											<Col md={2} className="button-div">
												{" "}
												<Button
													className="ajouterb"
													onClick={() => this.add(values)}
													disabled={this.state.isSubmitted}
												>
													Ajouter
												</Button>
												<Button
													className="imprimerb"
													onClick={(e) => {
														this.setState({ button: "imprimer" });

														handleSubmit(e);
													}}
													disabled={
														!this.state.lo_barcode || this.state.isSubmitted
													}
												>
													Imprimer
												</Button>
											</Col>
										</div>
										<p className="required">
											Cliquez le package pour supprimer
										</p>
										{/* Boîte grise */}
									</div>

									<div
										className={
											this.state.mainlotpage
												? "hidden"
												: "zone-informations-colis"
										}
									>
										<div>
											<Row>
												<Col md={12}>
													<Form.Group className="checkbox-assurer-colis">
														<Form.Check
															type="checkbox"
															label="Assurer ce lot"
															value={this.state.assure_states}
															name="assure_state"
															onChange={(e) => {
																this.setState({
																	assure_states: e.target.checked,
																});
															}}
														/>
													</Form.Group>
													<Form.Control
														disabled={!this.state.assure_states}
														type="number"
														value={values.value}
														onChange={(e) => {
															this.setState({
																extra_fee: e.target.value * 0.075,
															});
															values.value = e.target.value;
														}}
														name="valeur"
														placeholder={`Valeur déclarée (€)`}
													/>
												</Col>

												<Col md={3}>
													<em
														className={
															!this.state.assure_states ? "hidden" : ""
														}
													>
														Supplément : {this.state.extra_fee}€
													</em>
												</Col>
											</Row>
										</div>
									</div>
									<Row className={this.state.mainlotpage ? "hidden" : "mt-4"}>
										<Col md={4}>
											<Form.Label className="">
												Conditionnement de votre envoi
											</Form.Label>
											<Form.Control
												as="select"
												name="type"
												value={values.type}
												onChange={(e) => {
													this.setState((prevDemande) => ({
														demande: {
															...prevDemande.demande,
															type: e.target.value,
														},
													}));
													handleChange(e);
												}}
											>
												<option>Colis</option>
												<option>Palette</option>
											</Form.Control>
										</Col>
										<Col md={4}>
											<Form.Label className="">Type de votre envoi</Form.Label>
											<Form.Control
												as="select"
												name="typeEnvoi"
												value={values.typeEnvoi}
												onChange={(e) => {
													this.setState((prevDemande) => ({
														demande: {
															...prevDemande.demande,
															typeEnvoi: e.target.value,
														},
													}));
													handleChange(e);
												}}
											>
												<option>Normal</option>
												<option>Zone Z1</option>
											</Form.Control>
										</Col>
										<Col md={4}>
											<Form.Label className="">
												Informations complémentaires de livraison
											</Form.Label>
											<Form.Control
												type="text"
												aria-describedby="inputGroupPrepend"
												name="infosParticulieres"
												value={values.infosParticulieres || ""}
												onChange={(e) => {
													this.setState((prevDemande) => ({
														demande: {
															...prevDemande.demande,
															infosParticulieres: e.target.value,
														},
													}));
													handleChange(e);
												}}
											/>
										</Col>
									</Row>

									<Row className={this.state.mainlotpage ? "hidden" : ""}>
										<Col className="d-flex flex-column justify-content-center align-items-center">
											<div className="expediteur-releve-prevue text-center mt-3">
												{/* TODO : date, frais fixe d'enlèvement hors site et lien bouton */}
												Relève prévue le{" "}
												{dateFormatConvert.getFormattedDate(new Date())}
												<br></br>
												<Row>
													<Col
														xs={12}
														style={{
															fontWeight: 500,
														}}
													>
														<div>
															{"Expéditeur : " +
																this.state.demande.nom +
																" " +
																this.state.demande.prenom}
														</div>
														<div>{this.state.demande.adresse}</div>
														<div>
															{this.state.demande.codepostal +
																" " +
																this.state.demande.ville}
														</div>
													</Col>
												</Row>
											</div>

											<Button
												className="mb-5 mt-4"
												size="sm"
												onClick={(e) => {
													this.setState({ button: "fermer" });
													handleSubmit(e);
												}}
												disabled={
													isSubmitting ||
													!this.state.all_printed ||
													this.state.isSubmitted
												}
											>
												Clôturer le lot
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

export default withRouter(formDemandeParLot);
