import React, { Component, useState } from "react";
import {
	Collapse,
	Form,
	Button,
	Row,
	Col,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Formik, FieldArray, Field } from "formik";
import DatePickerField from "helpers/datePickerField";
import * as config from "config/config";

class Search extends Component {
	constructor(props) {
		super(props);
		this.Search = this.Search.bind(this);
	}

	Search() {
		const [count1, setOpen1] = useState(true);
		const [count2, setOpen2] = useState(false);

		const lesMois = config.lesMois;

		let month = [];
		for (let number = 0; number < lesMois.length; number++) {
			month.push(
				<option key={"option_month_" + number} value={number}>
					{lesMois[number]}
				</option>
			);
		}
		return (
			<div>
				{" "}
				<ButtonToolbar>
					<Button
						size="sm"
						className="mr-1"
						variant="outline-secondary"
						onClick={() => {
							setOpen1(!count1);
							setOpen2(false);
						}}
						aria-controls="search_by_date"
						aria-expanded={count1}
						active={count1}
					>
						Recherche par mois
					</Button>
					<Button
						size="sm"
						className="mr-1"
						variant="outline-secondary"
						onClick={() => {
							setOpen2(!count2);
							setOpen1(false);
						}}
						aria-controls="search_by_month"
						aria-expanded={count2}
						active={count2}
					>
						Recherche par période
					</Button>
				</ButtonToolbar>
				<Collapse in={count1}>
					<div id="search_by_month">
						<Formik
							initialValues={{
								month: "0",
								year: "",
								id_client: "",
							}}
							onSubmit={(values) => {
								let month = parseInt(values.month) + 1;

								if (values.id_client != "") {
									window.location.href =
										"/admin/demande/FilterAdminDemandeMonthDateClient/page/1/" +
										month +
										"/" +
										values.year +
										"/" +
										values.id_client;
								} else {
									window.location.href =
										"/admin/demande/FilterAdminDemandeMonthDate/page/1/" +
										month +
										"/" +
										values.year;
								}
							}}
						>
							{(props) => {
								const {
									status,
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setFieldValue,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Row>
											<Col lg={6}>
												<Row>
													<Col md>
														{" "}
														<Form.Label className="required">Mois :</Form.Label>
														<Form.Control
															required
															as="select"
															name="month"
															onChange={handleChange}
														>
															{month}
														</Form.Control>
													</Col>
													<Col md>
														{" "}
														<Form.Label className="required">
															Année :
														</Form.Label>
														<Form.Control
															required
															type="number"
															aria-describedby="inputGroupPrepend"
															name="year"
															onChange={handleChange}
														></Form.Control>
													</Col>
												</Row>
												<Form.Label>
													Client (Laisser le champs vide pour afficher tout les
													clients) :{" "}
												</Form.Label>
												<Form.Group controlId="formBasicCheckbox">
													<Form.Control
														as="select"
														name="id_client"
														value={values.id_client}
														onChange={handleChange}
													>
														<option></option>
														{this.props.clients_list}
													</Form.Control>
												</Form.Group>
												<Row>
													<Col md>
														{" "}
														<Button
															size="sm"
															className="mb-4"
															type="submit"
															disabled={isSubmitting}
														>
															Rechercher
														</Button>
													</Col>
												</Row>
											</Col>
										</Row>

										{status && (
											<div className={"alert alert-danger"}>{status}</div>
										)}
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>
				<Collapse in={count2}>
					<div id="search_by_date">
						<Formik
							initialValues={{
								startDate: "",
								endDate: "",
								id_client: "",
							}}
							onSubmit={(values) => {
								let startDate =
									values.startDate.getFullYear() +
									"-" +
									(values.startDate.getMonth() + 1) +
									"-" +
									values.startDate.getDate();
								let endDate =
									values.endDate.getFullYear() +
									"-" +
									(values.endDate.getMonth() + 1) +
									"-" +
									values.endDate.getDate();

								if (values.id_client != "") {
									window.location.href =
										"/admin/demande/FilterAdminDemandeDateClient/page/1/" +
										startDate +
										"/" +
										endDate +
										"/" +
										values.id_client;
								} else {
									window.location.href =
										"/admin/demande/FilterAdminDemandeDate/page/1/" +
										startDate +
										"/" +
										endDate;
								}
							}}
						>
							{(props) => {
								const {
									status,
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setFieldValue,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Row>
											<Col lg={6}>
												<Row>
													<Col md>
														<Form.Label>Période du : </Form.Label>

														<DatePickerField
															name="startDate"
															className="form-control"
														/>
													</Col>

													<Col md>
														<Form.Label>Au :</Form.Label>
														<DatePickerField
															name="endDate"
															className="form-control"
														/>
													</Col>
												</Row>
												<Form.Label>
													Client (Laisser le champs vide pour afficher tout les
													clients) :{" "}
												</Form.Label>
												<Form.Group controlId="formBasicCheckbox">
													<Form.Control
														as="select"
														name="id_client"
														value={values.id_client}
														onChange={handleChange}
													>
														<option></option>
														{this.props.clients_list}
													</Form.Control>
												</Form.Group>
												<Row>
													<Col md>
														<Button
															size="sm"
															type="submit"
															disabled={isSubmitting}
															className="mb-4"
														>
															Rechercher
														</Button>
													</Col>
												</Row>
											</Col>
										</Row>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Collapse>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.Search />
			</div>
		);
	}
}

export default withRouter(Search);
