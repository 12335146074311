/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";

class SearchForm extends Component {
	constructor() {
		super();
	}
	render() {
		return (
			<div>
				<Formik
					initialValues={{ nom: "", prenom: "", ville: "" }}
					onSubmit={(values, { setSubmitting }) => {
						let nom_form = values.nom || null;
						let prenom_form = values.prenom || null;
						let ville_form = values.ville || null;
						this.props.history.push(
							"/admin/clients/" +
								nom_form +
								"/" +
								prenom_form +
								"/" +
								ville_form +
								"/1"
						);
						this.props.history.go(0);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<form onSubmit={handleSubmit}>
							<Row>
								<Col md={6}>
									<Row>
										<Col md>
											<Form.Label>Nom</Form.Label>
											<input
												className="form-control"
												type="text"
												name="nom"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email}
											/>
										</Col>
										<Col md>
											<Form.Label>Ville</Form.Label>
											<input
												className="form-control"
												type="text"
												name="ville"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.password}
											/>
										</Col>
									</Row>
									<Row>
										<Col md>
											<Button size="sm" type="submit" disabled={isSubmitting}>
												Rechercher
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</form>
					)}
				</Formik>
			</div>
		);
	}
}

export default withRouter(SearchForm);
