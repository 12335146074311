/* eslint-disable import/first */
/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import { Route, withRouter, Redirect } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Formik } from "formik";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");
import DatePickerField from "helpers/datePickerField";

/*Css*/

export class ModifyVehicule extends React.Component {
	constructor() {
		super();
		this.state = {
			vehicules: {},
			total: 0,
			agences: [],
			data: [],
		};
	}

	componentDidMount() {
		//update if back from another page

		if (performance.navigation.type == 2) {
			window.location.reload();
		}

		config
			.apiFetch(
				config.Api_Url +
					"/vehicules/getVehiculesById/" +
					this.props.match.params.id_vehicule
			)
			.then((response) => response.json())
			.then((vehicules) => {
				if (vehicules["data"].length != 0) {
					this.setState({
						vehicules: vehicules["data"][0],
					});
				} else {
					this.props.history.push("/404");
				}
			});
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		//IF ADMIN, we provide agence modify
		//If not we set agence by defaut of the account
		if (user_role == "Admin") {
			config
				.apiFetch("/agences")
				.then((response) => response.json())
				.then((data) => {
					let agences = [];
					for (let number = 0; number < data["data"].length; number++) {
						agences.push(
							<option
								key={"option_agences_" + number}
								value={data["data"][number]["agence_id"]}
							>
								{data["data"][number]["agence_name"]}
							</option>
						);
					}
					this.setState({
						agences: [agences],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						data: data["data"][0],
					});
				});
		}
	}

	render() {
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		return (
			<div id="gestion-des-vehicules">
				<NavAdmin />
				<div className="container-fluid">
					<h1>Modification de véhicule</h1>
					<div className="container">
						<Row className="justify-content-center">
							<Col md={6}>
								<Formik
									initialValues={this.state.vehicules}
									enableReinitialize
									onSubmit={(values, { setSubmitting }) => {
										let anneeImmatriculation = "";
										if (!values.properanneeImmatriculation) {
											anneeImmatriculation = dateFormatConvert.convertDateHolderForSql(
												this.state.vehicules.anneeImmatriculation
											);
										} else {
											anneeImmatriculation = dateFormatConvert.convertDateForSql(
												values.properanneeImmatriculation
											);
										}
										let date = "";
										if (!values.properdate) {
											date = dateFormatConvert.convertDateHolderForSql(
												this.state.vehicules.dernierReleve
											);
										} else {
											date = dateFormatConvert.convertDateForSql(
												values.properdate
											);
										}

										config
											.apiFetch(config.Api_Url + "/update/vehicules", {
												method: "POST",
												body: JSON.stringify({
													id_vehicule: this.props.match.params.id_vehicule,
													codeVehicule: values.codeVehicule,
													marque: values.marque,
													immatriculation: values.immatriculation,
													anneeImmatriculation: anneeImmatriculation,
													societe: values.societe,
													depot: values.depot,
													kilometrage: values.kilometrage,
													dernierReleve: date,
													intervalleRemplacementPneusAV:
														values.intervalleRemplacementPneusAV,
													dernierRemplacementPneusAV:
														values.dernierRemplacementPneusAV,
													intervalleRemplacementPneusAR:
														values.intervalleRemplacementPneusAR,
													dernierRemplacementPneusAR:
														values.dernierRemplacementPneusAR,
													intervalleVidange: values.intervalleVidange,
													derniereVidange: values.derniereVidange,
													intervalleRemplacementFreinsAV:
														values.intervalleRemplacementFreinsAV,
													dernierRemplacementFreinsAV:
														values.dernierRemplacementFreinsAV,
													intervalleRemplacementFreinsAR:
														values.intervalleRemplacementFreinsAR,
													dernierRemplacementFreinsAR:
														values.dernierRemplacementFreinsAR,
													intervalleRemplacementDisqueAV:
														values.intervalleRemplacementDisqueAV,
													dernierRemplacementDisqueAV:
														values.dernierRemplacementDisqueAV,
													intervalleRemplacementDisqueAR:
														values.intervalleRemplacementDisqueAR,
													dernierRemplacementDisqueAR:
														values.dernierRemplacementDisqueAR,
													chauffeur: values.chauffeur,
													agence:
														user_role == "Admin"
															? values.agence
																? values.agence
																: "NULL"
															: this.state.data.agence_id,
												}),
												headers: { "Content-Type": "application/json" },
											})
											.then((response) => response.json())
											.then((data) => {
												window.location.reload();
											});
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										/* and other goodies */
									}) => (
										<Form onSubmit={handleSubmit}>
											<Form.Label className="required">
												Code véhicule :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="codeVehicule"
												value={values.codeVehicule || ""}
												onChange={handleChange}
											/>
											<Form.Label className="required">Marque :</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="marque"
												value={values.marque || ""}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Immatriculation :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="immatriculation"
												value={values.immatriculation || ""}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Première mise en circulation :
											</Form.Label>
											<DatePickerField
												className="form-control"
												name="properanneeImmatriculation"
												placeholderText={values.anneeImmatriculation?.replaceAll(
													"/",
													"-"
												)}
											/>

											<Form.Label className="required">Société :</Form.Label>
											<Form.Control
												required
												type="text"
												name="societe"
												value={values.societe || ""}
												onChange={handleChange}
											></Form.Control>
											<Form.Label className="required">Dépot :</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="depot"
												value={values.depot || ""}
												onChange={handleChange}
											/>
											<Form.Label className="required">Chauffeur : </Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="chauffeur"
												value={values.chauffeur || ""}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Dernier relevé kilométrique :
											</Form.Label>
											<Form.Control
												required
												type="number"
												aria-describedby="inputGroupPrepend"
												name="kilometrage"
												value={values.kilometrage || ""}
												onChange={handleChange}
											/>
											<Form.Label className="required">En date du :</Form.Label>
											<DatePickerField
												name="properdate"
												className="form-control"
												placeholderText={values.dernierReleve?.replaceAll(
													"/",
													"-"
												)}
											/>

											<Form.Label className="required">Agence :</Form.Label>
											{user_role == "Admin" ? (
												<Form.Control
													required
													as="select"
													name="agence"
													value={values.agence || ""}
													onChange={handleChange}
												>
													<option></option>
													{this.state.agences}
												</Form.Control>
											) : (
												<div>
													<Form.Control
														readOnly
														placeholder={this.state.data.agence_name}
													></Form.Control>
												</div>
											)}

											<h2 className="mt-5">Pneus avant</h2>
											<Form.Label className="required">
												Fréquence de remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="intervalleRemplacementPneusAV"
												value={values.intervalleRemplacementPneusAV || 0}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Dernier remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="dernierRemplacementPneusAV"
												value={values.dernierRemplacementPneusAV || 0}
												onChange={handleChange}
											/>
											<h2 className="mt-5">Pneus arrière</h2>
											<Form.Label className="required">
												Fréquence de remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="intervalleRemplacementPneusAR"
												value={values.intervalleRemplacementPneusAR || 0}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Dernier remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="dernierRemplacementPneusAR"
												value={values.dernierRemplacementPneusAR || 0}
												onChange={handleChange}
											/>
											<h2 className="mt-5">Vidange</h2>
											<Form.Label className="required">
												Fréquence (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="intervalleVidange"
												value={values.intervalleVidange || 0}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Dernier remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												required
												name="derniereVidange"
												value={values.derniereVidange || 0}
												onChange={handleChange}
											></Form.Control>
											<h2 className="mt-5">Freins avant</h2>
											<Form.Label className="required">
												Fréquence de remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="intervalleRemplacementFreinsAV"
												value={values.intervalleRemplacementFreinsAV || 0}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Dernier remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="dernierRemplacementFreinsAV"
												value={values.dernierRemplacementFreinsAV || 0}
												onChange={handleChange}
											/>
											<h2 className="mt-5">Freins arrière</h2>
											<Form.Label className="required">
												Fréquence de remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="intervalleRemplacementFreinsAR"
												value={values.intervalleRemplacementFreinsAR || 0}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Dernier remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="dernierRemplacementFreinsAR"
												value={values.dernierRemplacementFreinsAR || 0}
												onChange={handleChange}
											/>
											<h2 className="mt-5">Disques avant</h2>
											<Form.Label className="required">
												Fréquence de remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="intervalleRemplacementDisqueAV"
												value={values.intervalleRemplacementDisqueAV || 0}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Dernier remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="dernierRemplacementDisqueAV"
												value={values.dernierRemplacementDisqueAV || 0}
												onChange={handleChange}
											/>
											<h2 className="mt-5">Disques arrière</h2>
											<Form.Label className="required">
												Fréquence de remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="intervalleRemplacementDisqueAR"
												value={values.intervalleRemplacementDisqueAR || 0}
												onChange={handleChange}
											/>
											<Form.Label className="required">
												Dernier remplacement (en km) :
											</Form.Label>
											<Form.Control
												required
												type="text"
												aria-describedby="inputGroupPrepend"
												name="dernierRemplacementDisqueAR"
												value={values.dernierRemplacementDisqueAR || 0}
												onChange={handleChange}
											/>

											<Button
												size="sm"
												className="my-4"
												type="submit"
												disabled={isSubmitting}
											>
												Enregistrer
											</Button>
										</Form>
									)}
								</Formik>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ModifyVehicule);
