import React, { Component } from 'react';
import { Route , withRouter} from 'react-router-dom';
import * as config from 'config/config';


class EnableClient extends Component  {
    constructor() {
        super();
    }
    componentDidMount() {
        config.apiFetch(config.Api_Url+'/enable/clients', {
            method: 'POST',
            body: JSON.stringify({
                id_client:this.props.match.params.id,
            }),
            headers: {"Content-Type": "application/json"}
            })
            .then(response => response.json())
            .then(data => {
                this.props.history.goBack();
        })
    }
    render() {
        return null;
    }
};


export default withRouter(EnableClient);