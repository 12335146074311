/* eslint-disable import/first */
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

class DeleteProfil extends Component {
	constructor() {
		super();
	}

	componentDidMount() {
		let profil = "";

		if (this.props.location.state && this.props.location.state.profil) {
			profil = this.props.location.state.profil;
		} else {
			this.props.history.push("/404");
		}
		config
			.apiFetch(config.Api_Url + "/delete/profil", {
				method: "POST",
				body: JSON.stringify({
					profil: profil,
				}),
				headers: { "Content-Type": "application/json" },
			})
			.then((response) => response.json())
			.then((data) => {
				this.props.history.goBack();
			});
	}
	render() {
		return null;
	}
}

export default withRouter(DeleteProfil);
