/* eslint-disable import/first */
import React from "react";
import * as config from "config/config";
import { withRouter } from "react-router-dom";
import SearchTable from "./searchTable";
const geolocalisation = require("helpers/geolocalisation");
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import MarkerWithInfoWindow from "helpers/markerWithInfoWindow";
import { Alert } from "react-bootstrap";

export class searchByLotCode extends React.Component {
	constructor() {
		super();
		this.state = {
			colis: [],
			items: [],
			geolocalisation: [],
			centerLon: 0,
			centerLat: 0,
			title: "",
			showMap: false,
		};
	}
	componentDidMount() {
		let colis_id = this.props.match.params.colis_id;

		config
			.apiFetch(config.Api_Url + "/lot/getLotbyCode/" + colis_id, {
				method: "GET",

				headers: { "Content-Type": "application/json" },
			})
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					colis: [data],
				});
				config
					.apiFetch(
						config.Api_Url + "/gps/getLotGeolocalisationById/" + colis_id,
						{
							method: "GET",
							headers: { "Content-Type": "application/json" },
						}
					)
					.then((response) => response.json())
					.then((data) => {
						if (data["data"].length) {
							this.setState({
								geolocalisation: [data],
								centerLon: data["data"][0].cg_lon,
								centerLat: data["data"][0].cg_lat,
								showMap: true,
							});
						}
					});
			});
	}
	render() {
		return (
			<div>
				<SearchTable
					colis={this.state.colis}
					ncolis={this.state.ncolis}
					methodCode={true}
					hasPagination={false}
				/>
				{this.state.showMap ? (
					<LoadScript googleMapsApiKey={config.googleMapsApiKey}>
						<GoogleMap
							mapContainerStyle={{ width: "100%", height: "500px" }}
							center={{ lng: this.state.centerLon, lat: this.state.centerLat }}
							zoom={22}
						>
							{this.state.geolocalisation.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								let total = d[keys].length;
								return d[keys].map((data, index) => {
									let test = geolocalisation.generateOverlappingMarker(
										data.cg_lon,
										data.cg_lat,
										index,
										total
									);
									return (
										<MarkerWithInfoWindow
											key={data.cg_colis_id}
											position={{
												lng: test[0],
												lat: test[1] + 0.000005,
											}}
											key={data.cg_colis_id}
											info={data.cg_add}
										/>
									);
								});
							})}
						</GoogleMap>
					</LoadScript>
				) : (
					<div>
						<Alert variant="warning">
							Pas de position GPS disponible au moment de la livraison.
						</Alert>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(searchByLotCode);
