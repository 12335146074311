import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

/*Css*/

export class DetailsProfil extends React.Component {
	constructor() {
		super();
		this.state = {
			details: [],
			nom_profil: "",
			agence_name: "",
		};
	}

	//interval Min var
	currentIntervalleMin = 0;

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let profil = "";

		if (this.props.location.state && this.props.location.state.profil) {
			profil = this.props.location.state.profil;
			config
				.apiFetch(config.Api_Url + "/profil/getProfilbyName", {
					method: "POST",
					body: JSON.stringify({
						profil: profil,
					}),
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response) => response.json())
				.then((details) => {
					this.setState({
						details: [details],
						nom_profil: details["data"][0].nom,
						agence_name: details["data"][0].agence_name,
					});
				});
		} else {
			this.props.history.push("/404");
		}
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<h1>Détail du profil {this.state.nom_profil}</h1>
					<h2>Agence : {this.state.agence_name}</h2>
					<div>
						<Table responsive bordered hover>
							<thead>
								<tr>
									<th>Intervalle poids (Kg)</th>
									<th>Département 14</th>
									<th>Département 28</th>
									<th>Département 30</th>
									<th>Département 34</th>
									<th>Département 44</th>
									<th>Département 50</th>
									<th>Département 53</th>
									<th>Département 61</th>
									<th>Département 72</th>
									<th>National</th>
									<th>Spécifique</th>
									<th>Palette</th>
									<th>En Lot</th>
								</tr>
							</thead>
							<tbody>
								{this.state.details.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;
									return d[keys].map((data) => {
										//update interval Min
										let min = this.currentIntervalleMin;
										this.currentIntervalleMin = data.max;
										return (
											<tr key={data.max}>
												<td>
													De {min} à {data.max}
												</td>
												<td>{data.prix14}</td>
												<td>{data.prix28}</td>
												<td>{data.prix30}</td>
												<td>{data.prix34}</td>
												<td>{data.prix44}</td>
												<td>{data.prix50}</td>
												<td>{data.prix53}</td>
												<td>{data.prix61}</td>
												<td>{data.prix72}</td>
												<td>{data.prixHors14}</td>
												<td>{data.prixSpec}</td>
												<td>{data.prixPalette}</td>
												<td>{data.prixLot}</td>
											</tr>
										);
									});
								})}
							</tbody>
						</Table>
						<Button
							onClick={this.handleClick}
							size="sm"
							className="pr-3 back-button mb-5"
						>
							<i className="fas fa-angle-left mr-2"></i>
							Retour
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DetailsProfil);
