/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { NavAdmin } from "components/nav/NavAdmin";
import * as config from "config/config";

/*CSS*/
import { withRouter } from "react-router-dom";

class AlertVehicule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vehicules: [],
			immatriculation: "",
			societe: "",
			etat_pneu_AV: false,
			etat_pneu_AR: false,
			etat_Freins_AV: false,
			etat_Freins_AR: false,
			etat_vidange: false,
			etat_disque_AV: false,
			etat_disque_AR: false,
		};
	}
	componentDidMount() {
		//update if back from another page

		if (performance.navigation.type == 2) {
			window.location.reload();
		}

		config
			.apiFetch(
				config.Api_Url +
					"/vehicules/getVehiculesById/" +
					this.props.match.params.id_vehicule
			)
			.then((response) => response.json())
			.then((vehicules) => {
				if (vehicules["data"].length != 0) {
					//get vehicle infos
					this.setState({
						vehicules: [vehicules],
						immatriculation: vehicules["data"][0].immatriculation,
						societe: vehicules["data"][0].societe,
					});
					//data treatment
					let vehicule = vehicules["data"][0];
					//do the maths - figure out which part is needed to be changed

					this.setState({
						etat_pneu_AV:
							vehicule.kilometrage - vehicule.intervalleRemplacementPneusAV >
							vehicule.dernierRemplacementPneusAV,
					});
					this.setState({
						etat_pneu_AR:
							vehicule.kilometrage - vehicule.intervalleRemplacementPneusAR >
							vehicule.dernierRemplacementPneusAR,
					});
					this.setState({
						etat_vidange:
							vehicule.kilometrage - vehicule.intervalleVidange >
							vehicule.derniereVidange,
					});
					this.setState({
						etat_Freins_AV:
							vehicule.kilometrage - vehicule.intervalleRemplacementFreinsAV >
							vehicule.dernierRemplacementFreinsAV,
					});
					this.setState({
						etat_Freins_AR:
							vehicule.kilometrage - vehicule.intervalleRemplacementFreinsAR >
							vehicule.dernierRemplacementFreinsAR,
					});
					this.setState({
						etat_disque_AV:
							vehicule.kilometrage - vehicule.intervalleRemplacementDisqueAV >
							vehicule.dernierRemplacementDisqueAV,
					});
					this.setState({
						etat_disque_AR:
							vehicule.kilometrage - vehicule.intervalleRemplacementDisqueAR >
							vehicule.dernierRemplacementDisqueAR,
					});
				} else {
					this.props.history.push("/404");
				}
			});
	}
	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<h3>
						Alertes sur le véhicule immatriculé {this.state.immatriculation} de
						la société {this.state.societe}
					</h3>
					<Table responsive bordered hover className="tableau-pneus">
						<thead>
							<tr>
								<th>Type</th>
								<th>Mis à jour il y a </th>
								<th></th>
							</tr>
						</thead>
						{this.state.vehicules.map((dynamicData, Key) => {
							let keys = Object.keys(dynamicData);
							let d = dynamicData;

							return d[keys].map((data) => {
								let td_list = [];
								if (this.state.etat_pneu_AV) {
									td_list.push(
										<tr key={"etat_pneu_AV" + data.id_vehicule}>
											<td>Pneus AV</td>
											<td>{data.kilometrage} km</td>
											<td>
												<Formik
													initialValues={{
														piece: "dernierRemplacementPneusAV",
													}}
													onSubmit={(values) => {
														config
															.apiFetch(
																config.Api_Url + "/vehicules/fixVehicule",
																{
																	method: "POST",
																	body: JSON.stringify({
																		id_vehicule: data.id_vehicule,
																		piece: values.piece,
																		kilometrage: data.kilometrage,
																	}),
																	headers: {
																		"Content-Type": "application/json",
																	},
																}
															)
															.then((response) => response.json())
															.then((data) => {
																window.location.reload();
															});
													}}
												>
													{({
														handleSubmit,
														isSubmitting,
														/* and other goodies */
													}) => (
														<Form onSubmit={handleSubmit}>
															<Button
																size="sm"
																type="submit"
																disabled={isSubmitting}
															>
																Régler
															</Button>
														</Form>
													)}
												</Formik>
											</td>
										</tr>
									);
								}

								if (this.state.etat_pneu_AR) {
									td_list.push(
										<tr key={"etat_pneu_AR" + data.id_vehicule}>
											<td>Pneus AR</td>
											<td>{data.kilometrage} km</td>
											<td>
												<Formik
													initialValues={{
														piece: "dernierRemplacementPneusAR",
													}}
													onSubmit={(values) => {
														config
															.apiFetch(
																config.Api_Url + "/vehicules/fixVehicule",
																{
																	method: "POST",
																	body: JSON.stringify({
																		id_vehicule: data.id_vehicule,
																		piece: values.piece,
																		kilometrage: data.kilometrage,
																	}),
																	headers: {
																		"Content-Type": "application/json",
																	},
																}
															)
															.then((response) => response.json())
															.then((data) => {
																window.location.reload();
															});
													}}
												>
													{({
														handleSubmit,
														isSubmitting,
														/* and other goodies */
													}) => (
														<Form onSubmit={handleSubmit}>
															<Button
																size="sm"
																type="submit"
																disabled={isSubmitting}
															>
																Régler
															</Button>
														</Form>
													)}
												</Formik>
											</td>
										</tr>
									);
								}

								if (this.state.etat_vidange) {
									td_list.push(
										<tr key={"etat_vidange" + data.id_vehicule}>
											<td>Vidange</td>
											<td>{data.kilometrage} km</td>
											<td>
												<Formik
													initialValues={{ piece: "derniereVidange" }}
													onSubmit={(values) => {
														config
															.apiFetch(
																config.Api_Url + "/vehicules/fixVehicule",
																{
																	method: "POST",
																	body: JSON.stringify({
																		id_vehicule: data.id_vehicule,
																		piece: values.piece,
																		kilometrage: data.kilometrage,
																	}),
																	headers: {
																		"Content-Type": "application/json",
																	},
																}
															)
															.then((response) => response.json())
															.then((data) => {
																window.location.reload();
															});
													}}
												>
													{({
														handleSubmit,
														isSubmitting,
														/* and other goodies */
													}) => (
														<Form onSubmit={handleSubmit}>
															<Button
																size="sm"
																type="submit"
																disabled={isSubmitting}
															>
																Régler
															</Button>
														</Form>
													)}
												</Formik>
											</td>
										</tr>
									);
								}

								if (this.state.etat_Freins_AV) {
									td_list.push(
										<tr key={data.id_vehicule}>
											<td>Freins AV</td>
											<td>{data.kilometrage} km</td>
											<td>
												<Formik
													initialValues={{
														piece: "dernierRemplacementFreinsAV",
													}}
													onSubmit={(values) => {
														config
															.apiFetch(
																config.Api_Url + "/vehicules/fixVehicule",
																{
																	method: "POST",
																	body: JSON.stringify({
																		id_vehicule: data.id_vehicule,
																		piece: values.piece,
																		kilometrage: data.kilometrage,
																	}),
																	headers: {
																		"Content-Type": "application/json",
																	},
																}
															)
															.then((response) => response.json())
															.then((data) => {
																window.location.reload();
															});
													}}
												>
													{({
														handleSubmit,
														isSubmitting,
														/* and other goodies */
													}) => (
														<Form onSubmit={handleSubmit}>
															<Button
																size="sm"
																type="submit"
																disabled={isSubmitting}
															>
																Régler
															</Button>
														</Form>
													)}
												</Formik>
											</td>
										</tr>
									);
								}

								if (this.state.etat_Freins_AR) {
									td_list.push(
										<tr key={"etat_Freins_AR" + data.id_vehicule}>
											<td>Freins AR</td>
											<td>{data.kilometrage} km</td>
											<td>
												<Formik
													initialValues={{
														piece: "dernierRemplacementFreinsAR",
													}}
													onSubmit={(values) => {
														config
															.apiFetch(
																config.Api_Url + "/vehicules/fixVehicule",
																{
																	method: "POST",
																	body: JSON.stringify({
																		id_vehicule: data.id_vehicule,
																		piece: values.piece,
																		kilometrage: data.kilometrage,
																	}),
																	headers: {
																		"Content-Type": "application/json",
																	},
																}
															)
															.then((response) => response.json())
															.then((data) => {
																window.location.reload();
															});
													}}
												>
													{({
														handleSubmit,
														isSubmitting,
														/* and other goodies */
													}) => (
														<Form onSubmit={handleSubmit}>
															<Button
																size="sm"
																type="submit"
																disabled={isSubmitting}
															>
																Régler
															</Button>
														</Form>
													)}
												</Formik>
											</td>
										</tr>
									);
								}

								if (this.state.etat_disque_AV) {
									td_list.push(
										<tr key={"etat_disque_AV" + data.id_vehicule}>
											<td>Disques AR</td>
											<td>{data.kilometrage} km</td>
											<td>
												<Formik
													initialValues={{
														piece: "dernierRemplacementDisqueAV",
													}}
													onSubmit={(values) => {
														config
															.apiFetch(
																config.Api_Url + "/vehicules/fixVehicule",
																{
																	method: "POST",
																	body: JSON.stringify({
																		id_vehicule: data.id_vehicule,
																		piece: values.piece,
																		kilometrage: data.kilometrage,
																	}),
																	headers: {
																		"Content-Type": "application/json",
																	},
																}
															)
															.then((response) => response.json())
															.then((data) => {
																window.location.reload();
															});
													}}
												>
													{({
														handleSubmit,
														isSubmitting,
														/* and other goodies */
													}) => (
														<Form onSubmit={handleSubmit}>
															<Button
																size="sm"
																type="submit"
																disabled={isSubmitting}
															>
																Régler
															</Button>
														</Form>
													)}
												</Formik>
											</td>
										</tr>
									);
								}

								if (this.state.etat_disque_AR) {
									td_list.push(
										<tr key={"etat_disque_AR" + data.id_vehicule}>
											<td>Disques AR</td>
											<td>{data.kilometrage} km</td>
											<td>
												<Formik
													initialValues={{
														piece: "dernierRemplacementDisqueAR",
													}}
													onSubmit={(values) => {
														config
															.apiFetch(
																config.Api_Url + "/vehicules/fixVehicule",
																{
																	method: "POST",
																	body: JSON.stringify({
																		id_vehicule: data.id_vehicule,
																		piece: values.piece,
																		kilometrage: data.kilometrage,
																	}),
																	headers: {
																		"Content-Type": "application/json",
																	},
																}
															)
															.then((response) => response.json())
															.then((data) => {
																window.location.reload();
															});
													}}
												>
													{({
														handleSubmit,
														isSubmitting,
														/* and other goodies */
													}) => (
														<Form onSubmit={handleSubmit}>
															<Button
																size="sm"
																type="submit"
																disabled={isSubmitting}
															>
																Régler
															</Button>
														</Form>
													)}
												</Formik>
											</td>
										</tr>
									);
								}
								return <tbody key="tbody">{td_list}</tbody>;
							});
						})}
					</Table>
				</div>
			</div>
		);
	}
}

export default withRouter(AlertVehicule);
