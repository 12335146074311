/* eslint-disable import/first */
import React, { Component } from "react";
import { Table, Form, Button } from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import { withRouter } from "react-router-dom";
const dateFormatConvert = require("helpers/dateFormatConvert");
import Checkbox from "helpers/checkbox";
import { Formik } from "formik";
const getColisInfos = require("helpers/getColisInfos");

export class ColisTable extends React.Component {
	constructor(props) {
		super(props);
		this.ColisTable = this.ColisTable.bind(this);
	}
	ColisTable() {
		return (
			<div className="container-fluid">
				<Formik
					enableReinitialize={true}
					initialValues={{ checked: [], changing_state: "31" }}
					onSubmit={(values, { setSubmitting }) => {
						//Remove all existed profil's line under the same name
						config
							.apiFetch(config.Api_Url + "/tournee/addColisToTournee", {
								method: "POST",
								body: JSON.stringify({
									tournee_id: this.props.tournee_id,
									colis: values.checked,
								}),
								headers: { "Content-Type": "application/json" },
							})
							.then((response) => response.json())
							.then((response_details) => {
								this.props.history.push(
									"/admin/tournee/fiche/" + this.props.tournee_id
								);
							});
					}}
				>
					{({
						values,
						handleChange,
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<Form onSubmit={handleSubmit} autoComplete="off">
							<Table responsive bordered hover className="mt-4 mb-3">
								<thead>
									<tr>
										<th></th>
										<th>Choix</th>
										<th>N°Colis</th>
										<th>Code barre sous-traitant</th>
										<th>Expediteur</th>
										<th>Poids (Kg)</th>
										<th>Nom Prénom Destinataire</th>
										<th>Adresse Destinataire</th>
										<th>Description</th>
										<th>Valeur assurée</th>
										<th>Type colis</th>
										<th>Infos Particulières</th>
										<th>Etat</th>
										<th>Lot</th>
										<th></th>
									</tr>
								</thead>

								<tbody>
									{this.props.colis.map((dynamicData, Key) => {
										let keys = Object.keys(dynamicData);
										let d = dynamicData;
										return d[keys].map((data, index) => {
											let modify_URL = "/admin/colis/modify/" + data.colis_id;
											let etat = getColisInfos.getColisState(
												data.etatlivraison
											);
											return (
												<tr key={data.colis_id}>
													<td>{index++} </td>
													<td>
														<Checkbox name="checked" value={data.colis_id} />
													</td>
													<td className={data.statut}>{data.colis_id}</td>
													<td>{data.colis_id_ciblex}</td>
													<td>
														{data.nomExpediteur + "\n"}
														{data.prenomExpediteur}
													</td>
													<td>{data.poidscolis}</td>
													<td>
														{data.nomdest + "\n"}
														{data.prenomdest}
													</td>
													<td>
														{data.adressedest + "\n"}
														{data.codepostaldest + "\n"}
														{data.villedest}
													</td>
													<td>{data.description}</td>
													<td>{data.valeur}</td>
													<td>{data.typeEnvoi}</td>
													<td>{data.infosParticulieres}</td>
													<td>{etat}</td>
													<td>{data.estLot ? "Oui" : "Non"}</td>
													<td>
														<a href={modify_URL}>
															<i className="fas fa-pencil-alt mr-1"></i>
															Modifier
														</a>
													</td>
												</tr>
											);
										});
									})}
								</tbody>
							</Table>
							<Button
								size="sm"
								className="mb-5"
								variant="primary"
								type="submit"
								disabled={isSubmitting}
							>
								Ajouter les colis selectionnés à la tournée
							</Button>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.ColisTable />
			</div>
		);
	}
}

export default withRouter(ColisTable);
