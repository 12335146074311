import React, { Component, useState } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
	Collapse,
	Container,
} from "react-bootstrap";
import DatePickerField from "helpers/datePickerField";
import * as config from "config/config";

const dateFormatConvert = require("helpers/dateFormatConvert");
class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.Search = this.Search.bind(this);
	}

	Search() {
		// Declare a new state variable, which we'll call "count"
		const [count1, setOpen1] = useState(true);
		const [count2, setOpen2] = useState(false);
		const lesMois = config.lesMois;
		let month = [];
		for (let number = 0; number < lesMois.length; number++) {
			month.push(
				<option key={"option_month_" + number} value={number}>
					{lesMois[number]}
				</option>
			);
		}
		return (
			<div>
				<div id="search_by_date">
					<Formik
						initialValues={{
							month: 0,
							client: this.props.first_client,
						}}
						enableReinitialize={true}
						onSubmit={(values) => {
							let month = parseInt(values.month) + 1;
							this.props.history.push(
								"/public/expedition/affichageDetailExpedition/" +
									month +
									"/" +
									values.year
							);
						}}
					>
						{(props) => {
							const {
								status,
								handleChange,
								handleSubmit,
								isSubmitting,
								/* and other goodies */
							} = props;
							return (
								<Form onSubmit={handleSubmit} autoComplete="off">
									<Row>
										<Col md>
											{" "}
											<Form.Label className="required">Mois :</Form.Label>
											<Form.Control
												required
												as="select"
												name="month"
												onChange={handleChange}
											>
												{month}
											</Form.Control>
										</Col>
										<Col md>
											{" "}
											<Form.Label className="required">Année :</Form.Label>
											<Form.Control
												required
												type="number"
												aria-describedby="inputGroupPrepend"
												name="year"
												onChange={handleChange}
											></Form.Control>
										</Col>
									</Row>
									<Row>
										<Col md>
											<Button
												size="sm"
												type="submit"
												disabled={isSubmitting}
												variant="primary"
											>
												Afficher le détail des expéditions
											</Button>
										</Col>
									</Row>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		);
	}
	render() {
		return (
			<div>
				<this.Search />
			</div>
		);
	}
}

export default withRouter(SearchForm);
