function getColisState(state) {
  let etat = "";
  switch (state) {
    case "EAE":
      etat = "En attente d’enlèvement";
      break;
    case "EAL":
      etat = "En cours de livraison";
      break;
    case "C":
      etat = "Collecté";
      break;
    case "E":
      etat = "Collecté";
      break;
    case "L":
      etat = "Livré";
      break;
    case "ER":
      etat = "Endommagé";
      break;
    case "CR":
      etat = "Non commandé";
      break;
    case "CRF":
      etat = "Refusé";
      break;
    case "AV":
      etat = "Avisé";
      break;
    case "A":
      etat = "Annulé";
      break;
    case "MVAD":
      etat = "Adresse éronnée";
      break;
    case "PL":
      etat = "Partiellement livré";
      break;
    case "PR":
      etat = "Partiellement refusé";
      break;
    case "PE":
      etat = "Partiellement enlevé";
      break;
  }
  return etat;
}

function getColisReason(reason) {
  let raison = "";
  switch (reason) {
    case "RS":
      raison = "Refus de signature";
      break;
    case "EA":
      raison = "Emballage abîmé";
      break;
    case "ECMCA":
      raison = "Emballage correct mais contenu abîmé";
      break;
    case "LI":
      raison = "Lot incomplet";
      break;
    case "NC":
      raison = "Non commandé";
      break;
    case "ABL":
      raison = "Absence de bon de livraison";
      break;
    case "RF":
      raison = "Refusé";
      break;
    case "AV":
      raison = "Avisé";
      break;
    case "F":
      raison = "Fermé";
      break;
  }

  return raison;
}

function getColisReasonCss(reason) {
  let css_class_reason = "";

  switch (reason) {
    case "RS":
      css_class_reason = "cm";
      break;
    case "EA":
      css_class_reason = "cm";
      break;
    case "ECMCA":
      css_class_reason = "cm";
      break;
    case "LI":
      css_class_reason = "cm";
      break;
    case "NC":
      css_class_reason = "cm";
      break;
    case "ABL":
      css_class_reason = "cm";
      break;
    case "RF":
      css_class_reason = "cm";
      break;
    case "F":
      css_class_reason = "cm";
      break;
  }

  return css_class_reason;
}

function checkLotPrincipalId(data) {
  return data.estLot && /^\d+$/.test(data.colis_id);
}

exports.getColisState = getColisState;
exports.getColisReason = getColisReason;
exports.getColisReasonCss = getColisReasonCss;
exports.checkLotPrincipalId = checkLotPrincipalId;
