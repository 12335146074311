/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import SearchForm from "./searchForm";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");

/*Css*/
import "./Tournee.css";

export class Tournee extends React.Component {
	constructor() {
		super();
		this.state = {
			data: [],
			total: 0,
			pagination_fixed: 20,
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		if (user_role == "Admin") {
			config
				.apiFetch(
					config.Api_Url + "/tournee/liste/" + this.props.match.params.page_id
				)
				.then((response) => response.json())
				.then((tournee) => {
					this.setState({
						data: [tournee],
					});
				});
		} else {
			config
				.apiFetch(
					config.Api_Url +
						"/tourneeAgence/liste/" +
						user_id +
						"/" +
						this.props.match.params.page_id
				)
				.then((response) => response.json())
				.then((tournee) => {
					this.setState({
						data: [tournee],
					});
				});
		}

		/*
        config.apiFetch(config.Api_Url+"/tournee/total").
            then(response => response.json()).
            then(tournee => {
                
                this.setState({
                    total: [tournee["data"][0]["total"]
                ]
                });
            })
        */
	}

	render() {
		let active = parseInt(this.props.match.params.page_id);
		let classNamePagination = "";
		let items = [];
		for (let number = 1; number <= this.state.pagination_fixed; number++) {
			if (active === number) {
				classNamePagination = "page-item active";
			} else {
				classNamePagination = "page-item";
			}
			items.push(
				<li key={"pagination_" + number} className={classNamePagination}>
					<a className="page-link" href={number}>
						{number}
					</a>
				</li>
			);
		}

		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<h1>Bordereaux de livraisons</h1>

					<SearchForm />

					<Button
						className="mb-2 mt-4"
						size="sm"
						variant="outline-primary"
						type="submit"
						href="/admin/tournee/add"
					>
						<i className="fas fa-plus mr-1"></i>
						Ajouter tournée
					</Button>
					<div>
						<Table responsive bordered hover size="sm">
							<thead>
								<tr>
									<th>ID</th>
									<th>Date</th>
									<th>Numéro</th>
									<th>Livreur</th>
									<th>Etat</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{this.state.data.map((dynamicData, Key) => {
									let keys = Object.keys(dynamicData);
									let d = dynamicData;
									return d[keys].map((data) => {
										let fiche_url = "/admin/tournee/fiche/" + data.tournee_id;
										let delete_url = "/admin/tournee/delete/" + data.tournee_id;
										let modify_url = "/admin/tournee/modify/" + data.tournee_id;
										let etat = "";
										if (data.etathex == 32) {
											etat = "Terminée";
										} else {
											etat = "Non terminée";
										}
										return (
											<tr key={data.tournee_id}>
												<td className={data.statut}>{data.tournee_id}</td>
												<td>
													{dateFormatConvert.getFormattedDate(data.datetournee)}
												</td>
												<td>{data.zonetournee}</td>
												<td>
													{data.nom + " "}
													{data.prenom}
												</td>
												<td>{etat}</td>
												<td>
													<a href={fiche_url}>
														<i className="fas fa-file-alt"></i>
														Bordereau de livraison
													</a>
													<a href={delete_url}>
														<i className="fas fa-trash-alt"></i>
														Supprimer
													</a>
													<a href={modify_url}>
														<i className="fas fa-pencil-alt"></i>
														Modifier
													</a>
												</td>
											</tr>
										);
									});
								})}
							</tbody>
						</Table>
						<div>
							<nav aria-label="Page navigation example">
								<ul className="pagination">{items}</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Tournee);
