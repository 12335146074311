/* eslint-disable import/first */
import React, { Component } from "react";
import NavPublic from "components/nav/NavPublic";
import { Route, withRouter } from "react-router-dom";
import logo from "asset/images/citycolis-holding-logo.svg";
import { Container } from "react-bootstrap";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");

var Barcode = require("react-barcode");
/*CSS*/
import "./imprimerEtiquette.scss";
import EtiquetteThermique from "./etiquetteThermique";
import EtiquetteNormal from "./etiquetteNormal";

export class ImprimerEtiquetteLotPrincipal extends React.Component {
	constructor() {
		super();
		this.state = {
			demande: [],
			colis: [],
			expediteur: "",
			telephone: "",
			datedemande: "",
			lot_package_total: 0,
			messages: "",
			colisUPS: [],
			formatEtiquettes: "",
		};
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}

		this.state.formatEtiquettes = localStorage.getItem("formatEtiquettes")
			? localStorage.getItem("formatEtiquettes")
			: "normal";

		config
			.apiFetch(
				config.Api_Url +
					"/demande/getGroupementColis/" +
					this.props.match.params.groupement_id
			)
			.then((response) => response.json())
			.then((demande) => {
				if (demande["data"].length != 0) {
					config
						.apiFetch(
							config.Api_Url +
								"/demande/getColisbyGroupementID/" +
								this.props.match.params.groupement_id
						)
						.then((response) => response.json())
						.then((colis) => {
							let colisPrincipal = [];
							let data = colis["data"].filter(function (c, index) {
								return index === 0;
							});
							colisPrincipal["data"] = data;
							this.setState({
								colis: [colisPrincipal],
								lot_package_total: colis["data"].length - 1,
							});
							this.sleep(2000).then(() => {
								window.print();
							});
						});
					let src =
						"data:image/jpg;base64," +
						new Buffer(demande["data"][0].agence_logo).toString("base64");
					this.setState({
						expediteur:
							demande["data"][0].nomExpediteur +
							" " +
							demande["data"][0].prenomExpediteur,
						telephone: demande["data"][0].telephoneExpediteur,
						datedemande: dateFormatConvert.getFormattedDate(
							demande["data"][0].datedemande
						),
						logo: (
							<img src={src} style={{ maxHeight: 150, maxWidth: 150 }}></img>
						),
						agence_address: demande["data"][0].agence_address,
						agence_phone: demande["data"][0].agence_phone,
						agence_name: demande["data"][0].agence_name,
						agence_ville: demande["data"][0].agence_ville,
					});
				} else {
					this.props.history.push("/404");
				}
			});
	}

	render() {
		return (
			<div>
				<NavPublic />
				<Container>
					{this.state.formatEtiquettes == "thermique" ? (
						<EtiquetteThermique
							logo={this.state.logo}
							colis={this.state.colis}
							agence_name={this.state.agence_name}
							agence_address={this.state.agence_address}
							agence_ville={this.state.agence_ville}
							agence_phone={this.state.agence_phone}
							expediteur={this.state.expediteur}
							telephone={this.state.telephone}
							datedemande={this.state.datedemande}
							lot_package_total={this.state.lot_package_total}
						/>
					) : (
						<EtiquetteNormal
							logo={this.state.logo}
							colis={this.state.colis}
							agence_name={this.state.agence_name}
							agence_address={this.state.agence_address}
							agence_ville={this.state.agence_ville}
							agence_phone={this.state.agence_phone}
							expediteur={this.state.expediteur}
							telephone={this.state.telephone}
							datedemande={this.state.datedemande}
							lot_package_total={this.state.lot_package_total}
						/>
					)}
					{this.state.colisUPS.map((dynamicData, Key) => {
						let keys = Object.keys(dynamicData);
						let d = dynamicData;
						return d[keys].map((data) => {
							let imagePath = "data:image/jpg;base64," + data.upse_etiquette;

							return (
								<img
									key={data.upse_c_id}
									className="ups-etiquette"
									src={imagePath}
								/>
							);
						});
					})}
				</Container>
			</div>
		);
	}
}

export default withRouter(ImprimerEtiquetteLotPrincipal);
