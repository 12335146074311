export function isPartially(status, colisByGroupement, estLot) {
  let colis = []
  if (estLot) {
    colis = colisByGroupement[0]?.lots
  } else {
    colis = colisByGroupement[0]?.colis
  }
  switch (status) {
    case "collected":
      return (
        colis?.filter((x) => x.etatlivraison === "EAE")
          .length > 0 &&
        colis?.filter((x) => x.etatlivraison === "EAE")
          .length < colis.length
      );
    case "delivered":
      return (
        colis?.filter((x) => x.etatlivraison === "L")
          .length > 0 &&
        colis?.filter((x) => x.etatlivraison === "L")
          .length < colis.length
      );
    case "EAL":
      return (
        colis?.filter((x) => x.etatlivraison === "EAL")
          .length > 0 &&
        colis?.filter((x) => x.etatlivraison === "EAL")
          .length < colis.length &&
        colis?.filter((x) => x.etatlivraison === "L")
          .length === 0
      );
  }
}
