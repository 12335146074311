/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router-dom";
import DatePickerFieldRequired from "helpers/datePickerFieldRequired";
import * as config from "config/config";
import ColisTable from "./colisTable";
import { Formik } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import NavAdmin from "components/nav/NavAdmin";

class Colis extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: this.props.match.params.start,
			endDate: this.props.match.params.end,
			codepostaldest: "",
			isSubmitting: false,
			message: " ",
		};
	}

	componentDidMount() {
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		let endDate = this.props.match.params.end;
		let starDate = this.props.match.params.start;
		let id_client = this.props.match.params.id_client;
		if (user_role == "Admin") {
			config
				.apiFetch(config.Api_Url + "/clients")
				.then((response) => response.json())
				.then((clients) => {
					let clients_list = [];
					for (let number = 0; number < clients["data"].length; number++) {
						clients_list.push(
							<option
								key={"clients_" + number}
								value={clients["data"][number]["id_client"]}
							>
								{clients["data"][number]["nom"]}{" "}
								{clients["data"][number]["prenom"]}
							</option>
						);
					}
					this.setState({
						clients_list: [clients_list],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((agence) => {
					this.setState({
						agence: agence["data"][0],
					});

					config
						.apiFetch(
							config.Api_Url + "/clients/agence/" + this.state.agence.agence_id
						)
						.then((response) => response.json())
						.then((clients) => {
							let clients_list = [];
							for (let number = 0; number < clients["data"].length; number++) {
								clients_list.push(
									<option
										key={"clients_" + number}
										value={clients["data"][number]["id_client"]}
									>
										{clients["data"][number]["nom"]}{" "}
										{clients["data"][number]["prenom"]}
									</option>
								);
							}
							this.setState({
								clients_list: [clients_list],
							});
						})
						.catch((err) => {
							console.log(err);
							// some error handling
						});
				});
		}
		config
			.apiFetch(
				config.Api_Url +
					"/colis/getColisbyDateAndClient/" +
					starDate +
					"/" +
					endDate +
					"/" +
					id_client,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					colis: [data],
				});
			});
	}

	handleChange = (date) => {
		this.setState({
			startDate: date,
		});
	};

	submit(values, actions) {
		console.log("ok");
		let startDate =
			values.startDate.getFullYear() +
			"-" +
			(values.startDate.getMonth() + 1) +
			"-" +
			values.startDate.getDate();
		let endDate =
			values.endDate.getFullYear() +
			"-" +
			(values.endDate.getMonth() + 1) +
			"-" +
			values.endDate.getDate();
		this.setState({
			startDate: startDate,
			endDate: endDate,
			isSubmitting: true,
		});

		config
			.apiFetch(
				config.Api_Url +
					"/colis/getColisbyDateAndClient/" +
					startDate +
					"/" +
					endDate +
					"/" +
					values.id_client,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => response.json())
			.then((data) => {
				if (data["data"].length == 0) {
					this.setState({
						message: "Aucun colis à afficher. Précisez votre recherche.",
						isSubmitting: false,
					});
				} else if (0 < data["data"].length && data["data"].length > 200) {
					this.setState({
						message:
							"Votre recherche comporte trop de résultats, nous vous invitons à préciser votre demande",
						isSubmitting: false,
					});
				} else {
					this.setState({
						message: "",
						colis: [data],
						isSubmitting: false,
					});
				}
			});
	}

	render() {
		if (this.state.message == "") {
			return (
				<div>
					<NavAdmin />
					<div className="container">
						<h1>Rechercher des colis à ajouter à cette tournée</h1>

						<Formik
							initialValues={{
								startDate: "",
								endDate: "",
								id_client: "",
							}}
							onSubmit={(values, actions) => {
								this.submit(values, actions);
							}}
						>
							{(props) => {
								const {
									values,
									status,
									handleChange,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Row>
											<Col lg={3}>
												<Form.Label className="required">
													Période du :{" "}
												</Form.Label>
												<DatePickerFieldRequired
													name="startDate"
													className="form-control"
												/>
											</Col>
											<Col lg={3}>
												{" "}
												<Form.Label className="required">Au :</Form.Label>
												<DatePickerFieldRequired
													name="endDate"
													className="form-control"
												/>
											</Col>
											<Col lg={3}>
												<Form.Group controlId="formBasicCheckbox">
													<Form.Label className="required">Client :</Form.Label>
													<Form.Control
														as="select"
														name="id_client"
														onChange={handleChange}
														required
													>
														<option></option>
														{this.state.clients_list}
													</Form.Control>
												</Form.Group>
											</Col>
										</Row>
										<Col lg={3} className="d-flex align-items-end">
											<Button
												size=""
												className=""
												type="submit"
												disabled={this.state.isSubmitting}
												variant="primary"
											>
												Rechercher
											</Button>
										</Col>
									</Form>
								);
							}}
						</Formik>
					</div>
					<ColisTable
						startDate={this.state.startDate}
						endDate={this.state.endDate}
						colis={this.state.colis}
						tournee_id={this.props.match.params.tournee_id}
					/>
				</div>
			);
		} else {
			return (
				<div>
					<NavAdmin />
					<div className="container">
						<h1>Rechercher des colis à ajouter à cette tournée</h1>

						<Formik
							initialValues={{
								startDate: "",
								endDate: "",
								id_client: "",
							}}
							onSubmit={(values, actions) => {
								this.submit(values, actions);
							}}
						>
							{(props) => {
								const {
									values,
									status,
									handleChange,
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								} = props;
								return (
									<Form onSubmit={handleSubmit} autoComplete="off">
										<Row>
											<Col lg={3}>
												<Form.Label className="required">
													Période du :{" "}
												</Form.Label>
												<DatePickerFieldRequired
													name="startDate"
													className="form-control"
												/>
											</Col>
											<Col lg={3}>
												{" "}
												<Form.Label className="required">Au :</Form.Label>
												<DatePickerFieldRequired
													name="endDate"
													className="form-control"
												/>
											</Col>
											<Col lg={3}>
												<Form.Group controlId="formBasicCheckbox">
													<Form.Label className="required">Client :</Form.Label>
													<Form.Control
														as="select"
														name="id_client"
														onChange={handleChange}
													>
														<option></option>
														{this.state.clients_list}
													</Form.Control>
												</Form.Group>
											</Col>
										</Row>
										<Col className="d-flex align-items-end">
											<Button
												size=""
												className=""
												type="submit"
												disabled={this.state.isSubmitting}
												variant="primary"
											>
												Rechercher
											</Button>
										</Col>
									</Form>
								);
							}}
						</Formik>
						<div className="text-center mt-5">{this.state.message}</div>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(Colis);
