/* eslint-disable import/first */
import React, { Component } from "react";
import { Formik, CheckboxField, Field } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	Alert,
	InputGroup,
} from "react-bootstrap";
import * as config from "config/config";
import { NavAdmin } from "components/nav/NavAdmin";
import DatePickerField from "helpers/datePickerField";
import DynamicGuarantyForm from "./dynamicGuarantyForm";
import { Typeahead } from "react-bootstrap-typeahead";
const dateFormatConvert = require("helpers/dateFormatConvert");
const OffSiteAgencyHelper = require("helpers/getOffSiteAgency");
const upsService = require("helpers/upsService");
const getDateReleve = require("helpers/getDateReleve");
/*CSS*/
import "./addDemande.css";

class formDemande extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clients: [],
			child_array: [],
			extra_fee_array: [],
			assure_states: [],
			adresses: [],
			poidscolis: [],
			typehead: [],
			typeheadCode: [],
			array_added_colis: [],
			demande: {
				type: "",
				typeEnvoi: "",
				infosParticulieres: "",
				date: "",
				nom: "",
				prenom: "",
				ville: "",
				codepostal: "",
				adresse: "",
				telephone: "",
				nomdest: "",
				prenomdest: "",
				adressedest: "",
				codepostaldest: "",
				villedest: "",
				telephonedest: "",
				agence_origine: "",
				agence_cible: "",
				codesociete: "",
			},
			horssite: false,
			alertState: false,
			groupement_alert: "",
			agences: [],
			data: [],
			print_button_label: "En chargement, merci de patienter",
			print_small_button_label: "Imprimer les petites étiquettes 10*4",
			new_groupement_id: "",
			print_button_isdisable: true,
			print_small_button_hide: true,
			casUPS: "",
			upsService: "",
			nom: "",
			prenom: "",
			adresse: "",
			ville: "",
			codepostal: "",
			telephone: "",
			colis_loading: 0,
		};
	}
	componentDidMount() {
		let id_client = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_old_client_id
			? JSON.parse(localStorage.getItem("currentUser"))["data"][0]
					.aut_old_client_id
			: JSON.parse(localStorage.getItem("currentUser"))["data"][0].aut_id;
		this.setState({ id_client: id_client });
		config
			.apiFetch(config.Api_Url + "/adresse/getAdresseByClient/" + id_client)
			.then((response) => {
				if (response.status != 200) {
					window.location.reload();
				}
				return response.json();
			})
			.then((adresses) => {
				let typehead = [];
				let typeheadCode = [];
				for (let number = 0; number < adresses["data"].length; number++) {
					if (adresses["data"][number]["codesociete"]) {
						typeheadCode.push(adresses["data"][number]["codesociete"]);
					}
					typehead.push(adresses["data"][number]["nomdest"]);
				}
				this.setState({
					adresses: adresses["data"],
					typehead: typehead,
					typeheadCode: typeheadCode,
				});
			});
		config
			.apiFetch(config.Api_Url + "/findbyid/client/" + id_client)
			.then((response) => response.json())
			.then((data) => {
				let datereleve = getDateReleve.getDateReleve();
				this.setState((prevDemande) => ({
					demande: {
						...prevDemande.demande,
						nom: data["data"][0].nom,
						prenom: data["data"][0].prenom,
						adresse: data["data"][0].adresse,
						ville: data["data"][0].ville,
						codepostal: data["data"][0].codepostal,
						telephone: data["data"][0].telephone,
						date: datereleve,
						type: "Colis",
						typeEnvoi: "Normal",
						infosParticulieres: "",
					},
					casUPS: data["data"][0].casUPS,
					upsService: data["data"][0].serviceCode,
					nom: data["data"][0].nom,
					prenom: data["data"][0].prenom,
					adresse: data["data"][0].adresse,
					ville: data["data"][0].ville,
					codepostal: data["data"][0].codepostal,
					telephone: data["data"][0].telephone,
					date: datereleve,
					department: "",
				}));
			});
		config
			.apiFetch("/getAgenceByClientAccount/" + id_client)
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					data: data["data"][0],
				});
				config
					.apiFetch("/agences")
					.then((response) => response.json())
					.then((data) => {
						let agences = [];
						for (let number = 0; number < data["data"].length; number++) {
							if (
								this.state.data.agence_id != data["data"][number]["agence_id"]
							) {
								agences.push(
									<option
										key={"option_agences_" + number}
										value={data["data"][number]["agence_id"]}
									>
										{data["data"][number]["agence_name"]}
									</option>
								);
							}
						}
						this.setState({
							agences: [agences],
						});
					});
			});
	}
	setStateNumber(number) {
		let x = 0;
		let array = [];
		let assure_states = [];
		let extra_fee_array = [];
		let poidscolis = [];
		let values = [];
		/*let poidscolis = [
      ...this.state.poidscolis
    ];*/
		for (x; x < number; x++) {
			array.push(x);
			this.state.poidscolis && this.state.poidscolis[x]
				? poidscolis.push(this.state.poidscolis[x])
				: poidscolis.push(1);
			this.state.assure_states && this.state.assure_states[x]
				? assure_states.push(this.state.assure_states[x])
				: assure_states.push(false);
			this.state.extra_fee_array && this.state.extra_fee_array[x]
				? extra_fee_array.push(this.state.extra_fee_array[x])
				: extra_fee_array.push(0);
			this.state.values && this.state.values[x]
				? values.push(this.state.values[x])
				: values.push(0);
		}
		this.setState({ child_array: array });
		this.setState({ assure_states: assure_states });
		this.setState({ extra_fee_array: extra_fee_array });
		this.setState({ poidscolis: poidscolis });
		this.setState({ values: values });
	}

	toggleHorsSite() {
		this.setState({ horssite: !this.state.horssite });
	}

	print_etiquette() {
		console.log("this.state.department:", this.state.department);
		console.log("this.state.casUPS:", this.state.casUPS);

		//TODO UPS
		if (
			this.state.casUPS == "UPS" &&
			this.state.department != 61 &&
			this.state.department != 72
		) {
			window.open(
				"/public/demande/imprimeretiquetteUps/" + this.state.new_groupement_id
			);
		} else {
			window.open(
				"/public/demande/imprimeretiquette/" + this.state.new_groupement_id
			);
		}
	}

	render() {
		const extraFields = this.state.child_array.map((num) => ({
			[`poidscolis${num}`]: "",
			[`valeur${num}`]: "",
			[`assure_state${num}`]: true,
		}));
		const normalFields = {};
		let datereleve = getDateReleve.getDateReleve();

		return (
			<Formik
				initialValues={Object.assign(
					this.state.demande,
					...extraFields,
					...this.state.poidscolis
				)}
				enableReinitialize
				onSubmit={(values, { setSubmitting }) => {
					//write new groupementcolis
					if (!values.date) {
						let datereleve = getDateReleve.getDateReleve();
						values.date = datereleve;
					}

					config
						.apiFetch(config.Api_Url + "/new/demande", {
							method: "POST",
							body: JSON.stringify({
								id_client: this.state.id_client,
								datedemande: dateFormatConvert.convertDateTimeForSql(
									new Date()
								),
								datereleve: dateFormatConvert.convertDateTimeForSql(
									values.date
								),
								nomExpediteur: values.nom,
								prenomExpediteur: values.prenom,
								adresseExpediteur: values.adresse.replace(/'/g, "\\'"),
								codepostalExpediteur: values.codepostal,
								villeExpediteur: values.ville,
								telephoneExpediteur: values.telephone,
								etat: 30,
								agence_origine: this.state.horssite
									? this.state.data.agence_id
									: null,
								agence_cible: this.state.horssite
									? OffSiteAgencyHelper.getOffSiteAgency(
											values.codepostal,
											this.state.data.agence_id
									  )
									: null,
							}),
							headers: {
								"Content-Type": "application/json",
							},
						})
						.then((response) => response.json())
						.then((data) => {
							//once we got the id of the groupementcolis, we add colis into it
							if (data["data"].insertId) {
								this.setState({
									groupement_alert:
										"Vous avez bien déposé la demande d'enlèvement numéro : " +
										data["data"].insertId +
										".",
									new_groupement_id: data["data"].insertId,
								});
								//go on top after submitted
								//so the client can see the notification
								window.scrollTo(0, 0);
								let loading = 0;
								let counter = 0;
								let counter_created_colis = 0;
								let array_added_colis = [];
								let array_c_id_colis = [];
								this.state.child_array.forEach((num, index) => {
									let colis_info =
										"'" +
										this.state.poidscolis[index] +
										"','" +
										values.nomdest +
										"'," +
										"'" +
										values.prenomdest +
										"','" +
										values.adressedest.replace(/'/g, "\\'") +
										"'," +
										"'" +
										values.codepostaldest +
										"','" +
										values.villedest +
										"'," +
										"'" +
										values.telephonedest +
										"','','EAE','','" +
										values.type +
										"','" +
										values.infosParticulieres +
										"'," +
										"'" +
										this.state.values[index] +
										"','0',NULL,'" +
										values.typeEnvoi +
										"','0','" +
										this.state.values[index] +
										"','','0'";
									setTimeout(() => {
										config
											.apiFetch(config.Api_Url + "/add/addColisFromPublic", {
												method: "POST",
												body: JSON.stringify({
													groupement_id: data["data"].insertId,
													poidscolis: this.state.poidscolis[index],
													nomdest: values.nomdest,
													prenomdest: values.prenomdest,
													adressedest: values.adressedest,
													codepostaldest: values.codepostaldest,
													villedest: values.villedest,
													telephonedest: values.telephonedest,
													type: values.type,
													infosParticulieres: values.infosParticulieres,
													values: this.state.values[index],
													typeEnvoi: values.typeEnvoi,
												}),

												headers: {
													"Content-Type": "application/json",
												},
											})
											.then((response) => response.json())
											.then((data) => {
												counter_created_colis++;

												//we finished adding colis
												if (
													counter_created_colis == this.state.poidscolis.length
												) {
													//TO DO PROD UPSCASE
													//if this is an order using Ups service, we need to call their Api
													//Get the
													this.setState({
														department: values.codepostaldest.substring(0, 2),
													});

													if (
														this.state.casUPS == "UPS" &&
														this.state.department != 61 &&
														this.state.department != 72
													) {
														let upsJson = upsService.createUpsShippementJson(
															values,
															this.state.poidscolis,
															this.state.upsService,
															this.state.new_groupement_id
														);

														upsService
															.UpsShip(upsJson)
															.then((response) => response.json())
															.then((data) => {
																if (data["ShipmentResponse"]) {
																	let upsCode_array = [];
																	let packagesUps =
																		data["ShipmentResponse"]["ShipmentResults"][
																			"PackageResults"
																		];
																	let counter_ups = 0;
																	//when Posting to Ups Api, if the order only has 1 package, it returns an object and not a array of object
																	//so we need to convert it to array
																	if (!packagesUps.length) {
																		packagesUps = [packagesUps];
																	}
																	packagesUps.forEach((pu, index) => {
																		upsService
																			.UpsAddColisAndEtiquette(
																				array_c_id_colis[index],
																				pu.TrackingNumber,
																				pu["ShippingLabel"].GraphicImage
																			)
																			.then((response) => response.json())
																			.then((data) => {
																				counter_ups++;
																				if (counter_ups == packagesUps.length) {
																					//Display the button
																					this.setState({
																						print_small_button_hide: false,
																						print_button_isdisable: false,
																						print_button_label:
																							"Imprimer les étiquettes UPS",
																					});
																				}
																			});
																	});
																}
															});
													} else {
														this.setState({
															//print_small_button_hide: false,
															print_button_isdisable: false,
															print_button_label: "Imprimer les étiquettes",
														});
													}
												}
												if (data["colis_id"] && data["c_id"]) {
													array_c_id_colis.push(data["c_id"]);
												}
												loading = Math.round(
													(parseInt(counter_created_colis) /
														parseInt(this.state.child_array.length)) *
														100
												);
												this.setState({
													colis_loading: loading,
												});
											});
									}, 1000 * counter++);
									this.setState({
										alertState: true,
									});
								});
							}
						})
						.catch((err) => {
							console.log("err:", err);
							this.setState({
								groupement_alert:
									"Une erreur est survenue, merci de réessayer plus tard",
							});

							this.setState({
								alertState: true,
							});
						});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					getFieldValue,
					/* and other goodies */
				}) => (
					<Form onSubmit={handleSubmit}>
						<h2>Le destinataire</h2>
						<Alert
							className={!this.state.alertState ? "hidden" : "alert-info"}
							key={"alert"}
						>
							<Row>
								<Col>
									{this.state.groupement_alert}
									<br></br>
									{this.state.colis_loading} %
								</Col>
								<Col>
									<Button
										disabled={this.state.print_button_isdisable}
										onClick={(e) => {
											this.print_etiquette();
										}}
									>
										{this.state.print_button_label}
									</Button>
								</Col>
							</Row>
						</Alert>
						<Row>
							<Col lg={4}>
								<Row>
									{" "}
									<Col lg={12}>
										{" "}
										<Form.Label className="">Adresses</Form.Label>
									</Col>
									<Col lg={12}>
										<InputGroup>
											<Typeahead
												id="my-typeahead-id"
												labelKey="adresseTypeHead"
												value={values.adresse}
												multiple={false}
												options={this.state.typehead}
												placeholder="Recherche Dynamique..."
												onChange={(selected) => {
													setFieldValue("adresseTypeHead", selected);
													this.setState({ nomdest: selected[0] });
												}}
												onInputChange={(text, event) =>
													setFieldValue("adresseTypeHead", text)
												}
											/>
											<InputGroup.Append>
												<Button
													variant="outline-secondary"
													disabled={!this.state.nomdest}
													onClick={() => {
														let adresse_index = this.state.adresses.findIndex(
															(a) => a.nomdest == this.state.nomdest
														);
														this.setState((prevDemande) => ({
															demande: {
																...prevDemande.demande,
																adressedest: this.state.adresses[adresse_index]
																	.adressedest,
																nomdest: this.state.adresses[adresse_index]
																	.nomdest,
																prenomdest: this.state.adresses[adresse_index]
																	.prenomdest,
																codepostaldest: this.state.adresses[
																	adresse_index
																].codepostaldest,
																villedest: this.state.adresses[adresse_index]
																	.villedest,
																telephonedest: this.state.adresses[
																	adresse_index
																].telephonedest,
																codesociete: this.state.adresses[adresse_index]
																	.codesociete,
																codepostal: values.codepostal,
																nom: values.nom,
																prenom: values.prenom,
																adresse: values.adresse,
																telephone: values.telephone,
																ville: values.ville,
															},
														}));
													}}
												>
													<i className="fas fa-search"></i>
												</Button>
												<Button
													disabled={!this.state.nomdest}
													variant="outline-secondary"
													className=""
													onClick={(e) => {
														let adresse_index = this.state.adresses.findIndex(
															(a) => a.nomdest == this.state.nomdest
														);
														this.props.history.push(
															"/public/adresse/modify/" +
																this.state.adresses[adresse_index].a_id
														);
													}}
												>
													<i className="far fa-edit"></i>
												</Button>
											</InputGroup.Append>
										</InputGroup>
									</Col>
								</Row>
								<Row>
									<Col lg={12}>
										{" "}
										<Form.Label className="">Code société</Form.Label>
									</Col>
									<Col lg={12}>
										<InputGroup>
											<Typeahead
												id="my-typeaheadCode-id"
												labelKey="codeSocieteTypeHead"
												value={values.codesociete}
												multiple={false}
												options={this.state.typeheadCode}
												placeholder="Recherche Dynamique..."
												onChange={(selected) => {
													setFieldValue("codeSocieteTypeHead", selected);
													this.setState({ codesociete: selected[0] });
												}}
												onInputChange={(text, event) =>
													setFieldValue("codeSocieteTypeHead", text)
												}
											/>
											<InputGroup.Append>
												<Button
													variant="outline-secondary"
													disabled={!this.state.codesociete}
													onClick={() => {
														let adresse_index = this.state.adresses.findIndex(
															(a) => a.codesociete == this.state.codesociete
														);
														this.setState((prevDemande) => ({
															demande: {
																...prevDemande.demande,
																adressedest: this.state.adresses[adresse_index]
																	.adressedest,
																nomdest: this.state.adresses[adresse_index]
																	.nomdest,
																prenomdest: this.state.adresses[adresse_index]
																	.prenomdest,
																codepostaldest: this.state.adresses[
																	adresse_index
																].codepostaldest,
																villedest: this.state.adresses[adresse_index]
																	.villedest,
																telephonedest: this.state.adresses[
																	adresse_index
																].telephonedest,
																codesociete: this.state.adresses[adresse_index]
																	.codesociete,
																codepostal: values.codepostal,
																nom: values.nom,
																prenom: values.prenom,
																adresse: values.adresse,
																telephone: values.telephone,
																ville: values.ville,
															},
														}));
													}}
												>
													<i className="fas fa-search"></i>
												</Button>
												<Button
													disabled={!this.state.codesociete}
													variant="outline-secondary"
													className=""
													onClick={async (e) => {
														let adresse_index = await this.state.adresses.findIndex(
															(a) => a.codesociete == this.state.codesociete
														);
														if (this.state.adresses[adresse_index]) {
															this.props.history.push(
																"/public/adresse/modify/" +
																	this.state.adresses[adresse_index].a_id
															);
														}
													}}
												>
													<i className="far fa-edit"></i>
												</Button>
											</InputGroup.Append>
										</InputGroup>
									</Col>
								</Row>

								<Row>
									<Col className="d-flex  mt-3">
										<Button
											size="sm"
											variant="secondary"
											className="mx-2"
											href="/public/adresse/add"
										>
											Ajouter
										</Button>
										{/*
										<Button
											disabled={!this.state.nomdest}
											size="sm"
											variant="secondary"
											className=""
											onClick={(e) => {
												this.props.history.push(
													"/public/adresse/modify/" + this.state.nomdest
												);
											}}
										>
											Modifier
										</Button>
										*/}
									</Col>
								</Row>
							</Col>
							<Col lg={4}>
								<Form.Label className="">Code société</Form.Label>
								<Form.Control
									type="text"
									aria-describedby="inputGroupPrepend"
									name="codesociete"
									value={values.codesociete}
									onChange={handleChange}
								/>
								<Form.Label className="required">Nom ou société</Form.Label>
								<Form.Control
									required
									type="text"
									aria-describedby="inputGroupPrepend"
									name="nomdest"
									value={values.nomdest || ""}
									onChange={handleChange}
								/>
								<Row>
									<Col lg={6}>
										{" "}
										<Form.Label className="">Prénom</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="prenomdest"
											value={values.prenomdest || ""}
											onChange={handleChange}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg={4}>
								<Form.Label className="required">Adresse</Form.Label>
								<Form.Control
									required
									type="text"
									aria-describedby="inputGroupPrepend"
									name="adressedest"
									value={values.adressedest || ""}
									onChange={handleChange}
								/>
								<Row>
									<Col lg={6}>
										{" "}
										<Form.Label className="required">Code Postal</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="codepostaldest"
											value={values.codepostaldest || ""}
											onChange={handleChange}
										/>
									</Col>
									<Col lg={6}>
										{" "}
										<Form.Label className="required">Ville</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="villedest"
											value={values.villedest || ""}
											onChange={handleChange}
										/>
									</Col>
								</Row>

								<Form.Label className="">Téléphone</Form.Label>
								<Form.Control
									type="text"
									aria-describedby="inputGroupPrepend"
									name="telephonedest"
									value={values.telephonedest || ""}
									onChange={handleChange}
								/>
							</Col>
						</Row>

						<Row></Row>

						<Row>
							<Col>
								<h2 className="">Informations colis</h2>

								{/* Boîte grise */}
								<div className="zone-informations-colis p-3 bg-grey">
									<Row>
										<Col lg={3}>
											<h3>Nombre de colis</h3>
										</Col>
										<Col lg={2}>
											<Form.Control
												required
												type="number"
												min={1}
												aria-describedby="inputGroupPrepend"
												name="number"
												value={values.number}
												onChange={(e) => {
													this.setStateNumber(e.target.value);
												}}
											/>
										</Col>
									</Row>
									{this.state.child_array.map((child_array) => (
										<div key={"dynamic_" + child_array}>
											<Row>
												<Col md={3}>
													<Form.Label>
														<strong className="text-uppercase">
															Poids du colis {child_array + 1} (kg)
														</strong>
													</Form.Label>
													<Form.Control
														type="number"
														placeholder={`Poids (kg)`}
														name="poidscolis"
														value={this.state.poidscolis[child_array]}
														onChange={(e) => {
															let poidscolis = [...this.state.poidscolis];
															// 2. Make a shallow copy of the p we want to mutate
															let p = {
																...poidscolis[child_array],
															};
															// 3. Replace the p we're intested in
															p = e.target.value;
															// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
															poidscolis[child_array] = p;
															// 5. Set the state to our new copy
															this.setState({ poidscolis: poidscolis });
														}}
													/>
												</Col>

												<Col md={3}>
													<Form.Group className="checkbox-assurer-colis">
														<Form.Check
															type="checkbox"
															label="Assurer ce colis"
															value={this.state.assure_states[child_array]}
															name="assure_state"
															onChange={(e) => {
																// 1. Make a shallow copy of the assure_states
																let assure_states = [
																	...this.state.assure_states,
																];
																// 2. Make a shallow copy of the assure_state we want to mutate
																let assure_state = {
																	...assure_states[child_array],
																};
																// 3. Replace the assure_state we're intested in
																assure_state = e.target.checked;
																// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
																assure_states[child_array] = assure_state;
																// 5. Set the state to our new copy
																this.setState({ assure_states: assure_states });
															}}
														/>
													</Form.Group>
													<Form.Control
														disabled={!this.state.assure_states[child_array]}
														type="number"
														value={this.state.value}
														onChange={(e) => {
															setFieldValue(
																`valeur${child_array}`,
																e.target.value
															);
															// 1. Make a shallow copy of the assure_states
															let extra_fee_array = [
																...this.state.extra_fee_array,
															];
															// 2. Make a shallow copy of the assure_state we want to mutate
															let extra_fee = {
																...extra_fee_array[child_array],
															};
															// 3. Replace the assure_state we're intested in
															extra_fee = e.target.value * 0.075;
															// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
															extra_fee_array[child_array] = extra_fee;
															// 5. Set the state to our new copy
															this.setState({
																extra_fee_array: extra_fee_array,
															});
															// 1. Make a shallow copy of the values
															let values = [...this.state.values];
															// 2. Make a shallow copy of the value we want to mutate
															let value = {
																...values[child_array],
															};
															// 3. Replace the value we're intested in
															value = e.target.value;
															// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
															values[child_array] = value;
															// 5. Set the state to our new copy
															this.setState({
																values: values,
															});
															handleChange(`valeur${child_array}`);
														}}
														name="valeur"
														placeholder={`Valeur déclarée (€)`}
													/>
												</Col>

												<Col md={3}>
													<em
														className={
															!this.state.assure_states[child_array]
																? "hidden"
																: ""
														}
													>
														Supplément :{" "}
														{this.state.extra_fee_array[child_array]}€
													</em>
												</Col>
											</Row>
										</div>
									))}
								</div>

								<Row className="mt-4">
									<Col md={4}>
										<Form.Label className="">
											Conditionnement de votre envoi
										</Form.Label>
										<Form.Control
											as="select"
											name="type"
											value={values.type}
											onChange={(e) => {
												this.setState((prevDemande) => ({
													demande: {
														...prevDemande.demande,
														type: e.target.value,
													},
												}));
												handleChange(e);
											}}
										>
											<option>Colis</option>
											<option>Palette</option>
										</Form.Control>
									</Col>
									<Col md={4}>
										<Form.Label className="">Type de votre envoi</Form.Label>
										<Form.Control
											as="select"
											name="typeEnvoi"
											value={values.typeEnvoi}
											onChange={(e) => {
												this.setState((prevDemande) => ({
													demande: {
														...prevDemande.demande,
														typeEnvoi: e.target.value,
													},
												}));
												handleChange(e);
											}}
										>
											<option>Normal</option>
											<option>Zone Z1</option>
										</Form.Control>
									</Col>
									<Col md={4}>
										<Form.Label className="">
											Informations complémentaires de livraison
										</Form.Label>
										<Form.Control
											type="text"
											aria-describedby="inputGroupPrepend"
											name="infosParticulieres"
											value={values.infosParticulieres || ""}
											onChange={(e) => {
												handleChange(e);
											}}
										/>
									</Col>
								</Row>

								<Row>
									<Col className="d-flex flex-column justify-content-center align-items-center">
										<div className="expediteur-releve-prevue text-center mt-3">
											{/* TODO : date, frais fixe d'enlèvement hors site et lien bouton */}
											Relève prévue le{" "}
											{dateFormatConvert.getFormattedDate(new Date())}
											<br></br>
											<Row>
												<Col
													xs={6}
													style={{
														fontWeight: 500,
													}}
												>
													<div>
														{"Expéditeur : " +
															this.state.demande.nom +
															" " +
															this.state.demande.prenom}
													</div>
													<div>{this.state.demande.adresse}</div>
													<div>
														{this.state.demande.codepostal +
															" " +
															this.state.demande.ville}
													</div>
												</Col>
												<Col xs={6}>
													<Button
														variant="outline-secondary"
														size="sm"
														onClick={(e) => {
															this.toggleHorsSite();
															if (this.state.horssite) {
																setFieldValue("nom", this.state.nom);
																setFieldValue("prenom", this.state.prenom);
																setFieldValue("adresse", this.state.adresse);
																setFieldValue(
																	"codepostal",
																	this.state.codepostal
																);
																setFieldValue("ville", this.state.ville);
																setFieldValue(
																	"telephone",
																	this.state.telephone
																);
																setFieldValue("date", datereleve);
															} else {
																setFieldValue("nom", "");
																setFieldValue("prenom", "");
																setFieldValue("adresse", "");
																setFieldValue("codepostal", "");
																setFieldValue("ville", "");
																setFieldValue("telephone", "");
																setFieldValue("date", "");
															}
														}}
													>
														Demander un enlèvement hors site<br></br>
														(Supplément : {config.hors_site_sup_value}€)
													</Button>
												</Col>
											</Row>
										</div>
										{/*TOGGLE FORM HORS SITE*/}

										<Row className={!this.state.horssite ? "hidden" : "mt-2"}>
											<Col xs={12}>
												<h2>Adresse de l'enlèvement hors site</h2>
											</Col>
											{/* TODO V2
											<Col md={12}>
												<Form.Label className="required">
													Choisissez-vous l'agence de l'enlèvement hors site
												</Form.Label>
												<Form.Control
													required={!this.state.horssite ? false : true}
													as="select"
													name="agence_cible"
													value={values.agence_cible || ""}
													onChange={handleChange}
												>
													<option></option>
													{this.state.agences}
												</Form.Control>{" "}
											</Col>
*/}
											<Col md={4}>
												<Form.Label className="required">
													Date d'enlèvement
												</Form.Label>
												<DatePickerField
													className="form-control"
													name="date"
													minDate={datereleve}
													required
												/>
											</Col>
											<Col md={4}>
												<Form.Label className="required">
													Nom ou Société
												</Form.Label>
												<Form.Control
													required
													type="text"
													name="nom"
													value={values.nom || ""}
													onChange={handleChange}
												/>
											</Col>
											<Col md={4}>
												<Form.Label className="">
													Prénom ou Éxpéditeur
												</Form.Label>
												<Form.Control
													type="text"
													aria-describedby="inputGroupPrepend"
													name="prenom"
													value={values.prenom || ""}
													onChange={handleChange}
												/>
											</Col>
											<Col md={3}>
												<Form.Label className="required">Adresse</Form.Label>
												<Form.Control
													required
													type="text"
													name="adresse"
													value={values.adresse || ""}
													onChange={handleChange}
												/>
											</Col>
											<Col md={3}>
												<Form.Label className="required">
													Code Postal
												</Form.Label>
												<Form.Control
													required
													type="text"
													name="codepostal"
													value={values.codepostal || ""}
													onChange={handleChange}
												/>
											</Col>
											<Col md={3}>
												<Form.Label className="required">Ville</Form.Label>
												<Form.Control
													required
													type="text"
													aria-describedby="inputGroupPrepend"
													name="ville"
													value={values.ville || ""}
													onChange={handleChange}
												/>
											</Col>
											<Col md={3}>
												<Form.Label className="">Téléphone</Form.Label>
												<Form.Control
													type="text"
													placeholder="0123456789"
													aria-describedby="inputGroupPrepend"
													name="telephone"
													value={values.telephone || ""}
													onChange={handleChange}
												/>
											</Col>
										</Row>
										<Button
											className="mb-5 mt-4"
											size="sm"
											type="submit"
											disabled={isSubmitting}
										>
											Valider cette demande d'enlèvement
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		);
	}
}

export default withRouter(formDemande);
