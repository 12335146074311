/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

/*Css*/

export class Agences extends React.Component {
	constructor() {
		super();
		this.state = {
			agences: [],
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		config
			.apiFetch("/agences")
			.then((response) => response.json())
			.then((agences) => {
				this.setState({
					agences: [agences],
				});
			});
	}

	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Agences</h1>

							<Button
								className="mb-2 mt-4"
								size="sm"
								variant="outline-primary"
								type="submit"
								href="agence/add"
							>
								<i className="fas fa-plus mr-1"></i>
								Ajouter une agence
							</Button>
							<div>
								<Table responsive bordered hover>
									<thead>
										<tr>
											<th>Nom</th>
											<th>Modification / Activation</th>
										</tr>
									</thead>
									<tbody>
										{this.state.agences.map((dynamicData, Key) => {
											let keys = Object.keys(dynamicData);
											let d = dynamicData;
											return d[keys].map((data) => {
												let modify_link = "agence/modify/" + data.agence_id;
												let delete_link = "agence/delete/" + data.agence_id;
												return (
													<tr key={data.agence_id}>
														<td>{data.agence_name}</td>
														<td>
															<a
																href={delete_link}
																onClick={(e) => {
																	if (
																		!window.confirm(
																			"Supprimez l'agence " +
																				data.agence_name +
																				"?"
																		)
																	)
																		e.preventDefault();
																}}
															>
																<i className="fas fa-trash-alt mr-1"></i>
																Supprimer
															</a>
															<a href={modify_link}>
																<i className="fas fa-pencil-alt mr-1"></i>
																Modifier
															</a>
														</td>
													</tr>
												);
											});
										})}
									</tbody>
								</Table>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(Agences);
