import React, { Component, useState } from "react";
import { Formik } from "formik";
import { Route, withRouter } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  Button,
  Row,
  Col,
  Jumbotron,
  Table,
  Tr,
  Td,
  Pagination,
  ButtonToolbar,
  Collapse,
  Container
} from "react-bootstrap";
import DatePickerField from "helpers/datePickerField";
const dateFormatConvert = require("helpers/dateFormatConvert");
class DynamicGuarantyForm extends Component {
  constructor() {
    super();
  }
  Search(key) {
    return (
      <div key={key}>
        <Row>
          <Col md={3}>
            <Form.Label>
              <strong className="text-uppercase">Colis 1</strong>
            </Form.Label>
            <Form.Control
              type="number"
              aria-describedby="inputGroupPrepend"
              name="poidscolis"
              value="values.poidscolis"
              placeholder="Poids (kg)"
            />
          </Col>
          <Col md={3}>
            <Form.Control
              type="number"
              aria-describedby="inputGroupPrepend"
              name="volume"
              placeholder="Volume (m³)"
            />
          </Col>
          <Col md={3}>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Assurer ce colis" />
            </Form.Group>
            <Form.Control
              type="number"
              aria-describedby="inputGroupPrepend"
              name="valeur"
              placeholder="Valeur à assurer (€)"
            />
          </Col>
          <Col md={3}>
            <em id="affichage-majoration">Supplément : 9€</em>
          </Col>
        </Row>
      </div>
    );
  }
  render() {
    return (
      <div>
        <this.Search />
      </div>
    );
  }
}

export default withRouter(DynamicGuarantyForm);
