/* eslint-disable import/first */ /* eslint-disable import/first */
import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Role } from "helpers/role";
import { authenticationService } from "services/authentication/authenticationService";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import Authentication from "./components/authentication/Authentication";
import Home from "./components/home/Home";
import Admin from "./components/admin/Admin";
import NotFound from "./components/404/404";

{
	/*##################################################################################Admin import###############################################################################
  ##############################################################################################################################################################################*/
}
{
	/*#####################Clients import###############*/
}
import Clients from "./components/admin/clients/Clients";
import AddClient from "./components/admin/clients/addClient/addClient";
import ModifyClient from "./components/admin/clients/modifyClient/modifyClient";
import EnableClient from "./components/admin/clients/modifyClient/enableClient";
import DisableClient from "./components/admin/clients/modifyClient/disableClient";
import SearchResultsClients from "./components/admin/clients/searchResults/searchResults";

{
	/*#####################Livreurs import###############*/
}
import Livreurs from "./components/admin/livreurs/Livreurs";
import addLivreur from "./components/admin/livreurs/add/addLivreur";
import modifyLivreur from "./components/admin/livreurs/modify/modifyLivreur";
import deleteLivreur from "./components/admin/livreurs/modify/deleteLivreur";

{
	/*#####################Agences import################*/
}
import Agences from "./components/admin/agences/Agences";
import AddAgence from "./components/admin/agences/add/addAgence";
import DeleteAgence from "./components/admin/agences/modify/deleteAgence";
import ModifyAgence from "./components/admin/agences/modify/modifyAgence";

{
	/*#####################Colis import###############*/
}
import Colis from "./components/admin/colis/Colis";
import ModifyColis from "./components/admin/colis/modify/modifyColis";
import searchByPackageCode from "./components/admin/colis/searchResults/searchByPackageCode";
import SearchByDateAndState from "./components/admin/colis/searchResults/searchByDateAndState";
import SearchByDateAndDestination from "./components/admin/colis/searchResults/searchByDateAndDestination";
/* import searchBySociety from "./components/admin/colis/searchResults/searchBySociety"; */
import ErrorPackage from "./components/admin/colis/error/error";
import TrackingPackage from "./components/admin/colis/tracking/trackingPackage";
import ListColisInLotAdmin from "./components/admin/colis/listcolisinlot/listColisInLot";

{
	/*#####################Vehicules import###############*/
}
import Vehicules from "./components/admin/vehicules/Vehicules";
import HistoriqueVehicule from "./components/admin/vehicules/historique/historiqueVehicule";
import ModifyVehicule from "./components/admin/vehicules/modify/modifyVehicule";
import AlertVehicule from "./components/admin/vehicules/alert/alertVehicule";
import DeleteVehicule from "./components/admin/vehicules/modify/deleteVehicule";
import AddVehicule from "./components/admin/vehicules/add/addVehicule";
import DetailsVehicule from "./components/admin/vehicules/details/detailsVehicules";

{
	/*#####################Demande import###############*/
}
import Demandes from "./components/admin/demandes/Demandes";
import DetailsDemandes from "./components/admin/demandes/details/details";
import VisualiserEtiquette from "./components/admin/demandes/etiquette/visualiserEtiquette";
import GenererEtiquette from "./components/admin/demandes/etiquette/genererEtiquette";
import AddDemande from "./components/admin/demandes/add/addDemande";
import EtiquetteNormal from "./components/admin/demandes/etiquette/etiquetteNormal";
import EtiquetteThermique from "./components/admin/demandes/etiquette/etiquetteThermique";
import FilterAdminDemandeMonth from "./components/admin/demandes/filter/filterMonthDate";
import FilterAdminDemandeMonthClient from "./components/admin/demandes/filter/filterMonthDateClient";
import FilterAdminDemandeDate from "./components/admin/demandes/filter/filterDate";
import FilterAdminDemandeDateClient from "./components/admin/demandes/filter/filterDateClient";
{
	/*#####################Tournee import###############*/
}
import Tournee from "./components/admin/tournee/Tournee";
import FicheTournee from "./components/admin/tournee/fiche";
import AddTournee from "./components/admin/tournee/add/addTournee";
import DeleteTournee from "./components/admin/tournee/modify/delete";
import ModifyTournee from "./components/admin/tournee/modify/modify";
import FilterTourneeByMonthYear from "./components/admin/tournee/filter/filterbyMonthYear";
import FilterTourneeByPeriodTime from "./components/admin/tournee/filter/filterbyPeriodTime";
import AddColisToTournee from "./components/admin/tournee/add/addColisToTournee";

{
	/*#####################Profil import###############*/
}

import Profil from "./components/admin/profils/Profil";
import DetailsProfil from "./components/admin/profils/details/details";
import ModifyProfil from "./components/admin/profils/details/modify/modifyProfil";
import RenameProfil from "./components/admin/profils/details/modify/renameProfil";
import CreateProfil from "./components/admin/profils/details/create/createProfil";
import DeleteProfil from "./components/admin/profils/details/modify/deleteProfil";

{
	/*#####################Importer import###############*/
}

import ImporterAdresse from "./components/admin/importer/ImporterAdresse";
import ImporterColis from "./components/admin/importer/ImporterColis";

{
	/*###################Facturation import###############*/
}
import Facturation from "./components/admin/facturation/Facturation";
import AffichageFacturation from "./components/admin/facturation/affichage/affichageFacturation";
import AffichageFacturationByProfil from "./components/admin/facturation/affichage/affichageFacturationByProfil";
import AffichageFacturationAgence from "./components/admin/facturation/affichage/affichageFacturationAgence";
import AffichageCA from "./components/admin/facturation/affichage/affichageCA";
import AffichageCAByProfil from "./components/admin/facturation/affichage/affichageCAByProfil";
import AffichageCAByAgence from "./components/admin/facturation/affichage/affichageCAByAgence";

{
	/*###################Facturation Hors Site import###############*/
}

import FacturationHorsSite from "./components/admin/facturationhorssite/FacturationHorsSite";
import AffichageFacturationOrigine from "./components/admin/facturationhorssite/affichage/affichageFacturationOrigine";
import AffichageFacturationCible from "./components/admin/facturationhorssite/affichage/affichageFacturationCible";
import AffichageFacturationOriginePerCible from "./components/admin/facturationhorssite/affichage/filter/affichageFacturationOriginePerCible";
import AffichageFacturationCiblePerOrigine from "./components/admin/facturationhorssite/affichage/filter/affichageFacturationCiblePerOrigine";
import AffichageCAOrigine from "./components/admin/facturationhorssite/affichage/affichageCAOrigine";
import AffichageCACible from "./components/admin/facturationhorssite/affichage/affichageCACible";
import AffichageCAOriginePerCible from "./components/admin/facturationhorssite/affichage/filter/affichageCAOriginePerCible";
import AffichageCACiblePerOrigine from "./components/admin/facturationhorssite/affichage/filter/affichageCACiblePerOrigine";

{
	/*###############################################################################Fin Admin import##############################################################################
  ##############################################################################################################################################################################*/
}

{
	/*##################################################################################Public import###############################################################################
  ##############################################################################################################################################################################*/
}

{
	/*###################Demande import###############*/
}
import Public from "./components/public/Public";
import AddDemandePublic from "./components/public/demande/add/addDemande";
import AddDemandeParLotPublic from "./components/public/demande/add/addDemandeParLot";
import Demande from "./components/public/demande/Demandes";
//Filter
import FilterMonthDate from "./components/public/demande/filter/filterMonthYear";
import FilterDate from "./components/public/demande/filter/filteDate";
import FilterHasBordereau from "./components/public/demande/filter/filterHasBordereau";
import FilterWithoutBordereau from "./components/public/demande/filter/filterWithoutBordereau";
import FilterWithoutBordereauFilterMonthYear from "./components/public/demande/filter/filterWithoutBordereauFilterMonthYear";
import FilterWithoutBordereauFilterDate from "./components/public/demande/filter/filterWithoutBordereauFilterDate";
import FilterHasBordereauFilterMonthYear from "./components/public/demande/filter/filterHasBordereauFilterMonthYear";
import FilterHasBordereauFilterDate from "./components/public/demande/filter/filterHasBordereauFilterDate";

{
	/*####################Adresse import################*/
}

import AddAdresse from "./components/public/adresse/add/addAdresse";
import ModifyAdresse from "./components/public/adresse/modify/modifyAdresse";

{
	/*####################Bordereau import################*/
}

import Bordereau from "./components/public/bordereau/Bordereau";
import DetailsDemande from "./components/public/bordereau/detailsDemande/detailsDemande";
import VisualiserEtiquettePublic from "./components/public/demande/etiquette/visualiserEtiquette";
import ImprimerEtiquettePublic from "./components/public/demande/etiquette/imprimerEtiquette";
import ImprimerEtiquetteUPSPublic from "./components/public/demande/etiquette/imprimerEtiquetteUPS";

import DetailsColisPublic from "./components/public/bordereau/detailsColis/detailsColis";
import DeleteDemandePublic from "./components/public/bordereau/deleteDemande/deleteDemande";
import VisualiserBordereau from "./components/public/bordereau/detailsDemande/bordereau/visualiserBordereau";
import DeleteBordereauPublic from "./components/public/bordereau/modify/deleteBordereau";

{
	/*#####################Lot import##################*/
}

import LotPublic from "./components/public/lot/Lot";
import SearchByLotCodePublic from "./components/public/lot/searchResults/searchByLotCode";
/* import SearchLotBySocietyPublic from "./components/public/lot/searchResults/searchBySociety"; */
import SearchLotByDateAndStatePublic from "./components/public/lot/searchResults/searchByDateAndState";
import SearchLotByDateAndDestinationPublic from "./components/public/lot/searchResults/searchByDateAndDestination";
import ListColisInLotPublic from "./components/public/lot/searchResults/listcolisinlot/listColisInLot";
import ImprimerEtiquetteLot from "./components/public/demande/etiquette/imprimerEtiquetteLot";
import ImprimerEtiquetteLotUnite from "./components/public/demande/etiquette/imprimerEtiquetteLotUnite";
import ImprimerEtiquetteLotPrincipal from "./components/public/demande/etiquette/imprimerEtiquetteLotPrincipal";
{
	/*#####################Colis import##################*/
}

import ColisPublic from "./components/public/colis/Colis";
import SearchByPackageCodePublic from "./components/public/colis/searchResults/searchByPackageCode";
/* import SearchBySocietyPublic from "./components/public/colis/searchResults/searchBySociety"; */
import SearchByDateAndStatePublic from "./components/public/colis/searchResults/searchByDateAndState";
import SearchByDateAndDestinationPublic from "./components/public/colis/searchResults/searchByDateAndDestination";
import TrackingPackagePublic from "./components/public/colis/tracking/trackingPackage";

{
	/*####################Expedition import################*/
}
import Expedition from "./components/public/expedition/Expedition";
import AffichageDetailExpedition from "./components/public/expedition/detail/affichageDetailExpedition";

{
	/*##################################################################################Edi import###############################################################################
  ##############################################################################################################################################################################*/
}

{
	/*########################Edi import####################*/
}
import Edi from "./components/edi/Edi";

function App() {
	const PrivateRoute = ({ component: Component, roles, ...rest }) => (
		<Route
			{...rest}
			render={(props) => {
				let currentUser = authenticationService.currentUserValue;
				if (!currentUser) {
					// not logged in so redirect to login page with the return url
					return (
						<Redirect to={{ pathname: "/", state: { from: props.location } }} />
					);
				}

				// check if route is restricted by role
				if (roles && roles.indexOf(currentUser["data"][0].aut_role) === -1) {
					// role not authorised so redirect to home page

					return <Redirect to={{ pathname: "/" }} />;
				}

				// authorised so return component
				return <Component {...props} />;
			}}
		/>
	);
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/" exact component={Authentication}></Route>
				<Route
					path="/404"
					exact
					component={NotFound}
					roles={[Role.Admin, Role.Agence, Role.Client]}
				></Route>

				{/*#################################################################################Admin Section###############################################################################
        ##############################################################################################################################################################################*/}

				<PrivateRoute
					path="/admin"
					roles={[Role.Admin, Role.Agence]}
					exact
					component={Admin}
				></PrivateRoute>

				{/*#####################Clients Section###############*/}
				<PrivateRoute
					path="/admin/clients/:nom/:prenom/:ville/:page_id"
					component={SearchResultsClients}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/clients/modify/:id"
					component={ModifyClient}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/clients/enable/:id"
					component={EnableClient}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/clients/disable/:id"
					component={DisableClient}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/clients/add"
					component={AddClient}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/clients/:page_id"
					component={Clients}
				></PrivateRoute>

				{/*#####################Livreurs Section##############*/}
				<PrivateRoute
					path="/admin/livreurs/modify/:id"
					component={modifyLivreur}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/livreurs/delete/:id"
					component={deleteLivreur}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/livreurs/add"
					component={addLivreur}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/livreurs/"
					component={Livreurs}
				></PrivateRoute>

				{/*#####################Agences Section###############*/}
				<PrivateRoute
					exact
					path="/admin/agence"
					roles={[Role.Admin]}
					component={Agences}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/agence/add"
					roles={[Role.Admin]}
					component={AddAgence}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/agence/delete/:agence_id"
					roles={[Role.Admin]}
					component={DeleteAgence}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/agence/modify/:agence_id"
					roles={[Role.Admin]}
					component={ModifyAgence}
				></PrivateRoute>

				{/*#####################Vehicules Section#############*/}
				<PrivateRoute
					exact
					path="/admin/vehicules"
					roles={[Role.Admin, Role.Agence]}
					component={Vehicules}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/add/:agence"
					roles={[Role.Admin, Role.Agence]}
					component={AddVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/historique/:immatriculation"
					roles={[Role.Admin, Role.Agence]}
					component={HistoriqueVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/details/:agence"
					component={DetailsVehicule}
					roles={[Role.Admin, Role.Agence]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/modify/:id_vehicule"
					roles={[Role.Admin, Role.Agence]}
					component={ModifyVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/fix/:id_vehicule"
					roles={[Role.Admin, Role.Agence]}
					component={AlertVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/delete/:id_vehicule"
					roles={[Role.Admin, Role.Agence]}
					component={DeleteVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/add"
					roles={[Role.Admin, Role.Agence]}
					component={AddVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/historique/:immatriculation"
					roles={[Role.Admin, Role.Agence]}
					component={HistoriqueVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/details"
					component={DetailsVehicule}
					roles={[Role.Admin, Role.Agence]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/modify/:id_vehicule"
					roles={[Role.Admin, Role.Agence]}
					component={ModifyVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/fix/:id_vehicule"
					roles={[Role.Admin, Role.Agence]}
					component={AlertVehicule}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/vehicules/delete/:id_vehicule"
					roles={[Role.Admin, Role.Agence]}
					component={DeleteVehicule}
				></PrivateRoute>
				{/*#####################Colis Section#################*/}
				<PrivateRoute
					path="/admin/colis/erreur"
					component={ErrorPackage}
					roles={[Role.Admin, Role.Agence]}
				></PrivateRoute>

				<PrivateRoute
					path="/admin/colis/dateandstate/:start/:end/:etatlivraison/page/:page"
					component={SearchByDateAndState}
					roles={[Role.Admin, Role.Agence]}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/colis/dateanddestination/:start/:end/:nom/:ville/page/:page"
					component={SearchByDateAndDestination}
					roles={[Role.Admin, Role.Agence]}
				></PrivateRoute>
				{/* <PrivateRoute
					path="/admin/colis/society/:societyCode"
					component={searchBySociety}
					roles={[Role.Admin, Role.Agence]}
				></PrivateRoute> */}
				<PrivateRoute
					exact
					path="/admin/colis/modify/:colis_id"
					roles={[Role.Admin, Role.Agence]}
					component={ModifyColis}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/colis/:colis_id"
					roles={[Role.Admin, Role.Agence]}
					component={searchByPackageCode}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/colis/tracking/:colis_id_ciblex"
					roles={[Role.Admin, Role.Agence]}
					component={TrackingPackage}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/colis/listcolisinlot/:colis_id"
					roles={[Role.Admin, Role.Agence]}
					component={ListColisInLotAdmin}
				></PrivateRoute>
				<PrivateRoute path="/admin/colis/" component={Colis}></PrivateRoute>

				{/*####################Demande Section################*/}
				<PrivateRoute
					path="/admin/demande/page/:page_id"
					roles={[Role.Admin, Role.Agence]}
					component={Demandes}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/demande/FilterAdminDemandeMonthDate/page/:page_id/:month/:year"
					roles={[Role.Admin, Role.Agence]}
					component={FilterAdminDemandeMonth}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/demande/FilterAdminDemandeMonthDateClient/page/:page_id/:month/:year/:id_client"
					roles={[Role.Admin, Role.Agence]}
					component={FilterAdminDemandeMonthClient}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/demande/FilterAdminDemandeDate/page/:page_id/:startDate/:endDate"
					roles={[Role.Admin, Role.Agence]}
					component={FilterAdminDemandeDate}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/demande/FilterAdminDemandeDateClient/page/:page_id/:startDate/:endDate/:id_client"
					roles={[Role.Admin, Role.Agence]}
					component={FilterAdminDemandeDateClient}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/demande/details/:groupement_id"
					roles={[Role.Admin, Role.Agence]}
					component={DetailsDemandes}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/demande/visualiseretiquette/:groupement_id"
					component={VisualiserEtiquette}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/demande/genereretiquette"
					component={GenererEtiquette}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/demande/add"
					component={AddDemande}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/demande/etiquettenormal/:id_client/:nombre"
					component={EtiquetteNormal}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/demande/etiquettethermique/:id_client/:nombre"
					component={EtiquetteThermique}
				></PrivateRoute>

				{/*####################Tournee Section################*/}
				<PrivateRoute
					path="/admin/tournee/page/:page_id"
					component={Tournee}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/tournee/filtrebymonthyear/:month/:year/page/:page_id"
					component={FilterTourneeByMonthYear}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/tournee/filtrebyperiodtime/:start/:end/page/:page_id"
					component={FilterTourneeByPeriodTime}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/tournee/fiche/:tournee_id"
					component={FicheTournee}
				></PrivateRoute>
				<PrivateRoute
					path="/admin/tournee/modify/:id"
					component={ModifyTournee}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/tournee/delete/:id"
					component={DeleteTournee}
				></PrivateRoute>

				<PrivateRoute
					path="/admin/tournee/add"
					component={AddTournee}
				></PrivateRoute>

				<PrivateRoute
					path="/admin/tournee/addcolistotournee/:tournee_id/:start/:end/:id_client"
					component={AddColisToTournee}
				></PrivateRoute>

				{/*####################Profil Section################*/}

				<PrivateRoute
					exact
					path="/admin/profil/"
					component={Profil}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/profil/create"
					component={CreateProfil}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/profil/:nom"
					component={DetailsProfil}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/profil/modify/:nom"
					component={ModifyProfil}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/profil/rename/:nom"
					component={RenameProfil}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/profil/delete/:nom"
					component={DeleteProfil}
				></PrivateRoute>

				{/*####################Profil Section################*/}

				<PrivateRoute
					exact
					path="/admin/importer-colis/"
					component={ImporterColis}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/importer-adresse/"
					component={ImporterAdresse}
				></PrivateRoute>

				{/*##################Facturation Section################*/}
				<PrivateRoute
					exact
					path="/admin/facturation/"
					component={Facturation}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/facturation/affichagefacturation/:id_client/:month/:year"
					component={AffichageFacturation}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/facturation/affichagefacturationagence/:agence/:month/:year"
					component={AffichageFacturationAgence}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/facturation/affichageCA/:id_client/:month/:year"
					component={AffichageCA}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/facturation/affichagefacturationbyprofil/:profil/:month/:year"
					component={AffichageFacturationByProfil}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/facturation/AffichageCAByProfil/:profil/:month/:year"
					component={AffichageCAByProfil}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/admin/facturation/affichagecaagence/:agence/:month/:year"
					component={AffichageCAByAgence}
				></PrivateRoute>

				{/*###############Facturation Hors Site Section#############*/}

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/"
					component={FacturationHorsSite}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/affichagefacturationorigine/:agence/:month/:year"
					component={AffichageFacturationOrigine}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/affichagefacturationcible/:agence/:month/:year"
					component={AffichageFacturationCible}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/affichagecaorigine/:agence/:month/:year"
					component={AffichageCAOrigine}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/affichagecacible/:agence/:month/:year"
					component={AffichageCACible}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/affichagefacturationoriginepercible/:agence_origine/:agence_cible/:month/:year"
					component={AffichageFacturationOriginePerCible}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/affichagefacturationcibleperorigine/:agence_origine/:agence_cible/:month/:year"
					component={AffichageFacturationCiblePerOrigine}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/affichagecaoriginepercible/:agence_origine/:agence_cible/:month/:year"
					component={AffichageCAOriginePerCible}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/admin/facturationhorssite/affichagecacibleperorigine/:agence_origine/:agence_cible/:month/:year"
					component={AffichageCACiblePerOrigine}
				></PrivateRoute>

				{/*################################################################################Fin Admin Section#############################################################################
        ##############################################################################################################################################################################*/}

				{/*################################################################################Public Section##############################################################################
        ##############################################################################################################################################################################*/}

				{/*####################Demande Section################*/}

				<PrivateRoute
					exact
					path="/public/demande/page/:page_id"
					component={Demande}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/visualiseretiquette/:groupement_id"
					component={VisualiserEtiquettePublic}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/imprimeretiquette/:groupement_id"
					component={ImprimerEtiquettePublic}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/imprimeretiquetteups/:groupement_id"
					component={ImprimerEtiquetteUPSPublic}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/details/:groupement_id"
					roles={[Role.Client]}
					component={DetailsColisPublic}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/adddemande"
					component={AddDemandePublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/demande/adddemandeparlot"
					component={AddDemandeParLotPublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/demande/adddemandeparlot/:a_id"
					component={AddDemandeParLotPublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public"
					component={Public}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/imprimerEtiquetteLot/:a_id"
					component={ImprimerEtiquetteLot}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/imprimerEtiquetteLotUnite/:a_id/:lc_id"
					component={ImprimerEtiquetteLotUnite}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/imprimerEtiquetteLotPrincipal/:groupement_id"
					component={ImprimerEtiquetteLotPrincipal}
					roles={[Role.Client]}
				></PrivateRoute>

				{
					//FILTER
				}
				<PrivateRoute
					exact
					path="/public/demande/filter/:month/:year/page/:page_id"
					component={FilterMonthDate}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/filterdate/:start/:end/page/:page_id"
					component={FilterDate}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/FilterHasBordereauFilterMonthYear/:month/:year/page/:page_id"
					component={FilterHasBordereauFilterMonthYear}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/FilterHasBordereauFilterDate/:start/:end/page/:page_id"
					component={FilterHasBordereauFilterDate}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/filterHasBordereau/page/:page_id"
					component={FilterHasBordereau}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/filterWithoutBordereau/page/:page_id"
					component={FilterWithoutBordereau}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/FilterWithoutBordereauFilterMonthYear/:month/:year/page/:page_id"
					component={FilterWithoutBordereauFilterMonthYear}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/demande/FilterWithoutBordereauFilterDate/:start/:end/page/:page_id"
					component={FilterWithoutBordereauFilterDate}
					roles={[Role.Client]}
				></PrivateRoute>

				{/*####################Adresse Section################*/}

				<PrivateRoute
					exact
					path="/public/adresse/add"
					component={AddAdresse}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/adresse/modify/:a_id"
					component={ModifyAdresse}
				></PrivateRoute>

				{/*###################Bordereau Section################*/}

				<PrivateRoute
					exact
					path="/public/bordereau/page/:page_id"
					component={Bordereau}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/bordereau/demande/:groupement_id"
					component={DetailsDemande}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/bordereau/visualiseretiquette/:groupement_id"
					component={VisualiserEtiquettePublic}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/public/bordereau/demande/details/:groupement_id"
					roles={[Role.Client]}
					component={DetailsColisPublic}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/bordereau/demande/delete/:groupement_id"
					roles={[Role.Client]}
					component={DeleteDemandePublic}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/bordereau/visualiserbordereau/:id_bordereau"
					roles={[Role.Client]}
					component={VisualiserBordereau}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/bordereau/delete/:id_bordereau"
					roles={[Role.Client]}
					component={DeleteBordereauPublic}
				></PrivateRoute>
				{/*####################Colis Section##################*/}

				<PrivateRoute
					exact
					path="/public/colis"
					component={ColisPublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/colis/:colis_id"
					component={SearchByPackageCodePublic}
					roles={[Role.Client]}
				></PrivateRoute>
				{/* <PrivateRoute
					exact
					path="/public/colis/society/:societyCode"
					component={SearchBySocietyPublic}
					roles={[Role.Client]}
				></PrivateRoute> */}
				<PrivateRoute
					exact
					path="/public/colis/date/:start/:end/:etatlivraison/page/:page"
					component={SearchByDateAndStatePublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/colis/dateanddestination/:start/:end/:nom/:ville/page/:page"
					component={SearchByDateAndDestinationPublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/colis/tracking/:colis_id_ciblex"
					roles={[Role.Client]}
					component={TrackingPackagePublic}
				></PrivateRoute>
				{/*####################Expedition Section################*/}
				<PrivateRoute
					exact
					path="/public/expedition/"
					component={Expedition}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/expedition/affichageDetailExpedition/:month/:year"
					component={AffichageDetailExpedition}
				></PrivateRoute>
				{/*####################Lot Section##################*/}

				<PrivateRoute
					exact
					path="/public/lot"
					component={LotPublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/lot/listcolisinlot/:colis_id"
					component={ListColisInLotPublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/lot/:colis_id"
					component={SearchByLotCodePublic}
					roles={[Role.Client]}
				></PrivateRoute>
				{/* <PrivateRoute
					exact
					path="/public/lot/society/:societyCode"
					component={SearchLotBySocietyPublic}
					roles={[Role.Client]}
				></PrivateRoute> */}
				<PrivateRoute
					exact
					path="/public/lot/date/:start/:end/:etatlivraison/page/:page"
					component={SearchLotByDateAndStatePublic}
					roles={[Role.Client]}
				></PrivateRoute>
				<PrivateRoute
					exact
					path="/public/lot/dateanddestination/:start/:end/:nom/:ville/page/:page"
					component={SearchLotByDateAndDestinationPublic}
					roles={[Role.Client]}
				></PrivateRoute>

				<PrivateRoute
					exact
					path="/deposerfichieredi"
					component={Edi}
					roles={[Role.EDI]}
				></PrivateRoute>
				<PrivateRoute component={NotFound}></PrivateRoute>
			</Switch>
		</BrowserRouter>
	);
}

export default App;
