/* eslint-disable import/first */
/* eslint-disable import/first */
import React from "react";
import NavAdmin from "components/nav/NavAdmin";
import { Button, Table, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import * as config from "config/config";
import SummaryTable from "./summaryTableByProfil";
const dateFormatConvert = require("helpers/dateFormatConvert");
const calculFacturation = require("helpers/calculFacturation");

/*Css*/

export class AffichageFacturation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			client: [],
			colis: [],
			array_interval: [],
			nb_total: [],
			sum_total: [],
			clientNomPrenom: "",
			lesMois: config.lesMois,
			messages: "En cours de chargement...",
			sup_hors_site: config.hors_site_sup_value,
			colis_dans_lot: [],
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		//get user name
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let profil = "";

		if (this.props.location.state && this.props.location.state.profil) {
			profil = this.props.location.state.profil;
		} else {
			this.props.history.push("/404");
		}
		config
			.apiFetch(config.Api_Url + "/profil/getProfilbyName", {
				method: "POST",
				body: JSON.stringify({
					profil: profil,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => response.json())
			.then((details) => {
				let array_interval = [];
				let nb_total = [];
				let sum_total = [];
				let colis_dans_lot = [];
				details["data"].forEach((d) => {
					array_interval.push(d.max);
					nb_total.push(0);
					sum_total.push(0);
					colis_dans_lot.push(0);
				});
				nb_total.push(0);
				sum_total.push(0);
				this.setState({
					details: [details],
					array_interval: array_interval,
					nb_total: nb_total,
					sum_total: sum_total,
					colis_dans_lot: colis_dans_lot,
				});
			});

		config
			.apiFetch(
				config.Api_Url +
					"/colis/getAllColisbyDateAndProfil/" +
					this.props.match.params.month +
					"/" +
					this.props.match.params.year,
				{
					method: "POST",
					body: JSON.stringify({
						profil: profil,
					}),
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((response) => response.json())
			.then((colis) => {
				if (colis.data.length == 0) {
					this.setState({
						messages:
							"Erreur, ou aucune donnée ou il n'y a pas de profils de facturation correspondant pour ce client",
						colis: [],
					});
				} else {
					this.setState({
						colis: [colis],
					});
					if (user_role == "Agence") {
						config
							.apiFetch("/getAgenceByAccount/" + user_id)
							.then((response) => {
								if (response.status == "401") {
									window.location.reload();
								} else {
									return response.json();
								}
							})
							.then((agence) => {
								let src =
									"data:image/jpg;base64," +
									new Buffer(agence["data"][0].agence_logo).toString("base64");
								this.setState({
									agence: agence["data"][0],
									logo: (
										<img
											src={src}
											style={{ maxHeight: 150, maxWidth: 150 }}
										></img>
									),
								});
							});
					}
				}
			});
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	render() {
		if (
			!this.state.details.length ||
			!this.state.colis.length ||
			!this.state.array_interval.length
		)
			return (
				<div>
					<NavAdmin />
					<div className="container-fluid">
						<div className="text-center">
							<h2>{this.state.messages}</h2>
						</div>
					</div>
				</div>
			);
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<div className="p-5">
						<Row>
							<Col xs={6}>{this.state.logo}</Col>
						</Row>
						<h1>Facturation</h1>
						<SummaryTable
							details={this.state.details}
							client={this.state.client}
							colis={this.state.colis}
							array_interval={this.state.array_interval}
							nb_total={this.state.nb_total}
							sum_total={this.state.sum_total}
							lesMois={this.state.lesMois}
							clientNomPrenom={this.state.clientNomPrenom}
							clientZipCode={this.state.clientZipCode}
							profil={decodeURIComponent(
								decodeURIComponent(this.props.match.params.profil)
							)}
							colis_dans_lot={this.state.colis_dans_lot}
						/>
						<div>
							<h3 className="page-break-before">Détail des colis</h3>
							<Table bordered hover>
								<thead>
									<tr className="print-text-black">
										<th>N°</th>
										<th>ID Colis</th>
										<th>Date</th>
										<th>Type</th>
										<th>Poids</th>
										<th>Tranche</th>
										<th>Nom du destinataire</th>
										<th>Destination</th>
										<th>Prix</th>
										<th>Supplément</th>
									</tr>
								</thead>
								<tbody>
									{this.state.colis.map((dynamicData, Key) => {
										let keys = Object.keys(dynamicData);
										let d = dynamicData;
										let details = this.state.details[0]["data"];
										let count_row = 0;
										return d[keys].map((data) => {
											count_row++;
											let prix = 0;
											let result_getPoidInterval = [];
											let min = 0;
											let max = 0;
											//get price range correspond
											result_getPoidInterval = calculFacturation.getPoidInterval(
												data.poidscolis,
												this.state.array_interval
											);

											let index = result_getPoidInterval[0];

											min = result_getPoidInterval[1];

											max = result_getPoidInterval[2];
											//get package total number array and price sum total array
											let nb_total = this.state.nb_total;
											let sum_total = this.state.sum_total;
											//copy package total number value of the price range
											let temp_nb_total = nb_total[index];
											//add 1 to it
											temp_nb_total++;
											//update package total number for this particular price range
											nb_total[index] = temp_nb_total;
											//if oder (hors site), we need to add a sup amount
											if (data.agence_cible) {
												//copy package total number with sup value of the price range (always the last one)
												let temp_nb_total_sup_hors_site =
													nb_total[nb_total.length - 1];
												//add 1 to it
												temp_nb_total_sup_hors_site++;
												//update....
												nb_total[
													nb_total.length - 1
												] = temp_nb_total_sup_hors_site;
											}
											//this.setState({ nb_total: nb_total });
											//calcul the price
											prix = calculFacturation.getPrixFacturation(
												data,
												details,
												index
											);

											let sup_hors_site = 0;
											if (data.agence_cible) {
												sup_hors_site = this.state.sup_hors_site;
											}
											let temp_sum_total = sum_total[index];
											let temp_sum_sup_hors_site_total =
												sum_total[sum_total.length - 1];
											temp_sum_total = temp_sum_total + prix;
											temp_sum_sup_hors_site_total =
												temp_sum_sup_hors_site_total + sup_hors_site;
											sum_total[index] = temp_sum_total;
											sum_total[
												sum_total.length - 1
											] = temp_sum_sup_hors_site_total;

											//Get number of colis on a "lot"
											//Because we already count the "lot" itself as a "colis"
											//We remove one from the total number of "colis" on a "lot"
											let colis_dans_lot = this.state.colis_dans_lot;
											let temp_colis_dans_lot = colis_dans_lot[index];

											if (data.colis_dans_lot) {
												temp_colis_dans_lot += data.colis_dans_lot - 1;
											}
											colis_dans_lot[index] = temp_colis_dans_lot;
											return (
												<tr
													key={data.colis_id}
													className={
														data.agence_origine && data.agence_cible
															? "hors-site"
															: ""
													}
												>
													<td>{count_row}</td>
													<td>{data.colis_id}</td>
													<td>
														{dateFormatConvert.getFormattedDateWithHms(
															data.datedemande
														)}
													</td>
													<td>
														{data.estLot
															? "Lot - " + data.colis_dans_lot + " colis"
															: "Colis"}
													</td>
													<td>{data.poidscolis}</td>
													<td>
														{min} {typeof min == "number" ? " à " : ""}
														{max} Kg
													</td>
													<td>{data.nomdest}</td>
													<td>
														{data.adressedest + " "}
														{data.codepostaldest + " "}
														{data.villedest}
													</td>
													<td>{prix}</td>
													<td>
														{data.agence_cible
															? config.hors_site_sup_value
															: ""}
													</td>
												</tr>
											);
										});
									})}
								</tbody>
							</Table>
							<Button
								onClick={this.handleClick}
								size="sm"
								className="pr-3 back-button mb-5 no-print"
							>
								<i className="fas fa-angle-left mr-2"></i>
								Retour
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(AffichageFacturation);
