/* eslint-disable import/first */
import React from "react";
import { Table } from "react-bootstrap";
import * as config from "config/config";
import { withRouter } from "react-router-dom";
const dateFormatConvert = require("helpers/dateFormatConvert");
const calculFacturation = require("helpers/calculFacturation");
/*CSS*/
import "../facturation.scss";

export class ColisbyClientTable extends React.Component {
	constructor(props) {
		super(props);
		this.ColisbyClientTable = this.ColisbyClientTable.bind(this);
	}

	ColisbyClientTable() {
		//let details = this.props.details[0]["data"];
		let details = this.props.details;

		if (
			!this.props.colis ||
			!details ||
			!this.props.nb_total ||
			!this.props.sum_total ||
			!this.props.array_interval
		) {
			return (
				<div>
					<h3 className="page-break-before">
						Il n'y a pas de profils de facturation correspondant pour ce client
					</h3>
				</div>
			);
		} else {
			let count_row = 0;
			let count_page = 2;
			return (
				<div>
					<h3 className="page-break-before">Détail des colis</h3>
					<div>
						<Table bordered hover className="table-header">
							<thead>
								<tr className="print-text-black">
									<th style={{ width: "5%" }}>N°</th>
									<th style={{ width: "10%" }}>ID Colis</th>
									<th style={{ width: "10%" }}>Date</th>
									<th style={{ width: "5%" }}>Type</th>
									<th style={{ width: "5%" }}>Poids</th>
									<th style={{ width: "5%" }}>Tranche</th>
									<th style={{ width: "10%" }}>Nom du destinataire</th>
									<th style={{ width: "15%" }}>Destination</th>
									<th style={{ width: "10%" }}>Origine</th>
									<th style={{ width: "10%" }}>Cible</th>
									<th style={{ width: "5%" }}>Prix</th>
									<th style={{ width: "10%" }}>Supplément</th>
								</tr>
							</thead>
							<tbody></tbody>
						</Table>
					</div>
					{this.props.colis.map((data) => {
						count_row++;
						let last_row = this.props.colis.length == count_row;
						let prix = 0;
						let result_getPoidInterval = [];
						let min = 0;
						let max = 0;
						//get price range correspond
						result_getPoidInterval = calculFacturation.getPoidInterval(
							data.poidscolis,
							this.props.array_interval
						);

						let index = result_getPoidInterval[0];

						min = result_getPoidInterval[1];

						max = result_getPoidInterval[2];
						let nb_total = this.props.nb_total;
						let sum_total = this.props.sum_total;
						let temp_nb_total = nb_total[index];
						temp_nb_total++;
						nb_total[index] = temp_nb_total;
						//
						//.log(details[index]);
						//this.setState({ nb_total: nb_total });
						//calcul the price
						prix = calculFacturation.getPrixFacturation(data, details, index);

						let temp_sum_total = sum_total[index];
						temp_sum_total = temp_sum_total + prix;
						sum_total[index] = temp_sum_total;

						//break page every 10 lines
						let break_page_css = "";
						if (count_row % 11 == 0) {
							break_page_css = "page-break-before";
							count_page++;
						}

						{/* last_row ? (break_page_css = "page-break-after") : null; */}

						if (last_row) {
							console.log(count_page % 2 == 1);
						}

						return (
							<div key={"parent_" + data.colis_id}>
								<div key={data.colis_id} className={break_page_css}>
									<Table responsive bordered hover className="table-row">
										<tbody className="billing-table-tbody">
											<tr
												className={
													data.origine && data.cible ? "hors-site" : ""
												}
											>
												<td style={{ width: "5%" }}>{count_row}</td>

												<td style={{ width: "10%" }}>{data.colis_id}</td>
												<td style={{ width: "10%" }}>
													{dateFormatConvert.getFormattedDateWithHms(
														data.datedemande
													)}
												</td>

												<td style={{ width: "5%" }}>
													{data.estLot
														? "Lot - " + data.colis_dans_lot + " colis"
														: "Colis"}
												</td>
												<td style={{ width: "5%" }}>{data.poidscolis}</td>
												<td style={{ width: "5%" }}>
													{min} {typeof min == "number" ? " à " : ""}
													{max} Kg{" "}
												</td>
												<td style={{ width: "10%" }}>{data.nomdest}</td>
												<td style={{ width: "15%" }}>
													{data.adressedest + " "}
													{data.codepostaldest + " "}
													{data.villedest}
												</td>
												<td style={{ width: "10%" }}>{data.origine}</td>
												<td style={{ width: "10%" }}>{data.cible}</td>
												<td style={{ width: "5%" }}>{prix}</td>
												<td style={{ width: "10%" }}>
													{data.cible ? config.hors_site_sup_value : ""}
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
								{last_row && count_page % 2 == 1 ? (
									<div className="page-break-after"> &nbsp; </div>
								) : null}
							</div>
						);
					})}
				</div>
			);
		}
	}
	render() {
		return (
			<div>
				<this.ColisbyClientTable />
			</div>
		);
	}
}

export default withRouter(ColisbyClientTable);
