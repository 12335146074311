/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import CreateForm from "./createForm";

import { Formik, submitForm, useFormikContext } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";

/*Css*/

export class CreateProfil extends React.Component {
	constructor() {
		super();
		this.state = {
			details: [],
		};
	}

	//interval Min var
	currentIntervalleMin = 0;

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	render() {
		return <CreateForm />;
	}
}

export default withRouter(CreateProfil);
