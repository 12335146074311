/* eslint-disable import/first */
/* eslint-disable import/first */
import React, { Component } from "react";
import NavAdmin from "components/nav/NavAdmin";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";
const dateFormatConvert = require("helpers/dateFormatConvert");
const calculFacturation = require("helpers/calculFacturation");
import SummaryTable from "./sumCA";
/*Css*/

export class AffichageCA extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			details: [],
			client: [],
			colis: [],
			array_interval: [],
			nb_total: [],
			sum_total: [],
			clientNomPrenom: "",
			lesMois: config.lesMois,
			messages: "En cours de chargement...",
			sup_hors_site: config.hors_site_sup_value,
			colis_dans_lot: [],
		};
	}

	componentDidMount() {
		if (performance.navigation.type == 2) {
			window.location.reload();
		}
		config
			.apiFetch(
				config.Api_Url +
					"/profil/getProfilbyIdClient/" +
					this.props.match.params.id_client
			)
			.then((response) => response.json())
			.then((details) => {
				let array_interval = [];
				let nb_total = [];
				let sum_total = [];
				let colis_dans_lot = [];
				details["data"].forEach((d) => {
					array_interval.push(d.max);
					nb_total.push(0);
					sum_total.push(0);
					colis_dans_lot.push(0);
				});
				nb_total.push(0);
				sum_total.push(0);
				this.setState({
					details: [details],
					array_interval: array_interval,
					nb_total: nb_total,
					sum_total: sum_total,
					colis_dans_lot: colis_dans_lot,
				});
			});
		config
			.apiFetch(
				config.Api_Url + "/findbyid/client/" + this.props.match.params.id_client
			)
			.then((response) => response.json())
			.then((client) => {
				if (client["data"].length != 0) {
					this.setState({
						client: [client],
						clientNomPrenom:
							client["data"][0].nom + " " + client["data"][0].prenom,
						clientZipCode: client["data"][0].codepostal,
					});
					config
						.apiFetch(
							config.Api_Url +
								"/colis/getAllColisbyDateAndClient/" +
								client["data"][0].id_client +
								"/" +
								this.props.match.params.month +
								"/" +
								this.props.match.params.year
						)
						.then((response) => response.json())
						.then((colis) => {
							if (colis.data.length == 0) {
								this.setState({
									messages:
										"Aucun colis ou il n'y a pas de profils de facturation correspondant pour ce client",
								});
							}
							this.setState({
								colis: [colis],
							});
						});
				}
			});
	}

	handleClick = () => {
		this.props.history.goBack();
	};

	render() {
		if (!this.state.details || !this.state.colis)
			return (
				<div>
					<NavAdmin />
					<div className="container-fluid">
						<div className="text-center">
							<h2>Erreur ou pas de données</h2>
						</div>
					</div>
				</div>
			);
		return (
			<div>
				<NavAdmin />
				<div className="container-fluid">
					<h1>Facturation</h1>

					<div>
						<SummaryTable
							details={this.state.details}
							client={this.state.client}
							colis={this.state.colis}
							array_interval={this.state.array_interval}
							nb_total={this.state.nb_total}
							sum_total={this.state.sum_total}
							lesMois={this.state.lesMois}
							clientNomPrenom={this.state.clientNomPrenom}
							clientZipCode={this.state.clientZipCode}
							colis_dans_lot={this.state.colis_dans_lot}
						/>

						{this.state.colis.map((dynamicData, Key) => {
							let keys = Object.keys(dynamicData);
							let d = dynamicData;
							let details = this.state.details[0]["data"];
							return d[keys].map((data) => {
								let prix = 0;
								let result_getPoidInterval = [];
								let min = 0;
								let max = 0;
								//get price range correspond
								result_getPoidInterval = calculFacturation.getPoidInterval(
									data.poidscolis,
									this.state.array_interval
								);

								let index = result_getPoidInterval[0];

								min = result_getPoidInterval[1];

								max = result_getPoidInterval[2];
								//get package total number array and price sum total array
								let nb_total = this.state.nb_total;
								let sum_total = this.state.sum_total;
								//copy package total number value of the price range
								let temp_nb_total = nb_total[index];
								//add 1 to it
								temp_nb_total++;
								//update package total number for this particular price range
								nb_total[index] = temp_nb_total;
								//if oder (hors site), we need to add a sup amount
								if (data.agence_cible) {
									//copy package total number with sup value of the price range (always the last one)
									let temp_nb_total_sup_hors_site =
										nb_total[nb_total.length - 1];
									//add 1 to it
									temp_nb_total_sup_hors_site++;
									//update....
									nb_total[nb_total.length - 1] = temp_nb_total_sup_hors_site;
								}
								//calcul the price
								prix = calculFacturation.getPrixFacturation(
									data,
									details,
									index
								);
								//calcul sup hors site and push it to the array
								let sup_hors_site = 0;
								if (data.agence_cible) {
									sup_hors_site = this.state.sup_hors_site;
								}
								let temp_sum_total = sum_total[index];
								let temp_sum_sup_hors_site_total =
									sum_total[sum_total.length - 1];
								temp_sum_total = temp_sum_total + prix;
								temp_sum_sup_hors_site_total =
									temp_sum_sup_hors_site_total + sup_hors_site;
								sum_total[index] = temp_sum_total;
								sum_total[sum_total.length - 1] = temp_sum_sup_hors_site_total;

								//Get number of colis on a "lot"
								//Because we already count the "lot" itself as a "colis"
								//We remove one from the total number of "colis" on a "lot"
								let colis_dans_lot = this.state.colis_dans_lot;
								let temp_colis_dans_lot = colis_dans_lot[index];

								if (data.colis_dans_lot) {
									temp_colis_dans_lot += data.colis_dans_lot - 1;
								}
								colis_dans_lot[index] = temp_colis_dans_lot;
							});
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(AffichageCA);
