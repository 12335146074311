import React, { Component, useState } from "react";
import { Formik, submitForm, useFormikContext } from "formik";
import { withRouter } from "react-router-dom";
import { Button, Collapse, ButtonToolbar, Form } from "react-bootstrap";
import DatePickerField from "helpers/datePickerField";
import * as config from "config/config";
class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			profils: [],
			button: "",
		};
	}
	componentDidMount() {
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		//IF ADMIN, we provide agence modify
		//If not we set agence by defaut of the account
		if (user_role == "Admin") {
			config
				.apiFetch(config.Api_Url + "/profil/liste")
				.then((response) => response.json())
				.then((data) => {
					let etat = [];
					for (let number = 0; number < data["data"].length; number++) {
						etat.push(
							<option
								key={"option_profil_" + number}
								value={data["data"][number]["nom"]}
							>
								{data["data"][number]["nom"]}
							</option>
						);
					}

					this.setState({
						profils: [etat],
					});
				});
		} else {
			config
				.apiFetch(config.Api_Url + "/profilAgence/liste/" + user_id)
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					let etat = [];
					for (let number = 0; number < data["data"].length; number++) {
						etat.push(
							<option
								key={"option_profil_" + number}
								value={data["data"][number]["nom"]}
							>
								{data["data"][number]["nom"]}
							</option>
						);
					}

					this.setState({
						profils: [etat],
					});
				});
		}
	}

	render() {
		return (
			<div>
				<Formik
					enableReinitialize={true}
					initialValues={this.state.profils}
					onSubmit={(values, { setSubmitting }) => {
						if (this.state.button == "details") {
							this.props.history.push({
								pathname: "/admin/profil/" + encodeURIComponent(values.profil),
								state: { profil: values.profil },
							});
						} else if (this.state.button == "modify") {
							this.props.history.push({
								pathname:
									"/admin/profil/modify/" + encodeURIComponent(values.profil),
								state: { profil: values.profil },
							});
						} else if (this.state.button == "delete") {
							this.props.history.push({
								pathname:
									"/admin/profil/delete/" + encodeURIComponent(values.profil),
								state: { profil: values.profil },
							});
						} else if (this.state.button == "rename") {
							this.props.history.push(
								"/admin/profil/rename/" + encodeURIComponent(values.profil)
							);
						} else if (this.state.button == "create") {
							this.props.history.push("/admin/profil/create/");
						} else {
						}
					}}
				>
					{({
						values,
						handleChange,
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<Form onSubmit={handleSubmit} autoComplete="off">
							<Form.Label>Choix d'un profil :</Form.Label>
							<Form.Control
								className="mb-3"
								required
								as="select"
								name="profil"
								value={values.profil}
								onChange={handleChange}
								id="select"
								ref={(input) => (this.menu = input)}
							>
								{this.state.profils}
							</Form.Control>
							<ButtonToolbar>
								<Button
									size="sm"
									className="mr-2"
									onClick={(e) => {
										this.setState({ button: "details" });
										values.profil = this.menu.value;
										handleSubmit(e);
									}}
									type="submit"
									disabled={isSubmitting}
								>
									Voir
								</Button>
								<Button
									size="sm"
									className="mr-2"
									onClick={(e) => {
										this.setState({ button: "modify" });
										values.profil = this.menu.value;
										handleSubmit(e);
									}}
									type="submit"
									disabled={isSubmitting}
								>
									Modifier
								</Button>
								<Button
									size="sm"
									className="mr-2"
									onClick={(e) => {
										this.setState({ button: "delete" });
										values.profil = this.menu.value;
										handleSubmit(e);
									}}
									type="submit"
									disabled={isSubmitting}
								>
									Supprimer
								</Button>
								<Button
									size="sm"
									className="mr-2"
									onClick={(e) => {
										this.setState({ button: "rename" });
										values.profil = this.menu.value;
										handleSubmit(e);
									}}
									type="submit"
									disabled={isSubmitting}
								>
									Renommer
								</Button>
								<Button
									size="sm"
									className="mr-2"
									onClick={(e) => {
										this.setState({ button: "create" });
										handleSubmit(e);
									}}
								>
									Créer
								</Button>
							</ButtonToolbar>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

export default withRouter(SearchForm);
