import React, { Component } from "react";
import { Formik } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
} from "react-bootstrap";
import { NavAdmin } from "components/nav/NavAdmin";
import * as config from "config/config";
import * as yup from "yup";

/*CSS*/
import "../../../../asset/css/add.css";
import { withRouter } from "react-router-dom";
import LogoPreview from "../logoPreview";
const axios = require("axios");

class ModifyAgence extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agence: {},
			logo: "",
		};
	}
	componentDidMount() {
		config
			.apiFetch(
				config.Api_Url + "/findbyid/agence/" + this.props.match.params.agence_id
			)
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					agence: data["data"][0],
				});
				let src =
					"data:image/jpg;base64," +
					new Buffer(this.state.agence.agence_logo).toString("base64");
				this.setState({
					logo: src,
				});
			});
	}
	render() {
		const schema = yup.object({
			//.matches(
			//	"^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$",
			//	"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
			//)
			reaut_password: yup
				.string()
				.oneOf(
					[yup.ref("agence_pwd"), null],
					"Le mot de passe doit correspondre"
				),
		});
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1>Modifier l'agence</h1>
							<img src={this.state.logo} height="150px"></img>
							<Formik
								validationSchema={schema}
								initialValues={Object.assign(this.state.agence, {
									reaut_password: "",
								})}
								enableReinitialize
								onSubmit={(values, { setSubmitting }) => {
									let formData = new FormData();
									let logo = values.logo;
									if (logo) {
										let blob = logo.slice(0, logo.size, logo.type);
										formData.append("blob", blob);
									}
									formData.append(
										"body",
										JSON.stringify({
											agence_name: values.agence_name,
											agence_phone: values.agence_phone
												? values.agence_phone
												: "",
											agence_address: values.agence_address
												? values.agence_address
												: "",
											agence_ville: values.agence_ville
												? values.agence_ville
												: "",
											agence_id: this.props.match.params.agence_id,
											aut_login: values.aut_login,
											aut_password: values.agence_pwd,
											agence_auth: this.state.agence.agence_auth,
											agence_id: this.props.match.params.agence_id,
										})
									);
									axios
										.post(
											config.Api_Url + "/update/agence",
											formData,
											config.axios_config
										)
										.then((response) => {
											window.location.reload();
										})
										.catch((error) => {
											console.log("error:", error);
											alert("L'erreur de téléchargement d'image");
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									isValid,
									setFieldValue,
									/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit}>
										<Form.Label className="required">Nom :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="agence_name"
											value={values.agence_name || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Identifiant :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="aut_login"
											value={values.aut_login || ""}
											onChange={handleChange}
										/>
										<Form.Label className="required">Mot de passe :</Form.Label>
										<Form.Control
											required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="agence_pwd"
											value={values.agence_pwd || ""}
											onChange={handleChange}
											isInvalid={!!errors.aut_password}
										/>
										{/*
										<Form.Control.Feedback className="FeedBack" type="invalid">
											{errors.aut_password}
										</Form.Control.Feedback>
										<Form.Label className="required">
											Retaper votre mot de passe :
										</Form.Label>
										<Form.Control
											required
											type="password"
											aria-describedby="inputGroupPrepend"
											name="reaut_password"
											value={values.reaut_password || ""}
											onChange={handleChange}
											isInvalid={!!errors.reaut_password}
										/>
										<Form.Control.Feedback className="FeedBack" type="invalid">
											{errors.reaut_password}
										</Form.Control.Feedback>
										*/}
										<Form.Label>Téléphone :</Form.Label>
										<Form.Control
											//required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="agence_phone"
											value={values.agence_phone || ""}
											onChange={handleChange}
										/>
										<Form.Label>Adresse :</Form.Label>
										<Form.Control
											//required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="agence_address"
											value={values.agence_address || ""}
											onChange={handleChange}
										/>
										<Form.Label>Ville :</Form.Label>
										<Form.Control
											//required
											type="text"
											aria-describedby="inputGroupPrepend"
											name="agence_ville"
											value={values.agence_ville || ""}
											onChange={handleChange}
										/>
										<Form.Label className="">Logo :</Form.Label>
										<div className="input-group">
											<div className="custom-file mb-4">
												<input
													className="custom-file-input"
													id="logo"
													name="logo"
													type="file"
													onChange={(event) => {
														setFieldValue("logo", event.currentTarget.files[0]);
														if (event.currentTarget.files[0]) {
															this.setState({
																logo_file_name: event.target.files[0].name,
															});
														} else {
															this.setState({
																logo_file_name: "",
															});
														}
													}}
													accept=".jpg,.jpeg,.gif,.png"
												/>
												<label className="custom-file-label" htmlFor="logo">
													{this.state.logo_file_name}
												</label>
											</div>
										</div>
										<LogoPreview file={values.logo} />

										<br></br>
										<Button
											className="my-4"
											size="sm"
											type="submit"
											disabled={isSubmitting}
										>
											Enregistrer
										</Button>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(ModifyAgence);
