/* eslint-disable import/first */
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { withRouter } from "react-router-dom";
import { Table, Alert } from "react-bootstrap";
import * as config from "config/config";
import NavPublic from "components/nav/NavPublic";
const dateFormatConvert = require("helpers/dateFormatConvert");
const getColisInfos = require("helpers/getColisInfos");
const geolocalisation = require("helpers/geolocalisation");
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import MarkerWithInfoWindow from "helpers/markerWithInfoWindow";

/*CSS*/
import "asset/css/demandes.scss";

class DetailsColis extends Component {
	constructor() {
		super();
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			colis: [],
			date: "",
			groupement_id: "",
			messages: "",
			geolocalisation: [],
			centerLon: 0,
			centerLat: 0,
			title: "",
			showMap: false,
		};
	}

	componentDidMount() {
		let groupement_id = this.props.match.params.groupement_id;

		config
			.apiFetch(
				config.Api_Url + "/demande/getGroupementColis/" + groupement_id,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			)
			.then((response) => response.json())
			.then((data) => {
				if (data["data"].length != 0) {
					config
						.apiFetch(
							config.Api_Url +
								"/demande/getColisbyGroupementID/" +
								groupement_id,
							{
								method: "GET",
								headers: { "Content-Type": "application/json" },
							}
						)
						.then((response) => response.json())
						.then((data) => {
							this.setState({
								colis: [data],
							});
						});
					this.setState({
						date: dateFormatConvert.getFormattedDate(
							data["data"][0].datedemande
						),
						groupement_id: data["data"][0].groupement_id,
					});
					config
						.apiFetch(
							config.Api_Url +
								"/gps/getColisGeolocalisationByGroupementId/" +
								groupement_id,
							{
								method: "GET",
								headers: { "Content-Type": "application/json" },
							}
						)
						.then((response) => response.json())
						.then((data) => {
							if (data["data"].length) {
								this.setState({
									geolocalisation: [data],
									centerLon: data["data"][0].cg_lon,
									centerLat: data["data"][0].cg_lat,
									showMap: true,
								});
							}
						});
				} else {
					this.props.history.push("/404");
				}
			});
	}
	render() {
		return (
			<div>
				<NavPublic />
				<div className="container-fluid">
					<h1>
						Demande n° {this.state.groupement_id} le {this.state.date}
					</h1>
					<h2>{this.state.messages}</h2>

					<Table responsive bordered hover>
						<thead>
							<tr>
								<th></th>
								<th>N°Colis</th>
								<th>Code barre sous-traitant</th>
								<th>Poids (Kg)</th>
								<th>Nom Prénom Destinataire</th>
								<th>Adresse Destinataire</th>
								<th>Ville</th>
								<th>Téléphone</th>
								<th>Description</th>
								<th>Valeur assurée</th>
								<th>Infos Particulières</th>
								<th>Etat</th>
								<th>Date livraison</th>
								<th>Type colis</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.state.colis.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								return d[keys].map((data, index) => {
									let etat = getColisInfos.getColisState(data.etatlivraison);
									let raison = getColisInfos.getColisReason(data.raison);

									return (
										<tr key={data.colis_id}>
											<td>{index + 1}</td>
											<td className={data.statut}>{data.colis_id}</td>
											<td>{data.colis_id_ciblex}</td>
											<td>{data.poidscolis}</td>
											<td>
												{data.nomdest + "\n"}
												{data.prenomdest}
											</td>
											<td>{data.adressedest} </td>
											<td>
												{data.codepostaldest + "\n"}
												{data.villedest}
											</td>
											<td className="text-nowrap"> {data.telephonedest}</td>
											<td>{data.description}</td>
											<td>{data.valeur}</td>
											<td>{data.infosParticulieres}</td>
											<td>
												{" "}
												{etat} {raison ? "(" + raison + ")" : ""}
											</td>
											<td>
												{etat === "Livré" && data.datereleve
													? dateFormatConvert.getFormattedDateWithHms(
															data.datereleve
													  )
													: null}
												<br />
												{etat === "Livré" &&
												(data.nomliveur || data.prenomlivreur)
													? "par " + data.prenomlivreur + " " + data.nomliveur
													: null}
											</td>
											<td>{data.typeEnvoi}</td>
											<td>
												{data.colis_id_ciblex ? (
													<a
														href={
															"/public/colis/tracking/" + data.colis_id_ciblex
														}
													>
														En savoir plus
													</a>
												) : (
													""
												)}
											</td>
										</tr>
									);
								});
							})}
						</tbody>
					</Table>
				</div>
				{this.state.showMap ? (
					<LoadScript googleMapsApiKey={config.googleMapsApiKey}>
						<GoogleMap
							mapContainerStyle={{ width: "100%", height: "500px" }}
							center={{ lng: this.state.centerLon, lat: this.state.centerLat }}
							zoom={22}
						>
							{this.state.geolocalisation.map((dynamicData, Key) => {
								let keys = Object.keys(dynamicData);
								let d = dynamicData;
								let total = d[keys].length;
								return d[keys].map((data, index) => {
									let test = geolocalisation.generateOverlappingMarker(
										data.cg_lon,
										data.cg_lat,
										index,
										total
									);
									return (
										<MarkerWithInfoWindow
											key={data.cg_colis_id}
											position={{
												lng: test[0],
												lat: test[1] + 0.000005,
											}}
											cg_colis_id={data.cg_colis_id}
											info={data.cg_add}
										/>
									);
								});
							})}
						</GoogleMap>
					</LoadScript>
				) : (
					<div>
						<Alert variant="warning">
							Pas de position GPS disponible au moment de la livraison.
						</Alert>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(DetailsColis);
