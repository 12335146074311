import React, { Component, Input, useState } from "react";
import NavAdmin from "components/nav/NavAdmin";

import { Formik, submitForm, useFormikContext } from "formik";
import {
	Navbar,
	Nav,
	NavDropdown,
	Form,
	Button,
	Row,
	Col,
	Jumbotron,
	Table,
	Tr,
	Td,
	Pagination,
	ButtonToolbar,
	Collapse,
	Container,
} from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import * as config from "config/config";
import SearchForm from "./searchForm";

/*Css*/

export class FacturationHorsSite extends React.Component {
	constructor() {
		super();
		this.state = {
			clients: [],
			first_client: "",
			agences: [],
			data: [],
		};
	}

	//interval Min var
	currentIntervalleMin = 0;

	handleClick = () => {
		this.props.history.goBack();
	};
	componentDidMount() {
		/*AGENCE AREA */
		let user_role = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_role;
		let user_id = JSON.parse(localStorage.getItem("currentUser"))["data"][0]
			.aut_id;
		//IF ADMIN, we provide agence modify
		//If not we set agence by defaut of the account
		if (user_role == "Admin") {
			config
				.apiFetch("/agences")
				.then((response) => response.json())
				.then((data) => {
					let agences = [];
					for (let number = 0; number < data["data"].length; number++) {
						agences.push(
							<option
								key={"option_agences_" + number}
								value={data["data"][number]["agence_id"]}
							>
								{data["data"][number]["agence_name"]}
							</option>
						);
					}
					this.setState({
						agences: [agences],
					});
				});
		} else {
			config
				.apiFetch("/getAgenceByAccount/" + user_id)
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						data: data["data"][0],
					});
				});
		}
	}
	render() {
		return (
			<div>
				<NavAdmin />
				<div className="container">
					<Row className="justify-content-center">
						<Col md={6}>
							<h1 className="pb-5">Facturation Hors Site</h1>

							<SearchForm
								clients_list={this.state.clients_list}
								profil_list={this.state.profil_list}
								first_client={this.state.first_client}
								first_profil={this.state.first_profil}
								agences={this.state.agences}
								data={this.state.data}
							/>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(FacturationHorsSite);
